import styled from 'styled-components'
import tw from 'twin.macro'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Tooltip, withStyles } from '@material-ui/core'
import { SizeTitle } from '../../../components/containers'

export const StyledList = styled.ul`
  ${tw`list-disc list-inside p-3 border mt-2`}
  color: #8b8d99;
  background-color: #efefef;
  font-family: Varela Round;
  font-size: 14px;
  border-color: rgb(217, 217, 217);
`
export const InputHeader = styled.div`
  ${tw`uppercase w-full relative pb-1 ml-2`}
  color: #000000;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: bold;
`
export const StyledDatePicker = styled(KeyboardDatePicker)`
  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: none;
  }
  .MuiInput-underline::before {
    border-bottom: none;
  }
  .MuiInput-underline.Mui-disabled::before {
    border-bottom: none;
  }
  .MuiInputLabel-root {
    ${tw`uppercase w-full relative pb-1`}
    color: #000000;
    font-family: Montserrat;    
    font-weight: bold;
  }
  .MuiInput-formControl {
    ${tw`mt-0 pl-2 pt-1.5 pb-0.5`}
    height: 40px;
  }
  .MuiInputBase-root {
    ${tw`border`}
    width: 161px;
    font-size: 13px;
    font-family: Varela Round;
  }
  .MuiButtonBase-root {
    ${props => props.noButton && tw`hidden`}
  }
`

export const TimeAndDistance = props => {
  const SECONDS = 60;
  const METERS_IN_KM = 1000;
  const { duration, distance } = props;

  return (<div className='ml-5 mt-3 mb-5 w-full flex flex-col  justify-between text-xs'>
    <div>Trip Estimated Time: {duration ? Math.ceil(parseInt(duration) / SECONDS) + ' Min' : ''}</div>
    <div>Trip Estimated Distance: {distance ? Math.ceil(parseInt(distance) / METERS_IN_KM) + ' Km' : ''}</div>
  </div>)
  
}

export const SizeDescription = (props) => {
  const { sizeTitle, icon, sizeDescription, height } = props
  return (
    <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
      <div className='flex justify-center'>
        <SizeTitle>{sizeTitle}</SizeTitle>
        <img src={icon} alt={'car icon'} style={{ height: height, margin: '0px' }} />
      </div>

      <div className='flex justify-center'>
        <span className='text-center'>{sizeDescription}</span>
      </div>
    </div>
  )
}

export const StyledToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#efefef',
    maxWidth: '365px',
    padding: '20px',
    fontSize: '12px',
    color: 'black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  },
  arrow: {
    color: '#efefef',
  }
}))(Tooltip)


export const isValidCoordinate = (number) => {
  const notZero = number !== 0;
  const isNumber = !isNaN(number);

  return notZero && isNumber;
}
