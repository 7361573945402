import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import * as moment from 'moment';
// import { roundTime } from "../pages/DeeleeoDetails/components/PickupDropoffTimeSection";
// import { roundTime } from "../pages/DeeleeoDetails/components/PickupDropoffTimeSectionValet";
// import { createGlobalStyle } from "styled-components";



const StyledTextField = styled(TextField)`
  .MuiInput-underline.Mui-disabled::before {
    border-bottom: none;
  }
  .MuiInputBase-input.Mui-disabled {
    color: rgba(0,0,0,0.38);
  }
  .MuiInputBase-root {
    border: 1px solid #d9d9d9;
    padding: 0 8px;
    height: 40px;
    margin-right: 10px;
    margin-left: 10px;
    width: 120px;
  }
  .MuiInputBase-input {
    color: #3d405b;
    font-size: 13px;
    cursor: text;
  }
  .MuiInput-underline {
    &:before {
      border-bottom: none;
    }
  }
  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: none;
  } 
`

// const DatePickerWrapperStyles = createGlobalStyle`
//     .date-picker-custom {
//         width: 100px !important;
//     }
// `;

const DateTimePickerValet = (props) => {
  const { defaultValue, id, onChange, disabled, minTime, maxTime, timeClassName } = props;
  const format = 'HH:mm';
  
  // const [theDate, setTheDate] = useState(defaultValue);




  const handleChange = (date, e) => {
    // setTheDate(date)



    const selected = moment(date.getTime());

    onChange(selected.format(format));
  }


  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (

    <StyledTextField
      ref={ref}
      onClick={onClick}
      value={value}
    />

    // <button className="example-custom-input" onClick={onClick} ref={ref}>
    //     {value}
    // </button>
  ));


  const emptyFunction = () => { };
  


  //https://github.com/Hacker0x01/react-datepicker
  //https://date-fns.org/v2.0.0-alpha.18/docs/I18n
  //https://date-fns.org/v2.0.0-alpha.18/docs/format

  return (<DatePicker
    id={id}
    disabled={disabled}
    selected={defaultValue}
    onChange={(date, e) => handleChange(date, e)}
    showTimeSelect
    timeIntervals={15}
    timeCaption="Time"
    dateFormat={format}
    showTimeSelectOnly
    customInput={<ExampleCustomInput />}
    value={defaultValue}
    wrapperClassName='date-picker-custom'
    minTime={minTime}
    maxTime={maxTime}
    timeClassName={timeClassName ? timeClassName : emptyFunction}
  />);
}

export default DateTimePickerValet;