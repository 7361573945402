import React, { useEffect, useState } from 'react'
import PageLayout from '../PageLayout'
import { connect } from 'react-redux'
import { DashboardContainer, Divider } from '../Shipment/ShipmentDetails'
import { Flex } from '../../components/containers'
import { SectionHeader } from '../../components/text'
import DeeleeoTable from './components/DeeleeoTable'
import { cancelDeeleeo, fetchAllDeeleeos } from '../../redux/actions/dashboardActions'
import styled from 'styled-components'
import tw from 'twin.macro'
// import { routes } from '../../routes'
import { withRouter } from 'react-router-dom'
import DeeleeoTabs from '../../components/DeeleeoTabs'
import { clearDeeleeoData } from '../../redux/reducers/deeleeoReducer'
import { clearDropoffData } from '../../redux/reducers/pickupDropoffReducer'
import { updateProcessStatus } from '../../redux/reducers/deeleeosReducer'
import { useRecoilState } from 'recoil'
import { apiKeyAtom, dashboardPageIndex, dashboardPageSize, dashboardSearchDeeleeoStatus, dashboardSearchingDate, dashboardSearchingTerm, useQuery, userDeeleeos } from '../../App'
// import { getURI } from '../../helpers/apiHelpers'
// import { result } from 'lodash'
import LabelWarningModal from './components/LabelWarningModal'
import OrderSelectorModal from './components/OrderSelectorModal'


export const SmallDivider = styled.hr`
  ${tw`mb-4`}
`

function Dashboard(props) {
  const { apiCode, fetchAllDeeleeos, submittedDeeleeos, doneDeeleeos, sharedDeeleeos, cancelDeeleeo, clearDeeleeoData, clearDropoffData, updateProcessStatus } = props

  /**
   * Used to gather query parameters
   */
  let query = useQuery();

  const [deeleeos, setDeeleeos] = useRecoilState(userDeeleeos);//only store the amount of filtered deeleeos and draw.
  const [dashboardIndex, setDasboardIndex] = useRecoilState(dashboardPageIndex);
  // eslint-disable-next-line no-unused-vars
  const [dashboardSize, setDasboardSize] = useRecoilState(dashboardPageSize);
  // eslint-disable-next-line no-unused-vars
  const [searchingTerm, setSearchingTerm] = useRecoilState(dashboardSearchingTerm);

  // eslint-disable-next-line no-unused-vars
  const [searchingDate, setSearchingDate] = useRecoilState(dashboardSearchingDate);
  // eslint-disable-next-line no-unused-vars
  const [searchingStatus, setSearchingStatus] = useRecoilState(dashboardSearchDeeleeoStatus);
  // eslint-disable-next-line no-unused-vars
  const [apiKey, setApiKey] = useRecoilState(apiKeyAtom);
  const [deeleeoView, setDeeleeoView] = useState(1); //1:Active deeleeos, 2:on hold, 3: done
  const [deeleeosForTable, setDeeleeosForTable] = useState([]);

  const [viewMap, setViewMap] = useState(true);

  const mapButtonLabel = viewMap ? 'Hide Map' : 'Show Map';

  const [activeDeeleeos, setActiveDeeleeos] = useState(0);

  useEffect(() => {

    if (deeleeoView === 1) {

      if (doneDeeleeos?.data) {
        setActiveDeeleeos(doneDeeleeos?.data?.length);
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doneDeeleeos?.data?.length])


  try {
    /**
     * Get's the view value from query parameters
     */
    const view = parseInt(query.get('view'));
    if (!isNaN(view) && deeleeoView !== view) {
      setDeeleeoView(view);
    }
  } catch (e) {
    console.log(e);
  }


  const [selectedRows, setSelectedRows] = useState([]);
  const [labelModal, setLabelModal] = useState(false);
  const [orderedLAbelsModal, setOrderedLabelsModal] = useState(false);
  const [ids, setIds] = useState([]);


  // setApiKey(apiCode);



  useEffect(() => {
    if (submittedDeeleeos || doneDeeleeos || sharedDeeleeos) {
      //console.log('setting deeleeos');

      if (deeleeoView === 1) {
        setDeeleeos({ recordsFiltered: doneDeeleeos.recordsFiltered, draw: doneDeeleeos.draw });
        if (doneDeeleeos.data) {
          setDeeleeosForTable(doneDeeleeos.data);
        }
      }



      if (deeleeoView === 2) {
        setDeeleeos({ recordsFiltered: sharedDeeleeos.recordsFiltered, draw: sharedDeeleeos.draw });
        if (sharedDeeleeos.data) {
          setDeeleeosForTable(sharedDeeleeos.data);
        }
      }

      if (deeleeoView === 3) {
        setDeeleeos({ recordsFiltered: submittedDeeleeos.recordsFiltered, draw: submittedDeeleeos.draw });
        if (submittedDeeleeos.data) {
          setDeeleeosForTable(submittedDeeleeos.data);
        }
      }

    }
  }, [submittedDeeleeos?.data?.length, doneDeeleeos?.data?.length, sharedDeeleeos?.data?.length, submittedDeeleeos, doneDeeleeos, sharedDeeleeos, deeleeoView, setDeeleeos]);



  useEffect(() => {

    fetchAllDeeleeos(getPayload());

    /**
     * Onloading go to the top of the page
     */
    window.scrollTo(0, 0);

    /**
     * Any of these variables needs to trigger this useEffect
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardIndex, dashboardSize, searchingTerm, searchingStatus, deeleeoView, searchingDate])

  useEffect(() => {
    // console.log(deeleeoView);
    setDasboardIndex(0);
    //window.location.reload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeleeoView]);


  const getPayload = () => {
    let payload = {
      api_key: apiCode
    };

    setApiKey(apiCode);

    if (deeleeos.draw) {
      try {
        payload.draw = parseInt(deeleeos.draw);
      } catch (error) {
        console.log(error);
      }
    }

    payload.length = dashboardSize;

    payload.start = dashboardSize * dashboardIndex;
    if (searchingTerm) {
      payload.searching = searchingTerm;
    }

    if (searchingDate) {
      payload.searchingDate = searchingDate;
    }

    if (searchingStatus) {
      payload.status = searchingStatus;
    }

    payload.deeleeoView = deeleeoView

    return payload;
  }





  const downloadAllLabels = (e) => {
    //console.log(e);
    e.preventDefault();
    //const order = 'desc';
    /**
     * Build array of selected deeleeos
     */
    const ids = selectedRows.map(row => {
      const deeleeoId = row.original.deeleeoId;
      let result;
      const resultArray = deeleeoId.split('-');
      if (resultArray.length > 1) {
        result = parseInt(resultArray[1]);
      } else {
        result = parseInt(resultArray[0]);
      }
      return result;
    });

    /**
     * Send ids or not
     */
    if (ids.length > 0) {
      setIds(ids);
      setOrderedLabelsModal(true);
    } else {

      setLabelModal(true);
    }


  }


  useEffect(() => {
    clearDeeleeoData();
    clearDropoffData();
    updateProcessStatus('');
    // fetchAllDeeleeos(getPayload());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCode, clearDeeleeoData, clearDropoffData, updateProcessStatus])

  return (
    <PageLayout wider>
      <DeeleeoTabs selectedTab={2} />
      <Divider />
      <DashboardContainer>
        <Flex >
          <SectionHeader>
            My Deeleeos
            <p className='mt-1' style={{ fontSize: '12px', color: '#3d405b' }}>
              Download the Deeleeo mobile app today and manage your Deeleeos on the go.
            </p>
          </SectionHeader>
          {/* <ThinButton right onClick={() => history.push(routes.multiDeeleeo)}>Multi Deeleeo Batch</ThinButton> */}

        </Flex>
        <SmallDivider />

        <Flex>
          <DeeleeoTable
            deeleeos={deeleeosForTable}
            cancelDeeleeo={cancelDeeleeo}
            apiCode={apiCode}
            fetchAllDeeleeos={fetchAllDeeleeos}
            getPayloadDeeleeos={getPayload}
            pushSelectedRows={setSelectedRows}
            deeleeoView={deeleeoView}
            setDeeleeoView={setDeeleeoView}
            viewMap={viewMap}
            setViewMap={setViewMap}
            mapButtonLabel={mapButtonLabel}
            downloadAllLabels={downloadAllLabels}
            activeDeeleeos={activeDeeleeos}
          />
        </Flex>
        <LabelWarningModal
          contentLabel={'Label Warning Modal'}
          modalIsOpen={labelModal}
          setModalIsOpen={setLabelModal}
        ></LabelWarningModal>
        <OrderSelectorModal
          contentLabel={'Label Selector Modal'}
          modalIsOpen={orderedLAbelsModal}
          setModalIsOpen={setOrderedLabelsModal}
          apiCode={apiCode}
          ids={ids}
        ></OrderSelectorModal>
      </DashboardContainer>
    </PageLayout>
  )
}

const mapStateToProps = state => ({
  apiCode: state.userReducer.apiCode,
  submittedDeeleeos: state.dashboardReducer.submittedDeeleeos,
  doneDeeleeos: state.dashboardReducer.doneDeeleeos,
  sharedDeeleeos: state.dashboardReducer.sharedDeeleeos,
})

const mapDispatchToProps = dispatch => ({
  fetchAllDeeleeos: (requestData) => dispatch(fetchAllDeeleeos(requestData)),
  cancelDeeleeo: (requestData) => dispatch(cancelDeeleeo(requestData)),
  clearDeeleeoData: () => dispatch(clearDeeleeoData()),
  clearDropoffData: () => dispatch(clearDropoffData()),
  updateProcessStatus: (data) => dispatch(updateProcessStatus(data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
