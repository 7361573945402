import { getURI } from '../../helpers/apiHelpers'

export const orderActions = Object.freeze({
  UPDATE_ORDER: 'UPDATE_ORDER',
  UPDATE_DEELEEO: 'UPDATE_DEELEEO',
  ADD_DEELEEO: 'ADD_DEELEEO',
  DELETE_DEELEEO: 'DELETE_DEELEEO',
  GET_PRICE_REQUEST: 'GET_PRICE_REQUEST',
  GET_PRICE_SUCCESS: 'GET_PRICE_SUCCESS',
  GET_PRICE_FAILURE: 'GET_PRICE_FAILURE',
  CREATE_DEELEEO_REQUEST: 'CREATE_DEELEEO_REQUEST',
  CREATE_DEELEEO_SUCCESS: 'CREATE_DEELEEO_SUCCESS',
  CREATE_DEELEEO_FAILURE: 'CREATE_DEELEEO_FAILURE',
  CLEAR_DEELEEO_REQUEST: 'CLEAR_DEELEEO_REQUEST',
  UPLOAD_IMAGE_TO_SERVER_REQUEST: 'UPLOAD_IMAGE_TO_SERVER_REQUEST',
  UPLOAD_IMAGE_TO_SERVER_SUCCESS: 'UPLOAD_IMAGE_TO_SERVER_SUCCESS',
  UPLOAD_IMAGE_TO_SERVER_FAILURE: 'UPLOAD_IMAGE_TO_SERVER_FAILURE',
  CLEAR_ORDER: 'CLEAR_ORDER',
})

export const clearOrder = () => {
  return {
    type: orderActions.CLEAR_ORDER
  }
}

export const addDeeleeo = (deeleeo) => {
  return {
    type: orderActions.ADD_DEELEEO,
    data: deeleeo
  }
}

export const updateDeeleeo = (deeleeo, index) => {
  return {
    type: orderActions.UPDATE_DEELEEO,
    data: { deeleeo, index }
  }
}

export const deleteDeeleeo = (deeleeo) => {
  return {
    type: orderActions.DELETE_DEELEEO,
    data: deeleeo
  }
}

export const clearDeeleeoRequest = () => ({ type: orderActions.CLEAR_DEELEEO_REQUEST })

export const createDeeleeo = (requestData) => dispatch => {
  const request = () => ({ type: orderActions.CREATE_DEELEEO_REQUEST })
  const success = (data) => ({ type: orderActions.CREATE_DEELEEO_SUCCESS, data: data })
  const failure = (error) => ({ type: orderActions.CREATE_DEELEEO_FAILURE, data: error })
  dispatch(request())

  fetch(getURI('/api/web/deeleeo'), {
    method: 'POST',
    body: JSON.stringify({
      item_name: requestData.item_name,
      api_key: requestData.api_key,
      items: requestData.items,
      weight_category_id: requestData.weight_category_id,
      size_category_id: requestData.size_category_id,
      promo_code_id: requestData.promo_code_id,
      business_pickup_name: requestData.business_pickup_name,
      pickup_street_address: requestData.pickup_street_address,
      pickup_address_province: requestData.pickup_address_province,
      pickup_address_city: requestData.pickup_address_city,
      dropoff_street_address: requestData.dropoff_street_address,
      dropoff_address_province: requestData.dropoff_address_province,
      dropoff_address_city: requestData.dropoff_address_city,
      dropoff_latitude: requestData.dropoff_latitude,
      dropoff_longitude: requestData.dropoff_longitude,
      recipient_name: requestData.recipient_name,
      recipient_email: requestData.recipient_email,
      recipient_phone: requestData.recipient_phone,
      special_instructions: requestData.special_instructions,
      pickup_type: requestData.pickup_type,
      pickup_early_datetime: requestData.pickup_early_datetime,
      pickup_later_datetime: requestData.pickup_later_datetime,
      dropoff_type: requestData.dropoff_type,
      dropoff_early_datetime: requestData.dropoff_early_datetime,
      dropoff_later_datetime: requestData.dropoff_later_datetime,
      load_needs: requestData.load_needs,
      unload_needs: requestData.unload_needs
    }),
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then(response => {
      if (response.status !== 200) {
        dispatch(failure('Unable to create Deeleeo'))
        throw new Error('Unable to create Deeleeo')
      } else {
        return response.text()
      }
    })
    .then(response => {
      const parsedRes = JSON.parse(response)
      dispatch(success({
        deeleeoId: parsedRes.deeleeo.id
      }))
      return parsedRes.deeleeo.id
    })
    .then(deeleeoId => {
      let index = 0
      requestData.images.forEach(image => {

        image.append("deeleeo_id", deeleeoId)
        image.append("api_key", requestData.api_key)

        if (index === 0) image.append("is_main_image", 1)
        else image.append("is_main_image", 0)

        index++
        return dispatch(uploadImageToServer(image))
      })
    })
    .catch(err => console.log(err))
}

export const getPrice = (requestData, index) => dispatch => {
  const request = () => ({ type: orderActions.GET_PRICE_REQUEST })
  const success = (data) => ({ type: orderActions.GET_PRICE_SUCCESS, data: data })
  const failure = (error) => ({ type: orderActions.GET_PRICE_FAILURE, data: error })

  dispatch(request())

  fetch(getURI('/api/user/business/pricefromdimension'), {
    method: 'POST',
    body: JSON.stringify(requestData),
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then(response => response.text())
    .then(response => {
      const { subtotal, premium, delivery, gst } = JSON.parse(response)
      dispatch(success({ subtotal, premium, delivery, gst, index }))
    })
    .catch(e => dispatch(failure(e)))
}

export const uploadImageToServer = (requestData) => dispatch => {
  // console.log('did we make it', requestData)
  const request = () => ({ type: orderActions.UPLOAD_IMAGE_TO_SERVER_REQUEST })
  const success = (data) => ({ type: orderActions.UPLOAD_IMAGE_TO_SERVER_SUCCESS, data: data })
  const failure = (error) => ({ type: orderActions.UPLOAD_IMAGE_TO_SERVER_FAILURE, data: error })

  dispatch(request())

  fetch(getURI('/api/user/postDeeleeoImage'), {
    method: 'POST',
    body: requestData,
    headers: {
    }
  })
    .then(response => response.text())
    .then(response => {      
      dispatch(success())
    })
    .catch(e => dispatch(failure(e)))
}
