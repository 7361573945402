import React, { useEffect } from 'react'
import { PageLink, ResetPasswordButton, SubTextNoMargin, TitleText } from '../../Register/components/shared'
import FloatingInputField from '../../../components/FloatingInputField'
import { routes } from '../../../routes'
import { FieldContainer } from '../../../components/containers'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { ForgotStatus } from '../../../redux/reducers/authReducer'

function EnterEmail(props) {
  const { forgotStatus, forgotPassword, forgotError } = props

  const validationSchema = yup.object({
    email: yup.string().email('Enter a valid email address').required('Email is required'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values => {
      formik.setErrors({ email: '' })
      const data = {
        email: values.email,
      }
      forgotPassword(data)
    })
  })

  useEffect(() => {
    if (forgotStatus === ForgotStatus.ForgotError) {
      formik.setErrors({ email: forgotError })
    } else {
      formik.setErrors({ email: '' })
    }
  }, [forgotError, forgotStatus, formik])

  return (
    <FieldContainer noMargin className='justify-center h-full' onSubmit={formik.handleSubmit}>
      <TitleText>Reset your password</TitleText>
      <SubTextNoMargin className='mb-12 mt-2'>Just enter your email and we'll send you instructions</SubTextNoMargin>
      <FloatingInputField required id={'email'} label={'Email'}
                          error={(formik.touched.email && Boolean(formik.errors.email))}
                          helperText={formik.touched.email && formik.errors.email}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}/>
      <ResetPasswordButton type='submit'>Reset my password</ResetPasswordButton>
      <div className='mt-8'>Back to <PageLink to={routes.login}>Log in</PageLink></div>
    </FieldContainer>
  )
}

export default EnterEmail
