import { pickupActions } from '../actions/pickupActions'

const INITIAL_STATE = {
  pickupLocation: {
    address: '',
    street: '',
    appt: '',
    city: '',
    province: '',
    postalCode: ''
  },
  pickupHours: {
    sunday: {start: '09:00', end: '16:00'},
    monday: {start: '09:00', end: '16:00'},
    tuesday: {start: '09:00', end: '16:00'},
    wednesday: {start: '09:00', end: '16:00'},
    thursday: {start: '09:00', end: '16:00'},
    friday: {start: '09:00', end: '16:00'},
    saturday: {start: '09:00', end: '16:00'},
  }
}

export const pickupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pickupActions.UPDATE_PICKUP_LOCATION:
      return {
        ...state, pickupLocation: { ...state.pickupLocation, ...action.data }
      }
    case pickupActions.UPDATE_PICKUP_HOURS:
      return {
        ...state, ...action.data
      }

    default: return state
  }
}
