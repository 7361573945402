import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { connect } from "react-redux";
import { updateSizeSelection } from '../../../redux/reducers/deeleeoReducer'
import { validateSizeWeightSelection } from "../../../redux/reducers/validationReducer";

// const BaseContainer = styled.div`
//   ${tw`text-lightgrey mt-3`}
//   font-family: Montserrat;
//   font-size: 12px;
// `;

export const convertKGtoLBS = (kg, roundTo = 5) => {
  // Convert kg to lbs (1 kg = 2.20462 lbs)
  let lbs = kg * 2.20462;

  // Round to the nearest 5 or 10
  lbs = Math.round(lbs / roundTo) * roundTo;

  return lbs;
}

const ButtonToggle = styled.button`
  ${tw`rounded text-fontBlue uppercase w-11/12 my-2`}
  background-color: #efefef;
  border: 1px solid #d9d9d9;
  ${(props) => props.selected && tw`bg-secondaryOrange text-white border-none`}
  height: 44px;
  font-family: Varela Round;
  font-size: 13px;
`;

const SizingButton = (props) => {
  const { validateSizeWeightSelection, sizeData, updateSizeSelection, deeleeoDetail, validation } = props;
  // const [isSelected, setIsSelected] = useState('')

  const onClickHandler = (() => {
    updateSizeSelection(sizeData.id, sizeData.weight_category[0].id)

    if(!validation){
      validateSizeWeightSelection(true);
    }
  })

  return <ButtonToggle className="mx-2" onClick={onClickHandler} selected={deeleeoDetail.size_category_id === sizeData.id ? true : ''}>
    {sizeData.name}
    <p>
      {/* {sizeData.weight_category[0].low === 0 ? */}
         {/* <span>Less Than </span> : */}
        <span>{sizeData.weight_category[0].low} - </span>
       {/* } */}
      <span>{sizeData.weight_category[0].high} kg</span>
      <span> - ({convertKGtoLBS(sizeData.weight_category[0].low)} - </span>
      <span> {convertKGtoLBS(sizeData.weight_category[0].high)} lbs)</span>
    </p>
  </ButtonToggle>;
};

const mapStateToProps = state => ({
  validation: state.validationReducer.size_weight_selection
})

const mapDispatchToProps = dispatch => ({
  updateSizeSelection: (size_id, weight_id) => dispatch(updateSizeSelection(size_id, weight_id)),
  validateSizeWeightSelection: (data) => dispatch(validateSizeWeightSelection(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SizingButton);