import React, { Fragment, useEffect, useState } from "react";
import { Button, ThinButton } from "../../../components/buttons";
import * as XLSX from 'xlsx';
import { IndeterminateCheckBox, CheckBoxTwoTone } from "@material-ui/icons";
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable, useRowSelect } from "react-table";
import tw from "twin.macro";
import styled from 'styled-components'
import axios from "axios";
import { getURI } from "../../../helpers/apiHelpers";
import UpdateAddressModal from "./UpdateAddressModal";
import { getValForType } from "../../../components/AddressField";
import { isw3wAddress, StyledToolTip } from "../../../components/What3WordsField";
import { getDetails } from "use-places-autocomplete";
import { SectionHeader } from "../../../components/text";
import InfoIcon from "../../../assets/icons/Info-icon.svg";
import GenericModal from "../../Dashboard/components/GenericModal";
import MyFleetSelectorForTable from "./MyFleetSelectorForTable";
import { Flex } from "../../../components/containers";
import { HtmlTooltip, StyledLabel } from "../../../components/InputField";
import { createCookie, readCookie } from "../../../helpers/cookieHelper";
import { fileAlertText } from "../Choose";
import { v4 as uuidv4 } from 'uuid';
import { findDeeleeo } from "../../../helpers/requestHelpers";


const Styles = styled.div`
  ${tw`w-full mt-4 text-fontBlue `}
  table {
    ${tw`w-full`}
  }

  tr {
    ${tw`border-b-2 border-gray-300`}
    td {
      height: 67px;
    }
  }

  th {
    ${tw`text-primaryBlue font-bold uppercase text-left`}
    font-family: Montserrat;
    font-size: 13px;
    height: 32px;
    white-space:nowrap;
    padding: 0 5px;
  }

  td {
    font-size: 14px;
    font-family: Varela Round;
  }
`



export const RowContainer = styled.div`
  ${tw` w-full flex flex-wrap`}
`

export const EditButton = styled(Button)`
  ${tw`ml-auto my-auto`}
  height: 36px;
  width:85px;
  font-size: 14px;
  margin : 5px;
  white-space:nowrap;
`

const AddressFileUpload = (props) => {


    const { userData, regions, fileData, setFileData, updateDropoff } = props;

    const [state, setState] = useState({
        editing: false,
        editingRow: {},
        parameter: {},
        fileUploadModal: false,
        validateModal: false,
        myFleetDrivers: [],
        myFleetDriverSelected: null,
        invalidDataModal: false,
        editingConfirmed: false,
        editingFinished: false,
        validationPromises: [],
        addressValidationModal: false,
        addressValidationSent: false,
        startValidation: false,
        sentValidationCounter: 0,
        receivedValidationCounter: 0

    });

    const setEditing = (data) => {
        setState(state => ({ ...state, editing: data }));
    }

    const setEditingRow = (data) => {
        setState(state => ({ ...state, editingRow: data }));
    }

    const setParameter = (data) => {
        setState(state => ({ ...state, parameter: data }));
    }

    const setFileUploadModal = (data) => {
        setState(state => ({ ...state, fileUploadModal: data }));
    }

    const setValidateModal = (data) => {
        setState(state => ({ ...state, validateModal: data }));
    }

    const setMyFleetDrivers = (data) => {
        setState(state => ({ ...state, myFleetDrivers: data }));
    }

    // eslint-disable-next-line no-unused-vars
    const setMyFleetDriverSelected = (data) => {
        setState(state => ({ ...state, myFleetDriverSelected: data }));
    }

    const setInvalidDataModal = (data) => {
        setState(state => ({ ...state, invalidDataModal: data }));
    }

    const setEditingConfirmed = (data) => {
        setState(state => ({ ...state, editingConfirmed: data }));
    }

    const setEditingFinished = (data) => {
        setState(state => ({ ...state, editingFinished: data }));
    }

    const setValidationPromises = (data) => {
        setState(state => ({ ...state, validationPromises: data }));
    }

    const setAddressValidationModal = (data) => {
        setState(state => ({ ...state, addressValidationModal: data }));
    }

    const setAddressValidationSent = (data) => {
        setState(state => ({ ...state, addressValidationSent: data }));
    }

    const setStartValidation = (data) => {
        setState(state => ({ ...state, startValidation: data }));
    }

    const setSentValidationCounter = (data) => {
        setState(state => ({ ...state, sentValidationCounter: data }));
    }

    const setReceivedValidationCounter = (data) => {
        setState(state => ({ ...state, receivedValidationCounter: data }));
    }




    const confirmEditing = () => {
        setEditing(false);
        setEditingConfirmed(true);
    }



    useEffect(() => {

        if (userData.isMyFleetEnabled) {

            axios.get(getURI(`/api/web/my-fleet?api_code=${userData.apiCode}`))
                // axios.get(getURI(`/api/web/my-fleet?api_code=bb80bf8b-9b29-20f0-37c8-0fd3d09950`))
                .then(data => {

                    setMyFleetDrivers(data.data.data.drivers);
                    // setMyFleetCode(data.data.my_fleet_code);
                })
                .catch(error => {
                    console.log(error);
                });


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.myFleetDrivers.length]);

    useEffect(() => {


        if (state.validationPromises.length > 0) {
            let counter = 0;
            Promise.all(state.validationPromises)
                .then(results => {
                    if (results.length > 0) {


                        const dataAccumulator = [];
                        results.forEach(data => {
                            //  console.log(data.data);
                            // setTimeout(() => {


                            const { lat, lng, formatted_address, uuid, city } = data.data;


                            let zone = null;
                            if (data.data.zone) {
                                zone = data.data.zone;
                            }
                            let checkRadius = false;

                            setAddressValidationModal(true);

                            /**
                             *  Validate address now has a city property if it has a name, it means the city is valid
                             */
                            if (city) {
                                if (city.name) {
                                    checkRadius = true;
                                }
                            }

                            let element = findDeeleeo(uuid, fileData);
                            if (!checkRadius || !element) {
                                setValidateModal(true);
                                // dataAccumulator.push(element);
                            } else {
                                element['valid'] = true;
                                element['latitude'] = lat;
                                element['longitude'] = lng;
                                let cityFile = formatAddressElement(element['city']);
                                let provinceFile = formatAddressElement(element['province']);
                                element['address'] = formatted_address;
                                element['city'] = cityFile;
                                element['province'] = provinceFile;

                                if (zone) {
                                    element['zone'] = zone;
                                    //
                                }

                                // dataAccumulator.push(element);
                            }

                            dataAccumulator.push(element);
                            counter++;

                            setReceivedValidationCounter(counter);
                            setAddressValidationModal(true);

                            // }, 100);

                        });

                        const firstValidElement = dataAccumulator.find(e => e.zone);

                        if (firstValidElement) {
                            updateDropoff({ latitude: firstValidElement.latitude, longitude: firstValidElement.longitude });
                        }


                        setFileData(dataAccumulator);
                        // setAddressValidationModal(false);
                    }
                })
                .catch(error => {

                    console.log(error);
                    setAddressValidationModal(false);

                })
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.addressValidationSent])

    useEffect(() => {
        if (fileData.length > 0 && state.startValidation) {
            setAddressValidationModal(false);
            // setAddressValidationSent(false);
            let counter = 0;
            const validationRequests = fileData
                .filter((item, index) => {
                    return !item.valid;
                })
                .map((item, index) => {




                    // 

                    /**
                     * Only not validated items
                     */
                    item.api_code = userData.apiCode;
                    if (!item.valid) {
                        counter++;
                        setSentValidationCounter(counter);
                        // console.log('sentValidationCounter: ', counter);

                        return axios.post(getURI('/api/web/validate-address'), item);

                    } else {
                        return null;
                    }
                })
                .filter(item => item);

            setValidationPromises(validationRequests);
            setAddressValidationModal(true);
            setTimeout(() => {
                setAddressValidationSent(true);
                setStartValidation(false);
            }, 1000);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.startValidation])

    const hiddenFileInput = React.useRef(null);

    const handleClick = (event) => {

        const cookieName = 'newFileUploadButton-1';

        const counts = readCookie(cookieName);
        if (counts === '') {
            createCookie(cookieName, 1, 360);
            alert(fileAlertText);
        } else {
            let displayedTimes = parseInt(counts);
            if (displayedTimes < 2) {
                displayedTimes++;
                createCookie(cookieName, displayedTimes, 360);
                alert(fileAlertText);
            }
        }

        //console.log('handling click: ');
        //console.log(event);
        hiddenFileInput.current.click(event);
    };

    /**
     * Capitalizes first letter of the string
     * @param {String} element 
     * @returns 
     */
    const formatAddressElement = (element) => {
        try {
            element = element.toLowerCase();
            return element[0].toUpperCase() + element.substring(1);
        } catch (e) {
            console.log(e);
        }
    }


    const handleValidateClick = (event) => {        
        setStartValidation(true);
    };

    const handleChange = (event) => {
        if (event?.target?.files[0]) {
            const file = event.target.files[0];


            const reader = new FileReader();
            reader.onload = (event) => {
                if (!event.target) {
                    return;
                }
                const binaryString = event.target.result;
                const workBook = XLSX.read(binaryString, { type: 'binary' })
                const workSheetName = workBook.SheetNames[0];
                const workSheet = workBook.Sheets[workSheetName];
                const data: any[] = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
                const mappedData = data
                    .filter((item, index) => {
                        if (index === 0 || index === 1) {
                            return false;
                        }
                        // console.log(item);

                        /**
                         * Only handle items that have the address components and package count
                         */
                        if (((item[2] && item[3] && item[4]) || item[8]) && item[11]) {
                            return true;
                        }

                        /**
                         * Only set it to true if the first column has data. Some files are read with empty rows, we don't care about those to trigger this modal
                         */
                        if (item[1]) {
                            console.log('invalid data ', index, item);

                            setInvalidDataModal(true);
                        }


                        return false;
                    })
                    .map((item) => {

                        // console.log('item from file: ');                        
                        //  console.log(item);

                        let phone = '';
                        try {
                            /**
                             * Let's make it a string first. That way we can treat it always as a string to check for dashes and all that.
                             * 
                             */
                            item[7] = item[7] + '';
                            //https://devblogs.microsoft.com/oldnewthing/20150506-00/?p=44924
                            //https://stackoverflow.com/questions/10805125/how-to-remove-all-line-breaks-from-a-string
                            //https://stackoverflow.com/questions/60357971/remove-replace-unicode-characters-javascript
                            //https://www.soscisurvey.de/tools/view-chars.php

                            /**
                             * Some b%T#$^#%$^&#^%$& hidden chars in some excel files sent by the users.
                             */
                            phone = item[7].replaceAll('.', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').trim().replace(/^\s+|\s+$/g, '').replace(/\r?\n|\r/g, '').replace(/\u202d/g, '').replace(/\u202c/g, '');

                        } catch (e) {
                            console.log('empty phone, it is ok.');
                        }

                        if (item[10] && item[10].length > 0) {
                            if (item[9]) {
                                item[9] = ` ${item[9]} Item description: ${item[10]}`
                            } else {
                                item[9] = `Item description: ${item[10]}`
                            }

                        }



                        return {
                            name: item[0],
                            unitNumber: item[1],
                            address: item[2],
                            city: item[3],
                            province: item[4],
                            postal: item[5],
                            email: item[6],
                            phone: phone,
                            w3w: item[8],
                            item_name: item[0],
                            details: item[9],
                            package_count: item[11],
                            valid: false,
                            uuid: uuidv4(),
                        };
                    });

                setFileData(mappedData);
                setFileUploadModal(true);
            }

            reader.readAsBinaryString(file);
        }
    }

    /**
     * Important, this value needs to be the react-table row
     * @param {*} value 
     */
    const editRow = (value) => {


        setEditingRow(value);
        setEditing(true);
    }

    const doUpdate = (data) => {

        // console.log('data to update before', data);
        const sliced = fileData.slice();

        const index = state.editingRow.index;


        if (data.w3w || data.address) {
            if (data.latitude && data.longitude) {


                if (isw3wAddress(data.w3w)) {

                    sliced[index]['valid'] = true;
                    sliced[index]['latitude'] = data.latitude;
                    sliced[index]['longitude'] = data.longitude;
                    sliced[index]['address'] = data.w3w;
                    setFileData(sliced);

                } else {
                    getDetails(state.parameter)
                        .then((details) => {
                            const city = getValForType('locality', details);
                            const province = getValForType('administrative_area_level_1', details);
                            const address = data.address;

                            sliced[index]['valid'] = true;
                            sliced[index]['latitude'] = data.latitude;
                            sliced[index]['longitude'] = data.longitude;
                            sliced[index]['address'] = address;
                            sliced[index]['city'] = city;
                            sliced[index]['province'] = province;
                            // console.log(sliced);
                            setFileData(sliced);
                        });

                }

                /**
                 * Update the unit
                 */
                if (data.unit) {
                    sliced[index]['unitNumber'] = data.unit;
                    setFileData(sliced);
                }

                /**
                 * Update package count
                 */
                if (data.packages) {
                    sliced[index]['package_count'] = data.packages;
                }
                setEditingRow({});
                setEditing(false);
                setEditingConfirmed(false)
                setEditingFinished(true);
                // console.log('before updating drop off: ', data);

                if (data.zone) {
                    updateDropoff({ latitude: data.latitude, longitude: data.longitude });
                }

            }
        }
    }

    const getColumns = () => {
        let columns = [{
            Header: 'Order Number',
            accessor: 'item_name',
            Cell: ({ value }) => <strong className='mr-4'>{value}</strong>
        }, {
            Header: 'Details',
            accessor: 'details',
            Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }}></span>
        }, {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }}></span>
        }, {
            Header: 'Unit',
            accessor: 'unitNumber',
            Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }}></span>
        }, {
            Header: 'Address',
            accessor: 'address',
            Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }}></span>
        }, {
            Header: 'City',
            accessor: 'city',
            Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }}></span>
        }, {
            Header: 'Postal',
            accessor: 'postal',
            Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }}></span>
        }, {
            Header: 'what3words',
            accessor: 'w3w',
            Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }}></span>
        },
        {
            Header: 'Packages',
            accessor: 'package_count',
            Cell: ({ value }) => <div dangerouslySetInnerHTML={{ __html: value }} style={{ textAlign: 'center' }}></div>
        },
        {
            Header: 'Action',
            accessor: '[row identifier to be passed to button]',
            Cell: ({ row }) => (
                <div>
                    <EditButton onClick={e => editRow(row)}>Edit</EditButton>
                </div>
            )
        },
        {
            Header: 'Valid',
            accessor: 'valid',
            Cell: ({ value }) => {
                //console.log(value);
                return (<div>
                    {value ? <CheckBoxTwoTone className="float-right" style={{ fill: "green" }} /> :
                        <IndeterminateCheckBox className="float-right" style={{ fill: "red" }} />}
                </div>)
            }
        }];

        if (userData.isMyFleetEnabled) {
            columns.splice(8, 0, {
                Header: 'My Fleet Driver',
                accessor: 'mf',
                Cell: ({ row }) => {
                    // console.log(row);

                    return (<MyFleetSelectorForTable
                        id={row.index}
                        myFleetDrivers={state.myFleetDrivers}
                        handleSelection={updateDeeleeo}
                        selected={row.original.assign_my_fleet_driver}
                    />)
                }
            });
        }


        return columns;

    }

    /**
     * Updates driver for specific deeleeo
     * @param {*} data 
     */
    const updateDeeleeo = (data) => {
        const sliced = fileData.slice();
        sliced[data.uuid].assign_my_fleet_driver = data.driverId;
        setFileData(sliced)
    }

    /**
     * Updates all the drivers of the file
     * @param {*} data 
     */
    const handleMyFleetDriverGlobal = (data) => {
        const sliced = fileData.slice();
        const modified = sliced.map((deeleeo) => {
            deeleeo.assign_my_fleet_driver = data.driverId;
            return deeleeo;
        });
        setFileData(modified)
    }


    const tableInstance = useTable({
        columns: getColumns(),
        data: fileData,
        initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ['w3w', 'details'] },
        manualPagination: true,
        autoResetHiddenColumns: false,
    }, useFilters, useGlobalFilter, useSortBy, useExpanded, usePagination, useRowSelect)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        // gotoPage,
        // nextPage,
        // previousPage,
        // setPageSize,
        visibleColumns,
        // state: {
        //     //  pageIndex, 
        //     //  pageSize, 
        //     //  globalFilter,
        //     //   selectedRowIds 
        //     },
    } = tableInstance

    const expandedRow = (row) => {
        //{renderRowSubComponent(row)}
        /**
         * Get the original deeleeo from the array
         */
        const deeleeo = row;
        //console.log(deeleeo);
        return (
            <tr>
                <td colSpan={visibleColumns.length}>
                    <div className="py-2 container  px-2">
                        <div className="relative">
                            <div className="m-0 p-0">
                                <div className="flex group items-center  ">
                                    <div className="flex-1 ml-4 z-10 font-medium ">
                                        <div className="order-1 space-y-1  bg-gray-200 rounded-lg">
                                            <div className="  inline-block font-bold text-black">Drop Off Window: </div> <div className="  inline-block">{deeleeo.deliver_window}</div><br />

                                            <div className="  inline-block font-bold text-black">Fee: </div> <div className="  inline-block">{deeleeo.fee}</div><br />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>

        );
    }





    return (
        <>
            <UpdateAddressModal
                isOpen={state.editingConfirmed}
                closeModal={() => setEditingConfirmed(false)}
                regions={regions}
                doUpdate={doUpdate}
                setEditingRow={setEditingRow}
                editingRow={state.editingRow}
                setGoogleSearchParameters={setParameter}
            />
            <GenericModal
                content={'Upload complete. Please click “Validate Addresses” to continue'}
                contentLabel={'Notification'}
                modalIsOpen={state.fileUploadModal}
                setModalIsOpen={setFileUploadModal}
            />
            <GenericModal
                content={'If any addresses are invalid after validation, clicking the “Edit” button per row to easily edit and revalidate any addresses.'}
                contentLabel={'Notification'}
                modalIsOpen={state.validateModal}
                setModalIsOpen={setValidateModal}
            />
            <GenericModal
                content={'Some of the rows in your file is missing address data, W3W code, or Package Count'}
                contentLabel={'Notification'}
                modalIsOpen={state.invalidDataModal}
                setModalIsOpen={setInvalidDataModal}
            />
            <GenericModal
                content={'In the next window, to edit/update the package count or any other information, you will need to reconfirm/auto-complete the address again before clicking "Update". Thank you.'}
                contentLabel={'Notification'}
                modalIsOpen={state.editing}
                setModalIsOpen={confirmEditing}
            />
            <GenericModal
                content={'Updated Succesfully.'}
                contentLabel={'Notification'}
                modalIsOpen={state.editingFinished}
                setModalIsOpen={setEditingFinished}
            />
            <GenericModal
                content={`<p>Uploading Address Validation, Please wait... ${state.sentValidationCounter}/${fileData.length}</p>
                <p>Receiving Address Response, please wait... ${state.receivedValidationCounter}/${fileData.length}</p>`}
                contentLabel={'Notification'}
                modalIsOpen={state.addressValidationModal}
                setModalIsOpen={setAddressValidationModal}
            />
            <RowContainer>
                <RowContainer >

                    <div className="w-1/3">
                        <ThinButton className="mb-6">
                            <a href='../samples/DeeleeoBatchTemplate(WebApp Batching).xlsx'>
                                Download Template
                            </a>
                            <StyledToolTip
                                title={<p>Click here to download a simple excel file where you will need to input addresses and other information about the drop off locations within this ‘Deeleeo Batch’. Save and upload this file once completed using the “Upload Address” button. Please call, text, or email Deeleeo Success if you need help or have any question at 1-825-255-5778 or info@deeleeo.com. We are always happy to help!</p>}
                            >

                                <img
                                    style={{ display: 'inline' }}
                                    className="-mt-1 ml-1"
                                    src={InfoIcon}
                                    alt={"premium information"}
                                />
                            </StyledToolTip>
                        </ThinButton>
                    </div>

                    <div className="w-1/3">
                        <ThinButton onClick={handleClick}>
                            Upload Addresses
                        </ThinButton>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onInput={handleChange}
                            style={{ display: 'none' }}
                        />
                    </div>
                    <div className="w-1/3">
                        <ThinButton disabled={fileData.length === 0} onClick={handleValidateClick}>
                            Validate Addresses
                        </ThinButton>
                    </div>
                    <p style={{ fontWeight: 'bold', fontSize: 11 }}>Note: refresh the browser in order to clear out the current data and re-upload another file/new data.</p>
                </RowContainer>

                {fileData.length > 0 && <RowContainer>

                    <SectionHeader className='w-1/3'>File Data:</SectionHeader>
                    <Flex className='w-2/3'>
                        {state.myFleetDrivers.length > 0 && <StyledLabel className='w-2/3 mt-6 ' blackColor htmlFor="all">Assign Driver
                            <HtmlTooltip
                                style={{ marginLeft: 7 }}
                                placement="top"
                                title="This feature will allow you to quickly assign all Deeleeos to one of your MyFleet drivers. You will also be able to manually adjust individual Deeleeo assignment to other drivers as well, per Deeleeo.">
                                <img
                                    style={{ display: 'inline' }}
                                    className="-mt-1 ml-1"
                                    src={InfoIcon}
                                    alt={"premium information"}
                                />
                            </HtmlTooltip>
                        </StyledLabel>}
                        {/* <FormLabel className='w-2/3 mt-6' style={{ fontWeight: 'bold' }} >Assign Drive</FormLabel> */}

                        <MyFleetSelectorForTable
                            id='all'
                            myFleetDrivers={state.myFleetDrivers}
                            handleSelection={handleMyFleetDriverGlobal}
                            selected={state.myFleetDriverSelected}
                        />
                    </Flex>

                </RowContainer>}

                {fileData.length > 0 && <Styles>
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                        {row.isExpanded && expandedRow(row)}
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </Styles>}
            </RowContainer>
        </>
    );
}

export default AddressFileUpload;