
import { dashboardActions } from '../actions/dashboardActions'

const INITIAL_STATE = {
  submittedDeeleeos: [],
  multiDeeleeos: [],
  doneDeeleeos: [],
  sharedDeeleeos: [],
  fetchDeeleeosStatus: '',
  fetchDeeleeosError: '',
  fetchMultiDeeleeosStatus: '',
  postMultiDeeleeoStatus: '',
  cancelDeeleeoStatus: ''
}

export const FetchDeeleeosStatus = {
  FetchDeeleeosRequest: 'FetchDeeleeosRequest',
  FetchDeeleeosSuccess: 'FetchDeeleeosSuccess',
  FetchDeeleeosError: 'FetchDeeleeosError'
}

export const FetchSharedDeeleeosStatus = {
  FetchSharedDeeleeosRequest: 'FetchSharedDeeleeosRequest',
  FetchSharedDeeleeosSuccess: 'FetchSharedDeeleeosSuccess',
  FetchSharedDeeleeosError: 'FetchSharedDeeleeosError'
}

export const FetchDoneDeeleeosStatus = {
  FetchDoneDeeleeosRequest: 'FetchDoneDeeleeosRequest',
  FetchDoneDeeleeosSuccess: 'FetchDoneDeeleeosSuccess',
  FetchDoneDeeleeosError: 'FetchDoneDeeleeosError'
}

export const FetchMultiDeeleeoStatus = {
  FetchMultiDeeleeoRequest: 'FetchMultiDeeleeoRequest',
  FetchMultiDeeleeoSuccess: 'FetchMultiDeeleeoSuccess',
  FetchMultiDeeleeoError: 'FetchMultiDeeleeoError'
}

export const PostMultiDeeleeoStatus = {
  PostMultiDeeleeoRequest: 'PostMultiDeeleeoRequest',
  PostMultiDeeleeoSuccess: 'PostMultiDeeleeoSuccess',
  PostMultiDeeleeoError: 'PostMultiDeeleeoError',
  PostMultiDeeleeoStatusReset: ''
}

export const CancelDeeleeosStatus = {
  CancelDeeleeoRequest: 'submitting',
  CancelDeeleeoSuccess: 'success',
  CancelDeeleeoError: 'error',
  CancelDeeleeoStatusReset: ''
}

export const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case dashboardActions.CANCEL_DEELEEO:
      return {
        ...state, ...action.data
      }
    case dashboardActions.FETCH_ALL_DEELEEOS_REQUEST:
      return {
        ...state, fetchDeeleeosStatus: FetchDeeleeosStatus.FetchDeeleeosRequest, fetchDeeleeosError: ''
      }
    case dashboardActions.FETCH_ALL_DEELEEOS_SUCCESS:
      return {
        ...state, fetchDeeleeosStatus: FetchDeeleeosStatus.FetchDeeleeosSuccess, submittedDeeleeos: action.data
      }
    case dashboardActions.FETCH_DONE_DEELEEOS_SUCCESS:
      return {
        ...state, fetchDeeleeosStatus: FetchDoneDeeleeosStatus.FetchDoneDeeleeosSuccess, doneDeeleeos: action.data
      }
    case dashboardActions.FETCH_SHARED_DEELEEOS_SUCCESS:
      return {
        ...state, fetchDeeleeosStatus: FetchSharedDeeleeosStatus.FetchSharedDeeleeosSuccess, sharedDeeleeos: action.data
      }
    case dashboardActions.FETCH_ALL_DEELEEOS_FAILURE:
      return {
        ...state, fetchDeeleeosStatus: FetchDeeleeosStatus.FetchDeeleeosError, fetchDeeleeosError: action.data
      }
    case dashboardActions.FETCH_MULTI_DEELEEOS_REQUEST:
      return {
        ...state, fetchMultiDeeleeosStatus: FetchMultiDeeleeoStatus.FetchMultiDeeleeoRequest, fetchDeeleeosError: ''
      }
    case dashboardActions.FETCH_MULTI_DEELEEOS_FAILURE:
      return {
        ...state, fetchMultiDeeleeosStatus: FetchMultiDeeleeoStatus.FetchMultiDeeleeoError, multiDeeleeos: action.data.data
      }
    case dashboardActions.FETCH_MULTI_DEELEEOS_SUCCESS:
      return {
        ...state, fetchMultiDeeleeosStatus: FetchMultiDeeleeoStatus.FetchMultiDeeleeoSuccess, multiDeeleeos: action.data.data
      }
    case dashboardActions.POST_MULTIDEELEEOS_REQUEST:
      return {
        ...state, postMultiDeeleeoStatus: PostMultiDeeleeoStatus.PostMultiDeeleeoRequest,
      }
    case dashboardActions.POST_MULTIDEELEEOS_SUCCESS:
      return {
        ...state, postMultiDeeleeoStatus: PostMultiDeeleeoStatus.PostMultiDeeleeoSuccess,
      }
    case dashboardActions.POST_MULTIDEELEEOS_FAILURE:
      return {
        ...state, postMultiDeeleeoStatus: PostMultiDeeleeoStatus.PostMultiDeeleeoError,
      }
    case dashboardActions.POST_MULTIDEELEEOS_STATUS_RESET:
      return {
        ...state, postMultiDeeleeoStatus: PostMultiDeeleeoStatus.PostMultiDeeleeoStatusReset
      }
    case dashboardActions.CANCEL_DEELEEO_SUBMIT:
      return {
        ...state, cancelDeeleeoStatus: CancelDeeleeosStatus.CancelDeeleeoRequest
      }
    case dashboardActions.CANCEL_DEELEEO_SUCCESS:
      return {
        ...state, cancelDeeleeoStatus: CancelDeeleeosStatus.CancelDeeleeoSuccess
      }
    case dashboardActions.CANCEL_DEELEEO_FAILURE:
      return {
        ...state, cancelDeeleeoStatus: CancelDeeleeosStatus.CancelDeeleeoError
      }
    case dashboardActions.CANCEL_DEELEEO_STATUS_RESET:
      return {
        ...state, cancelDeeleeoStatus: CancelDeeleeosStatus.CancelDeeleeoStatusReset
      }
    default: return state
  }


}
