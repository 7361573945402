import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FieldContainer } from '../../../components/containers'
import FloatingInputField from '../../../components/FloatingInputField'
import { Button } from '../../../components/buttons'
import { TitleText } from '../../Register/components/shared'
import { ResetStatus } from '../../../redux/reducers/authReducer'

function UpdatePassword(props) {
  const { resetPassword, otp, resetToken, resetStatus } = props
  const [showPassword, setShowPassword] = useState(false)

  const validationSchema = yup.object({
    newPassword: yup.string().min(6, 'Password must be at least 6 characters').required('Must enter a new password'),
  })

  const formik = useFormik({
    initialValues: {
      newPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values => {
      const data = {
        password: values.newPassword,
        otp: otp,
        reset_password_token: resetToken,
        password_confirmation: values.newPassword
      }
      // console.log(data)
      resetPassword(data)
    })
  })

  useEffect(() => {
    if (resetStatus === ResetStatus.ResetError) {
      formik.setErrors({ newPassword: 'There was an issue updating your password' })
    } else {
      formik.setErrors({ email: '' })
    }
  }, [formik, resetStatus])

  return (
    <FieldContainer noMargin className='justify-center h-full' onSubmit={formik.handleSubmit}>
      <TitleText className='mb-16'>Change password</TitleText>
      <FloatingInputField required id={'newPassword'} label={'New Password'} inputType='password'
                          error={(formik.touched.newPassword && Boolean(formik.errors.newPassword))}
                          helperText={formik.touched.newPassword && formik.errors.newPassword}
                          onBlur={formik.handleBlur}
                          handleClickShowPassword={() => setShowPassword(!showPassword)} showPassword={showPassword}
                          onChange={formik.handleChange}/>
      <Button className='w-52' type='submit'>Change password</Button>
    </FieldContainer>
  )
}

export default UpdatePassword
