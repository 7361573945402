import React from 'react'
import { withRouter } from 'react-router-dom'
import { Flex } from './containers'
import { routes } from '../routes'
import styled from 'styled-components'
import tw from 'twin.macro'

const SelectItem = styled.div`
  ${tw`hover:border-b-2 hover:border-cardBorder mr-4 pb-2 flex justify-center items-center uppercase text-fontBlue cursor-pointer`}
  ${props => props.selected && tw`border-b-2 border-cardBorder`}
  width: 150px;
  font-family: Varela Round;
  font-size: 14px;
`

function DeeleeoTabs(props) {
  const { selectedTab, history } = props
  return (
    <Flex>
      <SelectItem selected={selectedTab === 1} onClick={() => history.push(routes.choose)}>
        Make a Deeleeo
      </SelectItem>
      <SelectItem selected={selectedTab === 2} onClick={() => history.push(routes.dashboard)}>
        My Deeleeos
      </SelectItem>
      <SelectItem selected={selectedTab === 4} onClick={() => history.push(routes.favourites)}>
        Delivery Agents
      </SelectItem>
      <SelectItem selected={selectedTab === 3} onClick={() => history.push(routes.addPayment)}>
        Manage Payment
      </SelectItem>
      <SelectItem selected={selectedTab === 5} onClick={() => history.push(routes.userAddress)}>
        Contacts
      </SelectItem>
    </Flex>
  )
}

export default withRouter(DeeleeoTabs)
