import React from "react";
import Modal from 'react-modal';

const EditModal = ({ contentLabel, modalIsOpen, setModalIsOpen, acceptEditDeeleeo, deeleeo }) => {

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (<Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={contentLabel}
    >
        <div className="" style={{ "width": "450px" }} >
            <div className="w-full bg-white  " >
                <p className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <span className="text-red-600 font-black">Attention:</span> if you continue and edit this Deeleeo, a new label will be generated and required to print again. The current label will become void. You will not be charged for editing this Deeleeo. Thank you.
                </p>
                <div className="flex items-center justify-between mt-3">
                    <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={closeModal}>
                        close
                    </button>
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => acceptEditDeeleeo(deeleeo)}
                    >
                        Edit
                    </button>
                </div>
            </div>
        </div>

    </Modal>);

}

export default EditModal;