import React from "react";
import Modal from 'react-modal';
import { HtmlTooltip } from "../../../components/InputField";
import InfoIcon from "../../../assets/icons/Info-icon.svg";
import { createCookie } from "../../../helpers/cookieHelper";

const GetLabelModal = ({ contentLabel, modalIsOpen, setModalIsOpen, getLabel, packages }) => {

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const createPopUpCookie = () => {
        createCookie('hidelabelreminder', 1, 360);
        getLabel(null);
        
    }


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (<Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={contentLabel}
    >
        <div className="" style={{ "width": "450px" }} >
            <div className="w-full bg-white  " >
                <p className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
                     If your package count for this Deeleeo does not match the actual amount of packages needed to be delivered for this Deeleeo, then please edit the Deeleeo to input the correct number of packages. Thank you..
                </p>

                <span className="text-red-600 font-black">Current Packages: { packages}</span>
                <div className="flex items-center justify-between mt-3">
                    <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={closeModal}>
                        close
                    </button>

                    <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={createPopUpCookie}>
                        Don't Show this again
                        <HtmlTooltip
                            style={{ marginLeft: 7 }}
                            placement="top"
                            title="Please don't show this to me again. I understand I need to properly submit and label each package.">

                            <img
                                style={{ display: 'inline' }}
                                className="-mt-1 ml-1"
                                src={InfoIcon}
                                alt={"premium information"}
                            />
                        </HtmlTooltip>
                    </button>

                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => getLabel(null)}
                    >
                        Get Label
                    </button>
                </div>
            </div>
        </div>

    </Modal>);

}

export default GetLabelModal;