import React from 'react'
import { Divider } from '../../Shipment/ShipmentDetails'
import { Flex } from '../../../components/containers'
import LineItem from './LineItem'
import { SummarySectionTitle } from '../../../components/text'

function OrderItems(props) {
  const { deeleeos, isOneToMany, isManyToOne, setSelectedDeeleeo, setSelectedBundle } = props

  const getBundleData = (bundleId) => {
    return deeleeos.filter(
      (deeleeo) => {
        return bundleId === deeleeo.bundle_id;
      }
    );

  }

  const clickHandler = (deeleeo) => {
    setSelectedDeeleeo(deeleeo);
    setSelectedBundle(getBundleData(deeleeo.bundle_id));
  }

  return (
    <div>
      <Flex className='content-around'>
        <SummarySectionTitle>Recipient</SummarySectionTitle>
        <SummarySectionTitle>Service & Prices</SummarySectionTitle>
      </Flex>
      <Divider />
      {deeleeos.length > 0 && deeleeos.map((deeleeo, index) => {
        return <div key={index}>
          <LineItem clickHandler={clickHandler} key={index} deeleeoInfo={deeleeo} index={index} isOneToMany={isOneToMany} isManyToOne={isManyToOne} />
          <Divider />
        </div>
      })
      }
    </div>
  )
}

export default OrderItems
