import React, { useEffect } from 'react'
import {
  IconSubText,
  LeftSide,
  RightSide,
} from '../Register/components/shared'
import { Container, Flex } from '../../components/containers'
import DeeleeoIcon from '../../assets/icons/deeleeo-reverse.svg'
import { routes } from '../../routes'
import { connect } from 'react-redux'
import { clearForgotStatus, forgotPassword, resetPassword, updateOtp } from '../../redux/actions/authActions'
import { ForgotStatus, ResetStatus } from '../../redux/reducers/authReducer'
import { withRouter } from 'react-router-dom'
import EnterEmail from './components/EnterEmail'
import EnterOtp from './components/EnterOTP'
import UpdatePassword from './components/UpdatePassword'

function ForgotPassword(props) {
  const { forgotStatus, history, clearForgotStatus, resetStatus, otp } = props

  useEffect(() => {
    if (resetStatus === ResetStatus.ResetSuccess) {
      clearForgotStatus()
      history.push(routes.login)
    }
  }, [clearForgotStatus, history, resetStatus])

  return (
    <Container>
      <LeftSide>
        <Flex className='justify-center pt-96'>
          <div>
            <img src={DeeleeoIcon} alt='Deeleeo Logo'/>
            <IconSubText>Delivery simplified.</IconSubText>
          </div>
        </Flex>
      </LeftSide>

      <RightSide>
        {forgotStatus !== ForgotStatus.ForgotSuccess && <EnterEmail {...props} />}

        {(otp === '' && forgotStatus === ForgotStatus.ForgotSuccess) && <EnterOtp {...props} />}

        {otp !== '' && <UpdatePassword {...props} />}
      </RightSide>
    </Container>
  )
}

const mapStateToProps = state => ({
  forgotStatus: state.authReducer.forgotStatus,
  forgotError: state.authReducer.forgotError,
  forgotEmail: state.authReducer.forgotEmail,
  resetToken: state.authReducer.resetToken,
  resetStatus: state.authReducer.resetStatus,
  otp: state.authReducer.otp
})

const mapDispatchToProps = dispatch => ({
  forgotPassword: (requestData) => dispatch(forgotPassword(requestData)),
  clearForgotStatus: () => dispatch(clearForgotStatus()),
  updateOtp: (otp) => dispatch(updateOtp(otp)),
  resetPassword: (requestData) => dispatch(resetPassword(requestData))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword))
