import { getURI } from '../../helpers/apiHelpers'
import { updatePickupHours } from './pickupActions'
import { formatPickupHoursFromRequest } from '../../helpers/requestHelpers'

export const userActions = Object.freeze({
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',

  UPDATE_MOBILE_REQUEST: 'UPDATE_MOBILE_REQUEST',
  UPDATE_MOBILE_SUCCESS: 'UPDATE_MOBILE_SUCCESS',

  SERVICED_REGIONS_REQUEST: 'SERVICED_REGIONS_REQUEST',
  SERVICED_REGIONS_SUCCESS: 'SERVICED_REGIONS_SUCCESS',

  COMPANY_INFO_REQUEST: 'COMPANY_INFO_REQUEST',
  COMPANY_INFO_SUCCESS: 'COMPANY_INFO_SUCCESS',

})

export const updateUserInfo = (userInfo) => {
  return {
    type: userActions.UPDATE_USER,
    data: userInfo
  }
}

export const updateMobileNumber = (requestData) => dispatch => {
  const request = () => ({type: userActions.UPDATE_MOBILE_REQUEST})
  const success = () => ({type: userActions.UPDATE_MOBILE_SUCCESS})

  dispatch(request())

  fetch(getURI('/api/user/updateBusinessUsers'), {
    method: 'PATCH',
    body: JSON.stringify(requestData),
    headers: {
      'Content-Type': 'application/json',
    }

  })
    .then(response => response.text())
    .then(response => {
      const { savedUser: { mobile } } = JSON.parse(response)
      const userInfo = { mobile }
      dispatch(updateUserInfo(userInfo))
      dispatch(success())
    })
}

export const updateServicedRegions = () => dispatch => {
  const request = () => ({type: userActions.SERVICED_REGIONS_REQUEST})
  const success = (regionList) => ({type: userActions.SERVICED_REGIONS_SUCCESS, data: regionList})

  dispatch(request())

  fetch(getURI('/api/web/get-sign-up-active-regions'), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }

  })
    .then(response => response.text())
    .then(response => {
      const parsedRegions = JSON.parse(response)
      dispatch(success(parsedRegions.data))
    })
}

export const getCompanyInfo = (api_key) => dispatch => {
  const request = () => ({type: userActions.COMPANY_INFO_REQUEST})
  const success = (companyInfo) => ({type: userActions.COMPANY_INFO_SUCCESS, data: companyInfo})

  dispatch(request())

  fetch(getURI('/api/user/companyInfo?' + new URLSearchParams({api_key: api_key})), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }

  })
    .then(response => {
      if (response.status === 200) {
        return response.text()
      } else {
        throw new Error('Error fetching company info')
      }
    })
    .then(response => {
      const parsedRes = JSON.parse(response)
        //  console.log(parsedRes);
      
      let data = {
        email: parsedRes.user.contacEmail,
        mobile: parsedRes.user.mobile,
        hasCard: parsedRes.cards.length > 0,
        name: parsedRes.business.name,
        firstName: parsedRes.user.firstname,
        lastName: parsedRes.user.lastname,
        card_id: parsedRes.cards.length > 0 ? parsedRes.cards[0].card_id : '',
        credit: parsedRes.user.credit,
        isFavouriteAgentEnable: parsedRes.user.enable_favourite_agent,
        isPickupAvailability: parsedRes.user.is_pickup_availability,
        isOnHoldEnabled: parsedRes.user.on_hold_deeleeo,
        isMyFleetEnabled: parsedRes.user.enable_my_fleet,
        isValetEnabled: parsedRes.user.valet_enabled,
        isExpressEnabled: parsedRes.user.express_enabled,
        expressMaxTime: parsedRes.user.express_max_time,
        availableTimes: parsedRes.available_times,
        isEFTPayment: parsedRes.is_eft_payment,
        isUserCredit: parsedRes.is_use_credit,
        isSundayDeliveryEnabled: parsedRes.is_sunday_delivery_enabled,
        mapLatLng: parsedRes.operating_region_lat_lng,
        region_times: parsedRes.region_times,
        rush_distance: parsedRes.user.rush_distance,
        intercity_min_special_time: parsedRes.user.intercity_min_special_time,
        local_min_special_time: parsedRes.user.local_min_special_time
      }




      dispatch(success(data))

      

      try {
        const pickupHours = formatPickupHoursFromRequest(parsedRes.pickup.length > 0 ? parsedRes.pickup[0].hours : 0)
        dispatch(updatePickupHours({ pickupHours }))
      } catch (e) {
        
      }
      
    })
    .catch(err => {
      console.log(err);
      // console.log(window.location.href);
      // console.log(window.location.href.indexOf(routes.registerPickupLocation));
      
      
      // if(window.location.href.indexOf(routes.registerPickupLocation) === -1){
      //   window.location.replace(routes.registerPickupLocation);
      // }
      
    })
}



