import React, { useEffect, useState } from 'react'
import PageLayout from '../PageLayout'
import { Flex } from '../../components/containers'
import { AccentHeader, ErrorSubText, ErrorText, SectionHeader } from '../../components/text'
import ShipmentInformation from '../../components/ShipmentInformation'
import { Button } from '../../components/buttons'
import { DetailsContainer, Divider } from '../Shipment/ShipmentDetails'
import { connect } from 'react-redux'
import PhotoUpload from './components/PhotoUpload'
import { withRouter } from 'react-router-dom'
import { routes } from '../../routes'
import DeeleeoTabs from '../../components/DeeleeoTabs'
import SizeSection from './components/SizeSection'
import DescriptionSection from './components/DescriptionSection'
import PremiumSection from './components/PremiumSection'
import DropoffTypeSection from './components/DropoffTypeSection'
import { fetchSizeCategory } from '../../redux/reducers/sizeCategoryReducer'
import { addDeeleeoToList, updateDeeleeoFromList } from '../../redux/reducers/deeleeosReducer'
import { formatDatetime, gatheringDeeleeoData, getTimes } from '../../helpers/deeleeoHelper'
import { clearDeeleeoData } from '../../redux/reducers/deeleeoReducer'
import { clearDropoffData } from '../../redux/reducers/pickupDropoffReducer'

// import { validateDeeleeo, validatePickupData, validateDropoffData } from '../../redux/reducers/validationReducer'
import { updateValidationStatus, resetValidationStatus } from '../../redux/reducers/validationReducer'
import { validateAll } from '../../helpers/validationHelper'
import OptionsSection from './components/OptionsSection'
import axios from 'axios'
import { getURI } from '../../helpers/apiHelpers'
import MyFleetSection from './components/MyFleetSection'
import { useRecoilState } from 'recoil'
import { isInterCity, userDeeleeosManyToOne, userDeeleeosOneToMany } from '../../App'
import ErrorBoundary from '../../helpers/ErrorBoundary'
import ReadyForPickupModal from '../Dashboard/components/ReadyForPickupModal'
import DropOffAddressTypeSection from './components/DropOffAddressTypeSection'
import PickupDropOffTimeSectionSingle from './components/PickupDropOffTimeSectionSingle'



export const GeneralError = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { isValidationDataValid, validationData, getValdiationErrorText } = props

  // console.log('isValidationDataValid');
  // console.log(isValidationDataValid);
  // console.log('validationData');
  // console.log(validationData);
  // console.log('getValdiationErrorText');
  // console.log(getValdiationErrorText);


  return (
    <div>
      <p className='my-2 text-red-500'>Please check the following sections and try again:</p>
      <ul className="list-disc list-inside bg-rose-200">
        {Object.entries(validationData).map(([key, index]) => {
          if (typeof (validationData[key]) !== 'object') {
            if (!validationData[key]) {
              return (
                <li key={key} className='text-red-500'>
                  {getValdiationErrorText(key)}
                </li>
              )
            }
          } else {
            if (!validationData[key].type || !validationData[key].date || !validationData[key].window_time) {
              return (
                <li key={key} className='text-red-500'>
                  {getValdiationErrorText(key)}
                </li>
              )
            }
          }
          return null;
        })}
      </ul>
    </div>
  )
}




export const creditCardMissingMessage = () => {

  return (<div>
    <ErrorText style={{ textAlign: 'left', fontSize: '16px' }}>There is no credit card associated with this account</ErrorText>

    <ErrorSubText style={{ textAlign: 'left', paddingBottom: '10px', paddingTop: '5px', color: '#F44336' }}>
      * Save a payment method (credit card) to your account in the "Manage Payment" section
    </ErrorSubText>

  </div>)
}

export const checkValidationData = (data) => {
  let return_data = true;

  for (let item in data) {
    // console.log('item: ', item,data[item]);

    if (typeof (data[item]) !== 'object') {
      if (!data[item]) {
        return_data = false;
      }
    } else {
      if (!data[item].type || !data[item].date || !data[item].window_time) {
        return_data = false;
      }
    }
  }

  return return_data;
}

function CreateDeeleeo(props) {
  const {
    pickupData, dropoffData, deeleeoDetail, addDeeleeoToList, updateDeeleeoFromList, clearDeeleeoData, clearDropoffData,
    history, fetchSizeCategory, userData, validation, updateValidationStatus
  } = props

  const [deeleeosManyToOne, setDeeleeosManyToOne] = useRecoilState(userDeeleeosManyToOne);
  const [deeleeosOneToMany, setDeeleeosOneToMany] = useRecoilState(userDeeleeosOneToMany);
  // const [isDeeleeosNotReadySubmitted, setIsDeeleeosNotReadySubmitted] = useRecoilState(deeleeosNotReadySubmitted);
  // eslint-disable-next-line no-unused-vars
  const [interCity, setInterCity] = useRecoilState(isInterCity);


  const [state, setState] = useState({
    validationData: validation,
    isValidationDataValid: true,
    isSpecialTiming: false,
    displayConfirmation: false,
  });

  const setValidationData = (data) => {
    setState(state => ({ ...state, validationData: data }));
  }

  const setIsValidationDataValid = (data) => {
    setState(state => ({ ...state, isValidationDataValid: data }));
  }

  const setIsSpecialTiming = (data) => {
    setState(state => ({ ...state, isSpecialTiming: data }));
  }

  const setDisplayConfirmation = (data) => {
    let temp_validation = validateDeeleeoData();
    let is_validation_data_valid = checkValidationData(temp_validation);

    setIsValidationDataValid(is_validation_data_valid);

    if (is_validation_data_valid) {
      setState(state => ({ ...state, displayConfirmation: data }));
    }
  }


  // const [validationData, setValidationData] = useState(validation);
  // const [isValidationDataValid, setIsValidationDataValid] = useState(true);

  const getValdiationErrorText = (key) => {
    switch (key) {
      case 'pickup_data_validation':
        return 'Pickup Information';
      case 'dropoff_data_validation':
        return 'Drop off Information';
      case 'item_name':
        return 'Reference Name';
      case 'size_weight_selection':
        return 'Weight & Size Selection';
      case 'dropoff_method':
        return 'Drop off Type';
      case 'description':
        return 'Description';
      case 'pickup_time':
        return 'Pickup Time';
      case 'dropoff_time':
        return 'Drop off Time';
      case 'refrigeration':
        return 'Please choose a Temperature Controlled Option'
      case 'package_count':
        return 'Please enter a valid package count'
      default:
        return '';
    }
  }

  // recheck if all validation status are TRUE


  const validateDeeleeoData = () => {

    let validated_deeleeo_data = validateAll(pickupData, dropoffData, deeleeoDetail, false, interCity);
    setValidationData({
      pickup_data_validation: validated_deeleeo_data.pickup_data_validation,
      dropoff_data_validation: validated_deeleeo_data.dropoff_data_validation,
      item_name: validated_deeleeo_data.item_name,
      size_weight_selection: validated_deeleeo_data.size_weight_selection,
      dropoff_method: validated_deeleeo_data.dropoff_method,
      description: validated_deeleeo_data.description,
      pickup_time: validated_deeleeo_data.pickup_time,
      dropoff_time: validated_deeleeo_data.dropoff_time,
      refrigeration: validated_deeleeo_data.refrigeration,
      package_count: validated_deeleeo_data.package_count
    })

    return validated_deeleeo_data;
  }

  /**
   * Update an existing on Hold deeleeo
   */
  const patchOnHoldDeeleeo = () => {

    const dropoff_early_datetime = formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_early_datetime);
    const dropoff_later_datetime = formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_later_datetime);
    const pickup_early_datetime = formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_early_datetime);
    const pickup_later_datetime = formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_later_datetime);

    deeleeoDetail.dropoff_early_datetime = dropoff_early_datetime;
    deeleeoDetail.dropoff_later_datetime = dropoff_later_datetime;
    deeleeoDetail.pickup_early_datetime = pickup_early_datetime;
    deeleeoDetail.pickup_later_datetime = pickup_later_datetime;


    const payload = { deeleeo: deeleeoDetail, pickupData: pickupData, dropoffData: dropoffData, api_code: userData.apiCode };
    axios.post(getURI('/api/web/on-hold-deeleeo/updateExisting'), payload)
      .then((data) => {
        clearDeeleeoData();
        clearDropoffData();
        history.push(routes.dashboard);
      })
      .catch(e => {
        console.log(e);
      })
  }

  const setConfirmation = (value) => {
    if (!value) {
      // setIsDeeleeosNotReadySubmitted(true);
    }
    addDeeleeoAndGoToSummary(value);
  }

  const addDeeleeoAndGoToSummary = (readyForPickup) => {
    let temp_validation = validateDeeleeoData();
    let is_validation_data_valid = checkValidationData(temp_validation);

    // console.log('is_validation_data_valid');
    // console.log(is_validation_data_valid);

    setIsValidationDataValid(is_validation_data_valid);

    if (is_validation_data_valid) {

      /**
       * If it's an existing on hold deeleeo, send it to the other end point
       */
      if (deeleeoDetail.is_editing && deeleeoDetail.status === 17) {
        patchOnHoldDeeleeo();
      } else {
        //gathering deeleeo data and add to list (do something so i can skip the assemble data later)
        let deeleeo_data = gatheringDeeleeoData(deeleeoDetail, pickupData, dropoffData, userData, interCity, state.isSpecialTiming);




        if (userData.isOnHoldEnabled
          && !deeleeo_data.assign_my_fleet_driver) {
          deeleeo_data.status = 17;
        }



        if (deeleeoDetail.isManyToOne || deeleeoDetail.isOneToMany) {
          /**
           * Merge the edited data with the original
           */
          deeleeo_data = { ...deeleeoDetail, ...deeleeo_data };

          /**
           * Update the collection and redirect
           */
          if (deeleeoDetail.isManyToOne) {
            //setDeeleeosManyToOne();
            const sliced = deeleeosManyToOne.slice();
            setDeeleeosManyToOne(sliced.map((e) => {
              /**
               * if the uuid matches, then we replace the data with the edited data
               */
              if (e.uuid === deeleeoDetail.uuid) {
                return deeleeo_data;
              }
              return e;
            }));

            clearDeeleeoData();
            clearDropoffData();
            history.push(routes.createManyToOne);
          }

          /**
           * Update the collection and redirect
           */
          if (deeleeoDetail.isOneToMany) {
            //setDeeleeosOneToMany();
            const sliced = deeleeosOneToMany.slice();
            setDeeleeosOneToMany(sliced.map((e) => {
              /**
               * if the uuid matches, then we replace the data with the edited data
               */
              if (e.uuid === deeleeoDetail.uuid) {
                return deeleeo_data;
              }
              return e;
            }));
            clearDeeleeoData();
            clearDropoffData();
            history.push(routes.createOneToMany);
          }

          return false;
        }




        if (deeleeoDetail.is_editing) {
          let index = deeleeoDetail.index;
          updateDeeleeoFromList(deeleeo_data, index);
        } else {
          addDeeleeoToList(deeleeo_data)
        }

        clearDeeleeoData();
        clearDropoffData();
        history.push(routes.summary)

      }
    }
  }

  useEffect(() => {
    fetchSizeCategory()
  }, [fetchSizeCategory])

  useEffect(() => {
    // dispatch update validation status
    updateValidationStatus(state.validationData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.validationData])



  const getButtonLabel = () => {
    if (deeleeoDetail.is_editing) {
      return 'Save And Go to Summary'
    }
    else {
      return 'Order Summary'
    }
  }



  return (
    <PageLayout wider>
      <ReadyForPickupModal
        times={getTimes(deeleeoDetail)}
        modalIsOpen={state.displayConfirmation}
        setModalIsOpen={setDisplayConfirmation}
        setConfirmation={setConfirmation}
      />
      <DeeleeoTabs selectedTab={1} />
      <Divider />
      <DetailsContainer>
        <AccentHeader>What’s the Deeleeo??</AccentHeader>
        <Flex>
          <SectionHeader>Shipment details {interCity ? '(Intercity)' : '(Local)'}:</SectionHeader>
        </Flex>
        <Flex>
          <div className='mt-8 w-1/2 first:mr-12' style={{ fontSize: 10, padding: 0, margin: 0 }}>* = Mandatory Fields</div>
        </Flex>
        <Flex>

          <ShipmentInformation error={validation.pickup_data_validation} shipmentInfo={pickupData} title={'Pickup Information'} isSender />
          <ShipmentInformation error={validation.dropoff_data_validation} shipmentInfo={dropoffData} title={'Drop off Information'} isRecipient />
        </Flex>

        <DropOffAddressTypeSection />
        

        <SizeSection />



        {/* Premium options */}
        <PremiumSection />

        {/* Dropoff type section - Contactless/In-person */}
        <DropoffTypeSection />

        {/* description */}
        <DescriptionSection />

        <PhotoUpload />
        {/* {image && <Divider className='mt-4' />} */}

        <ErrorBoundary><PickupDropOffTimeSectionSingle setIsSpecialTiming={setIsSpecialTiming} /></ErrorBoundary>

        <Flex className='flex-row'>
          {userData.isFavouriteAgentEnable && <OptionsSection />}

          {userData.isMyFleetEnabled && <MyFleetSection />}
        </Flex>

        {/* display list of current error */}
        {!state.isValidationDataValid &&
          <GeneralError getValdiationErrorText={getValdiationErrorText} isValidationDataValid={state.isValidationDataValid} validationData={state.validationData} />
        }

        <Button onClick={() => setDisplayConfirmation(true)}>{getButtonLabel()}</Button>

      </DetailsContainer>

    </PageLayout>
  )
}

const mapStateToProps = state => ({
  pickupData: state.pickupDropoffReducer.pickup_data,
  dropoffData: state.pickupDropoffReducer.dropoff_data,
  deeleeoDetail: state.deeleeoReducer,
  userData: state.userReducer,
  validation: state.validationReducer
})

const mapDispatchToProps = dispatch => ({
  fetchSizeCategory: () => dispatch(fetchSizeCategory()),
  addDeeleeoToList: (deeleeo) => dispatch(addDeeleeoToList(deeleeo)),
  updateDeeleeoFromList: (deeleeo, index) => dispatch(updateDeeleeoFromList(deeleeo, index)),
  clearDeeleeoData: () => dispatch(clearDeeleeoData()),
  clearDropoffData: () => dispatch(clearDropoffData()),
  updateValidationStatus: (data) => dispatch(updateValidationStatus(data)),
  resetValidationStatus: () => dispatch(resetValidationStatus()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateDeeleeo))
