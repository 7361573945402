import moment from 'moment'
import { getStatusFromEnum } from '../../helpers/requestHelpers'
// import { getHours } from '../../helpers/dateHelpers'
import { AddCircleOutlineOutlined, RemoveCircleOutline } from '@material-ui/icons';

export const formatTableData = (deeleeos, deeleeoView) => {
    
  if (deeleeos && deeleeos.length > 0) {
    return deeleeos.map((deeleeo, index) => {

      return {
        key: index,
        deeleeoId: deeleeo.deeleeo_id,
        delivery: getDeeleeoId(deeleeo),
        name: deeleeo.name,
        location: deeleeo.dropoff_address,
        date: deeleeoView === 1 ? moment(deeleeo.pickup_early_datetime).format('MMMM D YYYY'):moment(deeleeo.completed_at).format('MMMM D YYYY'),
        agent: getAgentCell(deeleeo),
        status: [getStatusFromEnum(deeleeo.status), deeleeo.status],
        realStatus: deeleeo.status,
        available: getAvailable(deeleeo.available),
        uuid: deeleeo.uuid
      }
    })
  }
  else {
    return []
  }
}

const getAvailable = (value) => {
  const icon = value ?
    <span style={{ margin: 'auto' }} >Yes</span> :
    <span style={{ margin: 'auto' }} >No</span>;
  return (<div style={{ paddingLeft: '60px', paddingRight: '30px' }}>{icon}</div>);
}

const getDeeleeoId = (deeleeo) => {
  return (<div className="text-blue-600"> {deeleeo.deeleeo_id} </div>);
}

const getAgentCell = (deeleeo) => {
  return (<div>{deeleeo?.driver?.name} <br /><a href={'tel:' + deeleeo?.driver?.mobile}>{deeleeo?.driver?.mobile}</a></div>);
}

const getDateHeader = (deeleeoView) => {  
 return deeleeoView === 1?'Pickup Date':'Completed Date';
}

export const getColumns = (deeleeoView) => {
  return [
    {
      Header: () => null,
      id: 'expander', // 'id' is required
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <RemoveCircleOutline style={{ fill: "blue" }} /> : <AddCircleOutlineOutlined style={{ fill: "blue" }} />}
        </span>
      ),
    },
    {
      Header: 'Deeleeo #',
      accessor: 'delivery'
    },
    {
      Header: 'Order Id',
      accessor: 'name'
    },
    {
      Header: 'Drop Off Location',
      accessor: 'location'
    },
    {
      Header: getDateHeader(deeleeoView),
      accessor: 'date'
    },
    {
      Header: 'Delivery Agent',
      accessor: 'agent'
    },
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: 'Actions',
      accessor: 'actions'
    },

  ]
}

