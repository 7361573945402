import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const ProgressBarContainer = styled.div`
  ${tw`h-2 relative rounded overflow-hidden mt-2`}
  background-color: #DADADA;
`

const ProgressBarFill = styled.div`
  ${tw`h-full bg-secondaryOrange rounded absolute`}
  width: ${props => props.width}
`

function ProgressBar(props) {
  return (
    <ProgressBarContainer>
      <ProgressBarFill width={props.width}/>
    </ProgressBarContainer>
  )
}

export default ProgressBar
