import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DeeleeoTabs from '../../components/DeeleeoTabs';
import PageLayout from '../PageLayout';
import { DetailsContainer, Divider } from '../Shipment/ShipmentDetails';
import DriversTable from './Components/DriversTable';


const FavDrivers = (props) => {

    const { userData,apiCode } = props;

    return (
        <PageLayout wider>
            <DeeleeoTabs selectedTab={4} />
            <Divider />
            <DetailsContainer>
                {/* <SectionHeader>Manage Favourite Agents:</SectionHeader>
                
                <Divider /> */}

                <DriversTable 
                userData={userData}
                apiCode={apiCode} 
                />
            </DetailsContainer>
        </PageLayout>
    );
}

const mapStateToProps = state => ({
    userData: state.userReducer,
    apiCode: state.userReducer.apiCode
})

const mapDispatchToProps = dispatch => ({
    
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FavDrivers));