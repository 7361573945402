import React from 'react'
import PageLayout from '../PageLayout'
import { AccentHeader, BusinessName, DescriptionText, SectionHeader } from '../../components/text'
import ProgressBar from '../../components/ProgressBar'
import PickupHourSelector from '../DeeleeoDetails/components/PickupHourSelector'
import { BackButton, Button } from '../../components/buttons'
import leftChevron from '../../assets/icons/left-chevron.svg'
import { Flex } from '../../components/containers'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { savePickupHours, updatePickupHours } from '../../redux/actions/pickupActions'
import { routes } from '../../routes'

function RegisterPickupHours(props) {
  const { apiCode, history, pickupHours, updatePickupHours, savePickupHours, businessName } = props
  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  const createRequestData = () => {
    const requestData = {}
    Object.keys(pickupHours).forEach(day => {
      requestData[`${day}_start`] = pickupHours[day].start
      requestData[`${day}_end`] = pickupHours[day].end
    })
    requestData.api_key = apiCode
    return requestData
  }

  const saveHours = () => {
    savePickupHours(createRequestData())
    history.push(routes.choose)
  }

  return (
    <PageLayout>
      <AccentHeader>Account Details</AccentHeader>
      <BusinessName>{businessName}</BusinessName>
      <ProgressBar width={'100%'}/>
      <SectionHeader>Pickup hours</SectionHeader>
      <DescriptionText>Hours during which delivery agents may pick up your packages</DescriptionText>
      {daysOfWeek.map(day => {
        return <PickupHourSelector pickupHours={pickupHours} key={day} day={day} updatePickupHours={updatePickupHours}/>
      })}
      <Flex>
        <BackButton onClick={() => history.goBack()}><span><img src={leftChevron} alt={'left arrow'} /></span>Back</BackButton>
        <Button onClick={() => saveHours()}>Save account details</Button>
      </Flex>
    </PageLayout>
  )
}

const mapStateToProps = state => ({
  apiCode: state.userReducer.apiCode,
  pickupHours: state.pickupReducer.pickupHours,
  businessName: state.userReducer.businessName,
})

const mapDispatchToProps = dispatch => ({
  updatePickupHours: (pickupHours) => dispatch(updatePickupHours(pickupHours)),
  savePickupHours: (requestData) => dispatch(savePickupHours(requestData))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPickupHours))
