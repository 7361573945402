import React, { useEffect, useState } from 'react'
import { SubTitle } from '../../../components/text'
import { Flex } from '../../../components/containers'
import { connect } from 'react-redux'


import box from '../../../assets/icons/box.png'
import { HtmlTooltip } from '../../../components/InputField'
import InfoIcon from '../../../assets/icons/Info-icon.svg'
import SizingButtonSingle from './SizingButtonSingle'
import { SizeDescription } from './shared'



function SizeSection(props) {
  const {  sizeCategory, deeleeoDetail, validation } = props;
  const [error, setError] = useState(validation);

  useEffect(() => {
    setError(validation);
  }, [validation])

  return (
    <div>
      <SubTitle className='mt-6 mb-3'>Select Weight & Size *
        <HtmlTooltip
          // style={{ marginLeft: 7 }}
          placement="top"
          title={'Please identify all the items within your delivery order by selecting each size and weight category that corresponds to each item within your delivery order, and the number of packages for each size/weight category. Thank you.'}>

          <img
            style={{ display: 'inline-block' }}
            className="-mt-1 ml-1"
            height={20}
            width={20}
            src={InfoIcon}
            alt={"premium information"}
          />
        </HtmlTooltip>        
      </SubTitle>
      <div className='mt-8 w-1/2 first:mr-12' style={{ fontSize: 12, padding: 0, marginTop:10, marginBottom:10 }}>Please select all that apply for this delivery order.</div>

      {!error &&
        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
          Please Select Size & Weight
        </p>
      }

      {/* <Grid2 error={validation}>
          {sizeCategory.map((item, i) =>
            <SizingButton key={i} sizeData={item} deeleeoDetail={deeleeoDetail}></SizingButton>)
          }
      </Grid2> */}

      <div className='block border-2 rounded-md mb-2'>
        <div className='grid grid-cols-3 gap-4'>
          <SizeDescription sizeTitle={'SMALL'} icon={box} height={'30px'}
            sizeDescription={'Largest dimension of the item(s) is equal to or less than 20 inches (51 cm)'} />

          <div className='col-span-2'>
            <SizingButtonSingle key={0} sizeData={sizeCategory[0]} ></SizingButtonSingle>
            <SizingButtonSingle key={1} sizeData={sizeCategory[1]} ></SizingButtonSingle>
          </div>
        </div>
      </div>

      <div className='block border-2 rounded-md mb-2'>
        <div className='grid grid-cols-3 gap-4'>
          <SizeDescription sizeTitle={'MEDIUM'} icon={box} height={'45px'}
            sizeDescription={'Largest dimension of the item(s) is equal to or less than 4 feet (122 cm)'} />

          <div className='col-span-2'>
            <SizingButtonSingle key={2} sizeData={sizeCategory[2]} ></SizingButtonSingle>
            <SizingButtonSingle key={3} sizeData={sizeCategory[3]} ></SizingButtonSingle>
          </div>
        </div>
      </div>

      <div className='block border-2 rounded-md mb-2'>
        <div className='grid grid-cols-3 gap-4'>
          <SizeDescription sizeTitle={'LARGE'} icon={box} height={'60px'}
            sizeDescription={'Largest dimension of the item(s) is equal to or less than 8 feet (244 cm)'} />

          <div className='col-span-2'>
            <SizingButtonSingle key={4} sizeData={sizeCategory[4]} ></SizingButtonSingle>
            <SizingButtonSingle key={5} sizeData={sizeCategory[5]} ></SizingButtonSingle>
          </div>
        </div>
      </div>

      <Flex className="flex-row-reverse">
        <div className='border-b-4 border-indigo-500 font-bold text-lg'>Total package: {deeleeoDetail.package_count}</div>
      </Flex>
      

      {/* <div className='block border-2 rounded-md mb-2'>
        <div className='grid grid-cols-3 gap-4'>
          <SizeDescription sizeTitle={'COMMERCIAL'} icon={commercial} sizeDescription={'Commercial sized loads'} />

          <div className='col-span-2 my-3'>
            <SizingButton key={6} sizeData={sizeCategory[6]} deeleeoDetail={deeleeoDetail}></SizingButton>
          </div>
        </div>
      </div> */}

    </div>
  )
}

const mapStateToProps = state => ({
  sizeCategory: state.sizeCategoryReducer.data,
  deeleeoDetail: state.deeleeoReducer,
  validation: state.validationReducer.size_weight_selection
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SizeSection)
