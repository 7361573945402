import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Flex } from '../../../components/containers'
import { DeleteIcon, EditIcon } from '../../../components/styled-icons'
import Spinner from '../../../components/Spinner'
import moment from 'moment'
import { deleteRemoveFromList } from '../../../redux/reducers/deeleeosReducer'
import { editDeeleeo } from '../../../redux/reducers/deeleeoReducer'
import { updateDropoffData, updatePickupData } from '../../../redux/reducers/pickupDropoffReducer'
import { connect } from 'react-redux'
import { routes } from '../../../routes'
import { withRouter } from 'react-router-dom'
import { gatheringDeeleeoDataForEditing } from '../../../helpers/deeleeoHelper'
import { useRecoilState } from 'recoil'
import { userDeeleeosManyToOne, userDeeleeosManyToOneIsDirty, userDeeleeosOneToMany, userDeeleeosOneToManyIsDirty } from '../../../App'
import { HtmlTooltip } from '../../../components/InputField'
import InfoIcon from '../../../assets/icons/Info-icon.svg'


const BusinessName = styled.div`
  ${tw`text-fontBlue uppercase mb-1`}
  font-family: Varela Round;
  font-size: 16px;
`
const ContactDetails = styled.div`
  ${tw`text-fontBlue mt-1`}
  font-family: Varela Round;
  font-size: 12px;
`
const FeeAmount = styled.div`
  ${tw`text-fontBlue flex`}
  font-family: Varela Round;
  font-size: 14px;
`
const LightText = styled.div`
  color: #000;
  font-family: Varela Round;
  font-size: 14px;
`
const DeliveryInfo = styled(BusinessName)`
  font-size: 14px;
`

function LineItem(props) {
  const { clickHandler, deeleeoInfo, deleteRemoveFromList, editDeeleeo, updateDropoffData, updatePickupData, history, index, isOneToMany, isManyToOne } = props

  const getUnitAndAddress = (text) => {
    const [defaultAddress, unit, address] = text.split(/#([0-9]*|home [0-9]*|apartment [0-9]*)\s/g);
    return defaultAddress ? [defaultAddress] : [address, unit ? unit : '']
  }
  const [deeleeosManyToOne, setDeeleeosManyToOne] = useRecoilState(userDeeleeosManyToOne);
  const [deeleeosOneToMany, setDeeleeosOneToMany] = useRecoilState(userDeeleeosOneToMany);
  // eslint-disable-next-line no-unused-vars
  const [deeleeosOneToManyIsDirty, setDeeleeosOneToManyIsDirty] = useRecoilState(userDeeleeosOneToManyIsDirty);
  // eslint-disable-next-line no-unused-vars
  const [deeleeosManyToOneIsDirty, setDeeleeosManyToOneIsDirty] = useRecoilState(userDeeleeosManyToOneIsDirty);

  const getDeliveryTypeText = () => {
    // console.log('dero off type text', deeleeoInfo.dropoff_type);

    // console.log(deeleeoInfo);

    if (deeleeoInfo.do_special_time) {
      return 'Special Delivery Timing'
    }

    return 'Delivery Window'

    // switch (deeleeoInfo.dropoff_type) {
    //   case 1:
    //     return 'Same day'
    //   case 2:
    //     return 'Scheduled'
    //   case 3:
    //   case 5:
    //     return 'Urgent'
    //   default:
    //     return ''
    // }
  }

  const getTime = (timestamp) => {
    return moment(timestamp).format('YYYY-MM-DD HH:mm');
  }

  const deleteBtnHandle = (event) => {
    if (isOneToMany) {
      setDeeleeosOneToManyIsDirty(true);
      /**
       * Updates the collection removing this deeleeo
       */
      setDeeleeosOneToMany(
        deeleeosOneToMany.filter(
          (e) => {
            return e.uuid !== deeleeoInfo.uuid;
          }
        )
      );

      return false;
    }

    if (isManyToOne) {
      setDeeleeosManyToOneIsDirty(true);
      /**
      * Updates the collection removing this deeleeo
      */
      setDeeleeosManyToOne(
        deeleeosManyToOne.filter(
          (e) => {
            return e.uuid !== deeleeoInfo.uuid;
          }
        )
      );

      return false;
    }


    deleteRemoveFromList(deeleeoInfo);
  }

  const editBtnHandle = (event) => {
    const [dropOffAddress, dropOffUnit] = getUnitAndAddress(deeleeoInfo.dropoff_address);
    const dropoff_data = {
      name: deeleeoInfo.dropoff_name,
      address: dropOffAddress,
      unit: dropOffUnit,
      phonenumber: deeleeoInfo.dropoff_phonenumber,
      email: deeleeoInfo.dropoff_email,
      latitude: deeleeoInfo.dropoff_latitude,
      longitude: deeleeoInfo.dropoff_longitude
    }
    // update dropoff data
    updateDropoffData(dropoff_data);

    /**
     * When editing manyToOne or oneToMany, we need to set the pickup address
     */
    if (isManyToOne || isOneToMany || deeleeoInfo.is_valet) {
      const [pickupAddress, pickupUnit] = getUnitAndAddress(deeleeoInfo.pickup_address);
      const pickUp_data = {
        name: deeleeoInfo.pickup_name,
        address: pickupAddress,
        unit: pickupUnit,
        phonenumber: deeleeoInfo.pickup_phonenumber,
        email: deeleeoInfo.pickup_email,
        latitude: deeleeoInfo.pickup_latitude,
        longitude: deeleeoInfo.pickup_longitude

      }

      updatePickupData(pickUp_data);

    }

    // extract needed data for deeleeo data
    let deeleeo_data = gatheringDeeleeoDataForEditing(deeleeoInfo, index);


    /**
     * When editing manyToOne or oneToMany 
     * we need to preserve all the data of the original deeleeo, 
     * so that it can be resubmitted after the update
     */
    if (isManyToOne || isOneToMany) {

      deeleeo_data = { ...deeleeoInfo, ...deeleeo_data };

      if (isManyToOne) {
        /**
         * add this property to discriminate actions on saving method
         */
        deeleeo_data.isManyToOne = true;
        setDeeleeosManyToOneIsDirty(true);
      }

      if (isOneToMany) {
        /**
         * add this property to discriminate actions on saving method
         */
        deeleeo_data.isOneToMany = true;
        setDeeleeosOneToManyIsDirty(true);
      }
    }

    // update deeleeo data
    editDeeleeo(deeleeo_data);
    if (deeleeoInfo.is_valet) {
      history.push(routes.createDeeleeoValet);
    } else {
      history.push(routes.createDeeleeo);
    }

  }

  /**
   * Checks for alternative property for delivery_fee
   * @returns number
   */
  const getDeliveryFee = () => {
    if (deeleeoInfo.deliveryFee) {
      return deeleeoInfo.deliveryFee;
    } else {
      return deeleeoInfo.delivery_fee.toFixed(2);
    }
  }

  /**
     * Checks for alternative property for premiums_fee
     * @returns number
     */
  const getPremiumFee = () => {
    if (deeleeoInfo.premiumFee) {
      return deeleeoInfo.premiumFee;
    } else {
      return deeleeoInfo.premiums_fee.toFixed(2);
    }
  }

  const getDeliveryText = (time) => {
    let now = moment();
    let displaying = moment(time);
    let difference = displaying.diff(now, 'days')

    if (difference === 0) {
      return 'TODAY'
    } else {
      return displaying.format('yyyy/MM/DD');
    }
  }


  const getDeliveryInfo = (deeleeoInfo) => {

    getDeliveryText(deeleeoInfo.dropoff_later_datetime)

    if (deeleeoInfo.is_valet) {

      return <>
        <DeliveryInfo>valet {getDeliveryText(deeleeoInfo.dropoff_later_datetime)}</DeliveryInfo>
        <LightText>{getTime(deeleeoInfo.pickup_later_datetime)} - DIRECT</LightText>
      </>

    }

    return <>
      <DeliveryInfo>{getDeliveryTypeText()} </DeliveryInfo>
      <LightText>{getTime(deeleeoInfo.dropoff_early_datetime)} - {getTime(deeleeoInfo.dropoff_later_datetime)}</LightText>
    </>



  }

  /**
   * 
   * Used to display the icon with tooltip with  extra fees
   */
  const displayExtraFees = () => {
    if (deeleeoInfo.premiums_fee_detail) {
      const detail = deeleeoInfo.premiums_fee_detail;
      for (const key in detail) {

        if (detail.hasOwnProperty(key)) {

          if (detail[key] > 0) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Gets the tooltip content for extra fees 
   */
  const getTitle = () => {
    let packageFee = 0;
    let fragileFee = 0;
    let tempFee = 0;
    let inPersonFee = 0;
    let ageFee = 0;
    let specialTimeFee = 0;

    let packageFeeDiv = '';
    let fragileFeeDiv = '';
    let tempFeeDiv = '';
    let inPersonFeeDiv = '';
    let ageFeeDiv = '';
    let specialTimeDiv = '';

    if (deeleeoInfo.premiums_fee_detail) {
      const detail = deeleeoInfo.premiums_fee_detail;
      // console.log('details: ', detail);
      ageFee = (detail.age_restricted_fee / 100).toFixed(2);
      inPersonFee = (detail.inperson_confirmation_fee / 100).toFixed(2);
      packageFee = (detail.package_count_fee / 100).toFixed(2);
      tempFee = (detail.refrigerated_vehicle_fee / 100).toFixed(2);
      fragileFee = (detail.special_care_fee / 100).toFixed(2);
      specialTimeFee = (detail.do_special_time_fee / 100).toFixed(2);

      if (packageFee > 0) {
        packageFeeDiv = `<div class='text-left'>&#x2022; Extra Package(s) Fee: ${packageFee}</div>`;
      }

      if (fragileFee > 0) {
        fragileFeeDiv = `<div class='text-left'>&#x2022; Fragile Fee: ${fragileFee}</div>`;
      }

      if (tempFee > 0) {
        tempFeeDiv = `<div class='text-left'>&#x2022; Temp-controlled Fee: ${tempFee}</div>`;
      }

      if (inPersonFee > 0) {
        inPersonFeeDiv = `<div class='text-left'>&#x2022; In-person Delivery Fee: ${inPersonFee}</div>`;
      }

      if (ageFee > 0) {
        ageFeeDiv = `<div class='text-left'>&#x2022; Age Restricted Delivery Fee: ${ageFee}</div>`;
      }

      if (specialTimeFee > 0) {
        specialTimeDiv = `<div class='text-left'>&#x2022; Special Time Delivery Fee: ${specialTimeFee}</div>`;
      }

    }

    return `
      <h5>Extra Fees</h5>    
      ${packageFeeDiv}
      ${fragileFeeDiv}
      ${tempFeeDiv}
      ${inPersonFeeDiv}
      ${ageFeeDiv}
      ${specialTimeDiv}
          `;
  }

  // console.log(deeleeoInfo);

  return deeleeoInfo && (
    <div onClick={() => clickHandler(deeleeoInfo)} style={{ cursor: 'pointer' }} className='flex w-full pb-4'>

      <div className='w-1/2'>
        <BusinessName>{(deeleeoInfo.bundle_id ? 'Bundle:' + deeleeoInfo.bundle_id + ' ' : '') + deeleeoInfo.dropoff_name}</BusinessName>
        <ContactDetails>{deeleeoInfo.dropoff_address}</ContactDetails>
        <ContactDetails>{deeleeoInfo.dropoff_phonenumber}</ContactDetails>
        <ContactDetails>{deeleeoInfo.dropoff_email}</ContactDetails>
      </div>

      <div className='w-1/2'>
        <Flex className='mb-3'>
          <div className='w-36'>
            <LightText>Delivery fee</LightText>
            <LightText>Premium fee</LightText>
            {deeleeoInfo.service_admin_fee && <LightText>Service & Admin Fee</LightText>}
            {deeleeoInfo.package_count > 0 && <LightText>Package Count</LightText>}
          </div>
          <div>
            <FeeAmount>${deeleeoInfo.delivery_fee === 0 ? <Spinner /> : getDeliveryFee()} <DeleteIcon onClick={deleteBtnHandle} /> <EditIcon className='ml-2' onClick={editBtnHandle} />

            </FeeAmount>
            <FeeAmount>${deeleeoInfo.delivery_fee === 0 ? <Spinner /> : getPremiumFee()}
              {displayExtraFees() && <HtmlTooltip
                placement="top"
                title={<div dangerouslySetInnerHTML={{ __html: getTitle() }} />}>
                <img
                  style={{ display: 'inline-block' }}
                  className="-mt-1 ml-1"
                  height={20}
                  width={20}
                  src={InfoIcon}
                  alt={"premium information"}
                /></HtmlTooltip>}
            </FeeAmount>
            <FeeAmount>${deeleeoInfo.service_admin_fee}</FeeAmount>
            {deeleeoInfo.package_count > 0 && <FeeAmount>{deeleeoInfo.package_count}</FeeAmount>}
          </div>
        </Flex>

        {getDeliveryInfo(deeleeoInfo)}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
  deleteRemoveFromList: (data) => dispatch(deleteRemoveFromList(data)),
  editDeeleeo: (data) => dispatch(editDeeleeo(data)),
  updateDropoffData: (data) => dispatch(updateDropoffData(data)),
  updatePickupData: (data) => dispatch(updatePickupData(data))

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LineItem))
