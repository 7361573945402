import { getURI } from '../../helpers/apiHelpers'

export const pickupActions = Object.freeze({
  UPDATE_PICKUP_LOCATION: 'UPDATE_PICKUP_LOCATION',
  CREATE_PICKUP_LOCATION_REQUEST: 'CREATE_PICKUP_LOCATION_REQUEST',
  CREATE_PICKUP_LOCATION_SUCCESS: 'CREATE_PICKUP_LOCATION_SUCCESS',

  UPDATE_PICKUP_HOURS: 'UPDATE_PICKUP_HOURS',
  UPDATE_PICKUP_HOURS_REQUEST: 'UPDATE_PICKUP_HOURS_REQUEST',
  UPDATE_PICKUP_HOURS_SUCCESS: 'UPDATE_PICKUP_HOURS_SUCCESS',
})

export const updatePickupLocation = (pickupLocationInfo) => {
  return {
    type: pickupActions.UPDATE_PICKUP_LOCATION,
    data: pickupLocationInfo
  }
}

export const updatePickupHours = (pickupHoursInfo) => {
  return {
    type: pickupActions.UPDATE_PICKUP_HOURS,
    data: pickupHoursInfo
  }
}


export const createPickupLocation = (requestData) => dispatch => {
  const request = () => ({type: pickupActions.CREATE_PICKUP_LOCATION_REQUEST})
  const success = () => ({type: pickupActions.CREATE_PICKUP_LOCATION_SUCCESS})

  dispatch(request())

  fetch(getURI('/api/user/businessPickupAddress'), {
    method: 'POST',
    body: JSON.stringify(requestData),
    headers: {
      'Content-Type': 'application/json',
    }

  })
    .then(response => response.text())
    .then(response => {
      const pickupLocationInfo = JSON.parse(response)
      dispatch(updatePickupLocation(pickupLocationInfo))
      dispatch(success())
    })
}

export const savePickupHours = (requestData) => dispatch => {
  const request = () => ({type: pickupActions.UPDATE_PICKUP_HOURS_REQUEST})
  const requestSuccess = () => ({type: pickupActions.UPDATE_PICKUP_HOURS_SUCCESS})

  dispatch(request())

  fetch(getURI('/api/user/businessPickupHours'), {
    method: 'PATCH',
    body: JSON.stringify(requestData),
    headers: {
      'Content-Type': 'application/json',
    }

  })
    .then(response => response.text())
    .then(response => {
      // const { success } = JSON.parse(response)
      // console.log(success)
      // dispatch(updatePickupLocation(pickupLocationInfo))
      dispatch(requestSuccess())
    })
}

