import { AddCircleOutlineOutlined, RemoveCircleOutline } from "@material-ui/icons";
import axios from "axios";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useTable, useExpanded } from "react-table";
import { useRecoilState } from "recoil";
import { apiKeyAtom } from "../../../App";
import { ActionButton, Styles, TableShadow } from "../../../components/styledTableHelpers";
import { getURI } from "../../../helpers/apiHelpers";


const Contacts = (props) => {

    const { setActiveAddress } = props;

    const [adrresses, setAddresses] = useState([]);
    const data = adrresses;

    // eslint-disable-next-line no-unused-vars
    const [apiKey, setApiKey] = useRecoilState(apiKeyAtom);

    /**
     * Load Addresses
     */
    useEffect(() => {
        if (apiKey && apiKey !== '') {
            axios
                .get(getURI(`/api/web/user-contact/dt?` + new URLSearchParams({ api_code: apiKey })))
                .then((response) => {
                    if (response.data?.data) {
                        setAddresses(response.data.data);
                    }

                }).catch((error) => {
                    console.log(error);
                })
        }
    }, [apiKey])


    const columns = useMemo(() =>
        [
            {
                Header: () => null,
                id: 'expander', // 'id' is required
                Cell: ({ row }) => (
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? <RemoveCircleOutline style={{ fill: "blue" }} /> : <AddCircleOutlineOutlined style={{ fill: "blue" }} />}
                    </span>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
                headerClassName: "sticky"
            },
            {
                Header: 'Address',
                accessor: 'address'
            },
            // {
            //     Header: 'Unit',
            //     accessor: 'unit'
            // },
            // {
            //     Header: 'Phone',
            //     accessor: 'phone'
            // },
            // {
            //     Header: 'Email',
            //     accessor: 'email'
            // },
            // {
            //     Header: 'Latitude',
            //     accessor: 'latitude'
            // },
            // {
            //     Header: 'Longitude',
            //     accessor: 'longitude'
            // },
            {
                Header: 'Pickup',
                accessor: 'is_pickup'
            }
            ,
            {
                Header: 'Drop Off',
                accessor: 'is_dropoff'
            }
            ,
            {
                Header: 'Actions',
                accessor: ''
            }
        ], []
    )

    const tableInstance = useTable({ columns, data }, useExpanded)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns
    } = tableInstance;

    /**
     * Handles removing a card
     * @param {*} row 
     */
    const handleRemove = (row) => {

        const removePayload = {
            api_code: apiKey
        };
        /**
         * Remove from cards
         */
        axios.delete(getURI(`/api/web/user-contact/${row.original.id}?` + new URLSearchParams(removePayload)))
            .then((result) => {
                window.location.reload();
            })
            .catch(e => console.log(e));
    }



    const handleRowClick = (row) => {
        setActiveAddress({ ...row, latitude: parseFloat(row.latitude), longitude: parseFloat(row.longitude) });
    }

    const expandedRow = (row) => {
        return (
            <tr>
                <td colSpan={visibleColumns.length}>
                    <div className="py-2 container  px-2">
                        <div className="relative">
                            <div className="m-0 p-0">
                                <div className="flex group items-center  ">
                                    <div className="flex-1 ml-4 z-10 font-medium ">
                                        <div className="order-1 space-y-1  bg-gray-200 rounded-lg">
                                            {row.original.unit &&
                                                <>
                                                    <div className="  inline-block font-bold text-black">Unit: </div> <div className="  inline-block">{row.original.unit}</div><br />
                                                </>}
                                            <div className="  inline-block font-bold text-black">Email: </div> <div className="  inline-block">{row.original.email}</div><br />
                                            <div className="  inline-block font-bold text-black">Phone: </div> <div className="  inline-block">{row.original.phone}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    return (<>
        <Styles>
            <TableShadow>
                <table {...getTableProps()} >

                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} style={{ position: 'sticky', top: 3, backgroundColor: 'white' }}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr {...row.getRowProps()}
                                        onClick={() => { handleRowClick(row.original) }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {row.cells.map(cell => {
                                            switch (cell.column.Header) {

                                                /**
                                                 * Add the buttons to the actions column
                                                 */
                                                case 'Actions':
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            <ActionButton onClick={() => handleRemove(row)}>Remove</ActionButton>
                                                            {/* <ActionButton onClick={() => handleEdit(row)}>Edit</ActionButton> */}
                                                        </td>
                                                    )
                                                case 'Pickup':
                                                    return <td {...cell.getCellProps()}>
                                                        {row.original.is_pickup === 1 && <div>YES</div>}
                                                        {row.original.is_pickup === 0 && <div>NO</div>}
                                                    </td>
                                                case 'Drop Off':
                                                    return <td {...cell.getCellProps()}>
                                                        {row.original.is_dropoff === 1 && <div>YES</div>}
                                                        {row.original.is_dropoff === 0 && <div>NO</div>}
                                                    </td>

                                                default:
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                            }

                                        })}
                                    </tr>
                                    {row.isExpanded && expandedRow(row)}
                                </Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </TableShadow>
        </Styles>
    </>);
}

export default Contacts;