import React, { useEffect, useState } from 'react'
import { getGeocode, } from 'use-places-autocomplete'
import { HtmlTooltip, InputContainer, StyledInput, StyledLabel } from './InputField'
import styled from 'styled-components'
import tw from 'twin.macro'
import axios from 'axios'
import { getURI } from '../helpers/apiHelpers'
import {  Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import InfoIcon from "../assets/icons/Info-icon.svg";

const StyledSuggestionList = styled.ul`
  ${tw`absolute mt-14 bg-white w-full px-4 py-2 shadow-lg z-10`}
`

const StyledListItem = styled.li`
  ${tw`cursor-pointer my-3`}
`

export const StyledToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#efefef',
    maxWidth: '365px',
    padding: '20px',
    fontSize: '12px',
    color: 'black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  },
  arrow: {
    color: '#efefef',
  }
}))(Tooltip)




/**
  * Checks if we have at least 2 words separated by 2 dots.
  * It's a requirement for W3W api
  * @param {String} input 
  * @returns bool
  */
export const checkWhat3WordsString = (input) => {
  const array = input.split('.');
  //console.log(array);
  return array.length > 2;
}

/**
   * Checks if the string is a what3words string address
   * @param {String} address 
   * @returns 
   */
export const isw3wAddress = (address) => {
  return address.startsWith('///') && checkWhat3WordsString(address);
}


const What3WordsField = (props) => {
  const { id, label, type, onChange, onBlur, error, helperText } = props



  /**
   * The value of the input
   */
  const [value, setValue] = useState('');
  /**
   * to track the ajax status
   */
  const [status, setStatus] = useState('');
  /**
   * array of suggestions from W3W
   */
  const [suggestions, setSuggestions] = useState([]);

  /**
   * Selection Made
   */
  const [selected, setSelected] = useState(false);

 

  /**
   * Query the suggestion
   */
  useEffect(() => {
    /**
     * Only query the server if 2 words have been entered
     */
    // console.log('checkWhat3WordsString: ');
    // console.log(checkWhat3WordsString(value));

    // console.log('selected: ');
    // console.log(selected);

    if (checkWhat3WordsString(value)&&!selected) {
      //setSuggestions([]);
      /**
       * Build payload
       */
      const payload = { seed: value };
      /**
       * Do POST request
       */
      const loadSuggestions = () => {

        axios.post(getURI('/api/web/deeleeo/what3words/suggestions'), payload)
          .then(response => {
            //console.log(response.data.suggestions);
            /**
             * Get the suggestions
             */
            setSuggestions(response.data.suggestions)
            if (suggestions.length > 0) {
              setStatus('OK');
            }
          });
      }
      loadSuggestions();
    }

  }, [suggestions.length, value, selected])


  /**
   * Handles the input event
   * @param {ReactEvent} e 
   */
  const handleInput = (e) => {
    //console.log(e.target.value);
    /**
     * Sets a new value
     */
    setValue(e.target.value);
    /**
     * Ajax status is reset
     */
    setStatus('');
  };


  /**
   * Handles the selection of one of the W3W suggestions
   * 
   * @param {Object} suggestion 
   * @returns void
   */
  const handleSelect = (suggestion) => () => {
    setSelected(true);
    /**
     * Builds the payload to get the coordinates
     */
    const payload = {
      what3words: suggestion.words
    }


    /**
     * Post the server to get coordinates from the 3 words
     */
    axios.post(getURI('/api/web/deeleeo/what3words/convertToCoords'), payload)
      .then(response => {
        /**
         * Get the coordinates lat,lng
         */
        const coordinates = response.data.coordinates;

        // console.log('convertTocoords: ');
        // console.log(response.data);

        /**
         * Build parameters for google
         */
        const parameter = {
          location: coordinates
        };

       /**
        * Display the selected value with the 3 slashes
        */
        setValue('///' + suggestion.words);
        //onChange(response.data);
        /**
         * Now query google to get suggestions for that coordinate
         */
        getGeocode(parameter)
          .then((details) => {
            /**
             * trigger on change
             */

            onChange({ 'w3w': response.data, 'google': details });

          })
          .catch((error) => {
            console.log("Error: ", error);
          });

      });

    /**
     * Clean the W3W suggestions
     */
    setSuggestions([]);
    /**
     * Clear the W3W ajax status
     */
    setStatus('');
    /**
     * Clear the input
     */
    
  };

  /**
   * Render the W3W suggestions
   * @returns 
   */
  const renderSuggestions = () => {
    return suggestions.map((suggestion, index) => {
      return (
        <StyledListItem key={index} onClick={handleSelect(suggestion)}>
          <strong>{suggestion.words}</strong> <br /> <small>{suggestion.nearestPlace + ', ' + suggestion.country}</small>
        </StyledListItem>
      );
    });
  }

  /**
   * Set the input ready for more validation
   * @param {event} e 
   */
  const doTheReset = (e) => {
    setValue(e.target.value);
    setSelected(false);
  }


  const labelJsx =
    <StyledLabel error={error} htmlFor={id}>{label}
      <HtmlTooltip
        style={{ marginLeft: 7 }}
        placement="top"
        title="what3words is a new and incredibly accurate addressing system Deeleeo is implementing.
                     We encourage each user to find the ‘what3word address’ for your pickup/drop off locations by
                     using the link button below. This is optional and a normal address will still work, however,
                     we at Deeleeo like what3word addresses more. Go and find you what3word address now!
                     You will be glad you did!">

        <img
          style={{ display: 'inline' }}
          className="-mt-1 ml-1"
          src={InfoIcon}
          alt={"premium information"}
        />
      </HtmlTooltip>
    </StyledLabel>;


  return (
    <>
      <InputContainer className='relative'>
        {labelJsx}
        <StyledInput
          autocomplete='off'
          error={error}
          type={type}
          id={id} name={id}
          onBlur={onBlur}
          onInput={handleInput}
          onPaste={(e) => doTheReset(e)}
          onKeyUp={ (e)=>doTheReset(e)}
          value={value}
        />

        {error && <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>{helperText}</p>}
        {status === 'OK' && <StyledSuggestionList>{renderSuggestions()}</StyledSuggestionList>}

      </InputContainer>
      <InputContainer style={{ marginBottom: 0 }} className='relative'>
        <a style={{ padding: '3 0', color: 'blue', fontSize: 13 }} href='https://what3words.com/' target={'_blank'} rel={'noreferrer'}>Find the what3words address</a>
      </InputContainer>
    </>
  );
}

export default What3WordsField
