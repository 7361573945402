import React, { useEffect, useState } from "react";
import originIcon from '../../../assets/icons/green-pin.svg'
import destinationIcon from '../../../assets/icons/red-pin.svg'
import driverIcon from '../../../assets/icons/driver-pin.png'
import {
  // useLoadScript,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
  // useGoogleMap
} from "@react-google-maps/api";
import axios from "axios";
import { getURI } from "../../../helpers/apiHelpers";
import ErrorBoundary from "../../../helpers/ErrorBoundary";


const DashboardMap = (props) => {
  const { apiCode, deeleeoView, selectedDeeleeo, latLng } = props;

  // /**
  //  * Load google map script
  //  */
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: 'AIzaSyAiTYNynAOh-qIxDDKSGsuQGrSPxcduCZo' // ,
  //   // ...otherOptions
  // })


  // eslint-disable-next-line no-unused-vars
  const [center, setCenter] = useState({
    lat: 53.541427,
    lng: -113.500434
  });


  const [deeleeoCoords, setDeeleeoCoords] = useState([]);
  const [pickUp, setPickUp] = useState(null);
  const [dropOff, setDropOff] = useState(null);

  /**
   * Map reference
   */
  const [map, setMap] = useState(null);
  // const [bounds, setBounds] = useState(null);

  const [lastRequestDrivers, setLastRequestDrivers] = useState(0);
  const [lastRequestDeeleeos, setLastRequestDeeleeos] = useState(0);
  const [drivers, setDrivers] = useState([]);
  const [deeleeos, setDeeleeos] = useState([]);
  const [activeDriver, setActiveDriver] = useState(null);
  const [activeDeeleeo, setActiveDeleeo] = useState(null);
  const [bundleId, setBundleId] = useState(null);
  const [bundledeeleeos, setBundleDeeleeos] = useState([]);

  useEffect(() => {
    if (latLng.length > 0) {
      setCenter({ lat: latLng[0], lng: latLng[1] });
    }
  }, [latLng])


  /**
   * Get's drivers
   */
  let controllerDrivers = new AbortController();
  const getDrivers = () => {
    controllerDrivers.abort();
    controllerDrivers = new AbortController();
    axios.get(getURI(`/api/user/getOnlineDrivers?api_key=${apiCode}`), {
      signal: controllerDrivers.signal
    })
      .then((data) => {
        // console.log(data.data);
        setDrivers(data.data);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  /**
   * Request drivers every 5 seconds
   */
  useEffect(() => {

    getDrivers();
    // eslint-disable-next-line no-unused-vars
    const driversInterval = setTimeout(() => {
      // const d = new Date();
      setLastRequestDrivers(Date.now());
      // console.log('timer');
      // getDrivers();
    }, 30000);
    return () => {
      controllerDrivers.abort();
      clearTimeout(driversInterval)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRequestDrivers]);


  /**
   * Get Deeleeos
   */
  let controllerDeeleeos = new AbortController();
  const getDeeleeos = () => {
    controllerDeeleeos.abort();
    controllerDeeleeos = new AbortController();
    axios.get(getURI(`/api/user/getDeeleeoPositions?api_key=${apiCode}&deeleeoView=${deeleeoView}`), {
      signal: controllerDeeleeos.signal
    })
      .then((data) => {
        // console.log(data.data);
        /**
         * Append a pickup, dropoff to every deeleeo
         */
        const result = data.data.map((deeleeo) => {
          const pickup = { lat: parseFloat(deeleeo.pickup.lat), lng: parseFloat(deeleeo.pickup.lng) };
          const dropoff = { lat: parseFloat(deeleeo.dropoff.lat), lng: parseFloat(deeleeo.dropoff.lng) };
          deeleeo.pickup = pickup;
          deeleeo.dropoff = dropoff;
          return deeleeo;

        });

        setDeeleeos(result);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  /**
   * Request deeleeos every 15 seconds
   */

  useEffect(() => {

    getDeeleeos();

    // eslint-disable-next-line no-unused-vars
    const deeleeosInterval = setTimeout(() => {
      // const d = new Date();
      setLastRequestDeeleeos(Date.now());
      // getDeeleeos();
    }, 30000);
    return () => {
      controllerDeeleeos.abort();
      clearTimeout(deeleeosInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRequestDeeleeos, deeleeoView]);


  const getBundleDeeleeosByView = () => {
    axios.get(getURI(`/api/user/getBundleDeeleeosByView?api_key=${apiCode}&deeleeoView=${deeleeoView}&bundleId=${bundleId}`))
      .then((data) => {
        // console.log(data.data);
        /**
         * Append a pickup, dropoff to every deeleeo
         */
        const result = data.data.map((deeleeo) => {
          // console.log(deeleeo);

          const pickup = { lat: parseFloat(deeleeo.pickup.lat), lng: parseFloat(deeleeo.pickup.lng) };
          const dropoff = { lat: parseFloat(deeleeo.dropoff.lat), lng: parseFloat(deeleeo.dropoff.lng) };
          deeleeo.pickup = pickup;
          deeleeo.dropoff = dropoff;
          return deeleeo;

        });

        setBundleDeeleeos(result);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  useEffect(() => {
    if (bundleId) {
      getBundleDeeleeosByView();
    } else {
      setBundleDeeleeos([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleId])
  /**
   * Use effect to set the view of the map to a bounding box of the deeleeo clicked
   */
  useEffect(() => {
    let coordinates = [];
    /**
     * Clean coordinates
     */
    setDeeleeoCoords(coordinates);
    let pickUp = null;
    let dropOff = null;
    setPickUp(pickUp);
    setDropOff(dropOff);
    if (selectedDeeleeo) {
      // console.log('set selected');
      // console.log(selectedDeeleeo);
      if (selectedDeeleeo.bundle_id) {
        setBundleId(selectedDeeleeo.bundle_id);
      } else {
        setBundleId(null);
      }
      /**
       * Set pick and drop off of clicked deeleeo
       */
      try {
        pickUp = { lat: parseFloat(selectedDeeleeo.pickup_latitude), lng: parseFloat(selectedDeeleeo.pickup_longitude) };
        dropOff = { lat: parseFloat(selectedDeeleeo.dropoff_latitude), lng: parseFloat(selectedDeeleeo.dropoff_longitude) };
        setPickUp(pickUp);
        setDropOff(dropOff);
      } catch (e) {
        console.log(e);
      }
      const coords = JSON.parse(selectedDeeleeo.coords);
      /**
       * Build polyline coords of selected deeleeo
       */
      if (coords.polyline) {//On hold deeleeos do not have polyline
        for (let i = 0; i < coords.polyline.length; i++) {
          let coord = { lat: coords.polyline[i][0], lng: coords.polyline[i][1] };
          coordinates.push(coord);
        }
      }
    }

    /**
     * Populate deeleeo coordinates if any
     */
    // setDeeleeoCoords(coordinates);


    const bounds = new window.google.maps.LatLngBounds();
    let boundsModified = false;
    if (coordinates && coordinates.length > 0) {

      coordinates.forEach(element => {
        // console.log(element);
        boundsModified = true;
        bounds.extend(element);
      });


    }

    if (pickUp) {
      boundsModified = true;
      bounds.extend(pickUp);
    }

    if (dropOff) {
      boundsModified = true;
      bounds.extend(dropOff);
    }
    if (boundsModified) {
      // setBounds(bounds);
    }
    if (boundsModified && map) {
      map.fitBounds(bounds)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeeleeo])

  /**
   * When changing the view, we reset the map center and zoom level
   */
  useEffect(() => {

    if (map) {
      map.panTo(center);
      map.setZoom(11);
    }
    setActiveDeleeo(null);
    setActiveDriver(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeleeoView]);


  const containerStyle = {
    // width: '400px',
    height: '400px'
  };

  const windowTitleStyle = {
    fontWeight: 'bold'
  }

  const renderMap = () => {


    return (
      <GoogleMap
        mapContainerStyle={containerStyle}

        zoom={11}
        center={center}
        onLoad={map => setMap(map)}//To use a map reference
      >
        {deeleeoCoords.length > 0 && <Polyline
          key={`poly`}
          path={deeleeoCoords}
          geodesic={true}
          options={{
            strokeColor: 'rgba(0,0,200,.7)',
            strokeOpacity: 0.75,
            strokeWeight: 6,
          }}
        />}

        {(!bundleId && pickUp) && <Marker
          key={`pickUp`}
          icon={originIcon}
          position={pickUp}
        />}

        {(!bundleId && dropOff) && <Marker
          key={`dropOff`}
          icon={destinationIcon}
          position={dropOff}
        />}

        {drivers.map((driver, index) => {
          const point = { lat: parseFloat(driver.latitude), lng: parseFloat(driver.longitude) };
          return <Marker
            key={index}
            icon={driverIcon}
            position={point}
            onClick={() => {
              // console.log(driver);

              setActiveDriver(driver);
            }} />


        })}

        {activeDriver && <ErrorBoundary>
          <InfoWindow
            key={`activeDriverWindow`}
            position={{ lat: parseFloat(activeDriver.latitude), lng: parseFloat(activeDriver.longitude) }}
            onCloseClick={() => setActiveDriver(null)}
            onDomReady={() => setActiveDeleeo(null)}
          >
            <div>
              <div><span style={windowTitleStyle}>Name: </span>{activeDriver.driver.first_name}</div>
              <div><span style={windowTitleStyle}>Mobile: </span>825-255-5778</div>
              {activeDriver.driver.activeDeeleeos.length > 0 && <div><span style={windowTitleStyle}>Deeleeos Enroute:</span></div>}
              {activeDriver.driver.activeDeeleeos.map((e) => <div key={e.id}>{e.id}</div>)}
            </div>
          </InfoWindow>
        </ErrorBoundary>}


        {activeDeeleeo && <ErrorBoundary>
          <InfoWindow
            key={`activeDeeleeoWindow`}
            position={activeDeeleeo.position}
            onCloseClick={() => setActiveDeleeo(null)}
            onDomReady={() => setActiveDriver(null)}
          >
            <div>
              <div><span style={windowTitleStyle}>Deeleeo: </span>{activeDeeleeo.deeleeo.id}</div>
              <div><span style={windowTitleStyle}>Name: </span>{activeDeeleeo.deeleeo.name}</div>
              <div><span style={windowTitleStyle}>Pickup: </span>{activeDeeleeo.deeleeo.pickupAddress}</div>
              <div><span style={windowTitleStyle}>Drop Off: </span>{activeDeeleeo.deeleeo.dropOffAddress}</div>
              <div><span style={windowTitleStyle}>Description: </span>{activeDeeleeo.deeleeo.description}</div>
            </div>
          </InfoWindow>
        </ErrorBoundary>}

        {bundledeeleeos.map((deeleeo, index) => {
          return <>
            <Marker
              key={`pb-${index}`}
              icon={originIcon}
              position={deeleeo.pickup}
              onClick={() => {
                // console.log('deeleeo clicked');
                // console.log(deeleeo);
                setActiveDeleeo({ deeleeo: deeleeo, position: deeleeo.pickup });
              }}
            />
            <Marker
              key={`db-${index}`}
              icon={destinationIcon}
              position={deeleeo.dropoff}
              onClick={() => {
                // console.log('deeleeo clicked');
                // console.log(deeleeo);
                setActiveDeleeo({ deeleeo: deeleeo, position: deeleeo.dropoff });
              }}
            />
          </>
        })}


        {deeleeos.map((deeleeo, index) => {

          if (bundleId) {
            return null;
          }

          return <>
            <Marker
              key={`p-${index}`}
              icon={originIcon}
              position={deeleeo.pickup}
              onClick={() => {
                // console.log('deeleeo clicked');
                // console.log(deeleeo);
                setActiveDeleeo({ deeleeo: deeleeo, position: deeleeo.pickup });
              }}
            />
            <Marker
              key={`d-${index}`}
              icon={destinationIcon}
              position={deeleeo.dropoff}
              onClick={() => {
                // console.log('deeleeo clicked');
                // console.log(deeleeo);
                setActiveDeleeo({ deeleeo: deeleeo, position: deeleeo.dropoff });
              }}
            />
          </>
        })}

      </GoogleMap>
    );


  }


  return renderMap();


};



export default DashboardMap 