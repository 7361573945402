import React, { useEffect, useState } from "react";
import { SubTitle } from '../../../components/text'
import { Flex, Row } from "../../../components/containers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {  StyledDatePicker, StyledToolTip, isValidCoordinate } from "./shared";
import { updatePickupTime, updateDropoffTime } from '../../../redux/reducers/deeleeoReducer'
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import styled from "styled-components";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from "../../../assets/icons/Info-icon.svg";


import { validatePickupTime, validateDropoffTime, validatePickupWindowTime, validateDropoffWindowTime } from "../../../redux/reducers/validationReducer";
// import { TimeField } from "./PickupHourSelector";
import moment from "moment";
import { useRecoilState } from "recoil";
import { isInterCity } from "../../../App";
import axios from "axios";
import { getURI } from "../../../helpers/apiHelpers";
import GenericModal from "../../Dashboard/components/GenericModal";
import PickupDropOffTimeProfile from "./PickupDropOffTimeProfile";






// const Dash = styled.div`
//   ${tw`text-fontBlue`}
// `

const StyledLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: 14px;
    font-family: Montserrat;
    color: #000000;
  }
`
export const RadioOption = (props) => {
  const { value, label, selectedValue, isDisabled, isHTMLLabel = false, labelAction } = props

  if (isHTMLLabel) {


    const renderLabel = () => {
      return { __html: label };
    }

    const handleLabelClick = () => {
      try {
        labelAction(value);
      } catch (err) {
        console.log(err);

      }
    }


    return <span className="MuiFormControlLabel-root sc-fIavCj gSfqoo" style={{}}>
      <Radio value={value} disabled={isDisabled} checked={value === selectedValue} />
      <div
        onClick={handleLabelClick}
        style={{
          fontSize: '14px',
          fontFamily: 'Montserrat',
          color: '#000000'
        }}
        dangerouslySetInnerHTML={renderLabel()}
      />

    </span>



  }

  return (
    <StyledLabel value={value} control={<Radio disabled={isDisabled} checked={value === selectedValue} />} label={label}
    />
  )
}

// get nearest round up quarter of hour (also use this to get +2hours)
export const roundTime = (time, minutesToRound) => {
  // console.log('roundtime', time, minutesToRound);

  let [hours, minutes] = time.split(':');
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  // Convert hours and minutes to time in minutes
  time = (hours * 60) + minutes;

  let rounded = Math.ceil(time / minutesToRound) * minutesToRound;
  let rHr = '' + Math.floor(rounded / 60)
  let rMin = '' + rounded % 60

  return rHr.padStart(2, '0') + ':' + rMin.padStart(2, '0')
}

export const getFutureTime = (base_date, base_time, add_minute) => {
  var converted_time = new Date(base_date + ' ' + base_time);
  var future_time_string = moment().toDate();
  future_time_string.setTime(converted_time.getTime() + (add_minute * 60 * 1000))
  return future_time_string.getHours() + ':' + ((future_time_string.getMinutes() < 10 ? '0' : '') + future_time_string.getMinutes())
}

/**
   * Gets a javascript date combining the selected pickup date and the time passed as parameter
   * @param {string} time 
   * @returns Date
   */
export const getDateWithTime = (date, time) => {
  let result = moment(date);
  if (time) {
    const timeString = moment(time, 'HH:mm');

    result.set({
      hour: timeString.get('hour'),
      minute: timeString.get('minute'),
      second: timeString.get('second')
    });
  }


  const target = result.toDate();
  return target;
}

export const isTodaySelected = (date) => {
  const format = 'YYYY-MM-DD';
  const now = moment();
  const selected = moment(date);

  return now.format(format) === selected.format(format);

}

function PickupDropoffTimeSection(props) {
  // const pickupOptions = {
  //   READY_NOW: '1',
  //   SCHEDULE: '2',
  //   URGENT: '3'
  // }

  const dropoffOptions = {
    SAME_DAY: '1',
    SCHEDULE: '2',
    IMMEDIATELY: '5'
  }

  const {
    validatePickupTime, validateDropoffTime, validatePickupWindowTime, validateDropoffWindowTime, updateDropoffTime, updatePickupTime,
    // eslint-disable-next-line no-unused-vars
    pickup_value, dropoff_value, validation, isValet, userData, deeleeo_data, pickupData, dropoffData, setIsSpecialTiming, isBatch
  } = props;

  // console.log('validation old: ', validation);

  const [interCity, setInterCity] = useRecoilState(isInterCity);

  const [state, setState] = useState({
    pickupTimeValue: pickup_value,
    dropoffTimeValue: dropoff_value,
    isDropoffDateDisable: dropoff_value.dropoff_type === dropoffOptions.SCHEDULE ? false : true,
    isPickupStartTimeDisable: false,
    isPickupEndTimeDisable: false,
    isDropoffTimeDisable: dropoff_value.dropoff_type === dropoffOptions.SCHEDULE ? false : true,
    isDropoffTimeHidden: dropoff_value.dropoff_type === dropoffOptions.SAME_DAY ? true : false,
    pickupTimeRadio: '',
    dropOffTimeRadio: '',
    currentPickupDate: moment().toDate(),
    dropOffTimes: [],
    intercityContent: '',
    pudoTimes: [],
    isIntercityDown: false,
    isModalOpen: false,
    error: validation,
    distanceInKm: 0,    
  });

  // console.log(userData);


  const setPickupTimeValue = (data) => {
    setState(state => ({ ...state, pickupTimeValue: data }));
  }

  const setDropoffTimeValue = (data) => {
    setState(state => ({ ...state, dropoffTimeValue: data }));
  }

  // eslint-disable-next-line no-unused-vars
  const setPickupTimeRadio = (data) => {
    setState(state => ({ ...state, pickupTimeRadio: data }));
  }

  // eslint-disable-next-line no-unused-vars
  const setDropOffTimeRadio = (data) => {
    setState(state => ({ ...state, dropOffTimeRadio: data }));
  }

  const setCurrentPickupDate = (data) => {
    setState(state => ({ ...state, currentPickupDate: data }));
  }

  // eslint-disable-next-line no-unused-vars
  const setDropOffTimes = (data) => {
    setState(state => ({ ...state, dropOffTimes: data }));
  }

  const setInterCityContent = (data) => {
    setState(state => ({ ...state, intercityContent: data }));
  }

  const setPudoTimes = (data) => {
    setState(state => ({ ...state, pudoTimes: data }));
  }

  const setIsIntercityDown = (data) => {
    setState(state => ({ ...state, isIntercityDown: data }));
  }

  const setIsModalOpen = (data) => {
    setState(state => ({ ...state, isModalOpen: data }));
  }

  const setError = (data) => {
    setState(state => ({ ...state, error: data }));
  }

  const setDistanceInKm = (data) => {
    setState(state => ({ ...state, distanceInKm: data }));
  }  

  const setPudoOptionSelected = (data) => {
    setState(state => ({ ...state, pudoOptionSelected: data }))
  }

  useEffect(() => {

    const payload = {
      originLat: parseFloat(`${pickupData.latitude}`), originLon: parseFloat(`${pickupData.longitude}`), destinationLat: parseFloat(`${dropoffData.latitude}`), destinationLon: parseFloat(`${dropoffData.longitude}`)
    };
    //  console.log('interCity payload', payload);

    if (isValidCoordinate(payload.originLat) && isValidCoordinate(payload.originLon) && isValidCoordinate(payload.destinationLat) && isValidCoordinate(payload.destinationLon)) {
      axios.post(getURI('/api/web/deeleeo/isIntercity'), payload)
        .then(data => {
          // console.log('data', data);
          setInterCity(data.data === 1);
          getPudo();
        })
    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupData, dropoffData]);

  const getPudo = () => {

    const payload = {};
    payload.api_key = userData.apiCode;
    payload.lat = dropoffData.latitude;
    payload.lon = dropoffData.longitude;
    payload.local_intercity = interCity ? 1 : 0;
    if (payload.lat && payload.lat !== 0 && payload.lon && payload.lon !== 0) {

      axios.post(getURI('/api/web/get-custom-times-from-coordinate'), payload)
        .then((data) => {
          setPudoTimes(data.data.data);
        })
        .catch(e => {
          setPudoTimes([]);
          if (!state.isIntercityDown) {
            // alert(e.response.data.message);
            setInterCityContent(e.response.data.message);

            setIsIntercityDown(true);
            setIsModalOpen(true);
          }
        })
    }

  }

  useEffect(() => {
    // console.log('interCity down', isIntercityDown);    
    getPudo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.apiCode, state.pickupTimeValue, state.dropoffTimeValue, interCity, state.isIntercityDown, dropoffData.latitude, dropoffData.longitude]);

  useEffect(() => {
    // getDistanceInKm

    const payload = {
      originLat: parseFloat(`${pickupData.latitude}`), originLon: parseFloat(`${pickupData.longitude}`), destinationLat: parseFloat(`${dropoffData.latitude}`), destinationLon: parseFloat(`${dropoffData.longitude}`)
    };

    if (payload.originLat !== 0 && payload.originLon !== 0 && payload.destinationLat !== 0 && payload.destinationLon !== 0) {
      axios.post(getURI('/api/web/deeleeo/getDistance'), payload)
        .then(data => {
          // console.log('data', data);
          setDistanceInKm(data.data);
        })
    }


  }, [dropoffData.latitude, dropoffData.longitude, pickupData.latitude, pickupData.longitude]);

  useEffect(() => {
    setError(validation);
  }, [validation])

  useEffect(() => {
    const initialValue = moment().add(30, 'm');
    setCurrentPickupDate(initialValue.toDate());

    /**
     * Clean the status to force validation
     */
    setPickupTimeValue({
      ...state.pickupTimeValue,
      pickup_early_datetime: '',
      pickup_later_datetime: ''
    });


    setDropoffTimeValue({
      ...state.dropoffTimeValue,
      dropoff_early_datetime: '',
      dropoff_later_datetime: ''
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  const pickupDateChangeHandle = (full_date) => {
    var selected_date = new Date(full_date);

    setPickupTimeValue({
      ...state.pickupTimeValue,
      pickup_date: selected_date.toString()
    })

    setDropoffTimeValue({
      ...state.dropoffTimeValue,
      dropoff_date: selected_date.toString()
    })
  }

 


  useEffect(() => {
    updatePickupTime(state.pickupTimeValue);

    // update validate when pick up type not empty
    if (!state.error.pickup_time.type && state.pickupTimeValue.pickup_type !== '') {
      validatePickupTime(state.pickupTimeValue);
    }

    // update validate based on pickup time window
    if (!state.error.pickup_time.window_time && state.pickupTimeValue.pickup_early_datetime !== '' && state.pickupTimeValue.pickup_later_datetime !== '') {
      validatePickupWindowTime(state.pickupTimeValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pickupTimeValue, updatePickupTime, validatePickupTime, validatePickupWindowTime]);

  useEffect(() => {
    updateDropoffTime(state.dropoffTimeValue);

    // update validate when drop off type not empty
    if (!state.error.dropoff_time.type && state.dropoffTimeValue.type !== '') {
      validateDropoffTime(state.dropoffTimeValue);
    }

    // update validate based on dropoff time window
    if (!state.error.dropoff_time.window_time && state.dropoffTimeValue.dropoff_early_datetime !== '' && state.dropoffTimeValue.dropoff_later_datetime !== '') {
      validateDropoffWindowTime(state.dropoffTimeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dropoffTimeValue, updateDropoffTime, validateDropoffTime, validateDropoffWindowTime]);

  


  


  const disableSundays = (date) => {
    if (userData.isSundayDeliveryEnabled) {
      return false;
    }
    if (date.getDay() === 0) return true;
  }

 

  

  const pickupTimeOptionHandle = (event) => {
    setPudoOptionSelected(parseInt(event.target.value));
    setIsSpecialTiming(false);
  }

  // console.log('pickupdropofftimesection', state);


  return <div>
    <div>
      <SubTitle className="mt-6 mb-1">
        Pickup Time *      
      </SubTitle>

      {/* Error text about pickup type selection */}
      {!state.error.pickup_time.type &&
        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
          Please Select Your Pickup Type
        </p>
      }





      <div className='mt-3'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} sm={6} md={3}>
            <StyledDatePicker disableToolbar variant="inline" id="pickup-date" label="Date"
              format="MM-dd-yyyy" value={state.pickupTimeValue.pickup_date} onChange={pickupDateChangeHandle} minDate={state.currentPickupDate}
              shouldDisableDate={disableSundays} />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>





      

      <div className='mt-3 ' >
        <RadioGroup value="pickupTimeOption" onChange={pickupTimeOptionHandle} row>
          <RadioOption selectedValue={state.pudoOptionSelected} value={1} label={'Your Delivery Windows'} /><StyledToolTip
            title={
              <>
                <div>
                  Standard pickup and drop off time windows available to you based on your inputted pickup and drop off locations above and current time. “Special Drop Off Time” is available with each pickup window.
                </div>
              </>
            }
            placement={"top"} arrow>
            <img src={InfoIcon} alt={"premium information"} style={{ height: '24px', width: '24px', marginTop: '10px', marginRight: '15px' }} />
          </StyledToolTip>
        </RadioGroup>
      </div>

      <div className='mt-3 ' >
        <Flex >
          <Row className=' w-full'>
            {state.pudoOptionSelected === 1 && <PickupDropOffTimeProfile
              setIsSpecialTiming={setIsSpecialTiming}
              selectedDate={state.pickupTimeValue.pickup_date}
              setCurrentPickupDate={setCurrentPickupDate}
            />}

            
          </Row>
        </Flex>

      </div>
    </div>




   
    <GenericModal
      content={state.intercityContent}
      contentLabel={'Notification'}
      modalIsOpen={state.isModalOpen}
      setModalIsOpen={setIsModalOpen}
    />
  </div >;
}

const mapStateToProps = (state) => ({
  dropoff_value: {
    dropoff_type: state.deeleeoReducer.dropoff_type,
    dropoff_date: state.deeleeoReducer.dropoff_date !== '' ? state.deeleeoReducer.dropoff_date : moment().toDate().toString(),
    dropoff_early_datetime: state.deeleeoReducer.dropoff_early_datetime,
    dropoff_later_datetime: state.deeleeoReducer.dropoff_later_datetime
  },
  pickup_value: {
    pickup_type: state.deeleeoReducer.pickup_type,
    pickup_date: state.deeleeoReducer.pickup_date !== '' ? state.deeleeoReducer.pickup_date : moment().toDate().toString(),
    pickup_early_datetime: state.deeleeoReducer.pickup_early_datetime,
    pickup_later_datetime: state.deeleeoReducer.pickup_later_datetime
  },
  validation: {
    pickup_time: state.validationReducer.pickup_time,
    dropoff_time: state.validationReducer.dropoff_time,
  },
  deeleeo_data: state.deeleeoReducer,
  userData: state.userReducer,
  pickupData: state.pickupDropoffReducer.pickup_data,
  dropoffData: state.pickupDropoffReducer.dropoff_data,

});

const mapDispatchToProps = (dispatch) => ({
  updatePickupTime: (pickup_value) => dispatch(updatePickupTime(pickup_value)),
  updateDropoffTime: (dropoff_value) => dispatch(updateDropoffTime(dropoff_value)),
  validatePickupTime: (data) => dispatch(validatePickupTime(data)),
  validateDropoffTime: (data) => dispatch(validateDropoffTime(data)),
  validatePickupWindowTime: (data) => dispatch(validatePickupWindowTime(data)),
  validateDropoffWindowTime: (data) => dispatch(validateDropoffWindowTime(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupDropoffTimeSection);
