import moment from 'moment'

export const formatDatetime = (date, time, format = 'YYYY-MM-DD HH:mm:ss') => {    
    try {
        const [hour, minute] = time.split(':');
        return moment(date).hours(hour).minutes(minute).format(format) 
    } catch (e) {
        return '';
    }    
}

export const getTimes = (deeleeoDetail) => {

    if (!deeleeoDetail || !deeleeoDetail.pickup_date || !deeleeoDetail.pickup_early_datetime) {
        return '';
    }

    let result = {
        early: formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_early_datetime, 'HH:mm'),
        late: formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_later_datetime, 'HH:mm'),
        date: formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_early_datetime, 'YYYY-MM-DD')
    }

    return result;
}

export const gatheringDeeleeoData = (deeleeoDetail, pickupData, dropoffData, userData, interCity, isSpecialTiming) => {
    let dropoff_type = parseInt(deeleeoDetail.dropoff_type);
    let pickup_type = parseInt(deeleeoDetail.pickup_type);

    // determine in-person confirmation based on Drop off Type (Contactless/In-person)
    // or default 1 when alcohol premium is true
    // for now it based on dropoff method

    //  add current timezone of user to data for time converting in backend
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    /**
     * Somewhere an empty space is introduced in the address, this helps to remove it later
     */
    const fullPickUpAddress = `${pickupData.address}`;
    const fullDropoffAddress = `${dropoffData.address}`;

    let description = '';

    description += `${deeleeoDetail.description} `;

    if (pickupData.unit) {
        description += `Pickup Unit#: ${pickupData.unit} `;        
    }

    if (dropoffData.unit) {
        description += `Drop Off Unit#: ${dropoffData.unit} `;

    }

    if (deeleeoDetail.vin) {
        description += `VIN: ${deeleeoDetail.vin} `;
    }

    if (deeleeoDetail.ro) {
        description += `Repair Order #: ${deeleeoDetail.ro} `;
    }

    


    let data = {
        is_express:deeleeoDetail.is_express,
        timezone: timezone,
        api_code: userData.apiCode,
        description: description,
        assign_my_fleet_driver: deeleeoDetail.assign_my_fleet_driver,
        items: [
            {
                name: deeleeoDetail.item_name,
                weight: 0,
                length: 0,
                width: 0,
                height: 0
            }
        ],
        images: [
            {
                is_main_image: 0,
                using_placeholder: 1
            }
        ],
        pickup_unit:pickupData.unit,
        pickup_address: fullPickUpAddress.trim(),//removing empty spaces
        pickup_latitude: pickupData.latitude,
        pickup_longitude: pickupData.longitude,
        dropoff_unit: dropoffData.unit,
        dropoff_address: fullDropoffAddress.trim(),//removing empty spaces
        dropoff_latitude: dropoffData.latitude,
        dropoff_longitude: dropoffData.longitude,
        pickup_name: pickupData.name,
        dropoff_name: dropoffData.name,
        distance: 0,
        duration: 0,
        user_latitude: pickupData.latitude,
        user_longitude: pickupData.longitude,
        dropoff_type: dropoff_type,
        dropoff_early_datetime: formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_early_datetime),
        dropoff_later_datetime: formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_later_datetime),
        pickup_type: pickup_type,
        pickup_early_datetime: formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_early_datetime),
        pickup_later_datetime: formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_later_datetime),
        weight_category_id: parseInt(deeleeoDetail.weight_category_id),
        size_category_id: parseInt(deeleeoDetail.size_category_id),
        package_count: deeleeoDetail.package_count,
        multi_package: deeleeoDetail.multi_package,
        number_of_trip: 1,
        polyline: '',
        card_id: userData.card_id,
        applied_credit: 0,
        billable_amount: 0,
        delivery_fee: 0,
        is_sweeten_deal: false,
        premiums_fee: 0,
        promo_amount: 0,
        sub_total: 0,
        sweeten_amount: 0,
        tax_rate: 0,
        taxes: 0,
        dropoff_phonenumber: dropoffData.phonenumber,
        pickup_phonenumber: pickupData.phonenumber,
        dropoff_email: dropoffData.email,
        is_assign_favourite_driver: deeleeoDetail.is_assign_favourite_driver,
        is_intercity: interCity ? 1 : 0,        
        do_special_time: isSpecialTiming ? 1 : 0,
        drop_off_address_type: deeleeoDetail.drop_off_address_type
        
    }

    // add premium option to return data if true
    Object.keys(deeleeoDetail.premium_options).forEach(key => {
        if (deeleeoDetail.premium_options[key]) {
            data[key] = 1;
        }
    })

    switch (parseInt(deeleeoDetail.dropoff_method)) {
        case 1:
            data['require_in_person_confirmation'] = 1;
            break;
        case 2:
            data['is_age_restricted'] = 1;
            break;
        default:
            break;
    }

    return data;
}

export const gatheringDeeleeoDataForEditing = (deeleeoInfo, index) => {
    let pickup_early_datetime = new Date(deeleeoInfo.pickup_early_datetime);
    let pickup_later_datetime = new Date(deeleeoInfo.pickup_later_datetime);
    let dropoff_early_datetime = new Date(deeleeoInfo.dropoff_early_datetime);
    let dropoff_later_datetime = new Date(deeleeoInfo.dropoff_later_datetime);
    let dropoff_method = '0';

    if (deeleeoInfo.require_in_person_confirmation === 1) {
        dropoff_method = '1';
    } else if (deeleeoInfo.age_restricted === 1) {
        dropoff_method = '2';
    }

    let deeleeo_data = {
        index: index,
        is_editing: true,
        description: deeleeoInfo.description,
        item_name: deeleeoInfo.name || deeleeoInfo.items[0].name,
        distance: '',
        duration: '',
        dropoff_type: deeleeoInfo.dropoff_type.toString(),
        dropoff_date: dropoff_early_datetime.toString(),
        dropoff_early_datetime: (dropoff_early_datetime.getHours() < 10 ? '0' : '') + dropoff_early_datetime.getHours() + ':' + (dropoff_early_datetime.getMinutes() < 10 ? '0' : '') + dropoff_early_datetime.getMinutes(),
        dropoff_later_datetime: (dropoff_later_datetime.getHours() < 10 ? '0' : '') + dropoff_later_datetime.getHours() + ':' + (dropoff_later_datetime.getMinutes() < 10 ? '0' : '') + dropoff_later_datetime.getMinutes(),
        pickup_type: deeleeoInfo.pickup_type.toString(),
        pickup_date: pickup_early_datetime.toString(),
        pickup_early_datetime: (pickup_early_datetime.getHours() < 10 ? '0' : '') + pickup_early_datetime.getHours() + ':' + (pickup_early_datetime.getMinutes() < 10 ? '0' : '') + pickup_early_datetime.getMinutes(),
        pickup_later_datetime: (pickup_later_datetime.getHours() < 10 ? '0' : '') + pickup_later_datetime.getHours() + ':' + (pickup_later_datetime.getMinutes() < 10 ? '0' : '') + pickup_later_datetime.getMinutes(),
        weight_category_id: deeleeoInfo.weight_category_id,
        size_category_id: deeleeoInfo.size_category_id,
        number_of_trip: 1,
        polyline: "",
        premium_options: {
            special_care: deeleeoInfo.special_care ? true : false,
            load_needs: deeleeoInfo.load_needs ? true : false,
            unload_needs: deeleeoInfo.unload_needs ? true : false,
            alcohol_delivery: deeleeoInfo.alcohol_delivery ? true : false,
            require_refrigerated_vehicle: deeleeoInfo.require_refrigerated_vehicle ? true : false,
            require_in_person_confirmation: deeleeoInfo.require_in_person_confirmation ? true : false
        },
        card_id: "",
        dropoff_method: dropoff_method
    }

    return deeleeo_data;
}