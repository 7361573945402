import { shipmentActions } from '../actions/shipmentActions'

const INITIAL_STATE = {
  senderInfo: {
    businessName: '',
    address: '',
    unit: '',
    street: '',
    city: '',
    province: '',
    mobile: '',
    email: ''
  },
  recipientInfo: {
    businessName: '',
    address: '',
    unit: '',
    street: '',
    city: '',
    province: '',
    mobile: '',
    email: ''
  },
  refNumber: '',
  images: [],
  imagesFiles: [],
  package: {
    small: {
      selected: false,
      qty: '',
      description: ''
    },
    medium: {
      selected: false,
      qty: '',
      description: ''
    },
    large: {
      selected: false,
      qty: '',
      description: ''
    },
    xlarge: {
      selected: false,
      qty: '',
      description: ''
    }
  },
  instructions: {
    details: '',
    premiumOptions: {
      special: false,
      loading: false,
      unloading: false,
      returning: false
    }
  },
  pickup: {
    type: 'REGULAR',
    date: '',
    windowStart: '',
    windowClose: ''
  },
  dropoff: {
    type: 'REGULAR',
    date: '',
    windowStart: '',
    windowClose: ''
  }
}

export const shipmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case shipmentActions.UPDATE_SENDER_INFO:
      return {
        ...state, senderInfo: action.data
      }
    case shipmentActions.UPDATE_RECIPIENT_INFO:
      return {
        ...state, recipientInfo: action.data
      }
    case shipmentActions.UPDATE_PACKAGE_INFO:
      return {
        ...state, package: { ...state.package, [action.data.size]: action.data.packageInfo }
      }
    case shipmentActions.UPDATE_SHIPPING_INSTRUCTIONS:
      return {
        ...state, instructions: { ...state.instructions, details: action.data.details }
      }
    case shipmentActions.UPDATE_SHIPPING_OPTIONS:
      return {
        ...state, instructions: { ...state.instructions, premiumOptions: action.data.premiumOptions }
      }
    case shipmentActions.UPLOAD_IMAGE:
      return {
        ...state, images: action.data
      }
    case shipmentActions.CLEAR_IMAGE:
      return {
        ...state, images: state.images.filter(image => image.imageURL !== action.data)
      }
    case shipmentActions.UPDATE_PICKUP:
      return {
        ...state, pickup: action.data
      }
    case shipmentActions.UPDATE_DROPOFF:
      return {
        ...state, dropoff: action.data
      }
    case shipmentActions.UPDATE_REFNUMBER:
      return {
        ...state, refNumber: action.data
      }
    case shipmentActions.DUPLICATE_DEELEEO:
      return {
        ...state, recipientInfo: { name: '', address: '', unit: '', mobile: '', email: '' }
      }
    case shipmentActions.RESET_DEELEEO:
      return {
         ...INITIAL_STATE, senderInfo: state.senderInfo
      }
    default:
      return state
  }
}
