const initState = {
    index: '', // this property is for updating correct deeleeo 
    is_editing: false, // this property is for checking if the current data is new or editing from sumamry page
    description: '',
    item_name: '',
    distance: '',
    duration: '',
    dropoff_type: '',
    dropoff_date: '',
    dropoff_early_datetime: '',
    dropoff_later_datetime: '',
    pickup_type: '',
    pickup_date: '',
    pickup_early_datetime: '',
    pickup_later_datetime: '',
    weight_category_id: '',
    size_category_id: '',
    multi_package: [],
    number_of_trip: 1,
    polyline: '',
    premium_options: {
        special_care: false,
        load_needs: false,
        unload_needs: false,
        alcohol_delivery: false,
        require_refrigerated_vehicle: false,
        require_in_person_confirmation: false,
        not_require_refrigerated_vehicle: false,
        age_restricted: false
    },
    card_id: "",
    dropoff_method: '0',
    is_assign_favourite_driver: false,
    assign_my_fleet_driver: '',
    package_count: 0,
    drop_off_address_type: '0',
    vin: '',
    ro: '',
    is_express: 0

}

const UPDATE_IS_EXPRESS = 'UPDATE_IS_EXPRESS';
const UPDATE_ITEM_NAME = 'UPDATE_ITEM_NAME';
const UPDATE_VIN = 'UPDATE_VIN';
const UPDATE_RO = 'UPDATE_RO';
const UPDATE_SIZE_SELECTION = 'UPDATE_SIZE_SELECTION';
const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
const UPDATE_OPTIONS = 'UPDATE_OPTIONS';
const UPDATE_PREMIUM_OPTIONS = 'UPDATE_PREMIUM_OPTIONS';
const UPDATE_PICKUP_TIME = 'UPDATE_PICKUP_TIME';
const UPDATE_DROPOFF_TIME = 'UPDATE_DROPOFF_TIME';
const UPDATE_DROPOFF_METHOD = 'UPDATE_DROPOFF_METHOD';
const EDIT_DEELEEO = 'EDIT_DEELEEO';
const DUPLICATE_DEELEEO = 'DUPLICATE_DEELEEO';
const CLEAR_DEELEEO_DATA = 'CLEAR_DEELEEO_DATA';
const UPDATE_PACKAGE_COUNT = 'UPDATE_PACKAGE_COUNT';
const UPDATE_DROP_ADDRESS_TYPE = 'UPDATE_DROP_ADDRESS_TYPE';
const UPDATE_MULTI_PACKAGE = 'UPDATE_MULTI_PACKAGE';


export const updateIsExpress = (data) => ({
    type: UPDATE_IS_EXPRESS,
    data: data
});


export const updateItemName = (data) => ({
    type: UPDATE_ITEM_NAME,
    data: data
});

export const updateVin = (data) => ({
    type: UPDATE_VIN,
    data: data
});

export const updateRO = (data) => ({
    type: UPDATE_RO,
    data: data
});

export const updateSizeSelection = (size_id, weight_id) => ({
    type: UPDATE_SIZE_SELECTION,
    size_category_id: size_id,
    weight_category_id: weight_id
});

export const updateDescription = (data) => ({
    type: UPDATE_DESCRIPTION,
    data: data
});

export const updateOptions = (data) => ({
    type: UPDATE_OPTIONS,
    data: data
});

export const updatePremiumOptions = (data) => ({
    type: UPDATE_PREMIUM_OPTIONS,
    data: data
});

export const updatePickupTime = (pickup_value) => ({
    type: UPDATE_PICKUP_TIME,
    pickup_type: pickup_value.pickup_type,
    pickup_early_datetime: pickup_value.pickup_early_datetime,
    pickup_later_datetime: pickup_value.pickup_later_datetime,
    pickup_date: pickup_value.pickup_date
});

export const updateDropoffTime = (dropoff_value) => ({
    type: UPDATE_DROPOFF_TIME,
    dropoff_type: dropoff_value.dropoff_type,
    dropoff_early_datetime: dropoff_value.dropoff_early_datetime,
    dropoff_later_datetime: dropoff_value.dropoff_later_datetime,
    dropoff_date: dropoff_value.dropoff_date
});

export const updateDropoffMethod = (data) => ({
    type: UPDATE_DROPOFF_METHOD,
    data: data
});

export const editDeeleeo = (data) => ({
    type: EDIT_DEELEEO,
    data: data
})

export const duplicateDeeleeo = (data) => ({
    type: DUPLICATE_DEELEEO,
    data: data
})

export const clearDeeleeoData = () => ({
    type: CLEAR_DEELEEO_DATA
});

export const updatePackageCount = (data) => ({
    type: UPDATE_PACKAGE_COUNT,
    data: data
});

export const updateDropOffAddressType = (data) => ({
    type: UPDATE_DROP_ADDRESS_TYPE,
    data: data
});

export const updateMultiPackage = (data) => ({
    type: UPDATE_MULTI_PACKAGE,
    data: data
});

export const deeleeoReducer = (state = initState, action) => {
    switch (action.type) {
        // case
        case 'UPDATE_IS_EXPRESS':
            return {
                ...state,
                is_express: action.data
            }
        case 'UPDATE_ITEM_NAME':
            return {
                ...state,
                item_name: action.data
            };

        case 'UPDATE_VIN':
            return {
                ...state,
                vin: action.data
            };

        case 'UPDATE_RO':
            return {
                ...state,
                ro: action.data
            };
        case 'UPDATE_SIZE_SELECTION':
            return {
                ...state,
                size_category_id: action.size_category_id,
                weight_category_id: action.weight_category_id
            };
        case 'UPDATE_DESCRIPTION':
            return {
                ...state,
                description: action.data
            };
        case 'UPDATE_OPTIONS':
            return {
                ...state,
                ...action.data
            };
        case 'UPDATE_PREMIUM_OPTIONS':
            return {
                ...state,
                premium_options: action.data
            };
        case 'UPDATE_PICKUP_TIME':
            return {
                ...state,
                pickup_type: action.pickup_type,
                pickup_early_datetime: action.pickup_early_datetime,
                pickup_later_datetime: action.pickup_later_datetime,
                pickup_date: action.pickup_date
            };
        case 'UPDATE_DROPOFF_TIME':
            return {
                ...state,
                dropoff_type: action.dropoff_type,
                dropoff_early_datetime: action.dropoff_early_datetime,
                dropoff_later_datetime: action.dropoff_later_datetime,
                dropoff_date: action.dropoff_date
            };
        case 'UPDATE_DROPOFF_METHOD':
            return {
                ...state,
                dropoff_method: action.data
            };
        case 'EDIT_DEELEEO':
            return {
                ...state,
                ...action.data,
                is_editing: true
            };
        case 'DUPLICATE_DEELEEO':
            return {
                ...state,
                ...action.data,
                is_editing: false
            }
        case 'CLEAR_DEELEEO_DATA':
            return {
                ...initState
            };
        case 'UPDATE_PACKAGE_COUNT':
            return {
                ...state,
                package_count: action.data
            };
        case 'UPDATE_DROP_ADDRESS_TYPE':
            return {
                ...state,
                drop_off_address_type: action.data
            }
        case 'UPDATE_MULTI_PACKAGE':
            return {
                ...state,
                multi_package: action.data
            }
        default:
            return state
    }
}