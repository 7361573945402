import React, { useEffect, useState } from "react";
import { FormControl, RadioGroup } from "@material-ui/core";
import { InputHeader, StyledToolTip, isValidCoordinate } from "./shared";
import { Col, Flex } from "../../../components/containers";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";
import { getURI } from "../../../helpers/apiHelpers";
import { useRecoilState } from "recoil";
import { isInterCity } from "../../../App";
import GenericModal from "../../Dashboard/components/GenericModal";
import { RadioOption, getFutureTime, roundTime } from "./PickupDropoffTimeSection";
import DateTimePicker from "../../../components/DateTimePicker";
import { gatheringDeeleeoData } from "../../../helpers/deeleeoHelper";
import { validateAll } from "../../../helpers/validationHelper";
import { checkValidationData } from "../CreateDeeleeo";
import { updateDropoffTime, updatePickupTime } from "../../../redux/reducers/deeleeoReducer";
import { validateDropoffTime, validateDropoffWindowTime, validatePickupTime, validatePickupWindowTime } from "../../../redux/reducers/validationReducer";
import InfoIcon from "../../../assets/icons/Info-icon.svg";
import { Dash } from "./PickupHourSelector";


const PickupDropOffTimeProfile = (props) => {

    const { pickup_value, dropoff_value, userData, pickupData, dropoffData, deeleeo_data, validation, setIsSpecialTiming, selectedDate, validationData, setCurrentPickupDate,
        updateDropoffTime, validateDropoffTime, validateDropoffWindowTime,
        updatePickupTime, validatePickupTime, validatePickupWindowTime } = props;
    const [interCity, setInterCity] = useRecoilState(isInterCity);

    const [state, setState] = useState({
        pickupTimeValue: pickup_value,
        dropoffTimeValue: dropoff_value,
        intercityContent: '',
        pudoTimes: [],
        isIntercityDown: false,
        isModalOpen: false,
        pickupTimeRadio: '',
        dropOffTimeRadio: '',
        dropOffTimes: [],
        error: validation,
        specialTimingBefore: false,
        specialTimingAfter: false,
        specialTimingBetween: false,
        selectedDate: selectedDate,
        validationData: validationData,
        isValidationDataValid: true,
        

    });

    // console.log('PickupDropOffTimeProfile', state);


    const setPickupTimeValue = (data) => {
        setState(state => ({ ...state, pickupTimeValue: data }));
    }

    const setDropoffTimeValue = (data) => {
        setState(state => ({ ...state, dropoffTimeValue: data }));
    }

    const setInterCityContent = (data) => {
        setState(state => ({ ...state, intercityContent: data }));
    }

    const setPudoTimes = (data) => {
        setState(state => ({ ...state, pudoTimes: data }));
    }

    const setIsIntercityDown = (data) => {
        setState(state => ({ ...state, isIntercityDown: data }));
    }

    const setIsModalOpen = (data) => {
        setState(state => ({ ...state, isModalOpen: data }));
    }

    const setPickupTimeRadio = (data) => {
        setState(state => ({ ...state, pickupTimeRadio: data }));
    }

    const setDropOffTimeRadio = (data) => {
        setState(state => ({ ...state, dropOffTimeRadio: data }));
    }

    const setDropOffTimes = (data) => {
        setState(state => ({ ...state, dropOffTimes: data }));
    }

    const setError = (data) => {
        setState(state => ({ ...state, error: data }));
    }

    const setSpecialTimingBefore = (data) => {
        setState(state => ({ ...state, specialTimingBefore: data }));
    }

    const setSpecialTimingAfter = (data) => {
        setState(state => ({ ...state, specialTimingAfter: data }));
    }

    const setSpecialTimingBetween = (data) => {
        setState(state => ({ ...state, specialTimingBetween: data }));
    }

    const setValidationData = (data) => {
        setState(state => ({ ...state, validationData: data }));
    }

    const setIsValidationDataValid = (data) => {
        setState(state => ({ ...state, isValidationDataValid: data }));
    }    

    // const setIsThisOptionSelected = (data) => {
    //     setState(state => ({ ...state, isThisOptionSelected: data }));
    // }

    const getPudo = () => {
        // console.log('getting pudo');


        const payload = {};
        payload.api_key = userData.apiCode;
        payload.lat = dropoffData.latitude;
        payload.lon = dropoffData.longitude;
        payload.local_intercity = interCity ? 1 : 0;

        // console.log('pudo payload', payload);


        //  console.log('payload', payload);
        if (payload.lat && payload.lat !== 0 && payload.lon && payload.lon !== 0) {
            //  console.log('payload passed', payload);

            axios.post(getURI('/api/web/get-custom-times-from-coordinate'), payload)
                .then((data) => {
                    setPudoTimes(data.data.data);
                })
                .catch(e => {
                    setPudoTimes([]);
                    if (!state.isIntercityDown) {
                        // alert(e.response.data.message);
                        if (e?.response?.data?.message) {
                            setInterCityContent(e.response.data.message);
                        }
                        setIsIntercityDown(true);
                        setIsModalOpen(true);
                    }
                })
        }

    }

    const validateDeeleeoData = () => {

        let validated_deeleeo_data = validateAll(pickupData, dropoffData, deeleeo_data, false, interCity);
        setValidationData({
            pickup_data_validation: validated_deeleeo_data.pickup_data_validation,
            dropoff_data_validation: validated_deeleeo_data.dropoff_data_validation,
            item_name: validated_deeleeo_data.item_name,
            size_weight_selection: validated_deeleeo_data.size_weight_selection,
            dropoff_method: validated_deeleeo_data.dropoff_method,
            description: validated_deeleeo_data.description,
            pickup_time: validated_deeleeo_data.pickup_time,
            dropoff_time: validated_deeleeo_data.dropoff_time,
            refrigeration: validated_deeleeo_data.refrigeration,
            package_count: validated_deeleeo_data.package_count
        })

        return validated_deeleeo_data;
    }

    useEffect(() => {
        setPickupTimeValue(pickup_value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pickup_value.pickup_date])

    useEffect(() => {

        const payload = {
            originLat: parseFloat(`${pickupData.latitude}`), originLon: parseFloat(`${pickupData.longitude}`), destinationLat: parseFloat(`${dropoffData.latitude}`), destinationLon: parseFloat(`${dropoffData.longitude}`)
        };
        // console.log('interCity payload', payload);

        if (isValidCoordinate(payload.originLat) && isValidCoordinate(payload.originLon) && isValidCoordinate(payload.destinationLat) && isValidCoordinate(payload.destinationLon)) {
            // console.log('PickupDropOffTimeProfile getting is intercity');

            /**
             * Intercity check
             */
            axios.post(getURI('/api/web/deeleeo/isIntercity'), payload)
                .then(data => {
                    // console.log('data', data);
                    setInterCity(data.data === 1);
                    getPudo();
                })
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickupData, dropoffData]);


    useEffect(() => {

        try {
            let temp_validation = validateDeeleeoData();
            let is_validation_data_valid = checkValidationData(temp_validation);

            // console.log('is_validation_data_valid');
            // console.log(is_validation_data_valid);

            setIsValidationDataValid(is_validation_data_valid);
            if (is_validation_data_valid) {

                const specialTimingSelected = state.specialTimingAfter || state.specialTimingBefore;
                const summaryPayload = gatheringDeeleeoData(deeleeo_data, pickupData, dropoffData, userData, interCity, specialTimingSelected)

                // console.log('Deeleeo Data: ', summaryPayload);


                /**
                 * Get the summary
                 */
                axios({
                    method: 'post',
                    url: getURI('/api/web/deeleeo/payment-summary'),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: summaryPayload,
                    validateStatus: (status) => status === 200,
                })
                    .then(response => {
                        const estimate_price = response.data;
                        console.log('Estimate Data: ', estimate_price);
                        // dispatch(updateEstimatePrice(estimate_price, index))
                    })
                    .catch((e) => {
                        console.log(e);
                        
                        // const message = e.response?.data?.message || 'An error ocurred while processing your request';
                        // dispatch(notifyError(message));
                        // dispatch(updateProcessStatus('Fail'));

                    });
            }
        } catch (e) {
            console.log(e);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deeleeo_data, interCity, state.specialTimingBefore, state.specialTimingAfter, state.pickupTimeValue, state.dropoffTimeValue,  pickupData, dropoffData, userData])


    /**
     * Checks pickup time, drop of time, drop off lat/lon had changed.
     */
    useEffect(() => {
        getPudo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData.apiCode, state.pickupTimeValue, state.dropoffTimeValue, interCity, state.isIntercityDown, dropoffData.latitude, dropoffData.longitude, selectedDate]);

    useEffect(() => {
        // getDistanceInKm

        const payload = {
            api_code: userData.apiCode,
            pickup_latitude: parseFloat(`${pickupData.latitude}`),
            pickup_longitude: parseFloat(`${pickupData.longitude}`),
            dropoff_latitude: parseFloat(`${dropoffData.latitude}`),
            dropoff_longitude: parseFloat(`${dropoffData.longitude}`)
        };

        // console.log(payload);
        

        if ((payload.pickup_latitude !== 0 &&!isNaN(payload.pickup_latitude))&&
            (payload.pickup_longitude !== 0 && !isNaN(payload.pickup_longitude)) &&
            (payload.dropoff_latitude !== 0 && !isNaN(payload.dropoff_latitude)) &&
            (payload.dropoff_longitude !== 0 && !isNaN(payload.dropoff_longitude))) {
            axios.post(getURI('/api/web/deeleeo/getTimeDistance'), payload)
                .then(data => {
                    // console.log('data', data);
                    // setDistanceInKm(data.data);
                })
        }


    }, [dropoffData.latitude, dropoffData.longitude, pickupData.latitude, pickupData.longitude, userData.apiCode]);

    useEffect(() => {
        setError(validation);
    }, [validation]);


    useEffect(() => {
        const initialValue = moment().add(30, 'm');
        setCurrentPickupDate(initialValue.toDate());
        /**
          * Clean the status to force validation
          */
        setPickupTimeValue({
            ...state.pickupTimeValue,
            pickup_early_datetime: '',
            pickup_later_datetime: ''
        });


        setDropoffTimeValue({
            ...state.dropoffTimeValue,
            dropoff_early_datetime: '',
            dropoff_later_datetime: ''
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateDropoffTime(state.dropoffTimeValue);

        // update validate when drop off type not empty
        if (!state.error.dropoff_time.type && state.dropoffTimeValue.type !== '') {
            validateDropoffTime(state.dropoffTimeValue);
        }

        // update validate based on dropoff time window
        if (!state.error.dropoff_time.window_time && state.dropoffTimeValue.dropoff_early_datetime !== '' && state.dropoffTimeValue.dropoff_later_datetime !== '') {
            validateDropoffWindowTime(state.dropoffTimeValue);
        }

        // setIsThisOptionSelected(true)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.dropoffTimeValue, updateDropoffTime, validateDropoffTime, validateDropoffWindowTime]);

    useEffect(() => {
        updatePickupTime(state.pickupTimeValue);

        // update validate when pick up type not empty
        if (!state.error.pickup_time.type && state.pickupTimeValue.pickup_type !== '') {
            validatePickupTime(state.pickupTimeValue);
        }

        // update validate based on pickup time window
        if (!state.error.pickup_time.window_time && state.pickupTimeValue.pickup_early_datetime !== '' && state.pickupTimeValue.pickup_later_datetime !== '') {
            validatePickupWindowTime(state.pickupTimeValue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.pickupTimeValue, updatePickupTime, validatePickupTime, validatePickupWindowTime]);


    /**
   * Gets a javascript date combining the selected pickup date and the time passed as parameter
   * @param {string} time 
   * @returns Date
   */
    const getDateWithTime = (time) => {
        let result = moment(state.pickupTimeValue.pickup_date);
        const timeString = moment(time, 'HH:mm');

        result.set({
            hour: timeString.get('hour'),
            minute: timeString.get('minute'),
            second: timeString.get('second')
        });

        const target = result.toDate();
        return target;
    }

    const minDateSpecialTiming = (isDropOffBefore, isNextDay, isNotInBetweenPickup = false) => {
        /**
        * Get a moment with selected date and pickup later time
        */
        let pickup = moment(getDateWithTime(state.pickupTimeValue.pickup_later_datetime));

        // console.log('original pickup late time', pickup.toDate());


        if (isNextDay) {

            pickup.add(1, 'day').set({ hours: 11, minutes: 0 });

        }

        /**
         * Add either local time or intercity time
         */
        if (isDropOffBefore) {
            pickup.add({ hours: interCity ? userData.intercity_min_special_time : userData.local_min_special_time });
        }

        // console.log('adding', interCity ? userData.intercity_min_special_time : userData.local_min_special_time);


        if (!isNotInBetweenPickup) {
            pickup.add({ hours: 1 });
        }


        // console.log('min date special', pickup.toDate());


        return pickup.toDate();
    }

    const minDateSpecialTimingBetween = (isDropOff, isNextDay) => {
        let maxHour = 21;
        let maxMinute = 0;
        /**
        * Get a moment with selected date and pickup later time
        */
        let pickup = moment(getDateWithTime(state.pickupTimeValue.pickup_later_datetime));

        // console.log('original pickup late time', pickup.toDate());


        if (isNextDay) {

            pickup.add(1, 'day').set({ hours: 11, minutes: 0 });

        }

        /**
         * Add either local time or intercity time
         */

        pickup.add({ hours: interCity ? userData.intercity_min_special_time : userData.local_min_special_time });

        if (state.dropoffTimeValue.dropoff_early_datetime !== '' && isDropOff) {
            // console.log('pickup_early_datetime', state.dropoffTimeValue.dropoff_early_datetime);

            const split = state.dropoffTimeValue.dropoff_early_datetime.split(':');
            if (split.length > 1) {
                pickup.set({
                    hours: parseInt(split[0]),
                    minutes: parseInt(split[1])
                });

                pickup.add(1, 'hours');
            }

            const limit = moment(getDateWithTime(state.pickupTimeValue.pickup_later_datetime));
            if (isNextDay) {

                limit.add(1, 'day');

            }
            limit.set('hours', maxHour);
            limit.set('minutes', maxMinute);

            if (pickup.isAfter(limit)) {
                // pickup = limit;
            }

        }




        // console.log('adding', interCity ? userData.intercity_min_special_time : userData.local_min_special_time);


        // if (!isNotInBetweenPickup) {
        //     pickup.add({ hours: 1 });
        // }


        // console.log('min date special', pickup.toDate());


        return pickup.toDate();
    }
    const maxDateSpecialTiming = (isDropOffBefore, isNextDay, isPickup = false) => {

        /**
         * Get a moment with selecteddate and pickup later time
         */
        const pickup = moment(getDateWithTime(state.pickupTimeValue.pickup_later_datetime));

        /**
         * Add either local time or intercity time
         */
        pickup.hour(20);

        if (!isDropOffBefore) {
            pickup.hour(18);
        }

        if (!isDropOffBefore && isPickup) {
            pickup.hour(19);
        }

        if (!isDropOffBefore && !isPickup) {
            pickup.hour(20);
        }

        return pickup.toDate();
    }

    const maxDateSpecialTimingBetween = (isDropOff, isNextDay) => {
        /**
         * Get a moment with selecteddate and pickup later time
         */
        const pickup = moment(getDateWithTime(state.pickupTimeValue.pickup_later_datetime));



        if (isDropOff) {
            pickup.hour(21);
        } else {
            pickup.hour(20);
        }

        return pickup.toDate();
    }


    const getPickup = () => {
        if (interCity && state.isIntercityDown) {
            return <div>Intercity is not available at the moment.</div>;
        }
        /**
         * If the pickup location has a custom region for PUDO's set up
         */
        if (state.pudoTimes.length > 0) {



            const customResult = state.pudoTimes
                .map((t, i) => {
                    // console.log('looping: ', t);

                    const pickupTime = t.pickup_time[0];
                    if (pickupTime.type !== 3) {
                        const earlyTime = getDateWithTime(pickupTime.cut_off_time);
                        const nowMoment = moment();
                        const laterMoment = moment(earlyTime);

                        /**
                       * Get the time different from now
                       */
                        const diff = laterMoment.diff(nowMoment, 'minutes')
                        // console.log('diff: ', diff);

                        const dayOfWeek = laterMoment.day();

                        /**
                         * get the service days of this pickup time
                         */
                        const integerServicedays = pickupTime.service_days.map(d => parseInt(d));

                        /**
                         * Check if the selected date is included in the service days of this pickup time.
                         */
                        let isDaySelectedEnabled = integerServicedays.includes(dayOfWeek);

                        /**
                         * if the time has passed, 
                         * or the day is not available for this pickup time, 
                         * or the intercity part of this pickup time does not match with the selection.
                         */
                        let disabled = diff < 1 || !isDaySelectedEnabled || (pickupTime.delivery_type !== 'Local' && !interCity);

                        /**
                         * if the time will be disabled, simply return null.
                         */
                        if (disabled) {

                            return null;
                        }

                        /**
                         * The normal radio button options
                         */
                        if (pickupTime.early_time && pickupTime.later_time) {
                            return <RadioOption key={'p-' + i} selectedValue={state.pickupTimeRadio} isDisabled={disabled} value={`c-${i}`} label={`${pickupTime.text}`} />;
                        }

                        return null;

                    } else {
                        const nowMoment = moment();
                        const selectedDate = moment(state.pickupTimeValue.pickup_date);

                        //  console.log('now',nowMoment.day());
                        //  console.log('selected', selectedDate.day());
                        let selectedDayOfDate = selectedDate.day();

                        /**
                         * This will handle Sunday selection, since Sunday is ZERO
                         */
                        if (selectedDate.day() === 0) {
                            selectedDayOfDate = 7;
                        }
                        const diff = selectedDayOfDate - nowMoment.day()
                        //  console.log('diff', diff);

                        /**
                         * When the selected date is not today, we disable rush
                         */
                        if (diff > 0) {
                            return null;
                        }

                        /**
                         * When the distance is greater than user rush distance kms, we disable rush
                         */
                        // console.log(state);

                        if (state.distanceInKm > userData.rush_distance) {
                            return null;
                        }

                        // /**
                        //  * No rush on batches
                        //  */
                        // if (isBatch) {
                        //     return null;
                        // }

                        //Rush was moved to a higher level component
                         return null;

                        // return <RadioOption key={'p-' + i} selectedValue={state.pickupTimeRadio} value={`c-${i}`} label={pickupTime.text} />;

                    }
                })
                .filter(e => e !== null);

            // setPickupTimes(pickupTimesCollector);


            if (customResult.length === 0) {
                return <>No Times Available Based On The Locations And Date Selected</>
            }

            return customResult;
        }
    }

    const getDropOff = () => {

        // eslint-disable-next-line no-unused-vars
        const [type, radioIndex] = state.pickupTimeRadio.split('-');
        const getIndex = parseInt(radioIndex);


        if (isNaN(getIndex)) return;

        const dropOffTimes = state.pudoTimes[getIndex].dropoff_time;

        return (dropOffTimes.map((p, i) => {
            let earlyTime = getDateWithTime(p.later_time);

            const nowMoment = getDateWithTime(state.pickupTimeValue.pickup_later_datetime);
            const laterMoment = moment(earlyTime);
            const diff = laterMoment.diff(nowMoment, 'minutes');
            let disabled = false;


            if (!p.is_next_day_do && !p.is_special_timing) {
                if ((p.early_time === '' && diff < 240) || (p.early_time !== '' && diff < 30) || state.pickupTimeValue.pickup_type === '3') {
                    disabled = true;
                    return null;
                }
            }

            let index = i;
            if (p.index) {
                index = p.index;
            }

            if (p.is_special_timing) {
                // console.log(p);

                return <Flex className='flex-col  mt-5'>
                    <div>
                        <span
                            style={{ color: '#000000', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 'bold', marginRight: '5px' }}>
                            SPECIAL DROP OFF TIME</span>
                        <span>(additional charges may apply)</span>
                    </div>
                    <Flex className='flex-row  mt-5 '>
                        <RadioOption
                            isHTMLLabel
                            key={'db-' + i}
                            selectedValue={state.dropOffTimeRadio}
                            isDisabled={disabled}
                            value={index + 'db'}
                            label={'<div><span class="font-black">Deliver before</span></div>'}
                            labelAction={dropOffTimeHandleValue}
                        />

                        <StyledToolTip
                            title={
                                <>
                                    <div>
                                        Selecting a time in this option will request a delivery to be dropped off <span className="font-black">before</span> the specific time you select. Keep in mind, this delivery has the pickup time window selected above. This option may be an extra charge that can be reviewed on the next page before submitting the delivery request.
                                    </div>
                                </>
                            }
                            placement={"top"} arrow>
                            <img src={InfoIcon} alt={"premium information"} style={{ height: '24px', width: '24px', marginTop: '10px' }} />
                        </StyledToolTip>
                        {state.specialTimingBefore &&
                            <>
                                <DateTimePicker
                                    id='dropoff-before-time'
                                    // defaultValue={minDateSpecialTiming(true, p.is_next_day_do, true)}
                                    disabled={false}
                                    onChange={(value) => dropoffDatetimeHandle(value, true)}
                                    minTime={minDateSpecialTiming(true, p.is_next_day_do, true)}
                                    maxTime={maxDateSpecialTiming(true, p.is_next_day_do, true)}
                                />{p.is_next_day_do && <span className="mt-1">[Next Day]</span>}
                            </>}
                    </Flex>
                    <Flex className='flex-row  mt-5 '>
                        <RadioOption
                            isHTMLLabel
                            key={'da-' + i}
                            selectedValue={state.dropOffTimeRadio}
                            isDisabled={disabled}
                            value={index + 'da'}
                            label={'<div><span class="font-black">Deliver after</span></div>'}
                            labelAction={dropOffTimeHandleValue}
                        />
                        <StyledToolTip
                            title={
                                <>
                                    <div>
                                        Selecting a time in this option will request a delivery to be dropped off <span className="font-black">after</span> the specific time you select. Keep in mind, this delivery has the pickup time window selected above. This option may be an extra charge that can be reviewed on the next page before submitting the delivery request.
                                    </div>
                                </>
                            }
                            placement={"top"} arrow>
                            <img src={InfoIcon} alt={"premium information"} style={{ height: '24px', width: '24px', marginTop: '10px' }} />
                        </StyledToolTip>
                        {state.specialTimingAfter &&
                            <>
                                <DateTimePicker
                                    id='dropoff-after-time'
                                    // defaultValue={minDateSpecialTiming(false, p.is_next_day_do, true)}
                                    disabled={false}
                                    onChange={(value) => dropoffDatetimeHandle(value, false)}
                                    minTime={minDateSpecialTiming(false, p.is_next_day_do, true)}
                                    maxTime={maxDateSpecialTiming(false, p.is_next_day_do, true)}
                                />{p.is_next_day_do && <span className="mt-1">[Next Day]</span>}
                            </>
                        }
                    </Flex>

                    <Flex className='flex-row  mt-5 '>
                        <RadioOption
                            isHTMLLabel
                            key={'dw-' + i}
                            selectedValue={state.dropOffTimeRadio}
                            isDisabled={disabled}
                            value={index + 'dw'}
                            label={'<div><span class="font-black">Deliver between</span></div>'}
                            labelAction={dropOffTimeHandleValue}
                        />
                        <StyledToolTip
                            title={
                                <>
                                    <div>
                                        Selecting a time in this option will request a delivery to be dropped off <span className="font-black">between</span> the specific time you select. Keep in mind, this delivery has the pickup time window selected above. This option may be an extra charge that can be reviewed on the next page before submitting the delivery request.
                                    </div>
                                </>
                            }
                            placement={"top"} arrow>
                            <img src={InfoIcon} alt={"premium information"} style={{ height: '24px', width: '24px', marginTop: '10px' }} />
                        </StyledToolTip>
                        {state.specialTimingBetween &&
                            <>
                                <DateTimePicker
                                    id='dropoff-after-time'
                                    // defaultValue={minDateSpecialTiming(true, p.is_next_day_do, true)}
                                    disabled={false}
                                    onChange={(value) => dropOffBetweenHandle(value, true)}
                                    minTime={minDateSpecialTimingBetween(false, p.is_next_day_do)}
                                    maxTime={maxDateSpecialTimingBetween(false, p.is_next_day_do)}
                                />

                                <Dash />

                                <DateTimePicker
                                    id='dropoff-after-time'
                                    //   defaultValue={()=>moment(state)}
                                    disabled={false}
                                    onChange={(value) => dropOffBetweenHandle(value, false)}
                                    minTime={minDateSpecialTimingBetween(true, p.is_next_day_do)}
                                    maxTime={maxDateSpecialTimingBetween(true, p.is_next_day_do)}
                                />{p.is_next_day_do && <span className="mt-1">[Next Day]</span>}
                            </>
                        }
                    </Flex>


                </Flex >
            }

            return <RadioOption
                key={'d-' + i}
                selectedValue={state.dropOffTimeRadio}
                isDisabled={disabled}
                value={index + ''}
                label={p.text}
            />;
        }))
            .filter(e => e !== null);
    }

    const pickupTimeHandle = (event) => {
        // console.log(event);
        // console.log('handling pickup time');
        // console.log('pickup value');
        // console.log(pickup_value);
        setPickupTimeRadio(event.target.value);
        /**
         * Always clear the drop off radio
         */
        setDropOffTimeRadio('');

        let timeOption = null;




        if (!timeOption) {
            // eslint-disable-next-line no-unused-vars
            const [type, radioIndex] = event.target.value.split('-');
            const getIndex = parseInt(radioIndex);
            timeOption = state.pudoTimes[getIndex].pickup_time[0];
            setDropOffTimes(state.pudoTimes[getIndex].dropoff_time);
            if (isNaN(getIndex)) return;

        }

        // console.log(timeOption);


        /**
         * This is an urgent guy
         */
        if (timeOption && timeOption?.early_time === '' && timeOption?.later_time === '') {
            const current = moment().toDate();
            const start_time = roundTime(current.getHours() + ':' + current.getMinutes(), 30);
            // update pickup
            setPickupTimeValue({
                ...state.pickupTimeValue,
                pickup_type: '3',
                pickup_early_datetime: start_time,
                pickup_later_datetime: start_time
            });

            const next_2h_time_string = getFutureTime(current.toLocaleDateString(), start_time, 120);
            setDropoffTimeValue({
                ...state.dropoffTimeValue,
                dropoff_type: '3',
                dropoff_early_datetime: start_time,
                dropoff_later_datetime: next_2h_time_string
            })
        } else {
            // console.log('in the else');

            setPickupTimeValue({
                ...state.pickupTimeValue,
                pickup_type: '2',
                pickup_early_datetime: timeOption.early_time,
                pickup_later_datetime: timeOption.later_time
            });

            setDropoffTimeValue({
                ...state.dropoffTimeValue,
                dropoff_type: '2',
                // dropoff_date: pickupTimeValue.pickup_date,
                // dropoff_early_datetime: timeOption.later_time,
                // dropoff_later_datetime: timeOption.later_time
            })
        }




    }

    const dropOffTimeHandleValue = (selectedValue) => {
        //Clean the drop off times
        setDropoffTimeValue({
            ...state.dropoffTimeValue,
            // dropoff_type: event.target.value,
            // dropoff_date: dropOffDate,
            dropoff_early_datetime: '',
            dropoff_later_datetime: ''
        })

        setDropOffTimeRadio(selectedValue);

        // console.log('radio selected:', event.target.value)


        let timeOption = null;
        try {
            timeOption = state.dropOffTimes.find((e, i) => {
                return parseInt(selectedValue) === i;
            });
        } catch (e) {

        }

        // console.log('dropoff', timeOption);

        // if (selectedValue.includes('db')) {
        //     setSpecialTimingBefore(false);
        //     setSpecialTimingAfter(false);
        // }

        // if (selectedValue.includes('da')) {
        //     setSpecialTimingBefore(false);
        //     setSpecialTimingAfter(false);
        // }

        setSpecialTimingBefore(false);
        setSpecialTimingAfter(false);
        setSpecialTimingBetween(false)
        setIsSpecialTiming(false);




        /**
         * Some custom drop offs may be next day delivery.
         * Here we are adding an extra day from the pickup date.
         */
        let dropOffDate = state.pickupTimeValue.pickup_date;

        if (timeOption.is_next_day_do) {
            let adjustedDate = moment(state.pickupTimeValue.pickup_date).add(1, 'day');
            dropOffDate = adjustedDate.toDate().toString();

        }

        // console.log('drop off selected', timeOption);


        if (timeOption.is_special_timing) {
            if (selectedValue.includes('db')) {
                setSpecialTimingBefore(true);
                setSpecialTimingAfter(false);
                setSpecialTimingBetween(false);
            }

            if (selectedValue.includes('da')) {
                setSpecialTimingBefore(false);
                setSpecialTimingAfter(true);
                setSpecialTimingBetween(false);
            }

            if (selectedValue.includes('dw')) {
                setSpecialTimingBefore(false);
                setSpecialTimingAfter(false);
                setSpecialTimingBetween(true);
            }
            setIsSpecialTiming(true);

            // const value = moment(minDateSpecialTiming()).format('HH:mm');

            // console.log('setting this value', value);


            // setDropoffTimeValue({ ...state.dropoffTimeValue, dropoff_later_datetime: value });

            setDropoffTimeValue({
                ...state.dropoffTimeValue,
                dropoff_date: dropOffDate
            })

        } else {
            setDropoffTimeValue({
                ...state.dropoffTimeValue,
                dropoff_date: dropOffDate,
                dropoff_early_datetime: timeOption.early_time,
                dropoff_later_datetime: timeOption.later_time
            })
        }


    }

    const dropOffTimeHandle = (event) => {
        const selectedValue = event.target.value;
        dropOffTimeHandleValue(selectedValue);
    }

    const dropoffDatetimeHandle = (value, isDropOffBefore) => {
        const selectedDate = moment(state.dropOffTimeRadio.dropoff_date);
        const split = value.split(':');
        selectedDate.set({
            hours: parseInt(split[0]),
            minutes: parseInt(split[1]),
        });

        // console.log('selected drop off: ', selectedDate.format('HH:mm'));


        let timeOption = null;

        if (!timeOption) {
            // eslint-disable-next-line no-unused-vars
            const [type, radioIndex] = state.pickupTimeRadio.split('-');
            const getIndex = parseInt(radioIndex);
            timeOption = state.pudoTimes[getIndex].pickup_time[0];
            setDropOffTimes(state.pudoTimes[getIndex].dropoff_time);
            if (isNaN(getIndex)) return;

        }

        if (isDropOffBefore) {
            // console.log('is before');

            setState(state => ({
                ...state,
                dropoffTimeValue: {
                    ...state.dropoffTimeValue,
                    pickup_type: '2',
                    dropoff_later_datetime: selectedDate.format('HH:mm'),
                    dropoff_early_datetime: timeOption.later_time
                }

            }));
        } else {
            // console.log('is after');

            setState(state => ({
                ...state,
                dropoffTimeValue: {
                    ...state.dropoffTimeValue,
                    pickup_type: '2',
                    dropoff_early_datetime: selectedDate.format('HH:mm'),
                    dropoff_later_datetime: moment(state.dropOffTimeRadio.dropoff_date).set({ hours: 20, minutes: 0 }).format('HH:mm')
                }

            }));

        }
    };

    const dropOffBetweenHandle = (value, isPickup) => {

        console.log(value, isPickup);


        if (isPickup) {
            setState(state => ({
                ...state,
                dropoffTimeValue: {
                    ...state.dropoffTimeValue,
                    pickup_type: '2',
                    dropoff_early_datetime: value,
                    dropoff_later_datetime: ''
                }
            }));
        } else {
            setState(state => ({
                ...state,
                dropoffTimeValue: {
                    ...state.dropoffTimeValue,
                    pickup_type: '2',
                    dropoff_later_datetime: value
                }
            }));
        }

    }



    //    console.log(state.error);



    return (
        <div className='mt-3 w-full '>
            <Flex className="mt-3" >
                <Col>
                    <InputHeader>Pickup Time Window *</InputHeader>
                    <Flex className='w-full'>
                        <FormControl component="fieldset">
                            <RadioGroup value="pickup" onChange={pickupTimeHandle}>
                                {getPickup()}
                            </RadioGroup>
                        </FormControl>
                        {!state.error.pickup_time.window_time &&
                            <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs' style={{ marginLeft: '8px', marginTop: '5px' }}>
                                {state.error.pickup_time.window_time_error_text}
                            </p>
                        }
                    </Flex>

                    <InputHeader>
                        Drop off Time Window*
                    </InputHeader>

                    {/* Error text about pickup type selection */}
                    {!state.error.dropoff_time.type &&
                        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
                            Please Select Your Drop off Type
                        </p>
                    }

                    <RadioGroup className="w-full" value="dropoff" onChange={dropOffTimeHandle}>
                        {getDropOff()}
                    </RadioGroup>
                    {!state.error.dropoff_time.window_time &&
                        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs' style={{ marginLeft: '8px', marginTop: '5px' }}>
                            {state.error.dropoff_time.window_time_error_text}
                        </p>
                    }
                    {/* <Button
                        className="w-full"
                        disabled={state.isThisOptionSelected}
                        onClick={handledSelectButton}
                    >Selected</Button> */}
                </Col>


            </Flex>

            <GenericModal
                content={state.intercityContent}
                contentLabel={'Notification'}
                modalIsOpen={state.isModalOpen}
                setModalIsOpen={setIsModalOpen}
            />
        </div>
    );

};

const mapStateToProps = (state) => ({
    dropoff_value: {
        dropoff_type: state.deeleeoReducer.dropoff_type,
        dropoff_date: state.deeleeoReducer.dropoff_date !== '' ? state.deeleeoReducer.dropoff_date : moment().toDate().toString(),
        dropoff_early_datetime: state.deeleeoReducer.dropoff_early_datetime,
        dropoff_later_datetime: state.deeleeoReducer.dropoff_later_datetime
    },
    pickup_value: {
        pickup_type: state.deeleeoReducer.pickup_type,
        pickup_date: state.deeleeoReducer.pickup_date !== '' ? state.deeleeoReducer.pickup_date : moment().toDate().toString(),
        pickup_early_datetime: state.deeleeoReducer.pickup_early_datetime,
        pickup_later_datetime: state.deeleeoReducer.pickup_later_datetime
    },
    deeleeo_data: state.deeleeoReducer,
    userData: state.userReducer,
    pickupData: state.pickupDropoffReducer.pickup_data,
    dropoffData: state.pickupDropoffReducer.dropoff_data,
    validation: {
        pickup_time: state.validationReducer.pickup_time,
        dropoff_time: state.validationReducer.dropoff_time,
    },
    validationData: state.validationReducer
});

const mapDispatchToProps = (dispatch) => ({
    updatePickupTime: (pickup_value) => dispatch(updatePickupTime(pickup_value)),
    updateDropoffTime: (dropoff_value) => dispatch(updateDropoffTime(dropoff_value)),
    validatePickupTime: (data) => dispatch(validatePickupTime(data)),
    validateDropoffTime: (data) => dispatch(validateDropoffTime(data)),
    validatePickupWindowTime: (data) => dispatch(validatePickupWindowTime(data)),
    validateDropoffWindowTime: (data) => dispatch(validateDropoffWindowTime(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupDropOffTimeProfile);

