import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './assets/styles.css'
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom'
import { routes } from './routes'

import LoginPage from './pages/Login/Login'
import RegisterAccountInfo from './pages/Register/RegisterAccountInfo'
import RegisterPickupLocation from './pages/Register/RegisterPickupLocation'
import RegisterPickupHours from './pages/Register/RegisterPickupHours'
import ShipmentDetails from './pages/Shipment/ShipmentDetails'
import Choose from './pages/DeeleeoDetails/Choose'
import CreateMany from './pages/DeeleeoDetails/CreateMany'
import CreateDeeleeo from './pages/DeeleeoDetails/CreateDeeleeo'
import Dashboard from './pages/Dashboard'
import ForgotPassword from './pages/ForgotPassword'
import { getCompanyInfo, updateServicedRegions } from './redux/actions/userActions'
import DeeleeoSummary from './pages/DeeleeoSummary'
import { LoginStatus } from './redux/reducers/authReducer'
import AuthRoute from './components/AuthRoute'
import { clearAllState } from './redux/actions/authActions'
import { fetchSizeCategory } from './redux/reducers/sizeCategoryReducer'
import CacheBuster from './CacheBuster';
import { atom, RecoilRoot } from 'recoil';
import MultiDeeleeo from './pages/MultiDeeleeo';
import AddPayment from './pages/AddPayment';
import FavDrivers from './pages/FavDrivers'
import CreateDeeleeoValet from './pages/DeeleeoDetails/CreateDeeleeoValet'
import UserAddress from './pages/UserContacts'


/**
 * 
 * @returns To get the url search parameters
 */
export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App(props) {
  const { fetchRegions, api_key, getCompanyInfo, loginStatus, clearAllState, fetchSizeCategory } = props

  useEffect(() => {
    fetchRegions()
  }, [fetchRegions])

  useEffect(() => {
    fetchSizeCategory()
  }, [fetchSizeCategory])

  useEffect(() => {
    if (api_key !== '' && api_key !== null) {
      getCompanyInfo(api_key)
    }
  }, [api_key, getCompanyInfo])

  useEffect(() => {
    if (loginStatus === LoginStatus.Logout) {
      localStorage.clear();
      clearAllState()
      fetchRegions()
      fetchSizeCategory()
    }
  }, [fetchSizeCategory, clearAllState, fetchRegions, loginStatus])

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <RecoilRoot>
            <Router>
              <Switch>
                <AuthRoute exact path={routes.userAddress} component={UserAddress} />
                <AuthRoute exact path={routes.addPayment} component={AddPayment} />
                <AuthRoute exact path={routes.registerPickupLocation} component={RegisterPickupLocation} />
                <AuthRoute exact path={routes.registerPickupHours} component={RegisterPickupHours} />
                <AuthRoute exact path={routes.shipment} component={ShipmentDetails} />
                <AuthRoute exact path={routes.choose} component={Choose} />
                <AuthRoute exact path={routes.createDeeleeo} component={CreateDeeleeo} />
                <AuthRoute exact path={routes.createDeeleeoValet} component={CreateDeeleeoValet} />
                <AuthRoute exact path={routes.createOneToMany} component={() => <CreateMany isToMany />} />
                <AuthRoute exact path={routes.createManyToOne} component={() => <CreateMany isToOne />} />
                <AuthRoute exact path={routes.summary} component={DeeleeoSummary} />
                <AuthRoute exact path={routes.dashboard} component={Dashboard} />
                <AuthRoute exact path={routes.multiDeeleeo} component={MultiDeeleeo} />
                <AuthRoute exact path={routes.favourites} component={FavDrivers} />
                <Route exact path={routes.forgotPassword} component={ForgotPassword} />
                <Route exact path={routes.login} component={LoginPage} />
                <Route path={routes.registerAccountInfo} component={RegisterAccountInfo} />
              </Switch>
            </Router>
          </RecoilRoot>
        );
      }}
    </CacheBuster>
  );
}

const mapStateToProps = state => ({
  api_key: state.userReducer.apiCode,
  loginStatus: state.authReducer.loginStatus
})

const mapDispatchToProps = dispatch => ({
  fetchSizeCategory: () => dispatch(fetchSizeCategory()),
  fetchRegions: () => dispatch(updateServicedRegions()),
  getCompanyInfo: (api_key) => dispatch(getCompanyInfo(api_key)),
  clearAllState: () => dispatch(clearAllState())
})

const localStorageEffect = (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      try{
        setSelf(JSON.parse(savedValue));
      }catch(e){
        console.log(`no saved value for: ${key}`);
        
      }
      
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const userDeeleeosOneToMany = atom({
  key: 'userDeeleeosOneToMany', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    localStorageEffect('userDeeleeosOneToMany'),
  ]
});

export const userDeeleeosOneToManyIsDirty = atom({
  key: 'userDeeleeosOneToManyIsDirty', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects: [
    localStorageEffect('userDeeleeosOneToManyIsDirty'),
  ]
});


export const userDeeleeosManyToOne = atom({
  key: 'userDeeleeosManyToOne', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects: [
    localStorageEffect('userDeeleeosManyToOne'),
  ]
});

export const userDeeleeosManyToOneIsDirty = atom({
  key: 'userDeeleeosManyToOneIsDirty', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects: [
    localStorageEffect('userDeeleeosManyToOneIsDirty'),
  ]
});

export const userDeeleeos = atom({
  key: 'userDeeleeos', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
  effects: [
    localStorageEffect('userDeeleeos'),
  ]
});

export const dashboardPageIndex = atom({
  key: 'dashboardPageIndex', // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
  effects: [
    localStorageEffect('dashboardPageIndex'),
  ]
});

export const dashboardPageSize = atom({
  key: 'dashboardPageSize', // unique ID (with respect to other atoms/selectors)
  default: 25, // default value (aka initial value)
  effects: [
    localStorageEffect('dashboardPageSize'),
  ]
});

export const dashboardSearchingTerm = atom({
  key: 'dashboardSearchingTerm', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
  effects: [
    localStorageEffect('dashboardSearchingTerm'),
  ]
});


export const dashboardSearchDeeleeoStatus = atom({
  key: 'dashboardSearchDeeleeoStatus', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
  effects: [
    localStorageEffect('dashboardSearchDeeleeoStatus'),
  ]
});

export const apiKeyAtom = atom({
  key: 'apiKeyAtom', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
  effects: [
    localStorageEffect('apiKeyAtom'),
  ]
});



export const editingCardAtom = atom({
  key: 'editingCardAtom', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
  effects: [
    localStorageEffect('editingCardAtom'),
  ]
});

export const dashboardSearchingDate = atom({
  key: 'dashboardSearchingDate', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
  effects: [
    localStorageEffect('dashboardSearchingDate'),
  ]
});

export const isInterCity = atom({
  key: 'isInterCity', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects: [
    localStorageEffect('isInterCity'),
  ]
});

export const isVehicleSwap = atom({
  key: 'isVehicleSwap', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects: [
    localStorageEffect('isVehicleSwap'),
  ]
});





export default connect(mapStateToProps, mapDispatchToProps)(App)
