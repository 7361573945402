import React, { useEffect, useState } from 'react'
import DeeleeoIcon from '../../assets/icons/deeleeo-reverse.svg'
import BigDIcon from '../../assets/icons/d-icon.svg'
import FloatingInputField from '../../components/FloatingInputField'
import { clearRegisterError, logout, registerUser } from '../../redux/actions/authActions'
import { connect } from 'react-redux'
import { SignupStatus } from '../../redux/reducers/authReducer'
import { withRouter } from 'react-router-dom'
import * as yup from 'yup'
import {
  IconSubText,
  LeftSide,
  RightSide,
  SubText,
  TitleText,
  RegisterInfoCard,
  Bold, SignUpButton, SmallText, BoldLink, PageLink
} from './components/shared'
import { routes } from '../../routes'
import { Col, Container, FieldContainer } from '../../components/containers'
import { useFormik } from 'formik'
import { useDebounce } from '../../hooks/useDebounce'
import AppStoreIcon from './components/AppStoreIcon'
import { InputContainer } from '../../components/InputField'
import { FormControl, InputLabel } from '@material-ui/core'

function RegisterPage(props) {

  const { apiCode, register, signupStatus, history, authError, clearRegisterError, logout, regions } = props
  useEffect(() => {
    logout();
  }, [logout])

  // console.log(regions);


  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')

  //const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


  const validationSchema = yup.object({
    fullName: yup.string().required('Full name is required'),
    email: yup.string().email('Enter a valid email address').required('Email is required'),
    password: yup.string().required('Password is required'),
    businessName: yup.string().required('Business name is required'),
    mobile: yup.string().phone('CA', false, 'Phone number must be valid'),
    region: yup.string().required('Region is required'),
  })

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      password: '',
      businessName: '',
      mobile: '',
      region: null
    },
    validationSchema: validationSchema,
    onSubmit: (values => {
      let [firstName, ...lastName] = values.fullName.split(' ')
      lastName = lastName.join(' ')
      const data = {
        company_name: values.businessName,
        first_name: firstName,
        last_name: lastName,
        password: values.password,
        password_confirmation: values.password,
        email: values.email,
        mobile: values.mobile,
        operating_region: parseInt(values.region)
      }
      register(data)
    })
  })




  const debouncedEmail = useDebounce(formik.values.email, 500)

  useEffect(() => {
    clearRegisterError()
    setError('')
  }, [clearRegisterError, debouncedEmail])

  useEffect(() => {
    if (signupStatus === SignupStatus.SignupSuccess) {
      history.push(routes.dashboard)
    }
    if (signupStatus === SignupStatus.SignupError) {
      setError(authError)
    }
  }, [history, signupStatus, apiCode, authError])

  /**
   * Gets regions Edmonton, Calgary, Red Deer, Toronto, Vancouver
   * @returns array of regions
   */
  const getRegions = () => {

    const result = [{ id: null, name: 'Select Operating Region' }]

    return result.concat(regions);


  }

  return (
    <Container>
      <LeftSide>
        <Col style={{ marginTop: '6rem' }}>
          <div>
            <img src={DeeleeoIcon} alt='Deeleeo Logo' />
            <IconSubText>Delivery simplified.</IconSubText>
          </div>
          <img style={{ marginBottom: '74px' }} src={BigDIcon} alt='Deeleeo Icon' />
        </Col>
      </LeftSide>

      <RightSide>
        <FieldContainer onSubmit={formik.handleSubmit}>
          <TitleText>Welcome to Deeleeo</TitleText>
          <SubText smallMargin>Got stuff to send? Or need something brought to you? Deeleeo puts a network of local, on-demand delivery agents at your fingertips.</SubText>
          <SubText>Already have an account? <PageLink bold to={routes.login}>Log in</PageLink></SubText>
          <FloatingInputField required id={'fullName'} label={'Full name'}
            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            helperText={formik.errors.fullName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange} />
          <FloatingInputField required id={'email'} label={'Email'}
            error={(formik.touched.email && Boolean(formik.errors.email)) || error !== ''}
            helperText={(formik.touched.email && formik.errors.email) || error}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange} />
          <FloatingInputField required inputType={'password'} id={'password'} label={'Password'}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            handleClickShowPassword={() => setShowPassword(!showPassword)} showPassword={showPassword} />
          <FloatingInputField required id={'mobile'} label={'Mobile Number'}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.errors.mobile}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange} />
          <RegisterInfoCard>Create a <Bold>Business Account</Bold> by entering your business name. Business accounts have store locations, contact info and pickup information.</RegisterInfoCard>
          <FloatingInputField required id={'businessName'} label={'Business name'}
            error={formik.touched.businessName && Boolean(formik.errors.businessName)}
            helperText={formik.touched.businessName && formik.errors.businessName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange} />
          <SmallText style={{ marginTop: '0px' }}>Not a business? Just enter your name here.</SmallText>

          <InputContainer className="mb-6">
            <FormControl required={true} error={formik.touched.region && Boolean(formik.errors.region)} fullWidth={true}>
              <InputLabel htmlFor={`region`}>{'Operating Region'}</InputLabel>
              <select
                name='region'
                id='region'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                {getRegions().map((r, i) => {
                  return <option key={i} value={r.id}>{r.name}</option>
                })}

              </select>
            </FormControl>
          </InputContainer>
          <SignUpButton type='submit'>Sign up</SignUpButton>
          <SmallText>By clicking on the Sign up button, you agree to Deeleeo's <BoldLink link={'https://www.deeleeo.com/terms-of-service'}>Terms of Use</BoldLink> and <BoldLink link={'https://www.deeleeo.com/privacy-policy-1'}>Privacy Policy</BoldLink></SmallText>

          {/* Icon here */}
          <AppStoreIcon />

        </FieldContainer>
      </RightSide>
    </Container>
  )
}

const mapStateToProps = state => ({
  authError: state.authReducer.error,
  apiCode: state.userReducer.apiCode,
  signupStatus: state.authReducer.signupStatus,
  regions: state.userReducer.servicedRegions,
})

const mapDispatchToProps = dispatch => ({
  register: (requestData) => dispatch(registerUser(requestData)),
  clearRegisterError: () => dispatch(clearRegisterError()),
  logout: () => dispatch(logout())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPage))
