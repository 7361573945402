/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react'
import PageLayout from '../PageLayout'
import { Flex } from '../../components/containers'
import { AccentHeader, SectionHeader } from '../../components/text'
import { Button } from '../../components/buttons'
import { DetailsContainer, Divider } from '../Shipment/ShipmentDetails'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { routes } from '../../routes'
import DeeleeoTabs from '../../components/DeeleeoTabs'
import SizeSectionBatch from './components/SizeSectionBatch'
import PremiumSection from './components/PremiumSection'
import PickupDropoffTimeSection from './components/PickupDropoffTimeSection'
import DropoffTypeSection from './components/DropoffTypeSection'
import { fetchSizeCategory } from '../../redux/reducers/sizeCategoryReducer'
import { prepayDeeleeo } from '../../redux/reducers/deeleeosReducer'
import { formatDatetime, getTimes } from '../../helpers/deeleeoHelper'
import { updateValidationStatus, resetValidationStatus } from '../../redux/reducers/validationReducer'
import { validateManyToOne, validateOneToMany } from '../../helpers/validationHelper'
import OptionsSection from './components/OptionsSection'
import ShipmentInformationFullWidth from './components/ShipmentInformationFullWidth'
import AddressFileUpload from './components/AddressFileUpload'
import axios from 'axios'
import { getURI } from '../../helpers/apiHelpers'
import { addItemsAndImages, findDeeleeo } from '../../helpers/requestHelpers'
import { creditCardMissingMessage, GeneralError } from './CreateDeeleeo'
import ErrorBoundary from '../../helpers/ErrorBoundary'
import OrderItems from '../DeeleeoSummary/components/OrderItems'
import OrderBreakdown from '../DeeleeoSummary/components/OrderBreakdown'
import { useRecoilState } from 'recoil'
import { isInterCity, userDeeleeosManyToOne, userDeeleeosManyToOneIsDirty, userDeeleeosOneToMany, userDeeleeosOneToManyIsDirty } from '../../App'
import GenericModal from '../Dashboard/components/GenericModal'
import BundleMap from './components/BundleMap'
import Sticky from 'react-sticky-el';
import { clearDropoffData, updateDropoffData } from '../../redux/reducers/pickupDropoffReducer'
import ReadyForPickupModal from '../Dashboard/components/ReadyForPickupModal'



function CreateMany(props) {
  const {
    pickupData, dropoffData, deeleeoDetail, history, fetchSizeCategory, userData, validation, updateValidationStatus, regions, isToMany, isToOne, updateDropoff, clearDropOff
  } = props

  const [deeleeosOneToMany, setDeeleeosOneToMany] = useRecoilState(userDeeleeosOneToMany);
  const [deeleeosManyToOne, setDeeleeosManyToOne] = useRecoilState(userDeeleeosManyToOne);
  const [deeleeosOneToManyIsDirty, setDeeleeosOneToManyIsDirty] = useRecoilState(userDeeleeosOneToManyIsDirty);
  // const [isDeeleeosNotReadySubmitted, setIsDeeleeosNotReadySubmitted] = useRecoilState(deeleeosNotReadySubmitted);
  // eslint-disable-next-line no-unused-vars
  const [deeleeosManyToOneIsDirty, setDeeleeosManyToOneIsDirty] = useRecoilState(userDeeleeosManyToOneIsDirty);

  // eslint-disable-next-line no-unused-vars
  const [interCity, setInterCity] = useRecoilState(isInterCity);

  /**
  * Button statuses
  */
  const submittingLabel = 'Submitting...';
  const submittedLabel = 'Submited';
  const errorLabel = 'Error, try again';
  const bundleButtonInitial = 'Continue';

  const bundleSubmitButtonInitial = 'Submit Deeleeos & Get Labels';


  const [state, setState] = useState({
    validationData: validation,
    isValidationDataValid: true,
    ajaxing: false,
    selectedDeeleeo: null,
    selectedBundle: [],
    promisesEstimate: [],
    promises: [],
    submissionEstimate: [],
    bundleButton: bundleButtonInitial,
    bundleSubmitButton: bundleSubmitButtonInitial,
    fileData: [],
    isSpecialTiming: false,
    failedSubmission: false,
    totalDeeleeos: 0,
    onholdProcessed: 0,
    submitProcess: '',
    submitting: false,
    displayConfirmation: false,
  })



  const setValidationData = (data) => {
    setState(state => ({ ...state, validationData: data }));
  }

  const setIsValidationDataValid = (data) => {
    setState(state => ({ ...state, isValidationDataValid: data }));
  }

  const setAjaxing = (data) => {
    setState(state => ({ ...state, ajaxing: data }));
  }

  const setSelectedDeeleeo = (data) => {
    setState(state => ({ ...state, selectedDeeleeo: data }));
  }

  const setSelectedBundle = (data) => {
    setState(state => ({ ...state, selectedBundle: data }));
  }

  const setPromisesEstimate = (data) => {
    setState(state => ({ ...state, promisesEstimate: data }));
  }

  const setPromises = (data) => {
    setState(state => ({ ...state, promises: data }));
  }

  const setSubmissionEstimate = (data) => {
    setState(state => ({ ...state, submissionEstimate: data }));
  }

  const setBundleButton = (data) => {
    setState(state => ({ ...state, bundleButton: data }));
  }

  const setBundleSubmitButton = (data) => {
    setState(state => ({ ...state, bundleSubmitButton: data }));
  }

  const setFailedSubmission = (data) => {
    setState(state => ({ ...state, failedSubmission: data }));
  }

  /**
   * Data imported from file
   */
  const setFileData = (data) => {
    setState(state => ({ ...state, fileData: data }));
  }

  const setIsSpecialTiming = (data) => {
    setState(state => ({ ...state, isSpecialTiming: data }));
  }

  const setTotalDeeleeos = (data) => {
    setState(state => ({ ...state, totalDeeleeos: data }));
    if (data > 0) {
      setState(state => ({ ...state, submitProcess: `${state.onholdProcessed}/${state.totalDeeleeos}` }));
      // setSubmitProcess(`${state.onholdProcessed}/${state.totalDeeleeos}`);
    }

  }

  const setOnholdProcessed = (data) => {
    setState(state => ({ ...state, onholdProcessed: data }));
    if (data > 0) {
      setState(state => ({ ...state, submitProcess: `${state.onholdProcessed}/${state.totalDeeleeos}` }));
      // setSubmitProcess(`${state.onholdProcessed}/${state.totalDeeleeos}`);
    }
  }

  const setSubmitProcess = (data) => {
    setState(state => ({ ...state, submitProcess: data }));
  }

  const setSubmitting = (data) => {
    setState(state => ({ ...state, submitting: data }));
  }

  const setDisplayConfirmation = (data) => {
    let is_validation_data_valid = doValidation();

    /**
     * If all data is valid
     */
    if (is_validation_data_valid) {
      setState(state => ({ ...state, displayConfirmation: data }));
    }
  }







  useEffect(() => {

    if (state.fileData.length === 0) {
      clearDropOff();
    }

  }, [clearDropOff, state.fileData.length]);

  useEffect(() => {
    try {
      if (isToMany && state.fileData.length > 0) {
        // console.log('is tomany and file data');

        const firstDeeleeo = state.fileData.find(d => d.zone !== null);
        // console.log('first deeleeo', firstDeeleeo);

        if (firstDeeleeo.latitude && firstDeeleeo.longitude) {
          // console.log('updating dropoff');
          // console.log(firstDeeleeo.latitude , firstDeeleeo.longitude);


          updateDropoff({ latitude: firstDeeleeo.latitude, longitude: firstDeeleeo.longitude });
        }
      }
    } catch (e) {
      console.log(e);

    }

  }, [state.fileData, state.fileData.length, isToMany, updateDropoff]);
  /**
   * refresh button labels id deeleeo deetail pickup type and drop off type changes
   */
  useEffect(() => {
    setBundleSubmitButton('Submit Deeleeos & Get Labels');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeleeoDetail.pickup_type, deeleeoDetail.dropoff_type, deeleeoDetail]);

  /**
   * Check if user has enough credit
   */
  const checkUserCredit = () => {
    if (!state.submitting) {
      createDeeleeo();
      setSubmitting(true);
    }
  }


  /**
   * Submit the bundles to the server
   */
  const createDeeleeo = () => {
    setBundleSubmitButton(submittingLabel);
    // const bundlesToSend = new Map();

    let redirectDeeleeosToOnHold = [];
    let normalDeeleeosData = [];

    /**
     * If on Hold enable and batch is not urgent
     */
    if (userData.isOnHoldEnabled
      // && parseInt(deeleeoDetail.pickup_type + '') !== 3
      // && parseInt(deeleeoDetail.dropoff_type + '') !== 3
    ) {
      /**
       * Get deeleeos with my fleet assigned or not
       */
      if (isToMany) {
        redirectDeeleeosToOnHold = deeleeosOneToMany.filter((deeleeo) => {
          return !deeleeo.assign_my_fleet_driver;
        });

        normalDeeleeosData = deeleeosOneToMany.filter((deeleeo) => {
          return deeleeo.assign_my_fleet_driver;
        });
      }

      if (isToOne) {
        redirectDeeleeosToOnHold = deeleeosManyToOne.filter((deeleeo) => {
          return !deeleeo.assign_my_fleet_driver;
        });

        normalDeeleeosData = deeleeosManyToOne.filter((deeleeo) => {
          return deeleeo.assign_my_fleet_driver;
        });
      }
    }
    else {
      /**
       * If user is not on hold enabled or urgent was selected, we send all deeleeos to normal process.
       */

      if (isToMany) {
        normalDeeleeosData = deeleeosOneToMany;
      }
      if (isToOne) {
        normalDeeleeosData = deeleeosManyToOne;
      }
    }

    /**
     * if there is any deeleeo with custom pricing, submit it to on hold
     */
    if (redirectDeeleeosToOnHold.length > 0) {
      ajaxSubmitToOnHold(redirectDeeleeosToOnHold);
    }

    /**
     * If bundles data did not get any deeleeo left,
     * return
     */
    if (normalDeeleeosData.length < 1) {
      return false;
    }

    /**
     * First regroup deeleeos by their bundle id
     */
    // normalDeeleeosData.forEach(e => {
    //   if (bundlesToSend.has(e.bundle_id)) {
    //     let singleBundle = bundlesToSend.get(e.bundle_id);
    //     singleBundle.push(e);
    //     bundlesToSend.set(e.bundle_id, singleBundle);
    //   } else {
    //     let singleBundle = [];
    //     singleBundle.push(e);
    //     bundlesToSend.set(e.bundle_id, singleBundle);
    //   }
    // });

    /**
     * Loop through the bundles and submit them
     */
    // let uuidsSubmitted = [];
    // eslint-disable-next-line no-unused-vars

    ajaxSubmitNormalDeeleeos(normalDeeleeosData);


  }



  const getLabelsByClientUUID = (ids) => {
    axios.post(getURI('/api/web/deeleeo/sendLabels'), { api_key: userData.apiCode, ids: ids })
      .then(response => {
        cleanData(ids);
      })
  }

  /**
   * Estimate promises
   */
  useEffect(() => {

    if (!state.promisesEstimate.length) {
      return;
    }

    Promise.all(state.promisesEstimate)
      .then(results => {

        let deeleeosAccumulator = [];
        results.forEach(data => {
          setBundleButton(submittedLabel);
          let element = data.data;

          element.deliveryFee = element.delivery_fee / 100;
          element.premiumFee = element.premiums_fee / 100;
          element.taxes = Number(((element.sub_total / 100) * (element.gst_rate / 100)) + ((element.sub_total / 100) * (element.pst_rate / 100))).toFixed(2);
          try {
            element.taxes = parseFloat(element.taxes);
          } catch (e) {
            console.log('problem setting taxes');
          }
          element.subTotal = element.sub_total / 100;
          element.service_admin_fee = element.service_admin_fee / 100;


          /**
           * Find the original full deeleeo data
           */
          let full = findDeeleeo(element.uuid, state.submissionEstimate);

          // console.log('full deeleeo data', full);


          /**
            * Required when creating deeleeos.
            */
          full = addItemsAndImages(full);

          /**
           * Merge objects
           */
          element = { ...full, ...element };

          // console.log('after merge', element);

          element.skip_label = true;

          deeleeosAccumulator.push(element);
        });

        /**
           * Every time we submit, we clean the is dirty flag,
           * and set to the correct collection
           */
        if (isToMany) {
          setDeeleeosOneToManyIsDirty(false);
          setDeeleeosOneToMany(deeleeosAccumulator);
        }
        if (isToOne) {
          setDeeleeosManyToOneIsDirty(false);
          setDeeleeosManyToOne(deeleeosAccumulator);
        }


        setAjaxing(false);
      })
      .catch(error => {
        alert('something went wrong')
        setBundleButton(errorLabel);
        console.log(error);
        setAjaxing(false);

      });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.promisesEstimate, state.promisesEstimate.length]);





  const cleanData = (uuid) => {

    let temp = null;
    if (isToMany) {
      temp = deeleeosOneToMany.filter(d => {
        return uuid.indexOf(d.uuid) === -1;
      })

      setDeeleeosOneToMany(temp);

    }

    if (isToOne) {
      temp = deeleeosManyToOne.filter(d => {
        return uuid.indexOf(d.uuid) === -1;
      })

      setDeeleeosManyToOne(temp);
    }

    // console.log('temp array', temp);


    if (temp && temp.length === 0) {
      setBundleButton(() => submittedLabel);
      redirect();
    } else {
      // console.log('in the else, some deeleeos were skipped');
      setFailedSubmission(true);

      setBundleSubmitButton('Submit Deeleeos & Get Labels');
    }
  }

  const redirect = () => {
    /**
     * Clean the data
     */
    setDeeleeosOneToMany([]);
    setDeeleeosManyToOne([]);
    setTotalDeeleeos(0);
    setOnholdProcessed(0);
    setSubmitProcess('');
    // console.log('redirecting');

    history.push(routes.dashboard);

  }


  /**
   * Get's the message to display when there is an error when validating data
   * 
   * @param {String} key 
   * @returns String
   */
  const getValdiationErrorText = (key) => {
    switch (key) {
      case 'fileData':
        return 'File data Missing'
      case 'pickup_data_validation':
        return 'Pickup Information';
      case 'dropoff_data_validation':
        return 'Drop off Information';
      case 'item_name':
        return 'Items are missing Reference Name (first column of CSV)';
      case 'size_weight_selection':
        return 'Weight & Size Selection';
      case 'dropoff_method':
        return 'Drop off Type';
      case 'description':
        return 'Description';
      case 'pickup_time':
        return 'Pickup Time';
      case 'dropoff_time':
        return 'Drop off Time';
      case 'addressValid':
        return 'Please make sure all addresses are validated';
      case 'contactInfo':
        return 'Make sure all data has either phone number (10 digits) or email';
      case 'refrigeration':
        return 'Please choose a Temperature Controlled Option'
      case 'package_count':
        return 'Please enter a valid package count'
      default:
        return '';
    }
  }

  /**
   * recheck if all validation status are TRUE
   * @param {*} data 
   * @returns boolean
   */
  const checkValidationData = (data) => {
    let return_data = true;

    for (var item in data) {
      if (typeof (data[item]) !== 'object') {
        if (!data[item]) {
          return_data = false;
        }
      } else {
        if (!data[item].type || !data[item].date || !data[item].window_time) {
          return_data = false;
        }
      }
    }

    return return_data;
  }

  /**
   * 
   * Validate all the data is set
   */
  const validateDeeleeoData = () => {

    if (isToMany) {
      let validated_deeleeo_data = validateOneToMany(pickupData, deeleeoDetail, state.fileData, interCity);
      setValidationData({
        pickup_data_validation: validated_deeleeo_data.pickup_data_validation,
        fileData: validated_deeleeo_data.fileData,
        item_name: validated_deeleeo_data.item_name,
        contactInfo: validated_deeleeo_data.contactInfo,
        size_weight_selection: validated_deeleeo_data.size_weight_selection,
        dropoff_method: validated_deeleeo_data.dropoff_method,
        description: validated_deeleeo_data.description,
        pickup_time: validated_deeleeo_data.pickup_time,
        dropoff_time: validated_deeleeo_data.dropoff_time,
        addressValid: validated_deeleeo_data.addressValid,
        refrigeration: validated_deeleeo_data.refrigeration,
        package_count: validated_deeleeo_data.package_count
      })

      return validated_deeleeo_data;
    }
    if (isToOne) {
      let validated_deeleeo_data = validateManyToOne(dropoffData, deeleeoDetail, state.fileData, interCity);
      setValidationData({
        dropoff_data_validation: validated_deeleeo_data.dropoff_data_validation,
        fileData: validated_deeleeo_data.fileData,
        item_name: validated_deeleeo_data.item_name,
        contactInfo: validated_deeleeo_data.contactInfo,
        size_weight_selection: validated_deeleeo_data.size_weight_selection,
        dropoff_method: validated_deeleeo_data.dropoff_method,
        description: validated_deeleeo_data.description,
        pickup_time: validated_deeleeo_data.pickup_time,
        dropoff_time: validated_deeleeo_data.dropoff_time,
        addressValid: validated_deeleeo_data.addressValid,
        refrigeration: validated_deeleeo_data.refrigeration,
        package_count: validated_deeleeo_data.package_count
      })

      return validated_deeleeo_data;
    }


  }

  const ajaxSubmitNormalDeeleeos = (normalDeeleeos) => {
    let promiseAccumulator = [];
    // setAjaxSubmitingToBundle(true);
    promiseAccumulator.push(...normalDeeleeos.map(value => axios.post(getURI('/api/web/deeleeo'), value)));
    const merged = [...state.promises, ...promiseAccumulator];
    setPromises(merged);
  }

  const ajaxSubmitToOnHold = async (submission) => {

    setTotalDeeleeos(submission.length);
    const uuids = [];

    const chunkSize = 10;

    for (let i = 0; i < submission.length; i += chunkSize) {
      const chunk = submission.slice(i, i + chunkSize);
      // setAjaxSubmitingToOnHold(true);
      try {
        let data = await axios.post(getURI('/api/web/deeleeo/on-hold'), chunk);
        // console.log('each bunch', data.data);

        uuids.push(...data.data.uuid);
        setOnholdProcessed(uuids.length);
      } catch (e) {
        console.log(e);
        setSubmitting(false);

      }
    }
    getLabelsByClientUUID(uuids);
    // const merged = [...state.promises, ...promiseAccumulator];
    // setPromises(merged);
  }

  const doValidation = () => {
    let temp_validation = validateDeeleeoData();
    let is_validation_data_valid = checkValidationData(temp_validation);
    setIsValidationDataValid(is_validation_data_valid);
    return is_validation_data_valid;
  }

  const mapDataForSubmission = (readyForPickup) => {

    if (isToMany) {
      return state.fileData.map((e, i) => {
        let result = {};
        result.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        result.uuid = e.uuid; //uuidv4();
        result.client_uuid = result.uuid;
        result.api_code = userData.apiCode;
        result.pickup_name = pickupData.name;
        result.pickup_address = pickupData.address;
        result.pickup_phonenumber = pickupData.phonenumber;
        result.pickup_email = pickupData.email;
        result.pickup_latitude = pickupData.latitude;
        result.pickup_longitude = pickupData.longitude;

        result.user_latitude = pickupData.latitude;
        result.user_longitude = pickupData.longitude;

        result.dropoff_name = e.name;
        /**
         * Use w3w if it's set
         */
        if ((e.address === '' || e.address === undefined) && e.w3w) {
          result.dropoff_address = e.w3w;
        } else {
          result.dropoff_address = e.address;
        }

        if (e.unitNumber) {
          result.customer_note = `Unit#: ${e.unitNumber} ${e.details} `;
          result.dropoff_unit = e.unitNumber;
        } else {
          result.customer_note = e.details;
        }

        // result.customer_note = e.details;
        result.dropoff_phonenumber = e.phone;
        result.dropoff_email = e.email ? e.email : '';
        result.dropoff_latitude = e.latitude;
        result.dropoff_longitude = e.longitude;





        result.item_name = e.item_name;
        result.distance = 0;
        result.duration = 0;
        result.number_of_trip = 1;


        result.size_category_id = parseInt(deeleeoDetail.size_category_id);
        result.weight_category_id = parseInt(deeleeoDetail.weight_category_id);
        result.description = deeleeoDetail.description;
        result.pickup_type = parseInt(deeleeoDetail.pickup_type);
        result.pickup_early_datetime = formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_early_datetime);
        result.pickup_later_datetime = formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_later_datetime)
        result.dropoff_type = parseInt(deeleeoDetail.dropoff_type);
        result.dropoff_early_datetime = formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_early_datetime);
        result.dropoff_later_datetime = formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_later_datetime);



        if (e.assign_my_fleet_driver) {
          result.assign_my_fleet_driver = e.assign_my_fleet_driver;
        }



        Object.keys(deeleeoDetail.premium_options).forEach(key => {
          if (deeleeoDetail.premium_options[key]) {
            result[key] = 1;
          }
        })

        switch (parseInt(deeleeoDetail.dropoff_method)) {
          case 1:
            result['require_in_person_confirmation'] = 1;
            break;
          case 2:
            result['is_age_restricted'] = 1;
            break;
          default:
            break;
        }

        result.is_intercity = interCity ? 1 : 0;

        result.package_count = e.package_count;
        result.do_special_time = state.isSpecialTiming ? 1 : 0;

        return result;

      });
    }
    if (isToOne) {
      return state.fileData.map((e, i) => {
        let result = {};
        result.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        result.uuid = e.uuid; //uuidv4();
        result.client_uuid = result.uuid;
        result.api_code = userData.apiCode;
        result.dropoff_name = dropoffData.name;
        result.dropoff_address = dropoffData.address;
        result.dropoff_phonenumber = dropoffData.phonenumber;
        result.dropoff_email = dropoffData.email ? dropoffData.email : '';
        result.dropoff_latitude = dropoffData.latitude;
        result.dropoff_longitude = dropoffData.longitude;

        result.pickup_name = e.name;
        /**
         * Use w3w if it's set
         */

        if ((e.address === '' || e.address === undefined) && e.w3w) {
          result.pickup_address = e.w3w;
        } else {
          result.pickup_address = e.address;
        }

        if (e.unitNumber) {
          result.customer_note = `Unit#: ${e.unitNumber} ${e.details}`;
          result.pickup_unit = e.unitNumber;
        } else {
          result.customer_note = e.details;
        }

        // result.customer_note = e.details;
        result.pickup_phonenumber = e.phone;
        result.pickup_email = e.email;
        result.pickup_latitude = e.latitude;
        result.pickup_longitude = e.longitude;

        result.user_latitude = e.latitude;
        result.user_longitude = e.longitude;


        result.item_name = e.item_name;
        result.distance = 0;
        result.duration = 0;
        result.number_of_trip = 1;

        result.size_category_id = parseInt(deeleeoDetail.size_category_id);
        result.weight_category_id = parseInt(deeleeoDetail.weight_category_id);
        result.description = deeleeoDetail.description;
        result.pickup_type = parseInt(deeleeoDetail.pickup_type);
        result.pickup_early_datetime = formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_early_datetime);
        result.pickup_later_datetime = formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_later_datetime)
        result.dropoff_type = parseInt(deeleeoDetail.dropoff_type);
        result.dropoff_early_datetime = formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_early_datetime);
        result.dropoff_later_datetime = formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_later_datetime);

        if (e.assign_my_fleet_driver) {
          result.assign_my_fleet_driver = e.assign_my_fleet_driver;
        }

        Object.keys(deeleeoDetail.premium_options).forEach(key => {
          if (deeleeoDetail.premium_options[key]) {
            result[key] = 1;
          }
        })

        switch (parseInt(deeleeoDetail.dropoff_method)) {
          case 1:
            result['require_in_person_confirmation'] = 1;
            break;
          case 2:
            result['is_age_restricted'] = 1;
            break;
          default:
            break;
        }


        result.is_intercity = interCity ? 1 : 0;


        result.package_count = e.package_count;
        result.do_special_time = state.isSpecialTiming ? 1 : 0;

        return result;

      });
    }


  }

  const reSubmitToBundle = () => {

    if (isToMany) {
      if (deeleeosOneToMany.length > 0) {
        ajaxSubmitToEstimate(deeleeosOneToMany);
      }
    }

    if (isToOne) {
      if (deeleeosManyToOne.length > 0) {
        ajaxSubmitToEstimate(deeleeosManyToOne);
      }
    }

  }

  const ajaxSubmitToEstimate = (submission) => {
    /**
    * Submit all the data to the server to generate bundles
    */
    setAjaxing(true);

    let promiseAccumulator = submission.map((data) => {
      return axios.post(getURI('/api/web/deeleeo/payment-summary'), data);
    });

    setPromisesEstimate(promiseAccumulator);

  }

  const setConfirmation = (value) => {
    if (!value) {
      // setIsDeeleeosNotReadySubmitted(true);
    }
    submitToEstimate(value);
  }


  /**
   * Submit data to be bundled
   */
  const submitToEstimate = (readyForPickup) => {

    let is_validation_data_valid = doValidation();

    /**
     * If all data is valid
     */
    if (is_validation_data_valid) {
      setBundleButton(submittingLabel);

      /**
       * Map the data for submission
       */
      const submission = mapDataForSubmission(readyForPickup);

      setSubmissionEstimate(submission);

      ajaxSubmitToEstimate(submission);
    }

  }

  useEffect(() => {
    fetchSizeCategory()
  }, [fetchSizeCategory])

  useEffect(() => {
    // dispatch update validation status
    updateValidationStatus(state.validationData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.validationData])

  /**
   * Refresh if bundle length changes or is dirty
   */
  useEffect(() => {
    if (!userData.isOnHoldEnabled && deeleeosOneToManyIsDirty) {
      reSubmitToBundle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeleeosOneToMany.length, deeleeosOneToManyIsDirty]);



  return (
    <PageLayout wider>
      <GenericModal
        content={'Some Deliveries failed to upload, please try again. If the problem persist, please contact support with the missing deeleeos information.'}
        contentLabel={'Notification'}
        modalIsOpen={state.failedSubmission}
        setModalIsOpen={setFailedSubmission}
      />


      <ReadyForPickupModal
        times={getTimes(deeleeoDetail)}
        modalIsOpen={state.displayConfirmation}
        setModalIsOpen={setDisplayConfirmation}
        setConfirmation={setConfirmation}
      />







      <DeeleeoTabs selectedTab={1} />
      <Divider />
      <DetailsContainer>
        <Flex style={{ justifyContent: 'space-between' }}>
          <AccentHeader>Deeleeo Batch {isToMany ? '(Many Drop Offs)' : '(Many Pickups)'} </AccentHeader>
          {(deeleeosOneToMany.length === 0 || deeleeosManyToOne.length === 0) &&
            <Button
              onClick={() => {
                setDeeleeosOneToMany([]);
                setDeeleeosManyToOne([]);
              }
              }>Clear Data</Button>
          }
        </Flex>

        {(deeleeosOneToMany.length === 0 && deeleeosManyToOne.length === 0) && <>
          <Flex>
            <SectionHeader>Deeleeo Details {interCity ? '(Intercity)' : '(Local)'}</SectionHeader>
          </Flex>
          <Flex>
            {isToMany && <ShipmentInformationFullWidth error={validation.pickup_data_validation} shipmentInfo={pickupData} title={'Pickup Information'} isSender={true} />}
            {!isToMany && <ShipmentInformationFullWidth error={validation.dropoff_data_validation} shipmentInfo={dropoffData} title={'Drop Off Information'} isSender={false} />}

            {/* <ShipmentInformation error={validation.dropoff_data_validation} shipmentInfo={dropoffData} title={'Dropoff information'} isRecipient /> */}
          </Flex>

          <Flex>
            <ErrorBoundary>
              <AddressFileUpload
                regions={regions}
                userData={userData}
                fileData={state.fileData}
                setFileData={setFileData}
                updateDropoff={updateDropoff}
              />
            </ErrorBoundary>
          </Flex>

          {/* referance name ( item name ) */}
          {/* <ItemName /> */}
          {/* size */}
          <SizeSectionBatch />

          {/* Premium options */}
          <PremiumSection />

          {/* Dropoff type section - Contactless/In-person */}
          <DropoffTypeSection />

          {/* description */}
          {/* <DescriptionSection /> */}

          {/* <PhotoUpload /> */}
          {/* {image && <Divider className='mt-4' />} */}

          <PickupDropoffTimeSection setIsSpecialTiming={setIsSpecialTiming} isBatch={true} />


          {userData.isFavouriteAgentEnable && <OptionsSection />}

          {/* display list of current error */}
          {!state.isValidationDataValid &&
            <GeneralError getValdiationErrorText={getValdiationErrorText} isValidationDataValid={state.isValidationDataValid} validationData={state.validationData} />
          }
          {/* temporary error message about missing card in user account */}
          {(!userData.hasCardOnAccount && !userData.isEFTPayment && !userData.isUserCredit) && creditCardMissingMessage()}
          {/* {!userData.isOnHoldEnabled && <Button disabled={bundleButton === submittingLabel} onClick={() => submitToBundle()}>{bundleButton}</Button>}
          {userData.isOnHoldEnabled && <Button
            disabled={(!userData.hasCardOnAccount && !userData.isEFTPayment) || onHoldButton === submittingLabel}
            onClick={() => submitOnHold()}>{onHoldButton}</Button>} */}
          <Button disabled={state.bundleButton === submittingLabel} onClick={() => setDisplayConfirmation(true)}>{state.bundleButton}</Button>
        </>}

        {(deeleeosOneToMany.length > 0 || deeleeosManyToOne.length > 0) &&
          <>
            <GenericModal
              content={'Updating data, please wait...'}
              contentLabel={'Notification'}
              modalIsOpen={state.ajaxing}
              setModalIsOpen={setAjaxing} />
            <Sticky>
              <ErrorBoundary><BundleMap deeleeos={[]} selectedDeeleeo={state.selectedDeeleeo} selectedBundle={state.selectedBundle} /></ErrorBoundary>
            </Sticky>


            {/* display detail of deeleeo in line */}
            <OrderItems setSelectedDeeleeo={setSelectedDeeleeo} setSelectedBundle={setSelectedBundle} deeleeos={isToMany ? deeleeosOneToMany : deeleeosManyToOne} isOneToMany={isToMany} isManyToOne={isToOne} />

            {/* display total amount */}
            <OrderBreakdown deeleeos={isToMany ? deeleeosOneToMany : deeleeosManyToOne} />

            <Flex className='mt-14 flex-col'>
              {/* temporary error message about missing card in user account */}
              {(!userData.hasCardOnAccount && !userData.isEFTPayment && !userData.isUserCredit) && creditCardMissingMessage()}
              <Flex>
                <Button
                  disabled={(!userData.hasCardOnAccount && !userData.isEFTPayment && !userData.isUserCredit) || state.bundleSubmitButton === submittingLabel || state.submitting}
                  onClick={() => checkUserCredit()}>{`${state.bundleSubmitButton} ${state.submitProcess}`}
                </Button>
              </Flex>
            </Flex>
          </>}

      </DetailsContainer>

    </PageLayout>
  )
}

const mapStateToProps = state => ({
  pickupData: state.pickupDropoffReducer.pickup_data,
  dropoffData: state.pickupDropoffReducer.dropoff_data,
  deeleeoDetail: state.deeleeoReducer,
  userData: state.userReducer,
  validation: state.validationReducer,
  regions: state.userReducer.servicedRegions
})

const mapDispatchToProps = dispatch => ({
  fetchSizeCategory: () => dispatch(fetchSizeCategory()),
  updateDropoff: (shipmentInfo) => dispatch(updateDropoffData(shipmentInfo)),
  // addDeeleeoToList: (deeleeo) => dispatch(addDeeleeoToList(deeleeo)),
  // updateDeeleeoFromList: (deeleeo, index) => dispatch(updateDeeleeoFromList(deeleeo, index)),
  // clearDeeleeoData: () => dispatch(clearDeeleeoData()),
  clearDropOff: () => dispatch(clearDropoffData()),
  prepayDeeleeo: (amount, api_key) => dispatch(prepayDeeleeo(amount, api_key)),
  updateValidationStatus: (data) => dispatch(updateValidationStatus(data)),
  resetValidationStatus: () => dispatch(resetValidationStatus())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateMany))
