const initState = {
    pickup_data: {
        name: '',
        address: '',
        unit: '',
        phonenumber: '',
        email: '',
        latitude: '',
        longitude: '',
    },
    dropoff_data: {
        name: '',
        address: '',
        unit: '',
        phonenumber: '',
        email: '',
        latitude: '',
        longitude: '',
    }
}

const UPDATE_PICKUP_DATA = 'UPDATE_PICKUP_DATA';
const UPDATE_DROPOFF_DATA = 'UPDATE_DROPOFF_DATA';
const CLEAR_DROPOFF_DATA = 'CLEAR_DROPOFF_DATA';

export const updatePickupData = (data) => ({
    type: UPDATE_PICKUP_DATA,
    data: data
});

export const updateDropoffData = (data) => ({
    type: UPDATE_DROPOFF_DATA,
    data: data
});

export const clearDropoffData = () => ({
    type: CLEAR_DROPOFF_DATA
})

// Use the initialState as a default value
export const pickupDropoffReducer = (state = initState, action) => {
    switch (action.type) {
        // case
        case 'UPDATE_PICKUP_DATA':
            return {
                ...state,
                pickup_data:{
                    ...state.pickup_data, ...action.data
                }
            };
        case 'UPDATE_DROPOFF_DATA':
            return {
                ...state,
                dropoff_data:{
                    ...state.dropoff_data, ...action.data
                }
            };
        case 'CLEAR_DROPOFF_DATA':
            return{
                ...state,
                dropoff_data: initState.dropoff_data
            }
        default:
            return state
    }
}