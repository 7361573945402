import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { connect } from 'react-redux'
import { clearImage } from '../redux/actions/shipmentActions'
import closeIcon from '../assets/icons/x-icon-white.svg'

const ThumbnailContainer = styled.div`
  ${tw`rounded mt-4 relative mr-4`}
`

const ThumbnailImg = styled.img`
  ${tw`rounded-lg w-32`}
`

const CloseButton = styled.img`
  ${tw`rounded-full w-7 absolute -top-2 -right-2 cursor-pointer p-1.5`}
  background-color: #8b8d99;
`

function Thumbnail(props) {
  const { src, clearImage } = props
  return (
    <>
      {src !== '' && <ThumbnailContainer>
        <CloseButton onClick={() => clearImage(src)} src={closeIcon}/>
        <ThumbnailImg src={src} alt={'thumbnail picture'}/>
      </ThumbnailContainer>}
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  clearImage: (image) => dispatch(clearImage(image))
})

export default connect(undefined, mapDispatchToProps)(Thumbnail)
