import React from "react";
import Modal from 'react-modal';
// import styled from 'styled-components'
// import tw from 'twin.macro';
import { Button } from "../../../components/buttons";

// const CloseButton = styled.button`
//   ${tw`flex justify-center  w-auto float-right rounded-full align-middle  mx-5 inline-block pb-2 bg-red-500 text-white hover:bg-blue-700`}
//   font-size: 12px;
//   width: 20px;
//   height: 20px;
//   line-height:20px;

// `

const GenericModal = ({ contentLabel, modalIsOpen, setModalIsOpen, content }) => {




    const closeModal = () => {
        setModalIsOpen(false);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay: {
            backgroundColor: 'rgba(0,0,0,.5)'
          }
    };





    return (<Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={contentLabel}
        ariaHideApp={false}        
    >
        <div className="" style={{ "width": "450px" }}>
            <div className="mb-4" dangerouslySetInnerHTML={{__html:content}}>
                
            </div>
            <div className="flex flex-row-reverse">
                {setModalIsOpen&&<Button className=" py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={closeModal}>
                    Ok
                </Button>}
            </div>
        </div>

    </Modal>);
}

export default GenericModal;