import * as moment from 'moment';

export const validatePickupDropOffData = (data, isDropoff) => {
    //Drop off data does not require email from now on
    if (isDropoff) {
        if (data.name === '' || data.address === '') {
            return false;
        } else {
            return true;
        }
    }
    if (data.name === '' || data.address === '' || data.email === '') {
        return false;
    } else {
        return true;
    }
}

export const validateItemname = (deeleeo) => {

    return !(deeleeo.item_name === '' || deeleeo.item_name === undefined)
}

export const validateSizeWeightSelection = (deeleeo) => {
    return (deeleeo.size_category_id === '' && deeleeo.weight_category_id === '' ? false : true);
}

export const validateDropoffMethod = (deeleeo) => {
    return deeleeo.dropoff_method === '' ? false : true;
}

export const validateDescription = (deeleeo, isValet) => {

    if (isValet) {
        return deeleeo.description === '' ? false : true
    }

    //return deeleeo.description === '' ? false : true
    /**
     * As per Jackson request, description does not need validation.
     */
    return true;
}

export const validatePickupTime = (data) => {
    let obj = {
        type: (data.pickup_type === '' ? false : true),
        date: (data.pickup_date === '' ? false : true),
        window_time: (data.pickup_early_datetime === '' || data.pickup_later_datetime === '' ? false : true)
    }

    return obj;
}

export const validateDropoffTime = (data) => {
    let obj = {
        type: (data.dropoff_type === '' ? false : true),
        date: (data.dropoff_date === '' ? false : true),
        window_time: (data.dropoff_early_datetime === '' || data.dropoff_later_datetime === '' ? false : true)
    }

    return obj;
}

export const validatePickupWindowTimeValet = (deeleeo) => {
    let obj = {
        error: '',
        error_content: ''
    }

    if (deeleeo.pickup_early_datetime === '' || deeleeo.pickup_later_datetime === '') {
        /// false
        obj.error = false;
        obj.error_content = 'Please Check Your Time';
    } else {


        // console.log('early_datetime: ' + deeleeo.pickup_early_datetime);
        // console.log('later_datetime: ' + deeleeo.pickup_later_datetime);





        let early_time = new Date(deeleeo.pickup_date);
        let [earlyHour, earlyMinutes] = deeleeo.pickup_early_datetime.split(':');
        early_time.setHours(parseInt(earlyHour));
        early_time.setMinutes(parseInt(earlyMinutes))

        let later_time = new Date(deeleeo.pickup_date);
        let [lateHour, lateMinutes] = deeleeo.pickup_later_datetime.split(':');
        later_time.setHours(parseInt(lateHour));
        later_time.setMinutes(parseInt(lateMinutes))



        const earlyMoment = moment(early_time);
        const laterTime = moment(later_time);

        // console.log('early: ', earlyMoment.format('HH:mm'));
        // console.log('later: ', laterTime.format('HH:mm'));

        const diff = laterTime.diff(earlyMoment, 'minutes');
        //  console.log('moment dif: ', diff);

        if (diff < 14) {
            obj.error = false;
            obj.error_content = 'Minimum Time Is 15 Minutes';
            return obj;
        }

        const now = moment();

        // console.log('now',now.format('HH:mm'));


        const diffNow = earlyMoment.diff(now, 'minutes');

        //  console.log('moment dif now: ', diffNow);


        if (diffNow < 15) {
            obj.error = false;
            obj.error_content = 'Minimum Time from current time is  Is 15 Minutes';

            return obj;
        }


        let minute_diff = Math.abs(Math.round(((later_time.getTime() - early_time.getTime()) / 1000) / 60));

        let nowMoment = moment();
        let latePickupMoment = moment(later_time);

        let difference = latePickupMoment.diff(nowMoment, 'minutes');


        // console.log('minute diff', minute_diff);
        // console.log(latePickupMoment);


        // console.log('difference: ' + difference);


        if (difference < 180) {
            deeleeo.dropoff_type = 5;
        } else {
            deeleeo.dropoff_type = 2;
        }


        // console.log('moment difference', difference);






        if (minute_diff < 15 && deeleeo.pickup_type !== '3') {
            //// false
            obj.error = false;
            obj.error_content = 'Minimum Time Is 15 Minutes';
        } else {
            /// true
            obj.error = true;
            obj.error_content = '';
        }
    }

    //  console.log(obj);


    return obj;

}

export const validateDropoffWindowTimeValet = (deeleeo) => {
    let obj = {
        error: '',
        error_content: ''
    }

    // console.log(deeleeo);


    if (deeleeo.dropoff_early_datetime === '' || deeleeo.dropoff_later_datetime === '') {
        /// false
        // console.log('here 1');

        obj.error = false;
        obj.error_content = 'Please Check Your Time';
    } else {
        let early_time = new Date(deeleeo.dropoff_date);
        let [earlyHour, earlyMinutes] = deeleeo.dropoff_early_datetime.split(':');
        early_time.setHours(parseInt(earlyHour));
        early_time.setMinutes(parseInt(earlyMinutes))
        let later_time = new Date(deeleeo.dropoff_date);
        let [lateHour, lateMinutes] = deeleeo.dropoff_later_datetime.split(':');
        later_time.setHours(parseInt(lateHour));
        later_time.setMinutes(parseInt(lateMinutes))





        let minute_diff = Math.abs(Math.round(((later_time.getTime() - early_time.getTime()) / 1000) / 60));

        if (minute_diff < 15) {
            //// false
            // console.log('here 2');

            obj.error = false;
            obj.error_content = 'Minimum Time Is 2 Hours';
        } else {
            /// true
            obj.error = true;
            obj.error_content = '';
        }
    }

    return obj;
}

export const validatePickupWindowTime = (deeleeo) => {
    // console.log('validatePickupWindowTime',deeleeo);
    
    let obj = {
        error: '',
        error_content: ''
    }

    if (deeleeo.pickup_early_datetime === '' || deeleeo.pickup_later_datetime === '') {
        /// false
        obj.error = false;
        obj.error_content = 'Please Check Your Time';
    } else {


        let early_time = moment(deeleeo.pickup_date);
        const splitEarly = deeleeo.pickup_early_datetime.split(':');
        early_time.set({
            hours: parseInt(splitEarly[0]),
            minutes: parseInt(splitEarly[1]),

        });


        let later_time = moment(deeleeo.pickup_date);
        const splitLate = deeleeo.pickup_later_datetime.split(':');
        later_time.set({
            hours: parseInt(splitLate[0]),
            minutes: parseInt(splitLate[1]),

        });


        let minute_diff = later_time.diff(early_time, 'minutes');

        const threshold = deeleeo.is_express ? 15 : 30;


        if (minute_diff < threshold && deeleeo.pickup_type !== '3') {
            //// false
            obj.error = false;
            obj.error_content = `Minimum Time Is ${threshold} Minutes`;
        } else {
            /// true
            obj.error = true;
            obj.error_content = '';
        }
    }

    // console.log(obj);


    return obj;
}

export const validateDropoffWindowTime = (deeleeo) => {
    let obj = {
        error: true,
        error_content: ''
    }

    if (deeleeo.dropoff_early_datetime === '' || deeleeo.dropoff_later_datetime === '') {
        /// false
        obj.error = false;
        obj.error_content = 'Please Check Your Time';
    } else {
        // console.log('deeleeo', deeleeo);

        let early_time = moment(deeleeo.dropoff_date);
        const splitEarly = deeleeo.dropoff_early_datetime.split(':');
        early_time.set({
            hours: parseInt(splitEarly[0]),
            minutes: parseInt(splitEarly[1]),

        });


        let later_time = moment(deeleeo.dropoff_date);
        const splitLate = deeleeo.dropoff_later_datetime.split(':');
        later_time.set({
            hours: parseInt(splitLate[0]),
            minutes: parseInt(splitLate[1]),

        });


        let minute_diff = later_time.diff(early_time, 'minutes');

        // console.log('minutes difference drop off window', early_time.toDate(), later_time.toDate());

        const threshold = deeleeo.is_express ? 15 : 60;

        if (minute_diff < threshold) {

            //// false
            obj.error = false;
            obj.error_content = `Minimum Time Is ${threshold} Minutes`;

        } 

    }


    return obj;
}

//validate all
export const validateAll = (pickupData, dropoffData, deeleeoDetail, isValet, isIntercity) => {
    var check_pickup_time_data = validatePickupTime(deeleeoDetail);
    var check_dropoff_time_data = validateDropoffTime(deeleeoDetail);
    let check_pickup_time_window, check_dropoff_time_window;
    if (isValet) {
        check_pickup_time_window = validatePickupWindowTimeValet(deeleeoDetail);
        check_dropoff_time_window = validateDropoffWindowTimeValet(deeleeoDetail);
    } else {
        check_pickup_time_window = validatePickupWindowTime(deeleeoDetail);
        check_dropoff_time_window = validateDropoffWindowTime(deeleeoDetail);
    }






    var return_data = {
        pickup_data_validation: validatePickupDropOffData(pickupData, false),
        dropoff_data_validation: validatePickupDropOffData(dropoffData, true),
        item_name: validateItemname(deeleeoDetail),
        size_weight_selection: isValet ? isValet : validatePackageCountAndWeight(deeleeoDetail),
        dropoff_method: validateDropoffMethod(deeleeoDetail),
        description: validateDescription(deeleeoDetail, isValet),
        pickup_time: {
            type: check_pickup_time_data.type,
            date: check_pickup_time_data.date,
            window_time: check_pickup_time_window.error,
            window_time_error_text: check_pickup_time_window.error_content
        },
        dropoff_time: {
            type: check_dropoff_time_data.type,
            date: check_dropoff_time_data.date,
            window_time: check_dropoff_time_window.error,
            window_time_error_text: check_dropoff_time_window.error_content
        },
        refrigeration: (isValet || !isIntercity) ? (isValet || !isIntercity) : validateRefrigeration(deeleeoDetail),
        package_count: isValet ? isValet : validatePackageCountAndWeight(deeleeoDetail),
        vin: isValet ? deeleeoDetail.vin.length === 17 : true
    }

    //  console.log('validate all');
    //  console.log(return_data);



    return return_data;
}

const validatePackageCountAndWeight = (deeleeoDetail) => {    
   return deeleeoDetail.multi_package.findIndex(p => p.packages > 0)>-1;
}



const validateDescriptionForArray = (data) => {


    const result = data.filter(e => {
        //console.log(e);
        /**
         * Return only if missing the item name
         */
        //console.log(!validateItemname(e));

        return !validateItemname(e);
    }).length;

    //console.log('result: ' +( result === 0));


    return result === 0;
}

const validateAddressInArray = (data) => {
    const result = data
        .filter(e => {
            return !e.valid;
        }).length;
    return result === 0;
}

const validateContactInArray = (data) => {
    const regexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    const result = data
        .filter(e => {
            let emailValid = false;
            try {
                emailValid = e.email && e.email.match(regexp);
            } catch (e) {
                console.log('Invalid email');
                console.log(e);

            }

            let phoneNumberValid = false;
            try {
                phoneNumberValid = e.phone &&
                    (e.phone.length === 10 || e.phone.length === 11) && parseInt(e.phone) > 0;
            } catch (e) {
                console.log('invalid phone');
                console.log(e);
            }
            return !(emailValid || phoneNumberValid);
        })
        .map(e => {
            return e;
        })
        .length;




    return result === 0;
}

const validateRefrigeration = (data) => {
    return (data.premium_options.require_refrigerated_vehicle || data.premium_options.not_require_refrigerated_vehicle)
}

const validatePackagesInArray = (data) => {
    const result = data
        .filter(e => {
            return !e.package_count || e.package_count <= 0;
        }).length;
    return result === 0;
}

export const validateOneToMany = (pickupData, deeleeoDetail, fileData, isInterCity) => {
    const check_pickup_time_data = validatePickupTime(deeleeoDetail);
    const check_pickup_time_window = validatePickupWindowTime(deeleeoDetail);

    const check_dropoff_time_data = validateDropoffTime(deeleeoDetail);
    const check_dropoff_time_window = validateDropoffWindowTime(deeleeoDetail);

    const checkItemName = validateDescriptionForArray(fileData);

    const checkValidatedAddress = validateAddressInArray(fileData);

    const checkValidateContact = validateContactInArray(fileData);

    const return_data = {
        fileData: fileData.length > 0,
        pickup_data_validation: validatePickupDropOffData(pickupData),
        addressValid: checkValidatedAddress,
        contactInfo: checkValidateContact,
        item_name: checkItemName,
        size_weight_selection: validateSizeWeightSelection(deeleeoDetail),
        dropoff_method: validateDropoffMethod(deeleeoDetail),
        description: validateDescription(deeleeoDetail),
        pickup_time: {
            type: check_pickup_time_data.type,
            date: check_pickup_time_data.date,
            window_time: check_pickup_time_window.error,
            window_time_error_text: check_pickup_time_window.error_content
        },
        dropoff_time: {
            type: check_dropoff_time_data.type,
            date: check_dropoff_time_data.date,
            window_time: check_dropoff_time_window.error,
            window_time_error_text: check_dropoff_time_window.error_content
        },
        refrigeration: !isInterCity ? !isInterCity : validateRefrigeration(deeleeoDetail),
        package_count: validatePackagesInArray(fileData)
    }

    return return_data;
}

export const validateManyToOne = (dropoffData, deeleeoDetail, fileData, isInterCity) => {
    const check_pickup_time_data = validatePickupTime(deeleeoDetail);
    const check_pickup_time_window = validatePickupWindowTime(deeleeoDetail);

    const check_dropoff_time_data = validateDropoffTime(deeleeoDetail);
    const check_dropoff_time_window = validateDropoffWindowTime(deeleeoDetail);

    const checkItemName = validateDescriptionForArray(fileData);

    const checkValidatedAddress = validateAddressInArray(fileData);

    const checkValidateContact = validateContactInArray(fileData);

    const return_data = {
        fileData: fileData.length > 0,
        dropoff_data_validation: validatePickupDropOffData(dropoffData, true),
        addressValid: checkValidatedAddress,
        contactInfo: checkValidateContact,
        item_name: checkItemName,
        size_weight_selection: validateSizeWeightSelection(deeleeoDetail),
        dropoff_method: validateDropoffMethod(deeleeoDetail),
        description: validateDescription(deeleeoDetail),
        pickup_time: {
            type: check_pickup_time_data.type,
            date: check_pickup_time_data.date,
            window_time: check_pickup_time_window.error,
            window_time_error_text: check_pickup_time_window.error_content
        },
        dropoff_time: {
            type: check_dropoff_time_data.type,
            date: check_dropoff_time_data.date,
            window_time: check_dropoff_time_window.error,
            window_time_error_text: check_dropoff_time_window.error_content
        },
        refrigeration: !isInterCity ? !isInterCity : validateRefrigeration(deeleeoDetail),
        package_count: validatePackagesInArray(fileData)
    }

    return return_data;
}