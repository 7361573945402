import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { apiKeyAtom } from '../App'
import { getURI } from '../helpers/apiHelpers'
import { InputContainer } from './InputField'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';



const UserContactSelect = (props) => {

    const { isSender, onChange } = props;
    const [addresses, setAddresses] = useState([]);
    const [options, setOptions] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState('');

    // eslint-disable-next-line no-unused-vars
    const [apiKey, setApiKey] = useRecoilState(apiKeyAtom);

    /**
     * Load Addresses
     */
    useEffect(() => {
        

        if (apiKey && apiKey !== '') {
            axios
                .get(getURI(`/api/web/user-contact?` + new URLSearchParams({ api_code: apiKey })))
                .then((response) => {
                    

                    if (response.data) {
                        setAddresses(response.data.filter((e) => {
                            if (isSender) {
                                return e.is_pickup;
                            } else {
                                return e.is_dropoff;
                            }
                        }));
                    }


                }).catch((error) => {
                    console.log(error);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiKey])

    const handleChange = (event, value, reason) => {

        try {
            const val = value.value;

            setValue(val);

            const filtered = addresses.filter((e) => {
                return parseInt(e.id) === parseInt(val)
            })

            if (filtered.length > 0) {
                /**
                 * Send it to the parent 
                 */
                onChange(filtered[0]);
            }
        } catch (e) {
            console.log('it is alright');
        }

    }



    useEffect(() => {
        setOptions(getOptions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addresses.length]);

    const getOptions = () => {
        return addresses.map((e) => {
            //return <option key={e.id} value={e.id}>{e.name+'/'+e.address}</option>;
            return { value: e.id, label: e.name + '/' + e.address };
        });
    }

    return (
        <InputContainer className='relative'>
            <Autocomplete
                onChange={handleChange}
                style={{ paddingBottom: 30 }}
                options={options}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} label={isSender ? 'Pickup Contacts' : 'Drop off Contacts'} />}
            />

        </InputContainer>
    );
}


export default UserContactSelect