import styled from 'styled-components'
import tw from 'twin.macro'
import { Link } from 'react-router-dom'

export const LeftSide = styled.div`
  font-family: Varela Round;
  ${tw`bg-primaryBlue flex justify-center`}
  width: 40%;
`

export const RightSide = styled.div`
  font-family: Varela Round;
  ${tw`flex flex-col`}
  width: 60%;
`

export const IconSubText = styled.p`
  font-family: Varela Round;
  font-size: 16px;
  color: #ffffff;
  text-decoration: rgb(255, 255, 255);
  text-align: center;
`

export const TitleText = styled.h1`
  ${tw`text-secondaryOrange`}
  font-family: Montserrat;
  font-size: 30px;
`

export const SubText = styled.p`
  ${tw`mt-3 mb-6`}
  ${props => props.smallMargin && tw`mb-2`}
  font-size: 15px;
  color: #3d405b;
  text-decoration: rgb(61, 64, 91);
  line-height: 22px;
`

export const SubTextNoMargin = styled.p`
  font-size: 15px;
  color: #3d405b;
  text-decoration: rgb(61, 64, 91);
  line-height: 22px;
`


export const RegisterInfoCard = styled.div`
  font-size: 12px;
  color: #3d405b;
  border-radius: 3px;
  ${tw`mt-1 mb-4 p-3.5 border-cardBorder border`}
`

export const Bold = styled.span`
  font-weight: bold;
`

export const BoldLink = (props) => {
  return (
    <Bold className='cursor-pointer'>
      <a href={props.link} target='_blank' rel='noreferrer'>{props.children}</a>
    </Bold>
  )
}

export const PageLink = (props) => {
  return (
    <>
      {props.bold && <Bold>
        <StyledLink to={props.to}>{props.children}</StyledLink>
      </Bold>}
      {!props.bold && <StyledLink {...props} to={props.to}>{props.children}</StyledLink>}
    </>
  )
}

const StyledLink = styled(Link)`
  ${tw`cursor-pointer text-primaryBlue`}
`

export const SignUpButton = styled.button`
  font-size: 16px;
  color: white;
  border-radius: 3px;
  ${tw`bg-primaryBlue h-12 w-44 mt-4`}
  ${props => props.topMargin && tw`mt-8`}
`

export const ResetPasswordButton = styled.button`
  font-size: 16px;
  color: white;
  border-radius: 3px;
  ${tw`bg-primaryBlue h-12 w-52 mt-4`}
  ${props => props.topMargin && tw`mt-8`}
`


export const SmallText = styled.div`
  color: #3d405b;
  font-size: 12px;
  ${tw`mt-6`}
`
