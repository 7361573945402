import React, { useEffect, useState } from 'react'
import PageLayout from '../PageLayout'
import { Flex } from '../../components/containers'
import { AccentHeader, ErrorSubText, ErrorText, SectionHeader } from '../../components/text'
import ShipmentInformation from '../../components/ShipmentInformation'
import { Button } from '../../components/buttons'
import { DetailsContainer, Divider } from '../Shipment/ShipmentDetails'
import { connect } from 'react-redux'
import ItemName from './components/ItemName'
import PhotoUpload from './components/PhotoUpload'
import { withRouter } from 'react-router-dom'
import { routes } from '../../routes'
import DeeleeoTabs from '../../components/DeeleeoTabs'
import DescriptionSection from './components/DescriptionSection'
import { fetchSizeCategory } from '../../redux/reducers/sizeCategoryReducer'
import { addDeeleeoToList, updateDeeleeoFromList } from '../../redux/reducers/deeleeosReducer'
import { formatDatetime, gatheringDeeleeoData } from '../../helpers/deeleeoHelper'
import { clearDeeleeoData, updateSizeSelection } from '../../redux/reducers/deeleeoReducer'
import { clearDropoffData } from '../../redux/reducers/pickupDropoffReducer'

// import { validateDeeleeo, validatePickupData, validateDropoffData } from '../../redux/reducers/validationReducer'
import { updateValidationStatus, resetValidationStatus } from '../../redux/reducers/validationReducer'
import { validateAll, validateSizeWeightSelection } from '../../helpers/validationHelper'
import axios from 'axios'
import { getURI } from '../../helpers/apiHelpers'
import MyFleetSection from './components/MyFleetSection'
import { useRecoilState } from 'recoil'
import { isVehicleSwap, userDeeleeosManyToOne, userDeeleeosOneToMany } from '../../App'
import PickupDropoffTimeSectionValet from './components/PickupDropoffTimeSectionValet'
// import ErrorBoundary from '../../helpers/ErrorBoundary'
import moment from 'moment'



export const GeneralError = (props) => {
  const { validationData, getValdiationErrorText } = props

  return (
    <div>
      <p className='my-2 text-red-500'>Please check the following sections and try again:</p>
      <ul className="list-disc list-inside bg-rose-200">
        {Object.entries(validationData).map(([key]) => {
          if (typeof (validationData[key]) !== 'object') {
            if (!validationData[key]) {
              return (
                <li className='text-red-500'>
                  {getValdiationErrorText(key)}
                </li>
              )
            }
          } else {
            if (!validationData[key].type || !validationData[key].date || !validationData[key].window_time) {
              return (
                <li className='text-red-500'>
                  {getValdiationErrorText(key)}
                </li>
              )
            }
          }
          return null;
        })}
      </ul>
    </div>
  )
}


export const creditCardMissingMessage = () => {

  return (<div>
    <ErrorText style={{ textAlign: 'left', fontSize: '16px' }}>There is no credit card associated with this account</ErrorText>

    <ErrorSubText style={{ textAlign: 'left', paddingBottom: '10px', paddingTop: '5px', color: '#F44336' }}>
      * Save a payment method (credit card) to your account in the "Manage Payment" section
    </ErrorSubText>

  </div>)
}

function CreateDeeleeoValet(props) {
  const {
    pickupData, dropoffData, deeleeoDetail, addDeeleeoToList, updateDeeleeoFromList, clearDeeleeoData, clearDropoffData,
    history, fetchSizeCategory, userData, validation, updateValidationStatus, validateSizeWeightSelection, updateSizeSelection
  } = props

  // console.log('Editing deeleeo? ');
  // console.log(deeleeoDetail);

  // eslint-disable-next-line no-unused-vars
  const [deeleeosManyToOne, setDeeleeosManyToOne] = useRecoilState(userDeeleeosManyToOne);
  // eslint-disable-next-line no-unused-vars
  const [deeleeosOneToMany, setDeeleeosOneToMany] = useRecoilState(userDeeleeosOneToMany);

  // eslint-disable-next-line no-unused-vars
  const [vehicleSwap, setVehicleSwap] = useRecoilState(isVehicleSwap);



  const [validationData, setValidationData] = useState(validation);
  const [isValidationDataValid, setIsValidationDataValid] = useState(true);

  const getValdiationErrorText = (key) => {
    // console.log('getting error: ',key);

    switch (key) {
      case 'pickup_data_validation':
        return 'Pickup Information';
      case 'dropoff_data_validation':
        return 'Drop off Information';
      case 'item_name':
        return 'Reference Name';
      case 'size_weight_selection':
        return 'Weight & Size Selection';
      case 'dropoff_method':
        return 'Drop off Type';
      case 'description':
        return 'Description';
      case 'pickup_time':
        return 'Pickup Time';
      case 'dropoff_time':
        return 'Drop off Time';
      case 'vin':
        return 'VIN';
      default:
        return '';
    }
  }

  // recheck if all validation status are TRUE
  const checkValidationData = (data) => {
    let return_data = true;

    for (var item in data) {
      if (typeof (data[item]) !== 'object') {
        if (!data[item]) {

          // console.log('here 1',data, item, data[item]);


          return_data = false;
        }
      } else {
        if (!data[item].type || !data[item].date || !data[item].window_time) {
          // console.log('here 2', data[item].type, data[item].date, data[item].window_time);

          return_data = false;
        }
      }
    }

    return return_data;
  }

  const validateDeeleeoData = () => {

    let validated_deeleeo_data = validateAll(pickupData, dropoffData, deeleeoDetail, true);
    // console.log(validated_deeleeo_data);

    setValidationData({
      pickup_data_validation: validated_deeleeo_data.pickup_data_validation,
      dropoff_data_validation: validated_deeleeo_data.dropoff_data_validation,
      item_name: validated_deeleeo_data.item_name,
      size_weight_selection: validated_deeleeo_data.size_weight_selection,
      dropoff_method: validated_deeleeo_data.dropoff_method,
      description: validated_deeleeo_data.description,
      pickup_time: validated_deeleeo_data.pickup_time,
      dropoff_time: validated_deeleeo_data.dropoff_time,
      vin: validated_deeleeo_data.vin
    })

    return validated_deeleeo_data;
  }

  /**
   * Update an existing on Hold deeleeo
   */
  const patchOnHoldDeeleeo = () => {

    const dropoff_early_datetime = formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_early_datetime);
    const dropoff_later_datetime = formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_later_datetime);
    const pickup_early_datetime = formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_early_datetime);
    const pickup_later_datetime = formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_later_datetime);

    deeleeoDetail.dropoff_early_datetime = dropoff_early_datetime;
    deeleeoDetail.dropoff_later_datetime = dropoff_later_datetime;
    deeleeoDetail.pickup_early_datetime = pickup_early_datetime;
    deeleeoDetail.pickup_later_datetime = pickup_later_datetime;


    const payload = { deeleeo: deeleeoDetail, pickupData: pickupData, dropoffData: dropoffData, api_code: userData.apiCode };
    axios.post(getURI('/api/web/on-hold-deeleeo/updateExisting'), payload)
      .then((data) => {
        clearDeeleeoData();
        clearDropoffData();
        history.push(routes.dashboard + '?view=2');
      })
      .catch(e => {
        console.log(e);
      })
  }


  const getPickupType = () => {
    // dropoff_early_datetime: formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_early_datetime),
    //   dropoff_later_datetime: formatDatetime(deeleeoDetail.dropoff_date, deeleeoDetail.dropoff_later_datetime),
    //     pickup_type: pickup_type,
    //       pickup_early_datetime: formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_early_datetime),
    //         pickup_later_datetime: formatDatetime(deeleeoDetail.pickup_date, deeleeoDetail.pickup_later_datetime),


    let selected = moment(deeleeoDetail.pickup_date);

    const splitted = deeleeoDetail.pickup_early_datetime.split(':');

    selected.set({
      hour: parseInt(splitted[0]),
      minute: parseInt(splitted[1]),
    });

    const diff = selected.diff(moment(), 'minutes');

    // console.log('getting pickup type',diff,selected.hour());
    

    if (diff < 90) {
      return 3
    }

    if (selected.hour() >= 17) {
      return 3
    }

    // console.log('will return 2');
    

    return 2;

  }

  const getDropOffType = () => {

    let selected = moment(deeleeoDetail.pickup_date);

    const splitted = deeleeoDetail.pickup_early_datetime.split(':');

    selected.set({
      hour: parseInt(splitted[0]),
      minute: parseInt(splitted[1]),
    });

    if (selected.hour() >= 17) {
      return 5
    }

    return 2;

  }

  const addDeeleeoAndGoToSummary = () => {
    let temp_validation = validateDeeleeoData();
    let is_validation_data_valid = checkValidationData(temp_validation);
    setIsValidationDataValid(is_validation_data_valid);
    // return false;
    if (is_validation_data_valid) {

      /**
       * If it's an existing on hold deeleeo, send it to the other end point
       */
      if (deeleeoDetail.is_editing && deeleeoDetail.status === 17) {
        patchOnHoldDeeleeo();
      } else {


        //gathering deeleeo data and add to list (do something so i can skip the assemble data later)
        var deeleeo_data = gatheringDeeleeoData(deeleeoDetail, pickupData, dropoffData, userData);
        /**
        * Valet is always true here
        */
        deeleeo_data.is_valet = 1;

        deeleeo_data.is_vehicle_swap = vehicleSwap ? 1 : 0;

        deeleeo_data.pickup_type = getPickupType();
        deeleeo_data.dropoff_type = getDropOffType();

        deeleeo_data.vin = deeleeoDetail.vin;
        deeleeo_data.ro = deeleeoDetail.ro;


        if (userData.isOnHoldEnabled
          // && parseInt(deeleeo_data.pickup_type + '') !== 3
          // && parseInt(deeleeo_data.dropoff_type + '') !== 3
        ) {
          deeleeo_data.status = 17;
        }

        if (deeleeoDetail.isManyToOne || deeleeoDetail.isOneToMany) {
          /**
           * Merge the edited data with the original
           */
          deeleeo_data = { ...deeleeoDetail, ...deeleeo_data };

          /**
           * Update the collection and redirect
           */
          if (deeleeoDetail.isManyToOne) {
            //setDeeleeosManyToOne();
            const sliced = deeleeosManyToOne.slice();
            setDeeleeosManyToOne(sliced.map((e, i) => {
              /**
               * if the uuid matches, then we replace the data with the edited data
               */
              if (e.uuid === deeleeoDetail.uuid) {
                return deeleeo_data;
              }
              return e;
            }));

            clearDeeleeoData();
            clearDropoffData();
            history.push(routes.createManyToOne);
          }

          /**
           * Update the collection and redirect
           */
          if (deeleeoDetail.isOneToMany) {
            //setDeeleeosOneToMany();
            const sliced = deeleeosOneToMany.slice();
            setDeeleeosOneToMany(sliced.map((e, i) => {
              /**
               * if the uuid matches, then we replace the data with the edited data
               */
              if (e.uuid === deeleeoDetail.uuid) {
                return deeleeo_data;
              }
              return e;
            }));
            clearDeeleeoData();
            clearDropoffData();
            history.push(routes.createOneToMany);
          }

          return false;
        }




        if (deeleeoDetail.is_editing) {
          let index = deeleeoDetail.index;
          updateDeeleeoFromList(deeleeo_data, index);
        } else {
          addDeeleeoToList(deeleeo_data)
        }

        clearDeeleeoData();
        clearDropoffData();
        history.push(routes.summary)

      }
    }
  }

  useEffect(() => {
    fetchSizeCategory()
  }, [fetchSizeCategory])

  useEffect(() => {
    // dispatch update validation status
    updateValidationStatus(validationData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationData])

  useEffect(() => {
    updateSizeSelection(5, 1);

    if (!validation) {
      validateSizeWeightSelection(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation]);



  const getButtonLabel = () => {
    if (deeleeoDetail.is_editing) {
      return 'Save And Go to Summary'
    }
    else {
      return 'Order Summary'
    }
  }

  const getSectionHeader = () => {
    if (vehicleSwap) {
      return 'Vehicle Swap Service Details:';
    } else {
      return 'Valet Service Details:';
    }
  }



  return (
    <PageLayout wider>
      <DeeleeoTabs selectedTab={1} />
      <Divider />
      <DetailsContainer>
        <AccentHeader>What’s the Deeleeo??</AccentHeader>
        <Flex>
          <SectionHeader>{getSectionHeader()}</SectionHeader>
        </Flex>
        <Flex>
          <div className='mt-8 w-1/2 first:mr-12' style={{ fontSize: 10, padding: 0, margin: 0 }}>* = Mandatory Fields</div>
        </Flex>
        <Flex>

          <ShipmentInformation error={validation.pickup_data_validation} shipmentInfo={pickupData} title={'Pickup Information'} isSender isValet />
          <ShipmentInformation error={validation.dropoff_data_validation} shipmentInfo={dropoffData} title={'Drop off Information'} isRecipient isValet />
        </Flex>

        {/* referance name ( item name ) */}
        <ItemName isValet />
        {/* size */}
        {/* <SizeSection /> */}

        {/* Premium options */}
        {/* <PremiumSection /> */}

        {/* Dropoff type section - Contactless/In-person */}
        {/* <DropoffTypeSection /> */}

        {/* description */}
        <DescriptionSection isValet />

        <PhotoUpload isValet />
        {/* {image && <Divider className='mt-4' />} */}

        <PickupDropoffTimeSectionValet />

        <Flex className='flex-row'>
          {/* {userData.isFavouriteAgentEnable && <OptionsSection />} */}

          {userData.isMyFleetEnabled && <MyFleetSection />}
        </Flex>

        {/* display list of current error */}
        {!isValidationDataValid &&
          <GeneralError getValdiationErrorText={getValdiationErrorText} isValidationDataValid={isValidationDataValid} validationData={validationData} />
        }

        <Button onClick={() => addDeeleeoAndGoToSummary()}>{getButtonLabel()}</Button>

      </DetailsContainer>

    </PageLayout>
  )
}

const mapStateToProps = state => ({
  pickupData: state.pickupDropoffReducer.pickup_data,
  dropoffData: state.pickupDropoffReducer.dropoff_data,
  deeleeoDetail: state.deeleeoReducer,
  userData: state.userReducer,
  validation: state.validationReducer,
  validationSW: state.validationReducer.size_weight_selection
})

const mapDispatchToProps = dispatch => ({
  fetchSizeCategory: () => dispatch(fetchSizeCategory()),
  addDeeleeoToList: (deeleeo) => dispatch(addDeeleeoToList(deeleeo)),
  updateDeeleeoFromList: (deeleeo, index) => dispatch(updateDeeleeoFromList(deeleeo, index)),
  clearDeeleeoData: () => dispatch(clearDeeleeoData()),
  clearDropoffData: () => dispatch(clearDropoffData()),
  updateValidationStatus: (data) => dispatch(updateValidationStatus(data)),
  resetValidationStatus: () => dispatch(resetValidationStatus()),
  updateSizeSelection: (size_id, weight_id) => dispatch(updateSizeSelection(size_id, weight_id)),
  validateSizeWeightSelection: (data) => dispatch(validateSizeWeightSelection(data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateDeeleeoValet))
