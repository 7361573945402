import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useRecoilState } from 'recoil';
import { apiKeyAtom } from '../../App';
import DeeleeoTabs from '../../components/DeeleeoTabs';
import { SectionHeader } from '../../components/text';
import PageLayout from '../PageLayout';
import { DetailsContainer, Divider } from '../Shipment/ShipmentDetails';
import Cards from './components/Cards';
import PaymentForm from './components/PaymentForm';

const AddPayment = (props) => {

    const { apiCode } = props;

    // eslint-disable-next-line no-unused-vars
    const [apiKey, setApiKey] = useRecoilState(apiKeyAtom);
    setApiKey(apiCode);

  

    return (
        <PageLayout wider>
            <DeeleeoTabs selectedTab={3} />
            <Divider />
            <DetailsContainer>
                <SectionHeader>Cards:</SectionHeader>
                <Divider />
                <Cards/>
                <SectionHeader>Add Payment Information:</SectionHeader>
                <Divider />
                <PaymentForm />                
            </DetailsContainer>
        </PageLayout>
    );
}

const mapStateToProps = state => ({   
    apiCode: state.userReducer.apiCode,
})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPayment));