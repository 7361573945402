import { getURI } from '../../helpers/apiHelpers'
import axios from 'axios'
import isEqual from 'lodash/isEqual'

const initState = {
    deeleeos: [],
    status: '',
    error: ''
}

const ADD_DEELEEO = 'ADD_DEELEEO';
const UPDATE_DEELEEO = 'UPDATE_DEELEEO';
const DELETE_DEELEEO = 'DELETE_DEELEEO';
const UPDATE_ESTIMATE_PRICE = 'UPDATE_ESTIMATE_PRICE';
const UPDATE_PROCESS_STATUS = 'UPDATE_PROCESS_STATUS';
const CLEAR_ALL_DEELEEO = 'CLEAR_ALL_DEELEEO';
const DELETE_DEELEEO_BY_INDEX = 'DELETE_DEELEEO_BY_INDEX';
const DELETE_DEELEEO_BY_UUID = 'DELETE_DEELEEO_BY_UUID';
const NOTIFY_ERROR = 'NOTIFY_ERROR';

export const addDeeleeoToList = (deeleeo) => ({
    type: ADD_DEELEEO,
    data: deeleeo
})

export const updateDeeleeoFromList = (deeleeo, index) => ({
    type: UPDATE_DEELEEO,
    data: deeleeo,
    index: index
})

export const deleteDeeleeo = (deeleeo) => ({
    type: DELETE_DEELEEO,
    data: deeleeo
})

export const updateEstimatePrice = (estimate_price, index) => ({
    type: UPDATE_ESTIMATE_PRICE,
    data: estimate_price,
    index: index
})

export const getEstimatePrice = (deeleeo, index) => dispatch => {
    axios({
        method: 'post',
        url: getURI('/api/web/deeleeo/payment-summary'),
        headers: {
            'Content-Type': 'application/json',
        },
        data: deeleeo,
        validateStatus: (status) => status === 200,
    })
        .then(response => {
            var estimate_price = response.data;
            dispatch(updateEstimatePrice(estimate_price, index))
        })
        .catch((e) => {
            const message = e.response?.data?.message || 'An error ocurred while processing your request';
            dispatch(notifyError(message));
            dispatch(updateProcessStatus('Fail'));

        });
}

export const prepayDeeleeo = (amount, api_key) => dispatch => {
    dispatch(updateProcessStatus('Prepaying Deeleeo'));
    axios({
        method: 'post',
        url: getURI('/api/web/prepay-deeleeo'),
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            "api_code": api_key,
            "amount": amount
        },
        validateStatus: (status) => status === 200,
    })
        .then(response => {
            if (response.status !== 200) {
                dispatch(updateProcessStatus('Prepay fail'));
                throw new Error('Unable to charge your card');
            } else {
                // console.log('success');
            }
        })
        .then(response => {
            dispatch(updateProcessStatus('Prepay Successful'));
        })
        .catch((e) => {
            const message = e.response?.data?.message || 'An error ocurred while processing your request';
            dispatch(notifyError(message));
            dispatch(updateProcessStatus('Prepay fail'));
        });
}

export const submitDeeleeo = (deeleeo, index) => dispatch => {
    dispatch(updateProcessStatus('Submitting'));
    axios({
        method: 'post',
        url: getURI('/api/web/deeleeo'),
        headers: {
            'Content-Type': 'application/json',
        },
        data: deeleeo,
        validateStatus: (status) => status === 200,
    })
        .then(response => {
            if (response.status !== 200) {
                dispatch(updateProcessStatus('Fail'));
                throw new Error('Unable to create Deeleeo');
            } else {
                // console.log('success');
                // return response.text()
            }
        })
        .then(response => {
            if (deeleeo.uuid) {
                dispatch(removeDeeleeoFromListByUUID(deeleeo.uuid));
            } else {
                dispatch(removeDeeleeoFromListByIndex(index));
            }

            dispatch(updateProcessStatus('Done'));
        })
        .catch((e) => {
            const message = e.response?.data?.message || 'An error ocurred while processing your request';
            dispatch(notifyError(message));
            dispatch(updateProcessStatus('Fail'));
        });
}


export const deleteRemoveFromList = (data) => ({
    type: DELETE_DEELEEO,
    data: data
})

export const removeDeeleeoFromListByIndex = (data) => ({
    type: DELETE_DEELEEO_BY_INDEX,
    data: data
})

export const removeDeeleeoFromListByUUID = (data) => ({
    type: DELETE_DEELEEO_BY_UUID,
    data: data
})

export const updateProcessStatus = (data) => ({
    type: UPDATE_PROCESS_STATUS,
    data: data
})

export const notifyError = (data) => ({
    type: NOTIFY_ERROR,
    data: data
})

export const clearAllDeeleeos = () => ({
    type: CLEAR_ALL_DEELEEO
});

export const deeleeosReducer = (state = initState, action) => {
    switch (action.type) {
        case ADD_DEELEEO:
            return {
                ...state,
                deeleeos: [...state.deeleeos, action.data]
            }
        case UPDATE_DEELEEO:
            let deeleeo_list = [...state.deeleeos];
            deeleeo_list[action.index] = action.data
            return {
                ...state,
                deeleeos: deeleeo_list
            }
        case UPDATE_ESTIMATE_PRICE:
            return {
                ...state,
                deeleeos: state.deeleeos.map((deeleeo, index) => {
                    if (index === action.index) {
                        let result = {
                            ...deeleeo,
                            applied_credit: action.data.applied_credit / 100,
                            billable_amount: action.data.billable_amount / 100,
                            delivery_fee: action.data.delivery_fee / 100,
                            is_sweeten_deal: action.data.is_sweeten_deal,
                            premiums_fee: action.data.premiums_fee / 100,
                            promo_amount: action.data.promo_amount / 100,
                            sub_total: action.data.sub_total / 100,
                            sweeten_amount: action.data.sweeten_amount / 100,
                            tax_rate: action.data.tax_rate,
                            gst_rate: action.data.gst_rate,
                            pst_rate: action.data.pst_rate,
                            taxes: action.data.taxes / 100,
                            dropoff_latitude: action.data.dropoff_latitude,
                            dropoff_longitude: action.data.dropoff_longitude,
                            pickup_latitude: action.data.pickup_latitude,
                            pickup_longitude: action.data.pickup_longitude,
                            user_latitude: action.data.pickup_latitude,
                            user_longitude: action.data.pickup_longitude,
                            distance: action.data.distance,
                            duration: action.data.duration,
                            polyline: action.data.polyline,
                            coordinates: action.data.coordinates,
                            premiums_fee_detail: action.data.premiums_fee_detail,
                            service_admin_fee: action.data.service_admin_fee / 100
                        }
                        if (action.data.custom_pricing) {
                            result.custom_pricing = action.data.custom_pricing;
                        }
                        return result;
                    } else {
                        return {
                            ...deeleeo
                        }
                    }
                })
            }
        case DELETE_DEELEEO:
            // need to check this, should I remove deeleeo by index or by data
            // if use index, got trouble by trigger twice delete btn
            // if use data, got trouble it will remoev all same data (maybe in real life dont have it)
            return {
                ...state,
                // deeleeos: [
                //     ...state.deeleeos.slice(0, action.data),
                //     ...state.deeleeos.slice(action.data + 1)
                // ]
                deeleeos: state.deeleeos.filter(deeleeo => !isEqual(deeleeo, action.data))
            }
        case DELETE_DEELEEO_BY_INDEX:
            let previous_data = [...state.deeleeos];
            previous_data.splice(action.data, 1);
            return {
                ...state,
                deeleeos: previous_data
            }
        case DELETE_DEELEEO_BY_UUID:
            let previous_data_by_uuid = [...state.deeleeos];
            previous_data_by_uuid = previous_data_by_uuid.filter((d) => {
                return d.uuid !== action.data;
            });
            // console.log('will set this as new');
            // console.log(previous_data_by_uuid);
            // console.log(action.data);



            return {
                ...state,
                deeleeos: previous_data_by_uuid
            }
        case UPDATE_PROCESS_STATUS:
            return {
                ...state,
                status: action.data
            }
        case NOTIFY_ERROR:
            return {
                ...state,
                error: action.data
            }
        case CLEAR_ALL_DEELEEO:
            return {
                ...initState
            }
        default:
            return state
    }
}