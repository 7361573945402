import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Col, Flex } from "../../../components/containers";
import { updateDropoffTime, updatePickupTime } from "../../../redux/reducers/deeleeoReducer";
import { validateDropoffTime, validateDropoffWindowTime, validatePickupTime, validatePickupWindowTime } from "../../../redux/reducers/validationReducer";
import DateTimePicker from "../../../components/DateTimePicker";
import { Dash } from "./PickupHourSelector";
import { isTodaySelected, roundTime } from "./PickupDropoffTimeSection";
import { getURI } from "../../../helpers/apiHelpers";
import axios from "axios";
import InfoIcon from "../../../assets/icons/Info-icon.svg";
import { SubTitle } from "../../../components/text";
import { StyledToolTip, TimeAndDistance } from "./shared";


const PickupDropOffTimeExpress = (props) => {

    const { pickup_value, dropoff_value, userData, pickupData, updateDropoffTime, updatePickupTime, dropoffData, validation } = props;
    const RECHECK = 15000;
    const MINUTES_TO_ROUND = 15;
    // const ADD_PICKUP_EARLY_TIME_MINUTES = 30;
    // const ADD_PICKUP_LATE_TIME_MINUTES = 90;
    const ADD_DROP_OFF_EARLY_TIME_MINUTES = 60;
    const ADD_DROP_OFF_LATE_TIME_MINUTES = 75;
    // const TIME_INTERVAL = 15;
    const SECONDS = 60;
    const DIFF_FOR_PREMIUM = 30;

    const MOCKED_TIME = moment()
        // .set({ hour: 16, minute: 44, second: 0 })
        .toDate();


    const [state, setState] = useState({
        pickupTimeValue: pickup_value,
        dropoffTimeValue: dropoff_value,
        timer: 0,
        timeDistance: null,
        error: validation,
    })

    const setPickupTimeValue = (data) => {
        setState(state => ({ ...state, pickupTimeValue: data }));
    }

    const setDropoffTimeValue = (data) => {
        setState(state => ({ ...state, dropoffTimeValue: data }));
    }

    const setTimer = (data) => {
        setState(state => ({ ...state, timer: data }));
    }

    const setTimeDistance = (data) => {
        setState(state => ({ ...state, timeDistance: data }));
    }

    const setError = (data) => {
        setState(state => ({ ...state, error: data }));
    }




    useEffect(() => {
        setError(validation);
    }, [validation]);

    useEffect(() => {
        const interval = setInterval(() => {
            let timer = moment().minute();
            setTimer(timer);
        }, RECHECK);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        // getDistanceInKm

        const payload = {
            api_code: userData.apiCode,
            pickup_latitude: parseFloat(`${pickupData.latitude}`),
            pickup_longitude: parseFloat(`${pickupData.longitude}`),
            dropoff_latitude: parseFloat(`${dropoffData.latitude}`),
            dropoff_longitude: parseFloat(`${dropoffData.longitude}`)
        };

        if ((payload.pickup_latitude !== 0 && !isNaN(payload.pickup_latitude)) &&
            (payload.pickup_longitude !== 0 && !isNaN(payload.pickup_longitude)) &&
            (payload.dropoff_latitude !== 0 && !isNaN(payload.dropoff_latitude)) &&
            (payload.dropoff_longitude !== 0 && !isNaN(payload.dropoff_longitude))) {
            axios.post(getURI('/api/web/deeleeo/getTimeDistance'), payload)
                .then(data => {
                    // console.log('data', data.data);
                    setTimeDistance(data.data);
                })
        }


    }, [dropoffData.latitude, dropoffData.longitude, pickupData.latitude, pickupData.longitude, userData.apiCode]);

    

    useEffect(() => {
        setPickupTimeValue(pickup_value);
    }, [pickup_value]);

    useEffect(() => {
        // console.log('updating drop off time value');

        updateDropoffTime(state.dropoffTimeValue);

        // update validate when drop off type not empty
        if (!state.error.dropoff_time.type && state.dropoffTimeValue.type !== '') {
            validateDropoffTime(state.dropoffTimeValue);
        }

        // update validate based on dropoff time window
        if (!state.error.dropoff_time.window_time && state.dropoffTimeValue.dropoff_early_datetime !== '' && state.dropoffTimeValue.dropoff_later_datetime !== '') {
            validateDropoffWindowTime(state.dropoffTimeValue);
        }


        const pickupValueForUpdate = getPickupTime();

        updateDropoffTime({ ...state.dropoffTimeValue, dropoff_type: pickupValueForUpdate.pickup_type });



        updatePickupTime(pickupValueForUpdate)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.dropoffTimeValue, updateDropoffTime, validateDropoffTime, validateDropoffWindowTime]);


  


    const getPickupTime = () => {

        const isToday = isTodaySelected(new Date(state.pickupTimeValue.pickup_date));
        const minimumTime = moment(minTime(true, false));
        let earlyDropOffTime = moment(state.dropoffTimeValue.dropoff_date);
        const split = state.dropoffTimeValue.dropoff_early_datetime.split(':');
        const duration = state.timeDistance ? Math.ceil(parseInt(state.timeDistance.duration) / SECONDS) : 0;
        earlyDropOffTime
            .set({
                hour: parseInt(split[0]),
                minute: parseInt(split[1]),
            });
        let result = {
            pickup_type: '2',
            pickup_date: state.pickupTimeValue.pickup_date,
            pickup_early_datetime: state.pickupTimeValue.pickup_early_datetime,
            pickup_later_datetime: state.pickupTimeValue.pickup_later_datetime

        }
        if (isToday) {

            const diff = earlyDropOffTime.diff(minimumTime, 'minutes');
            // console.log('difference: ', diff, DIFF_FOR_PREMIUM);

            if (diff <= DIFF_FOR_PREMIUM) {
                result.pickup_type = '3';
            }

        } else {
            result.pickup_type = '2';
        }


        /**
         * From the early drop off time we subtract the travel time plus the rounding time
         */
        earlyDropOffTime = earlyDropOffTime.subtract(duration + MINUTES_TO_ROUND, 'minutes');


        /**
         * the roundTime function always rounds up, hence we subtracted the rounding time previously
         * 
         * This will give us the later time value
         */
        result.pickup_later_datetime = roundTime(earlyDropOffTime.format('HH:mm'), MINUTES_TO_ROUND);

        /**
         * The we subtract 15 minutes more to obtain the earlier time value
         */
        result.pickup_early_datetime = roundTime(earlyDropOffTime.subtract(MINUTES_TO_ROUND, 'minutes').format('HH:mm'), MINUTES_TO_ROUND);


        // console.log('updating pickuptime with: ',result);


        return result;
    }

    

    const dropOffDatetimeHandle = (value, isEarly) => {
        // console.log(state.dropoffTimeValue);

        if (isEarly) {
            setState(state => ({
                ...state,
                dropoffTimeValue: {
                    ...state.dropoffTimeValue,
                    dropoff_early_datetime: value,
                    dropoff_later_datetime: ''
                }
            }));
        } else {
            setDropoffTimeValue({ ...state.dropoffTimeValue, dropoff_later_datetime: value });
        }

    }

    const getRoundedTimeWithAddedMinutesAsDate = (addMinutes, minutesToRound, date = moment().toDate()) => {
        const withMinutesAdded = moment(date).add(addMinutes, 'minutes');

        const rounded = roundTime(withMinutesAdded.format('HH:mm'), minutesToRound);
        const split = rounded.split(':');
        let hour = parseInt(split[0]);
        let minute = parseInt(split[1]);


        return moment(date)
            .set({
                hour: hour,
                minute: minute,
            })
            .toDate();
    }

    const getSelectedDateTimeAsDate = (date, time = '00:00') => {
        const current = moment(date);
        const split = time.split(':');
        if (isTimeSet(time)) {
            current
                .set({
                    hour: parseInt(split[0]),
                    minute: parseInt(split[1]),
                })
        }


        return current.toDate();
    }

    const isTimeSet = (time) => {
        const split = time.split(':');
        return split.length === 2;
    }

  

    

    const minTime = (isEarly, isDropOff) => {
        const minHour = 7;
        const minMinute = 0;
        // console.log('getting min time');


        const isToday = isTodaySelected(new Date(state.pickupTimeValue.pickup_date));
        let result;

        if (isToday && state.timeDistance) {
            // console.log('is today');

            const duration = Math.ceil(parseInt(state.timeDistance.duration) / SECONDS);//convert from seconds to minutes
            if (isEarly) {
                result = moment(getRoundedTimeWithAddedMinutesAsDate(ADD_DROP_OFF_EARLY_TIME_MINUTES + duration, MINUTES_TO_ROUND, MOCKED_TIME));
            } else {
                result = moment(getRoundedTimeWithAddedMinutesAsDate(ADD_DROP_OFF_LATE_TIME_MINUTES + duration, MINUTES_TO_ROUND, MOCKED_TIME));
            }


            const limit = moment(getSelectedDateTimeAsDate(state.pickupTimeValue.pickup_date));
            limit.set('hours', minHour);
            limit.set('minutes', minMinute);

  
        } else {
            result = moment(getSelectedDateTimeAsDate(state.pickupTimeValue.pickup_date));
            result.set('hours', minHour);
            result.set('minutes', minMinute);
            if (!isEarly) {
                result.add(MINUTES_TO_ROUND, 'minutes')
            }
        }

        if (state.dropoffTimeValue.dropoff_early_datetime !== '' && isDropOff) {
            // console.log('pickup_early_datetime', state.dropoffTimeValue.dropoff_early_datetime);

            const split = state.dropoffTimeValue.dropoff_early_datetime.split(':');
            if (split.length > 1) {
                result.set({
                    hours: parseInt(split[0]),
                    minutes: parseInt(split[1])
                });

                result.add(15, 'minutes');
            }

        }

        return result.toDate();
    }

    const maxTime = (isEarly, isDropOf) => {
        let maxHour = 16;
        let maxMinute = 0;

        if (userData.expressMaxTime) {
            const split = userData.expressMaxTime.split(':');
            if (split.length > 1) {
                maxHour = parseInt(split[0]);
                maxMinute = parseInt(split[1]);
            }
        }        

        let result = moment(getSelectedDateTimeAsDate(state.pickupTimeValue.pickup_date));

        result.set('hours', maxHour);
        result.set('minutes', maxMinute);


        return result.toDate();

    }

    const isDeliverable = () => {
        const isToday = isTodaySelected(new Date(state.pickupTimeValue.pickup_date));


        // if (isToday) {
        if (state.timeDistance && isToday) {
            const min = moment(minTime(true, true));
            const max = moment(maxTime(false, true));
            // console.log('min', min.toDate(), 'max', max.toDate());

            const sameTime = min.isSame(max, 'hour') && min.isSame(max, 'minute')

            return max.isAfter(min) || sameTime;         

        }
        return true;
    }

  

    const getHighlightedTimes = (time, minimalTime) => {

        const timeToCheck = moment(time);
        /**
         * We need to substract one minute to the min, so that is between returns correctly,
         * same to the max
         */
        const min = moment(minimalTime).subtract(1, 'minutes').set({ seconds: 0 });
        const max = moment(min).add((MINUTES_TO_ROUND * 3), 'minutes');
        const isToday = isTodaySelected(new Date(state.pickupTimeValue.pickup_date));

        // console.log('checking', timeToCheck.toDate(), min.toDate(), max.toDate(), isToday);


        if (isToday) {
            const isItBetween = timeToCheck.isBetween(min, max);
            // console.log('is between', isItBetween);

            return isItBetween ? 'text-red-700 font-bold' : '';
        }
    }

    return (

        isDeliverable() ? <div className='w-full mt-3'>
            <Flex className=" mt-3 " >
                <Col>               

                    <SubTitle className="uppercase w-full relative pb-1 ml-2">
                        Select Your Drop Off Window*
                        <StyledToolTip
                            title={
                                <>
                                    <div>
                                        Please note that if you are scheduling an Express Delivery on the same day, and you select a drop off time within 30 mins of the earliest time options available to you (identified in red), there will be a premium fee added to the delivery fee displayed on the next page.
                                    </div>
                                </>
                            }
                            placement={"top"} arrow>
                            <img src={InfoIcon} alt={"premium information"} />
                        </StyledToolTip>

                    </SubTitle>
                    <Flex className='w-full'>
                        <DateTimePicker
                            onChange={(value) => dropOffDatetimeHandle(value, true)}
                            minTime={minTime(true, false)}
                            maxTime={maxTime(true, true)}
                            interval={MINUTES_TO_ROUND}
                            timeClassName={(time) => getHighlightedTimes(time, minTime(true, false))}
                        />
                        <Dash>–</Dash>
                        <DateTimePicker
                            onChange={(value) => dropOffDatetimeHandle(value, false)}
                            minTime={minTime(false, true)}
                            maxTime={maxTime(false, true)}
                            interval={MINUTES_TO_ROUND}
                            timeClassName={(time) => getHighlightedTimes(time, minTime(true, false))}
                        />

                    </Flex>
                    <Flex className='w-full'>
                    {!state.error.dropoff_time.window_time &&
                        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs' style={{ marginLeft: '8px', marginTop: '5px' }}>
                            {state.error.dropoff_time.window_time_error_text}
                        </p>
                        }
                    </Flex>
                    {state.timeDistance &&<TimeAndDistance duration={state.timeDistance.duration} distance={state.timeDistance.distance} />}
                    {/* <Button
                        className="w-full"
                        disabled={state.isThisOptionSelected}
                        onClick={handledSelectButton}
                    >Selected</Button> */}
                </Col>
            </Flex>
        </div > : <SubTitle className="mt-6 mb-1">
            We are sorry, it is not possible to do an express delivery by now
        </SubTitle>
    );
}

const mapStateToProps = (state) => ({
    dropoff_value: {
        dropoff_type: state.deeleeoReducer.dropoff_type,
        dropoff_date: state.deeleeoReducer.dropoff_date !== '' ? state.deeleeoReducer.dropoff_date : moment().toDate().toString(),
        dropoff_early_datetime: state.deeleeoReducer.dropoff_early_datetime,
        dropoff_later_datetime: state.deeleeoReducer.dropoff_later_datetime
    },
    pickup_value: {
        pickup_type: state.deeleeoReducer.pickup_type,
        pickup_date: state.deeleeoReducer.pickup_date !== '' ? state.deeleeoReducer.pickup_date : moment().toDate().toString(),
        pickup_early_datetime: state.deeleeoReducer.pickup_early_datetime,
        pickup_later_datetime: state.deeleeoReducer.pickup_later_datetime
    },
    deeleeo_data: state.deeleeoReducer,
    userData: state.userReducer,
    pickupData: state.pickupDropoffReducer.pickup_data,
    dropoffData: state.pickupDropoffReducer.dropoff_data,
    validation: {
        pickup_time: state.validationReducer.pickup_time,
        dropoff_time: state.validationReducer.dropoff_time,
    },
    validationData: state.validationReducer
});

const mapDispatchToProps = (dispatch) => ({
    updatePickupTime: (pickup_value) => dispatch(updatePickupTime(pickup_value)),
    updateDropoffTime: (dropoff_value) => dispatch(updateDropoffTime(dropoff_value)),
    validatePickupTime: (data) => dispatch(validatePickupTime(data)),
    validateDropoffTime: (data) => dispatch(validateDropoffTime(data)),
    validatePickupWindowTime: (data) => dispatch(validatePickupWindowTime(data)),
    validateDropoffWindowTime: (data) => dispatch(validateDropoffWindowTime(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupDropOffTimeExpress);