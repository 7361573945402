import React from "react";
import Modal from 'react-modal';
// import styled from 'styled-components'
// import tw from 'twin.macro';
import { Button, CancelButton } from "../../../components/buttons";
import { Link } from "@material-ui/core";

// const CloseButton = styled.button`
//   ${tw`flex justify-center  w-auto float-right rounded-full align-middle  mx-5 inline-block pb-2 bg-red-500 text-white hover:bg-blue-700`}
//   font-size: 12px;
//   width: 20px;
//   height: 20px;
//   line-height:20px;

// `

const ReadyForPickupModal = ({ times, modalIsOpen, setModalIsOpen, setConfirmation }) => {


    const willBeReady = () => {
        setConfirmation(true);
        closeModal();

    }

    // const willNotBeReady = () => {
    //     setConfirmation(false);
    //     closeModal();
    // }


    const closeModal = () => {
        setModalIsOpen(false);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay: {
            backgroundColor: 'rgba(0,0,0,.5)'
        }
    };





    return (<Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={'Confirmation'}
        ariaHideApp={false}
    >
        <div className="" style={{ "width": "450px" }}>
            <div className="w-90 p-4 mb-4 text-center">
                <div>By submitting this Deeleeo request, you confirm that your package(s) will be ready for pickup at: {times.early} - {times.late} on {times.date}. </div>
                <div>Please note that there may be a fee for packages that are not ready at the beginning of the stated time window. <Link href="https://deeleeo.com/terms-of-use/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Deeleeo Terms of Use</Link></div>
            </div>
            <div className="flex flex-row-reverse space-x-4 justify-items-end">
                <CancelButton className=" rounded focus:outline-none focus:shadow-outline" type="button" onClick={closeModal}>
                    Cancel
                </CancelButton>

                {/* <Button className="  rounded focus:outline-none focus:shadow-outline" type="button" onClick={willNotBeReady}>
                    I will confirm later
                </Button> */}

                <Button className="  rounded focus:outline-none focus:shadow-outline" type="button" onClick={willBeReady}>
                    Yes
                </Button>

            </div>
        </div>

    </Modal>);
}

export default ReadyForPickupModal;