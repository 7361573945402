import React, { useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Flex } from '../../../components/containers'
import lightLeft from '../../../assets/icons/light-left-chevron.svg'
import lightRight from '../../../assets/icons/light-right-chevron.svg'
import darkLeft from '../../../assets/icons/dark-left-chevron.svg'
import darkRight from '../../../assets/icons/dark-right-chevron.svg'
import { PaginationText } from '../../../components/text'
import { ThinSelect } from '../../../components/buttons'

const StyledTableFooter = styled.div`
  ${tw`flex items-center justify-end`}
  height: 60px;
`


function Pagination(props) {
  const { canPreviousPage, previousPage, nextPage, canNextPage, pageIndex, pageSize, setPageSize, deeleeosCount} = props

  useEffect(() => {
    
  }, [pageSize])

  const handleSizeChange = (e) => {
    const newSize=parseInt(e.target.value)
    setPageSize(newSize)
  }

  const calculatePagination = () => {
    const maxSize = pageSize * (pageIndex + 1)
    if (maxSize >= deeleeosCount) {
      return deeleeosCount
    } else {
      return maxSize
    }
  }

  const pageNumber = pageIndex + 1
  const range = pageNumber === 1 ? 1 : ((pageSize * pageIndex) + 1)

  return (
    <StyledTableFooter>
      <Flex className='mt-8'>


        <ThinSelect
          className="select shadow appearance-none border rounded w-1/8  h-10 mr-200 pr-100  my-auto py-1 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
          onChange={handleSizeChange}>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </ThinSelect>
        <PaginationText>{deeleeosCount === 0 ? 0 : range}-{deeleeosCount <= pageSize ? deeleeosCount : calculatePagination()} of {deeleeosCount}</PaginationText>
        {!canPreviousPage && <img className='pr-2 ml-10' src={lightLeft} alt={'Back disabled'}/>}
        {canPreviousPage && <img className='pr-2 ml-10' src={darkLeft} alt={'Back'} onClick={previousPage}/>}
        {!canNextPage && <img className='pl-2' src={lightRight} alt={'Next disabled'}/>}
        {canNextPage && <img className='pl-2' src={darkRight} alt={'Next'} onClick={nextPage} />}
              </Flex>
    </StyledTableFooter>
  )
}

export default Pagination
