import { getURI } from '../../helpers/apiHelpers'
import axios from 'axios'

const initState = {
    data: []
}

const UPDATE_SIZE_CATEGORY = 'UPDATE_SIZE_CATEGORY';

export const updateSizeCategory = (data) => ({
    type: UPDATE_SIZE_CATEGORY,
    data: data
});

export const fetchSizeCategory = () => dispatch => {
    axios({
        method: 'get',
        url: getURI('/api/web/size-category'),
        validateStatus: (status) => status === 200,
    })
    .then(response => {
        dispatch(updateSizeCategory(response.data.data))
    })
}


export const sizeCategoryReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_SIZE_CATEGORY':
            return {
                ...state, data: action.data
            }

            default:
                return state
    }
}