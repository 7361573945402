import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import search from '../../../assets/icons/search.svg'
import { useAsyncDebounce } from 'react-table'
import { dashboardPageIndex, dashboardSearchingTerm } from '../../../App'
import { useRecoilState } from 'recoil'

const StyledInput = styled.input`
  ${tw`text-fontBlue focus:outline-none focus:border-primaryBlue pl-8 font-light w-full`}
  ${props => props.error && tw`border-errorRed`}
  height: 36px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-family: Varela Round;
  font-size: 13px;
`

function SearchField(props) {
  // eslint-disable-next-line no-unused-vars
  const { globalFilter, setGlobalFilter } = props
  const [value, setValue] = React.useState(globalFilter)

  // eslint-disable-next-line no-unused-vars
  const [searchingTerm, setSearchingTerm] = useRecoilState(dashboardSearchingTerm);
  // eslint-disable-next-line no-unused-vars
  const [dashboardIndex, setDasboardIndex] = useRecoilState(dashboardPageIndex);


  const onChange = useAsyncDebounce(value => {

    //setGlobalFilter(value || undefined)
    /**
     * Sets the searching term to be used in the query
     */
    setSearchingTerm(value || undefined);
    /**
     * When triggering the search reset the page index to zero, 
     * after that, the table plugin takes care of going back and forward in the pages
     */
    setDasboardIndex(0);

  }, 200)

  return (
    <div className={'w-full mb-4 mr-4'}>
      <StyledInput placeholder='Search deliveries' value={value || ""} onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      />
      <img className='absolute -mt-7 ml-2' src={search} alt='Magnifying glass' />
    </div>
  )
}

export default SearchField
