import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import tw from "twin.macro";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";



// const StyledSuggestionList = styled.ul`
//   ${tw`absolute mt-14 bg-white w-full px-4 py-2 shadow-lg z-10`}
// `

// const StyledListItem = styled.li`
//   ${tw`cursor-pointer my-1`}
// `







const MyFleetSelectorForTable = (props) => {
    const { id, myFleetDrivers, handleSelection, selected } = props;
    const [optionsValue, setOptionsValue] = useState('');

    // console.log(props);




    useEffect(() => {
        if (selected) {
            setOptionsValue(selected);
        }
    }, [selected]);

    // console.log(optionsValue);





    const selectorHandle = (event) => {
        //console.log('selector handle');
        //console.log(event.target.value);
        setOptionsValue(event.target.value)
        handleSelection({ driverId: event.target.value, uuid: id })
        //console.log(optionsValue);

    }





    const renderSuggestions = () => {
        let result = [];

        /**
         * Ad an empty option to deselect driver
         */
        result.push(<MenuItem key={-1} ><strong>NONE</strong></MenuItem>);

        if (myFleetDrivers && myFleetDrivers.length > 0) {
            result.push(myFleetDrivers.map((suggestion) => {
                //console.log(suggestion);

                const {
                    id,
                    name
                } = suggestion;
                return (
                    <MenuItem key={id} value={id}><strong>{name}</strong></MenuItem>
                );
            }));
            return result;
        } else {
            return null;
        }
    }



    const toRender =
        <FormControl fullWidth>
            <InputLabel id="myFleetDriver"  >Driver</InputLabel>
            <Select
                labelId="myFleetDriver"
                id="demo-simple-select"
                value={optionsValue}
                label="Age"
                onChange={selectorHandle}
            >
                {renderSuggestions()}
            </Select>
        </FormControl>;



    const finalRender = myFleetDrivers.length > 0 ? toRender : <></>;

    return (
        finalRender
    );

}



export default MyFleetSelectorForTable;
