import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useTable, usePagination, useFilters, useSortBy, useGlobalFilter, useExpanded, useRowSelect } from 'react-table'
import Pagination from './Pagination'
import styled from 'styled-components'
import tw from 'twin.macro'
import { getColumns, formatTableData } from '../dataSource'
import DeeleeoStatus from './DeeleeoStatus'
import SearchField from './SearchField'
import { matchSorter } from 'match-sorter'
import StatusSelect from './StatusSelect'
import {
  dashboardPageIndex,
  dashboardPageSize,
  dashboardSearchingDate,
  // dashboardSearchDeeleeoStatus, 
  // dashboardSearchingTerm, 
  userDeeleeos
} from '../../../App'
import { useRecoilState } from 'recoil'
import { routes } from '../../../routes'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { editDeeleeo } from '../../../redux/reducers/deeleeoReducer'
import { updateDropoffData, updatePickupData } from '../../../redux/reducers/pickupDropoffReducer'
import { clearDeeleeoData, duplicateDeeleeo } from '../../../redux/reducers/deeleeoReducer'
import { clearDropoffData } from '../../../redux/reducers/pickupDropoffReducer'
import { gatheringDeeleeoDataForEditing } from '../../../helpers/deeleeoHelper'
import { getURI } from '../../../helpers/apiHelpers'
import CancelModal from './CancelModal'
import { cancelDeeleeo, clearCancelDeeleeoStatus } from '../../../redux/actions/dashboardActions'
import { ActionButton, Styles } from '../../../components/styledTableHelpers'
import { ThinButton } from '../../../components/buttons'
import { Flex } from '../../../components/containers'
import DashboardMap from './DashboardMap'
import Sticky from 'react-sticky-el';
import ErrorBoundary from '../../../helpers/ErrorBoundary'
import { StyledDatePicker } from '../../DeeleeoDetails/components/shared'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment'
import { HtmlTooltip } from '../../../components/InputField'
import InfoIcon from "../../../assets/icons/Info-icon.svg";
import EditModal from './EditModal'
import axios from 'axios'
import GetLabelModal from './GetLabelModal'
import { readCookie } from '../../../helpers/cookieHelper'




const Delivery = styled.td`
  div {
    ${tw`last:pt-1 font-bold`}
    font-size: 13px;

    &:last-child {
      ${tw`font-light`}
      font-size: 14px;
    }
  }
`
const StatusCell = styled.td`
  width: 124px;
`


const ButtonInDropDown = styled.button`
  ${tw`flex justify-center  w-auto float-right rounded-full align-middle  mx-5 inline-block bg-blue-500 text-white hover:bg-blue-700`}
  font-size: 12px;
  width: 124px;
  height: 30px;
  line-height:20px;
  clear: both;

`



const TableCheckDiv = styled.div`
${tw`float-right rounded-full align-middle  mx-5 `}
`


function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

const DeeleeoTable = (props) => {
  const { deeleeos, cancelDeeleeo, cancelStatus, clearCancelDeeleeoStatus, apiCode, history,  updateDropoffData, updatePickupData, clearDeeleeoData, clearDropoffData, duplicateDeeleeo, fetchAllDeeleeos, getPayloadDeeleeos, pushSelectedRows, userData, deeleeoView, setDeeleeoView, viewMap,
    setViewMap, mapButtonLabel, downloadAllLabels, activeDeeleeos

  } = props
  const data = useMemo(() => formatTableData(deeleeos, deeleeoView), [deeleeos, deeleeoView])

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const [state, setState] = useState({
    serverPageCount: 0,
    selectedDeeleeo: null,
    modalIsOpen: false,
    cancelPayload: {},
    editModalIsOpen: false,
    editingDeeleeo: null,
    labelsModal: false,
    deeleeoLabel: null,
    columns:[]
  });

  const setServerPageCount = (data) => {
    setState(state => ({ ...state, serverPageCount: data }));
  }

  const setSelectedDeeleeo = (data) => {
    setState(state => ({ ...state, selectedDeeleeo: data }));
  }

  const setModalIsOpen = (data) => {
    setState(state => ({ ...state, modalIsOpen: data }));
  }

  const setCancelPayload = (data) => {
    setState(state => ({ ...state, cancelPayload: data }));
  }

  const setEditModalIsOpen = (data) => {
    setState(state => ({ ...state, editModalIsOpen: data }));
  }

  const setEditingDeeleeo = (data) => {
    setState(state => ({ ...state, editingDeeleeo: data }));
  }

  const setLabelsModal = (data) => {
    setState(state => ({ ...state, labelsModal: data }));
  }

  const setDeeleeoLabel = (data) => {
    setState(state => ({ ...state, deeleeoLabel: data }));
  }

  const setColumns = (data) => {
    setState(state => ({ ...state, columns: data }));
  }

  // eslint-disable-next-line no-unused-vars
  const [serverData, setServerData] = useRecoilState(userDeeleeos);//only store the amount of filtered deeleeos and draw.
  const [dashboardIndex, setDasboardIndex] = useRecoilState(dashboardPageIndex);
  const [dashboardSize, setDasboardSize] = useRecoilState(dashboardPageSize);
  const [searchingDate, setSearchingDate] = useRecoilState(dashboardSearchingDate);
  // const [searchingStatus, setSearchingStatus] = useRecoilState(dashboardSearchDeeleeoStatus);



  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  useEffect(() => {
    setColumns(getColumns(deeleeoView));
  }, [deeleeoView]);

  const tableInstance = useTable({
    columns:state.columns,
    data,
    initialState: { pageIndex: dashboardIndex, pageSize: dashboardSize },
    filterTypes,
    manualPagination: true,
    pageCount: state.serverPageCount,
  }, useFilters, useGlobalFilter, useSortBy, useExpanded, usePagination, useRowSelect, hooks => {

    hooks.visibleColumns.push(columns => {

      let result = [...columns];


      /**
       * To display this column based on user settings
       */
      if (userData.isPickupAvailability) {
        result.push({
          id: 'available',
          Header: () => ('Available for Pickup'),
          Cell: ({ row }) => {
            return (row.original.available);
          }
        });
      }

      // Let's make a column for selection
      result.push({
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <TableCheckDiv>
            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          </TableCheckDiv>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => {


          const realStatus = row.original.realStatus;

          const statusToDisplay = [1, 2, 3, 17];

          /**
           * Only return a check box if the deeleeo status is 
           * New,awaiting driver accept, accepted, on hold
           */
          if (statusToDisplay.includes(realStatus)) {

            return (
              <TableCheckDiv>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </TableCheckDiv>
            )
          } else {
            return (<></>);
          }
        },
      });

      return result;
    })
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    selectedFlatRows,
    state: {
      pageIndex,
      pageSize,
      globalFilter,
      // selectedRowIds 
    },
    setGlobalFilter,
    preFilteredRows,
    visibleColumns
  } = tableInstance

  useEffect(() => {



    pushSelectedRows(selectedFlatRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows.length]);

  useEffect(() => {
    // console.log('server data: ');
    // console.log(serverData);


    setServerPageCount(Math.ceil(serverData.recordsFiltered / pageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverData.recordsFiltered]);

  useEffect(() => {
    /**
     * When closing the modal, we will refresh the data
     * 
     * The payload needs to come from the index, as it has all the datatable data
     * 
     */
    const p = getPayloadDeeleeos();
    fetchAllDeeleeos(p);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.modalIsOpen])


  useEffect(() => {
    setDasboardIndex(pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex])

  useEffect(() => {
    setDasboardSize(pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  /**
   * just clear this guy when loading the page
   */
  useEffect(() => {
    setSearchingDate('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUnitAndAddress = (text) => {
    
    try {
      const [defaultAddress, unit, address] = text.split(/#([0-9]*|home [0-9]*|apartment [0-9]*)\s/g);
      return defaultAddress ? [defaultAddress] : [address, unit ? unit : '']
    } catch (e) {
      return text;
    }
    
  }

  const getApiKeyPayload = () => {
    const payload = {
      api_key: apiCode
    };

    return payload;
  }

  const handleCancel = (deeleeo) => {


    /**
     * Clear the status,
     * This tracks the status of the ajax request
     * submitting,success,error
     */
    clearCancelDeeleeoStatus();
    setModalIsOpen(true);
    let payload = getApiKeyPayload();
    payload.deeleeo = deeleeo;
    setCancelPayload(payload);

  }

  /**
   * On close the modal
   * Reset the cancel deeleeo status
   */
  const onCloseModal = () => {
    clearCancelDeeleeoStatus();
    setModalIsOpen(false);
  }

  const handleEdit = (deeleeo, index) => {

    if (deeleeo.name && !deeleeo.item_name) {
      try {
        deeleeo.item_name = deeleeo.name;
      } catch (error) {
        console.log(error);
      }
    }
    updateDropOffPick(deeleeo);
    setEditModalIsOpen(true);
    setEditingDeeleeo(deeleeo);
    // cancelDeeleeo(deeleeo);

    // action.index is needed in deeleeoReducer
    // editDeeleeo({ ...deeleeo, index });
    //  
  }

  const acceptEditDeeleeo = (deeleeo) => {

    let payload = getApiKeyPayload();
    payload.deeleeo_id = deeleeo.id;

    axios.post(getURI('/api/user/cancelDeeleeo'), payload)
      .then((response) => {
        setEditModalIsOpen(false);
        setEditingDeeleeo(null);
        history.push(routes.createDeeleeo);
      })
      .catch((error) => {
        alert(error.message);
      })


  }

  /**
   * Single place to update drop off and pickup data
   * @param {*} deeleeo 
   */
  const updateDropOffPick = (deeleeo) => {


    const [dropOffAddress, dropOffUnit] = getUnitAndAddress(deeleeo.dropoff_address);
    let dropoff_data = {
      name: deeleeo.dropoff_name,
      address: dropOffAddress,
      unit: dropOffUnit,
      phonenumber: deeleeo.dropoff_phonenumber,
      email: deeleeo.dropoff_email,
      latitude: deeleeo.dropoff_latitude,
      longitude: deeleeo.dropoff_longitude
    }

    const [pickupAddress, pickupUnit]= getUnitAndAddress(deeleeo.pickup_address);
    let pickup_data = {
      name: deeleeo.pickup_name,
      address: pickupAddress,
      unit: pickupUnit,
      phonenumber: deeleeo.pickup_phonenumber,
      email: deeleeo.pickup_email,
      latitude: deeleeo.pickup_latitude,
      longitude: deeleeo.pickup_longitude,
    }


    updateDropoffData(dropoff_data);
    updatePickupData(pickup_data);
  }

  /**
   * Repost a cancelled deeleeo
   * 
   * @param {index of deeleeo in array number} index 
   */
  const handleRepostDeeleeo = (index) => {
    clearDeeleeoData();
    clearDropoffData();
    let deeleeoInfo = deeleeos[index];
    let deeleeo_data = gatheringDeeleeoDataForEditing(deeleeoInfo, index);
    // update deeleeo data
    duplicateDeeleeo(deeleeo_data);
    updateDropOffPick(deeleeoInfo);

    history.push(routes.createDeeleeo);
  }

  /**
   * Handles the tracking link
   */
  const handleTracking = (index) => {
    let deeleeoInfo = getDeeleeo(index);
    const deeleeoId = deeleeoInfo.uuid;
    const url = getURI('/order-tracking/' + deeleeoId);
    window.open(url, '_blank');
  }

  /**
   * Gets html with all the pictures in line
   * @param {array of photo links} photos 
   * @returns 
   */
  const getPhotoLinks = (photos) => {
    const links = photos.map((element, index) => {
      const prodLink = element.replace('staging', 'production');
      return <a key={index} className="inline-block" href={prodLink} rel="noreferrer" target="_blank">
        <img width="40" height="40" alt="" src={prodLink} />
      </a>;
    });
    return <><div className="  inline-block font-bold text-black fotitos">Photos: {links}</div><br /></>;
  }

  /**
   * 
   * @param {Returns the original deeleeo from the array} rowIndex 
   * @returns 
   */
  const getDeeleeo = (rowIndex) => {
    return deeleeos[rowIndex];
  }

  /**
   * Checks if the status is one of the deeleeo cancelled status
   * used to display the button to create a deeleeo with the selected deeleeo data
   * @param {deeleeo status number} status 
   * @returns boolean
   */
  const displayRepost = (status) => {
    const cancelledStatuses = [6, 7, 8, 9, 10, 11, 12, 16];
    return cancelledStatuses.includes(status);
  }

  const handleDownloadDeeleeo = (rowIndex) => {
    let pages = '';

   

    const deeleeo = getDeeleeo(rowIndex);
    
    if (!state.labelsModal && displayEditButton(deeleeo)) {
      /**
       * if the modal is closed, and the deeleeo is editable,
       * display the modal
       */
      setDeeleeoLabel(deeleeo);//set deeleeo to the state for future use
      const hidingPopup = readCookie('hidelabelreminder');
      if (hidingPopup) {
        downloadLabel(deeleeo, pages);
      } else {
        setLabelsModal(true);
      }
      
    } else {
      /**
       * if the deeleeo is set ( it was set ) before opening the modal, else use the deeleeo from the row index
       */
      const currentDeeleeo = state.deeleeoLabel ? state.deeleeoLabel : deeleeo;
      
      downloadLabel(currentDeeleeo,pages);
      
      /**
       * clean the deeleeo in case it was set before opening the modal, and close the modal
       */
      setDeeleeoLabel(null);
      setLabelsModal(false);
    }



    // window.location.reload();
  }

  const downloadLabel = (deeleeo, pages)=>{
    const url = getURI(`/api/web/deeleeo/${deeleeo.id}/label?api_code=${apiCode}&update=1${pages}`);
    window.open(url, '_blank');
  }

  const displayCancelButton = (deeleeo) => {
    if (deeleeoView === 2) {
      return false;
    }
    /**
     * Only status 
     * NEW:1
     * ACCEPTED:3
     * ON_HOLD:17
     */
    const validStatus = [1, 3, 17];

    return validStatus.includes(deeleeo.status);
  }

  const displayEditButton = (deeleeo) => {
    if (deeleeoView === 2) {
      return false;
    }
    /**
     * Only status 
     * NEW:1 
     * ON_HOLD: 17
     */
    return deeleeo.status === 1 || deeleeo.status === 17;
  }

  const handlePageSize = (e) => {
    setDasboardSize(e);
  }

  const expandedRow = (row) => {
    //{renderRowSubComponent(row)}
    /**
     * Get the original deeleeo from the array
     */
    const deeleeo = getDeeleeo(row.index)
    const [pickupAddress, pickupUnit] = getUnitAndAddress(deeleeo.pickup_address);
    const [dropOffAddress, dropOffUnit] = getUnitAndAddress(deeleeo.dropoff_address);
    
 
    return (
      <tr>
        <td colSpan={visibleColumns.length}>
          <div className="py-2 container  px-2">
            <div className="relative" >
              <div className="m-0 p-0">
                <div className="flex group items-center  ">
                  <div className="flex-1 ml-4 z-10 font-medium ">
                    <div className="order-1 space-y-1  bg-gray-200 rounded-lg">
                      <div className="  inline-block font-bold text-black">Completed at: </div> <div className="  inline-block">{deeleeo.completed_at}</div>
                      {displayRepost(deeleeo.status) ? <ButtonInDropDown onClick={() => handleRepostDeeleeo(row.index)}>Repost</ButtonInDropDown> : null}<br />

                      <div className="  inline-block font-bold text-black">Pickup Date: </div> <div className="  inline-block">{deeleeo.pickup_date}</div><br />
                      <div className="  inline-block font-bold text-black">Pickup Window: </div> <div className="  inline-block">{deeleeo.pickup_window}</div><br/>
                      <ButtonInDropDown onClick={() => handleDownloadDeeleeo(row.index)}>Print Label</ButtonInDropDown>
                      {/* <NumbnerOfLabels type="number" step="1" min="1" onChange={(event) => { labelsNum = event.target.value }} placeholder="# of labels"></NumbnerOfLabels><br /> */}

                      <div className="  inline-block font-bold text-black">Pick Up Address: </div> <div className="  inline-block">{pickupAddress}</div><br /> <ButtonInDropDown onClick={() => handleTracking(row.index)}>Tracking link</ButtonInDropDown>
                      <div className="  inline-block font-bold text-black">Pick Up Unit: </div> <div className="  inline-block">{pickupUnit}</div><br />
                      <div className="  inline-block font-bold text-black">Drop Off Date: </div> <div className="  inline-block">{deeleeo.deliver_date}</div><br />
                      <div className="  inline-block font-bold text-black">Drop Off Window: </div> <div className="  inline-block">{deeleeo.deliver_window}</div><br />
                      <div className="  inline-block font-bold text-black">Drop Off Address: </div> <div className="  inline-block">{dropOffAddress}</div><br />
                      <div className="  inline-block font-bold text-black">Drop Off Unit: </div> <div className="  inline-block">{dropOffUnit}</div><br />
                      {deeleeoView === 2? null: <div><div className="  inline-block font-bold text-black">Fee: </div> <div className="  inline-block">{deeleeo.fee}</div><br /></div>}
                      {deeleeoView === 2 ? null : <div><div className="  inline-block font-bold text-black">Sweetener Amount: </div> <div className="text-green-400  inline-block">({deeleeo.feesweet})</div><br /></div>}
                      <div className="  inline-block font-bold text-black">Notes: </div> <div className="  inline-block">{deeleeo.notes.description}</div><br />
                      {deeleeo.is_valet === 1 && <><div className="  inline-block font-bold text-black">Valet: </div> <div className="  inline-block">YES</div><br /></>}
                      {deeleeo.photos ? getPhotoLinks(deeleeo.photos) : <></>}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>

    );
  }

  const handleRowClick = (row) => {

    /**
     * Find Deeleeo
     */
    const filtered = deeleeos.filter((e) => {
      return e.uuid === row.uuid;
    });


    /**
     * Set the deeleeo
     */
    if (filtered.length === 1) {
      setSelectedDeeleeo(filtered[0]);
    }


  }

  /**
   * On view change, we need to clear the data sent to the map
   * @param {*} view 
   */
  const handleViewChange = (view) => {

    setDeeleeoView(view);
    setSelectedDeeleeo(null);
  }

  const handleDateFilter = (jsDate, stringDate,) => {
    setSearchingDate(stringDate);
  }

  return (
    <>
      <Flex className="w-full h-96 flex-col">
        {viewMap &&
          <Sticky stickyStyle={{ backgroundColor: 'white', zIndex:1000 }}  >
            <div style={{ minHeight: 400 }}>
              <ErrorBoundary>
                <DashboardMap
                  apiCode={apiCode}
                  deeleeoView={deeleeoView}
                  selectedDeeleeo={state.selectedDeeleeo}
                  latLng={userData.mapLatLng}
                />
              </ErrorBoundary>

              <p style={{ fontWeight: 'bold', fontSize: 11, height: 24 }}>Note: depending on varying traffic conditions and other delivery related variables, delivery agents may or may not follow the exact route shown on the map above.</p>
              <br />
            </div>
          </Sticky>}
        <Styles>
          <Sticky stickyStyle={{ top: viewMap ? 430 : 0, backgroundColor: 'white', zIndex: 1000 }} topOffset={viewMap ? -430 : -30}>
            <div className={'flex'}>
              <SearchField globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
              <div className={'w-1/4 mb-4 mr-4'}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <StyledDatePicker disableToolbar variant="inline" id="deeleeo-filter-date" format="yyyy-MM-dd" onChange={handleDateFilter} value={searchingDate ? moment(searchingDate) : null} />
                  <HtmlTooltip
                    style={{ marginLeft: 7 }}
                    placement="top"
                    title="This Date filters Deeleeos by their Pickup Date. Clear the content of this date box to remove this filter.">
                    <img
                      style={{ display: 'inline' }}
                      className="-mt-1 ml-1"
                      src={InfoIcon}
                      alt={"premium information"}
                    />
                  </HtmlTooltip>
                </MuiPickersUtilsProvider>
              </div>


              <StatusSelect globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} preFilteredRows={preFilteredRows} />
            </div>
            <div className={'flex'}>
              <ThinButton selected={deeleeoView === 1} onClick={() => handleViewChange(1)} >Active Deeleeos ({activeDeeleeos})</ThinButton>
              <ThinButton selected={deeleeoView === 2} onClick={() => handleViewChange(2)} >Shared Deeleeos</ThinButton>
              <ThinButton selected={deeleeoView === 3} onClick={() => handleViewChange(3)} >Done Deeleeos</ThinButton>

              <ThinButton onClick={() => setViewMap(!viewMap)}>{mapButtonLabel}</ThinButton>
              <ThinButton style={{ width: 250 }}
                onClick={downloadAllLabels}
              >Download All labels ({selectedFlatRows.length})</ThinButton>
            </div>
          </Sticky>

          <table {...getTableProps()} >
            {/* <Sticky stickyStyle={{top:524,backgroundColor:'white'}} topOffset={-524}> */}
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => {
                    return (
                      <th key={index}  {...column.getHeaderProps(column.getSortByToggleProps())} style={{ position: 'sticky', top: viewMap ? 530 : 30, backgroundColor: 'white' }} className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? 'sort-desc'
                            : 'sort-asc'
                          : ''
                      }>
                        {column.render('Header')}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            {/* </Sticky> */}
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                //console.log(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr {...row.getRowProps()} >
                      {row.cells.map(cell => {

                        const deeleeo = getDeeleeo(row.index);
                        //console.log(deeleeo);

                        if (cell.column.Header === 'Delivery') {
                          return (
                            <Delivery {...cell.getCellProps()}>
                              {cell.value.map((val, index) => <div key={index}>{index === 0 && '#'}{val}</div>)}
                            </Delivery>
                          )
                        } else if (cell.column.Header === 'Status') {
                          return (
                            <StatusCell {...cell.getCellProps()}>
                              <DeeleeoStatus status={cell.value[0]} type={cell.value[1]} />
                            </StatusCell>
                          )
                        } else if (cell.column.Header === 'Actions') {
                          return (
                            <td className='capitalize' {...cell.getCellProps()}>
                              {displayEditButton(deeleeo) && <ActionButton onClick={() => handleEdit(deeleeo, row.index)}>EDIT</ActionButton>}
                              {displayCancelButton(deeleeo) && <ActionButton warn onClick={() => handleCancel(deeleeo)}>CANCEL</ActionButton>}
                            </td>
                          )

                        } else {
                          return (
                            <td className='capitalize' {...cell.getCellProps()} onClick={() => handleRowClick(row.original)} style={{ cursor: 'pointer' }}>
                              {cell.render('Cell')}
                            </td>
                          )
                        }
                      })}
                    </tr>
                    {row.isExpanded && expandedRow(row)}
                  </Fragment>
                )
              })}
            </tbody>
          </table>
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageIndex={pageIndex}
            pageSize={dashboardSize}
            previousPage={previousPage}
            setPageSize={handlePageSize}
            deeleeosCount={serverData.recordsFiltered}
          />

        </Styles>
      </Flex>
      <CancelModal
        contentLabel="I am a content label"
        modalIsOpen={state.modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        cancelPayload={state.cancelPayload}
        cancelDeeleeo={cancelDeeleeo}
        cancelStatus={cancelStatus}
        onRequestClose={onCloseModal}
      />
      <EditModal
        contentLabel="I am a content label"
        modalIsOpen={state.editModalIsOpen}
        setModalIsOpen={setEditModalIsOpen}
        acceptEditDeeleeo={acceptEditDeeleeo}
        deeleeo={state.editingDeeleeo}
      />
      <GetLabelModal
        contentLabel="I am a content label"
        modalIsOpen={state.labelsModal}
        setModalIsOpen={setLabelsModal}
        getLabel={handleDownloadDeeleeo}
        packages={state.deeleeoLabel?.package_count}
      />
    </>
  )
}

const mapStateToProps = state => ({
  cancelStatus: state.dashboardReducer.cancelDeeleeoStatus,
  userData: state.userReducer,
})

const mapDispatchToProps = dispatch => ({
  editDeeleeo: (data) => dispatch(editDeeleeo(data)),
  updateDropoffData: (data) => dispatch(updateDropoffData(data)),
  updatePickupData: (data) => dispatch(updatePickupData(data)),
  clearDeeleeoData: () => dispatch(clearDeeleeoData()),
  clearDropoffData: () => dispatch(clearDropoffData()),
  duplicateDeeleeo: (data) => dispatch(duplicateDeeleeo(data)),
  cancelDeeleeo: (requestData) => dispatch(cancelDeeleeo(requestData)),
  clearCancelDeeleeoStatus: () => dispatch(clearCancelDeeleeoStatus())
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeeleeoTable));
