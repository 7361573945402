import React, { useEffect, useState } from "react";
import { SubTitle } from "../../../components/text";
import styled from "styled-components";
import tw from "twin.macro";
import { connect } from "react-redux";
import { updateDescription } from '../../../redux/reducers/deeleeoReducer'
import { validateDescription } from "../../../redux/reducers/validationReducer";


const DescInput = styled.textarea`
  ${tw`h-4 w-full px-4 py-3 text-fontBlue focus:outline-none focus:border-primaryBlue`}
  height: 140px;
  border: 1px solid #d9d9d9;
  font-family: Varela Round;
  font-size: 14px;
  color: #121212;
  box-shadow: inset 0px 1px 12px rgba(0, 0, 0, 0.15);
  ${props => !props.error && tw`border-errorRed`}
`;

const DescriptionSection = (props) => {
    const { validateDescription, updateDescription, description, validation, isValet } = props;
    const [ descriptionValue, setDescriptionValue ] = useState(description);
    const [error, setError] = useState(validation);

    const onChangeHandler = ((event) => {
        setDescriptionValue(event.target.value);
    })

    useEffect(() => {
        setError(validation);
    }, [validation])

    useEffect(() => {
        const timeout = setTimeout(() => {
            updateDescription(descriptionValue)

            //if(!error && descriptionValue !== ''){
                validateDescription(true);
            //}
        }, 800);
        return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descriptionValue, updateDescription]);

    const getLabel=()=>{
        if(isValet){
            return 'Valet Instructions *'
        }
        return 'Deeleeo Description'
    }

    const getPlaceHolder=()=>{
        if(isValet){
            return 'Instructions you would like to provide the driver for pickup and/or drop off.'
        }

        return 'A short description of the item, eg. a sweater'
    }

    return (
        <div>
            <SubTitle className="mt-6 mb-3">{getLabel()}</SubTitle>

            {!error &&
                <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
                    Description Is Required
                </p>
            }

            <DescInput error={error} type="text" placeholder={getPlaceHolder()} 
                onChange={onChangeHandler} value={descriptionValue}/>
        </div>
    );
};

const mapStateToProps = (state) => ({
    description: state.deeleeoReducer.description,
    validation: state.validationReducer.description
});

const mapDispatchToProps = (dispatch) => ({
    updateDescription: (description) => dispatch(updateDescription(description)),
    validateDescription: (data) => dispatch(validateDescription(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionSection);
