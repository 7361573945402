import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import editIcon from '../assets/icons/edit-icon.svg'
import InputField from './InputField'
import { updatePickupData, updateDropoffData } from '../redux/reducers/pickupDropoffReducer'
import { updateItemName } from "../redux/reducers/deeleeoReducer";
import { validateItemName } from "../redux/reducers/validationReducer";
import { HelperText, SubTitle } from './text'
import AddressField from './AddressField'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Flex } from './containers'
import What3WordsField from './What3WordsField'
import UserContactSelect from './UserContactSelect'
import { StyledToolTip } from '../pages/DeeleeoDetails/components/shared'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useRecoilState } from 'recoil'
import { apiKeyAtom } from '../App'
import axios from 'axios'
import { getURI } from '../helpers/apiHelpers'

export const InformationContainer = styled.form`
  ${tw`mt-3 w-1/2 first:mr-12`}
`

const SaveButton = styled.button`
  ${tw`text-primaryBlue border flex justify-center items-center`}
  font-size: 12px;
  border-color: rgb(221, 221, 221);
  width: 52px;
  height: 26px;
`

export const TitleSpan = styled.span`
  ${tw`flex ml-6 flex items-center`}
  cursor:pointer;
`

const DisplayField = styled.div`
  ${tw`text-fontBlue mb-2`}
  font-family: Varela Round;
  font-size: 16px;
  font-weight:bold;
  text-transform: ${props => props.uppercase && 'uppercase'};
`
const StyledLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    ${tw`text-fontBlue`}
    font-family: Montserrat;
    font-size: 13px;
  }
`;

const EditButton = (props) => {
  const { editMode, setEditMode } = props
  return (
    <TitleSpan>
      {!editMode && <Flex onClick={() => setEditMode(!editMode)}>
        <img src={editIcon} alt={'pencil icon'} />
        <HelperText>Edit</HelperText>
      </Flex>}
      {editMode && <>
        <SaveButton type='submit'>Save</SaveButton>
      </>}
    </TitleSpan>
  )
}

function ShipmentInformation(props) {
  const { shipmentInfo, title, isSender, saveInfoCheckbox, updatePickup, updateDropoff, error, validation_item_name, updateItemName, isValet } = props
  const hasShipmentInfo = shipmentInfo !== undefined

  const [editMode, setEditMode] = useState(shipmentInfo.address === '' ? true : false);

  // eslint-disable-next-line no-unused-vars
  const [apiKey, setApiKey] = useRecoilState(apiKeyAtom);
  const [saveContact, setSaveContact] = useState(false);

  /**
   * To handle suggestions generated by the W3W component.
   * Note: These are google suggestions
   */
  const [w3wValues, setW3wValues] = useState([]);
  const cleanW3WValues = () => {
    setW3wValues([]);
  }

  const emailValidation = isSender ? yup.string().email('Enter a valid email address').required('Email is required') : yup.string().email('Enter a valid email address');

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    address: yup.string().when('w3w', {
      is: (w3w) => !w3w || w3w.length === 0,
      then: yup.string().required('Address is required'),
      otherwise: yup.string()
    }),
    w3w: yup.string().when('address', {
      is: (address) => !address || address.length === 0,
      then: yup.string().required('w3w is required'),
      otherwise: yup.string()
    }),
    phonenumber: yup.string().phone('CA', false, 'Phone number must be valid'),
    email: emailValidation
  }, [['address', 'w3w'], ['w3w', 'address']]);

  const formik = useFormik({
    initialValues: {
      name: hasShipmentInfo ? props.shipmentInfo.name : '',
      address: hasShipmentInfo ? props.shipmentInfo.address : '',
      w3w: hasShipmentInfo ? props.shipmentInfo.w3w : '',
      unit: hasShipmentInfo ? props.shipmentInfo.unit : '',
      phonenumber: hasShipmentInfo ? props.shipmentInfo.phonenumber : '',
      email: hasShipmentInfo ? props.shipmentInfo.email : '',
      latitude: hasShipmentInfo ? props.shipmentInfo.latitude : '',
      longitude: hasShipmentInfo ? props.shipmentInfo.longitude : ''
    },
    validationSchema: validationSchema,
    onSubmit: (values => {
      setEditMode(!editMode);
      submitContact();
    })
  })

  const submitContact = () => {
    if (saveContact) {
      // console.log(formik);
      const payload = { ...formik.values, api_code: apiKey, is_pickup: isSender, is_dropoff: !isSender, phone: formik.values.phonenumber };
      // return false
      axios
        .post(getURI(`/api/web/user-contact`), payload)
        .then((response) => {
          // console.log(response);
          window.location.reload(false);
        }).catch((error) => {
          console.log(error);
        })
    }
  }

  /**
   * When selecting an option, we validate the coords, and set the info if it passes validation
   * @param {*} data 
   */
  const handleAddressChange = (addressInfo) => {
    // console.log('handling handleAddressChange');
    // console.log(addressInfo);
    const { city, lat, lng, street, province } = addressInfo



    const payload = { lat: lat, lon: lng };

    axios
      .post(getURI(`/api/web/get-zone-from-coordinate`), payload)
      .then((response) => {

        console.log(response);
        formik.setValues({
          ...formik.values,
          address: street + ', ' + city + ' ' + province,
          latitude: lat,
          longitude: lng
        })
      })
      .catch(error => {
        console.log(error);
        formik.setFieldError('address', 'This location is not currently serviced by Deeleeo')

      });

  }

  /**
   * When selecting an option, we validate the coords, and set the info if it passes validation
   * @param {*} data 
   */
  const handleW3WChange = (data) => {
    /**
     * Data now contains w3w data and the google autocomplete options
     */
    const { w3w, google } = data;
    const { coordinates, words } = w3w;





    const payload = { lat: coordinates.lat, lon: coordinates.lng };

    axios
      .post(getURI(`/api/web/get-zone-from-coordinate`), payload)
      .then((response) => {

        console.log(response);


        /**
       * If we can service the location, we save the google options to be displayed
       */
        setW3wValues(google);
        /**
         * Also save w3w address and coords to the deeleeo
         */
        formik.setValues({
          ...formik.values,
          address: '///' + words,
          latitude: coordinates.lat,
          longitude: coordinates.lng
        })

      })
      .catch(error => {
        console.log(error);
        formik.setFieldError('w3w', 'This location is not currently serviced by Deeleeo')

      });


  }

  const handleUserAddressChange = (data) => {

    setEditMode(false);

    formik.setValues({
      ...formik.values,
      name: data.name,
      address: data.address,
      unit: data.unit ? data.unit : '',
      phonenumber: data.phone,
      email: data.email,
      latitude: data.latitude,
      longitude: data.longitude
    })

  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Object.keys(formik.errors).length === 0) {

        if (isSender) {
          updatePickup(formik.values)
        } else {
          if (!isValet) {
            updateItemName(formik.values.name);
          }
          updateDropoff(formik.values);

          if (!validation_item_name && formik.values.name !== '') {
            validateItemName(true);
          }
        }


      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [formik.errors, formik.values, isSender, isValet, updateDropoff, updateItemName, updatePickup, validation_item_name])

  return (
    <InformationContainer onSubmit={formik.handleSubmit}>
      <UserContactSelect
        isSender={isSender}
        onChange={handleUserAddressChange}
      />
      <SubTitle withMargin>
        {title}
        {!saveInfoCheckbox && <div className='w-full'><EditButton errors={formik.errors} editMode={editMode} setEditMode={setEditMode} /></div>}
        {!saveInfoCheckbox && editMode && <div className='w-full'>
          <StyledToolTip
            title={
              <div>
                {'Save it to your contacts.'}
              </div>
            }
            placement={"top"} arrow>

            <StyledLabel
              value={isSender ? 'is_pickup' : 'is_dropoff'}
              control={<Checkbox
                checked={saveContact}
                color="primary" />
              }
              label={isSender ? 'Save as Pickup Contact' : 'Save as Drop off Contact'}
              onChange={() => { setSaveContact(!saveContact) }}
              labelPlacement="end" />
          </StyledToolTip>
        </div>}
      </SubTitle>

      {!error &&
        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
          Please Check Your Data
        </p>
      }

      {!editMode && <>
        <DisplayField uppercase>{formik.values.name}</DisplayField>
        <DisplayField>{formik.values.address}</DisplayField>
        <DisplayField>{formik.values.unit}</DisplayField>
        <DisplayField>{formik.values.phonenumber}</DisplayField>
        <DisplayField>{formik.values.email}</DisplayField>
        <hr />
      </>}

      {editMode && <>
        <InputField required id={'name'} type={'text'} label={isSender ? 'Shipper name' : 'Recipient name'} onBlur={formik.handleBlur} helperText={formik.errors.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          onChange={formik.handleChange} value={formik.values.name} />

        <AddressField
          required
          id={'address'}
          label={isSender ? 'Pickup location' : 'Drop off location'}
          defaultValue={formik.values.address}
          onChange={handleAddressChange}
          onBlur={formik.handleBlur}
          helperText={formik.errors.address}
          error={formik.touched.address && Boolean(formik.errors.address)}
          values={w3wValues}//To pass external values to the component
          cleanValues={cleanW3WValues}//To clean these external values from the component
        />



        <What3WordsField
          id={'w3w'}
          label={isSender ? 'What3word pickup address (optional)' : 'What3word drop off address (optional)'}
          onChange={handleW3WChange}
          helperText={formik.errors.w3w}
          error={formik.touched.w3w && Boolean(formik.errors.w3w)}
          onBlur={formik.handleBlur}
        />

        <InputField id={'unit'} label={isSender ? 'Pickup unit' : 'Drop off unit'} onBlur={formik.handleBlur} defaultValue={formik.values.unit} helperText={formik.errors.unit}
          error={formik.touched.unit && Boolean(formik.errors.unit)} onChange={formik.handleChange} value={formik.values.unit} />

        <InputField required id={'phonenumber'} type={'text'} label={'Phone'} onBlur={formik.handleBlur} helperText={formik.errors.phonenumber}
          error={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}
          onChange={formik.handleChange} value={formik.values.phonenumber} />

        <InputField required={isSender} id={'email'} type={'text'} label={'Email'} onBlur={formik.handleBlur} helperText={formik.errors.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          onChange={formik.handleChange} value={formik.values.email} />
      </>}
    </InformationContainer>
  )
}

const mapStateToProps = state => ({
  apiCode: state.userReducer.apiCode,
  regions: state.userReducer.servicedRegions,

  item_name: state.deeleeoReducer.item_name,
  validation_item_name: state.validationReducer.item_name
})

const mapDispatchToProps = dispatch => ({
  updatePickup: (shipmentInfo) => dispatch(updatePickupData(shipmentInfo)),
  updateDropoff: (shipmentInfo) => dispatch(updateDropoffData(shipmentInfo)),

  updateItemName: (itemName) => dispatch(updateItemName(itemName)),
  validateItemName: (data) => dispatch(validateItemName(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentInformation)
