import React, { useEffect, useState } from "react";
import { SubTitle } from "../../../components/text";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Grid, RadioGroup } from "@material-ui/core";
import { StyledDatePicker, StyledToolTip } from "./shared";
import moment from "moment";
import { connect } from "react-redux";
import { validateDropoffTime, validateDropoffWindowTime, validatePickupTime, validatePickupWindowTime } from "../../../redux/reducers/validationReducer";
import PickupDropOffTimeProfile from "./PickupDropOffTimeProfile";
import { updateDropoffTime, updateIsExpress, updatePickupTime } from "../../../redux/reducers/deeleeoReducer";
import PickupDropOffTimeExpress from "./PickupDropOffTimeExpress";
import { Flex, Row } from "../../../components/containers";
import { RadioOption, getFutureTime, roundTime } from "./PickupDropoffTimeSection";
import InfoIcon from "../../../assets/icons/Info-icon.svg";
import axios from "axios";
import { getURI } from "../../../helpers/apiHelpers";
import { isInterCity } from "../../../App";
import { useRecoilState } from "recoil";

const PickupDropOffTimeSectionSingle = (props) => {

    const {
        validatePickupTime, validateDropoffTime, validatePickupWindowTime, validateDropoffWindowTime, updateDropoffTime, updatePickupTime,
        pickup_value, dropoff_value, validation, userData, setIsSpecialTiming, updateIsExpress, pickupData, dropoffData } = props;

    // eslint-disable-next-line no-unused-vars
    const [interCity, setInterCity] = useRecoilState(isInterCity);

    const [state, setState] = useState({
        pickupTimeValue: pickup_value,
        dropoffTimeValue: dropoff_value,
        currentPickupDate: moment().toDate(),
        error: validation,
        pudoOptionSelected: 0, //0:none,1:profile,2:express,3:rush
        distanceInKm: 0,
    });





    const setPickupTimeValue = (data) => {
        setState(state => ({ ...state, pickupTimeValue: data }));
    }

    const setDropoffTimeValue = (data) => {
        setState(state => ({ ...state, dropoffTimeValue: data }));
    }

    const setCurrentPickupDate = (data) => {
        setState(state => ({ ...state, currentPickupDate: data }));
    }

    const setError = (data) => {
        setState(state => ({ ...state, error: data }));
    }

    const setPudoOptionSelected = (data) => {
        setState(state => ({ ...state, pudoOptionSelected: data }))
    }

    const setDistanceInKm = (data) => {
        setState(state => ({ ...state, distanceInKm: data }));
    }

    useEffect(() => {
        if (state.pudoOptionSelected === 2) {
            updateIsExpress(1);
        } else {
            updateIsExpress(0);
        }

    }, [state.pudoOptionSelected, updateIsExpress])

    useEffect(() => {
        if (validation) {
            setError(validation);
        }

    }, [validation])

    useEffect(() => {
        updatePickupTime(state.pickupTimeValue);

        // update validate when pick up type not empty
        if (!state.error.pickup_time.type && state.pickupTimeValue.pickup_type !== '') {
            validatePickupTime(state.pickupTimeValue);
        }

        // update validate based on pickup time window
        if (!state.error.pickup_time.window_time && state.pickupTimeValue.pickup_early_datetime !== '' && state.pickupTimeValue.pickup_later_datetime !== '') {
            validatePickupWindowTime(state.pickupTimeValue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.pickupTimeValue, updatePickupTime, validatePickupTime, validatePickupWindowTime]);

    useEffect(() => {
        updateDropoffTime(state.dropoffTimeValue);

        // update validate when drop off type not empty
        if (!state.error.dropoff_time.type && state.dropoffTimeValue.type !== '') {
            validateDropoffTime(state.dropoffTimeValue);
        }

        // update validate based on dropoff time window
        if (!state.error.dropoff_time.window_time && state.dropoffTimeValue.dropoff_early_datetime !== '' && state.dropoffTimeValue.dropoff_later_datetime !== '') {
            validateDropoffWindowTime(state.dropoffTimeValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.dropoffTimeValue, updateDropoffTime, validateDropoffTime, validateDropoffWindowTime]);

    useEffect(() => {
        // getDistanceInKm

        const payload = {
            originLat: parseFloat(`${pickupData.latitude}`), originLon: parseFloat(`${pickupData.longitude}`), destinationLat: parseFloat(`${dropoffData.latitude}`), destinationLon: parseFloat(`${dropoffData.longitude}`)
        };

        if (payload.originLat !== 0 && payload.originLon !== 0 && payload.destinationLat !== 0 && payload.destinationLon !== 0) {
            axios.post(getURI('/api/web/deeleeo/getDistance'), payload)
                .then(data => {
                    // console.log('data', data);
                    setDistanceInKm(data.data);
                })
        }


    }, [dropoffData.latitude, dropoffData.longitude, pickupData.latitude, pickupData.longitude]);

    const pickupDateChangeHandle = (full_date) => {

        let selected_date = new Date(full_date);

        setPickupTimeValue({
            ...state.pickupTimeValue,
            pickup_date: selected_date.toString()
        })

        setDropoffTimeValue({
            ...state.dropoffTimeValue,
            dropoff_date: selected_date.toString()
        })
    }

    const disableSundays = (date) => {
        if (userData.isSundayDeliveryEnabled) {
            return false;
        }
        if (date.getDay() === 0) return true;
    }

    const pickupTimeOptionHandle = (event) => {
        setPudoOptionSelected(parseInt(event.target.value));
        setIsSpecialTiming(false);
        setDropoffTimeValue({
            ...state.dropoffTimeValue,
            dropoff_early_datetime: '',
            dropoff_later_datetime: ''
        })

        if (event.target.value === '3') {
            const current = moment().toDate();
            const start_time = roundTime(current.getHours() + ':' + current.getMinutes(), 30);
            // update pickup
            setPickupTimeValue({
                ...state.pickupTimeValue,
                pickup_type: '3',
                pickup_early_datetime: start_time,
                pickup_later_datetime: start_time
            });

            const next_2h_time_string = getFutureTime(current.toLocaleDateString(), start_time, 120);
            setDropoffTimeValue({
                ...state.dropoffTimeValue,
                dropoff_type: '3',
                dropoff_early_datetime: start_time,
                dropoff_later_datetime: next_2h_time_string
            })
        }

    }

    // console.log('state: ', state);

    const isRushAvailable = () => {

        if (interCity) {
            return false;
        }

        //Brought from the server constant
        const CUT_OFF_TIME_FOR_URGENT = 18;

        const nowMoment = moment();
        const selectedDate = moment(state.pickupTimeValue.pickup_date);

        //  console.log('now',nowMoment.day());
        //  console.log('selected', selectedDate.day());
        let selectedDayOfDate = selectedDate.day();

        /**
         * This will handle Sunday selection, since Sunday is ZERO
         */
        if (selectedDate.day() === 0) {
            selectedDayOfDate = 7;
        }
        const diff = selectedDayOfDate - nowMoment.day()
        //  console.log('diff', diff);

        /**
         * When the selected date is not today, we disable rush
         */
        if (diff > 0) {
            return false;
        }

        /**
         * When the distance is greater than user rush distance kms, we disable rush
         */
        // console.log(state);

        if (state.distanceInKm > userData.rush_distance) {
            return false;
        }

        if (nowMoment.hours() >= CUT_OFF_TIME_FOR_URGENT) {
            return false;
        }

        return true;
    }


    return (<div>
        <SubTitle className="mt-6 mb-1">
            Pickup Time *
        </SubTitle>

        {/* Error text about pickup type selection */}
        {!state.error?.pickup_time?.type &&
            <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
                Please Select Your Pickup Type
            </p>
        }



        <div className='mt-3 ' >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3} >
                    <Grid item xs={12} >
                        <StyledDatePicker disableToolbar variant="inline" id="pickup-date" label="Date"
                            format="MM-dd-yyyy" value={state.pickupTimeValue?.pickup_date} onChange={pickupDateChangeHandle}
                            minDate={state.currentPickupDate}
                            shouldDisableDate={disableSundays} />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>

        </div>

        <div className='mt-3 ' >
            <RadioGroup value="pickupTimeOption" onChange={pickupTimeOptionHandle} row>
                <RadioOption selectedValue={state.pudoOptionSelected} value={1} label={'Your Delivery Windows'} /><StyledToolTip
                    title={
                        <>
                            <div>
                                Standard pickup and drop off time windows available to you based on your inputted pickup and drop off locations above and current time. “Special Drop Off Time” is available with each pickup window.
                            </div>
                        </>
                    }
                    placement={"top"} arrow>
                    <img src={InfoIcon} alt={"premium information"} style={{ height: '24px', width: '24px', marginTop: '10px', marginRight: '15px' }} />
                </StyledToolTip>
                {!interCity && <><RadioOption selectedValue={state.pudoOptionSelected} value={2} label={'Express Delivery'} /><StyledToolTip
                    title={
                        <>
                            <div>
                                Express Delivery is for deliveries that either need to be dropped off immediately after pickup, or for deliveries with very specific drop off time requests. Express Delivery allows you to request exactly when you need your delivery dropped off (minimum 15 min window). Select a drop off window below and the system will generate a pickup window based on how long the delivery route will take to actually complete this order. Please note, pricing for this delivery option is based on distance from the pickup location.
                            </div>
                        </>
                    }
                    placement={"top"} arrow>
                    <img src={InfoIcon} alt={"premium information"} style={{ height: '24px', width: '24px', marginTop: '10px', marginRight: '15px' }} />
                </StyledToolTip>
                </>}
                {isRushAvailable() && <RadioOption selectedValue={state.pudoOptionSelected} value={3} label={'Rush (asap - 2 hours)'} />}
            </RadioGroup>
        </div>


        <div className='mt-3 ' >
            <Flex >
                <Row className=' w-full'>
                    {state.pudoOptionSelected === 1 && <PickupDropOffTimeProfile
                        setIsSpecialTiming={setIsSpecialTiming}
                        selectedDate={state.pickupTimeValue.pickup_date}
                        setCurrentPickupDate={setCurrentPickupDate}
                    />}

                    {state.pudoOptionSelected === 2 && <PickupDropOffTimeExpress
                    />}
                </Row>
            </Flex>

        </div>
    </div>);
}

const mapStateToProps = (state) => ({
    dropoff_value: {
        dropoff_type: state.deeleeoReducer.dropoff_type,
        dropoff_date: state.deeleeoReducer.dropoff_date !== '' ? state.deeleeoReducer.dropoff_date : moment().toDate().toString(),
        dropoff_early_datetime: state.deeleeoReducer.dropoff_early_datetime,
        dropoff_later_datetime: state.deeleeoReducer.dropoff_later_datetime
    },
    pickup_value: {
        pickup_type: state.deeleeoReducer.pickup_type,
        pickup_date: state.deeleeoReducer.pickup_date !== '' ? state.deeleeoReducer.pickup_date : moment().toDate().toString(),
        pickup_early_datetime: state.deeleeoReducer.pickup_early_datetime,
        pickup_later_datetime: state.deeleeoReducer.pickup_later_datetime
    },
    pickupData: state.pickupDropoffReducer.pickup_data,
    dropoffData: state.pickupDropoffReducer.dropoff_data,
    validation: {
        pickup_time: state.validationReducer.pickup_time,
        dropoff_time: state.validationReducer.dropoff_time,
    },
    userData: state.userReducer,

});

const mapDispatchToProps = (dispatch) => ({
    updatePickupTime: (pickup_value) => dispatch(updatePickupTime(pickup_value)),
    updateDropoffTime: (dropoff_value) => dispatch(updateDropoffTime(dropoff_value)),
    validatePickupTime: (data) => dispatch(validatePickupTime(data)),
    validateDropoffTime: (data) => dispatch(validateDropoffTime(data)),
    validatePickupWindowTime: (data) => dispatch(validatePickupWindowTime(data)),
    validateDropoffWindowTime: (data) => dispatch(validateDropoffWindowTime(data)),
    updateIsExpress: (data) => dispatch(updateIsExpress(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupDropOffTimeSectionSingle);