import styled from 'styled-components'
import tw from 'twin.macro'

export const AccentHeader = styled.h1`
  ${tw`text-secondaryOrange`}
  font-size: 18px;
  text-transform: uppercase;
  font-family: Montserrat;
`

export const BusinessName = styled.div`
  ${tw`text-fontBlue`}
  font-size: 26px;
  font-weight: bold;
  font-family: Montserrat;
`

export const SectionHeader = styled.div`
  ${tw`text-fontBlue mt-6`}
  font-size: 18px;
  font-family: Montserrat;
  font-weight: bold;
`

export const HeaderText = styled(SectionHeader)`
  ${tw`mt-0 flex items-center`}
`

export const HeaderSubText = styled.div`
  ${tw`text-fontBlue mt-2 mb-6`}
  font-family: Montserrat;
  font-size: 14px;
`

export const SectionHeaderRight = styled.div`
  ${tw`text-fontBlue ml-auto mr-0 mt-7 flex justify-center items-center`}
  font-size: 14px;
  font-family: Varela Round;
`

export const NumberListItem = styled.li`
  ${tw`text-fontBlue list-decimal list-outside`}
  font-size: 13px;
  font-family: Varela Round;
`

export const DescriptionText = styled.div`
  ${tw`text-fontBlue`}
  font-size: 14px;
  font-family: Montserrat;
`

export const LargerText = styled(DescriptionText)`
  font-family: Varela Round;
  font-size: 15px;
`

export const LargeText = styled(LargerText)`
  font-size: 20px;
`

export const SubTitle = styled.div`
  ${tw`text-fontBlue font-bold flex items-center`}
  ${props => props.withMargin && tw`mb-4`}
  font-family: Montserrat;
  font-size: 14px;
  //height: 26px;
`
export const HelperText = styled.div`
  ${tw`text-primaryBlue`}
  font-size: 12px;
`

export const HintText = styled.span`
  ${tw`text-fontBlue font-normal pl-2`}
  font-size: 14px;
`

export const PaginationText = styled.div`
  ${tw`text-fontBlue flex items-end`}
  font-size: 14px;
`

export const BlueTitle = styled.div`
  ${tw`text-primaryBlue uppercase font-bold`}
  font-family: Montserrat;
  font-size: 14px;
`

export const BlueTitleBig = styled.div`
  ${tw`text-primaryBlue`}
  font-family: Montserrat;
  font-size: 22px;
`

export const OrangeTitle = styled.div`
  ${tw`text-secondaryOrange uppercase font-bold`}
  font-family: Montserrat;
  font-size: 14px;
`

export const SummarySectionTitle = styled.div`
  // Use this one when promo code added
    // ${tw`flex text-primaryBlue uppercase font-semibold w-1/3 last:justify-end last:mr-2`}
  ${tw`flex text-primaryBlue uppercase font-semibold w-1/2`}
  font-size: 14px;
  font-family: Montserrat;
`

export const ErrorText = styled.div`
  ${tw`text-errorRed text-center`}
  font-size: 14px;
  font-family: Varela Round;
`

export const ErrorSubText = styled.div`
  font-family: Varela Round;
  font-size: 14px;
  color: #c8c3bc;
  text-decoration: rgb(200, 195, 188);
`


