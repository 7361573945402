import { combineReducers } from 'redux'

import { userReducer } from './userReducer'
import { authReducer } from './authReducer'
import { pickupReducer } from './pickupReducer'
import { shipmentReducer } from './shipmentReducer'
import { orderReducer } from './orderReducer'
import { dashboardReducer } from './dashboardReducer'

import { pickupDropoffReducer } from './pickupDropoffReducer'
import { deeleeoReducer } from './deeleeoReducer'
import { deeleeosReducer } from './deeleeosReducer'
import { sizeCategoryReducer } from './sizeCategoryReducer'
import { validationReducer } from './validationReducer'

const appReducer = combineReducers({
  authReducer, userReducer, pickupReducer, shipmentReducer, orderReducer, dashboardReducer, 
  pickupDropoffReducer, deeleeoReducer, sizeCategoryReducer, deeleeosReducer, validationReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STATE') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer;
