import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import { DeeleeoTextIcon } from './styled-icons'
import { Bold } from '../pages/Register/components/shared'
import { routes } from '../routes'

const HeaderContainer = styled.div`
  ${tw`mx-12 lg:mx-20 mt-5 pb-2.5 bottom-0 flex items-end justify-between`}
  border-bottom: 1px solid #6F84DA80;
  height: 69px;
`
const DetailsButton = styled.button`
  ${tw`flex text-fontBlue justify-end items-end pr-4 pb-2 -mb-2.5 hover:bg-hoverPurple`}
  width: 166px;
  font-size: 14px;
  height: 35px;
`

function Header(props) {
  const { deliveries, history } = props
  return (
    <HeaderContainer>
      <DeeleeoTextIcon/>
      <DetailsButton onClick={() => history.push(routes.summary)}>
        Order Summary: <Bold className='pl-2'>{deliveries}</Bold>
      </DetailsButton>
    </HeaderContainer>
  )
}

export default Header
