import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from 'react-modal';
import { getURI } from "../../../helpers/apiHelpers";
import { ThinSelect } from "../../../components/buttons";
import axios from "axios";
import { StyledDatePicker } from "../../DeeleeoDetails/components/shared";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { HtmlTooltip } from "../../../components/InputField";
import InfoIcon from "../../../assets/icons/Info-icon.svg";
import { RadioGroup } from "@material-ui/core";
import { RadioOption } from "../../DeeleeoDetails/components/PickupDropoffTimeSection";


const OrderSelectorModal = ({ contentLabel, modalIsOpen, setModalIsOpen, apiCode, ids }) => {


    const [state, setState] = useState({
        order: 'name-asc',
        allLabels: false,
        status: '',
        date: null,
        labelSize: '',
        pickupTimes: [],
        pickupTimeRadio: ''
    });

    const setOder = (data) => {
        setState(state => ({ ...state, order: data }));
    }

    const setAllLabels = (data) => {
        setState(state => ({ ...state, allLabels: data }));
    }

    const setStatus = (data) => {
        setState(state => ({ ...state, status: data }));
    }

    const setDate = (data) => {
        setState(state => ({ ...state, date: data }));
    }

    const setLabelSize = (data) => {
        setState(state => ({ ...state, labelSize: data }));
    }

    const setPickupTimes = (data) => {
        setState(state => ({ ...state, pickupTimes: data }));
    }

    const setPickupTimeRadio = (data) => {
        setState(state => ({ ...state, pickupTimeRadio: data }));
    }


    // const [order, setOder] = useState('id-asc');
    // const [allLabels, setAllLabels] = useState(false);
    // const [status, setStatus] = useState('');
    // const [date, setDate] = useState(null);

    const onChangeAllLabels = (e) => {
        setAllLabels(e.target.checked);
    }

    const oderChangeHandler = (e) => {
        setOder(e.target.value);
    }

    const sizeChangeHandler = (e) => {
        setLabelSize(e.target.value);
    }

    const onDateChange = (d, e) => {
        setDate(e);
    }

    /**
     * When closing, clean all state and reload page.
     */
    const closeModal = () => {
        setStatus('');
        setModalIsOpen(false);
        window.location.reload();
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {

        if (state.date) {
            const url = getURI(`/api/web/deeleeo/getPickupTimesForLabelsByDate?${new URLSearchParams({ api_code: apiCode, date: state.date })}`);
            // console.log(url);

            /**
         * request the data
         */
            axios.get(url)
                .then((result) => {
                    // console.log(result.data);
                    setPickupTimes(result.data);
                })
                .catch((error) => {
                    console.log(error);

                })
        }



    }, [apiCode, state.date]);

    const getPickupTimes = () => {
        return state.pickupTimes.map((time, i) => {
            return <RadioOption key={'p-' + i} selectedValue={state.pickupTimeRadio} value={time} label={time} />;
        })
    }

    const pickupTimeHandle = (event) => {
        // console.log(event.target.value);
        setPickupTimeRadio(event.target.value);
    }



    // eslint-disable-next-line no-unused-vars
    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = (data) => {
        const url = getURI(`/api/web/deeleeo/orderedlabels?${new URLSearchParams(
            {
                api_code: apiCode,
                update: 1,
                order: state.order,
                ids: ids,
                allLabels: state.allLabels,
                small: state.labelSize,
                pickupDate:state.date,
                pickupTime: state.pickupTimeRadio
            }
        )}`);

        /**
         * request the data
         */
        axios.get(url)
            .then((result) => {

                /**
                 * Set status to ok, so the content of the modal changes
                 */
                if (result.data.message === 'ok') {
                    setStatus('ok');
                } else {
                    setStatus('fail');
                }
            })
            .catch((error) => {
                setStatus('fail');
            })

    }

    let content = <></>;

    const getContent = () => {
        /**
         * status of the ajax call
         * ok,fail
         */
        switch (state.status) {
            case 'ok':
                content = <div className="" style={{ "width": "450px" }}>
                    <div className="mb-4">
                        <div className="mb-10">
                            Success, you will receive an email with your labels.
                        </div>
                        <div className="flex items-center justify-between">

                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={closeModal}>
                                close
                            </button>
                        </div>
                    </div>
                </div>;
                break;
            case 'fail':
                content = <div className="" style={{ "width": "450px" }}>
                    <div className="mb-4">

                        <div className="mb-10">
                            There was a problem, please try again
                        </div>


                        <div className="flex items-center justify-between">

                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={closeModal}>
                                close
                            </button>
                        </div>

                    </div>
                </div>;
                break;

            default:
                content = <div className="" style={{ "width": "450px" }}>
                    <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit(onSubmit)}>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="check">
                                Select All Labels:
                            </label>
                            <input
                                id="check"
                                type="checkbox"
                                checked={state.allLabels}
                                onChange={onChangeAllLabels}
                            />
                            <HtmlTooltip
                                style={{ marginLeft: 7 }}
                                placement="top"
                                title="By selecting this checkbox, you will request all the labels that match this popup filters, otherwise, only the selected deeleeos.">

                                <img
                                    style={{ display: 'inline' }}
                                    className="-mt-1 ml-1"
                                    src={InfoIcon}
                                    alt={"premium information"}
                                />
                            </HtmlTooltip>
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="select">
                                Select Order of Labels:
                            </label>

                            <ThinSelect id="select" className="select shadow appearance-none border rounded w-full  h-10 mb-7 ml-auto my-auto py-1 px-3 text-gray-700  focus:outline-none focus:shadow-outline" onChange={oderChangeHandler} >

                                {/* <option value="id-asc">Older to Recent (ID)</option>
                                <option value="id-desc">Recent to Older (ID)</option> */}
                                <option value="name-asc">Ascending A-Z (Name)</option>
                                <option value="name-desc">Descending Z-A (Name)</option>
                            </ThinSelect>

                            <br />
                            <br />
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="selectSize">
                                Select Labels Size:
                            </label>

                            <ThinSelect id="selectSize" className="select shadow appearance-none border rounded w-full  h-10 mb-7 ml-auto my-auto py-1 px-3 text-gray-700  focus:outline-none focus:shadow-outline" onChange={sizeChangeHandler} >
                                <option value="">A6 (4 X 6)</option>
                                <option value="small">A7 (3 X 4)</option>
                            </ThinSelect>
                            <br />


                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                                Select Pickup Deeleeo Date:
                            </label>


                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <StyledDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="pickup-date"
                                    format="MM-dd-yyyy"
                                    disabled={false}
                                    value={state.date}
                                    onChange={onDateChange}
                                />
                                <HtmlTooltip
                                    style={{ marginLeft: 7 }}
                                    placement="top"
                                    title="This DATE get's pickup times">

                                    <img
                                        style={{ display: 'inline' }}
                                        className="-mt-1 ml-1"
                                        src={InfoIcon}
                                        alt={"premium information"}
                                    />
                                </HtmlTooltip>
                            </MuiPickersUtilsProvider>

                            <br />


                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="time">
                                Select Pickup Deeleeo Time:
                            </label>

                            {!state.pickupTimes.length ? <div>
                                No pickup times for the selected date
                            </div> :

                                <RadioGroup row value="pickup" onChange={pickupTimeHandle}>
                                    {getPickupTimes()}
                                </RadioGroup>
                            }





                        </div>


                        <div className="flex items-center justify-between">

                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={closeModal}>
                                close
                            </button>

                            <input className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" />


                        </div>
                    </form>
                </div>;
                break;
        }
        return content;
        //console.log(content);

    }



    return (<Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={contentLabel}
    >

        {getContent()}

    </Modal>);





}

export default OrderSelectorModal;