import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Checkbox } from '@material-ui/core'
import DateTimePicker from '../../../components/DateTimePicker'

const HourSelectorContainer = styled.div`
  ${tw`flex items-center text-fontBlue my-6`}
  font-family: Varela Round;
`

export const Dash = styled.div`
  ${tw`text-fontBlue`}
`

// const StyledTextField = styled(TextField)`
//   .MuiInput-underline.Mui-disabled::before {
//     border-bottom: none;
//   }
//   .MuiInputBase-input.Mui-disabled {
//     color: rgba(0,0,0,0.38);
//   }
//   .MuiInputBase-root {
//     border: 1px solid #d9d9d9;
//     padding: 0 8px;
//     height: 40px;
//     margin-right: 10px;
//     margin-left: 10px;
//     width: 120px;
//   }
//   .MuiInputBase-input {
//     color: #3d405b;
//     font-size: 13px;
//     cursor: text;
//   }
//   .MuiInput-underline {
//     &:before {
//       border-bottom: none;
//     }
//   }
//   .MuiInput-underline:hover:not(.Mui-disabled)::before {
//     border-bottom: none;
//   } 
// `

const CheckboxContainer = styled.div`
  ${tw`w-36 capitalize flex items-center font-bold`}
  font-family: Montserrat;
  font-size: 13px;
`

const NoPickups = styled.div`
  ${tw`ml-24 text-fontBlue pl-2`}
  font-family: Varela Round;
  font-size: 14px;
`

// export const TimeField = (props) => {
//   const { defaultVal, id, onChange, disabled } = props
//   return (

//     <DateTimePicker
//       disabled={disabled}
//       id={id}
//       defaultValue={defaultVal}
//       onChange={onChange}
//     />

//     // <StyledTextField disabled={disabled} id={id} label="" type="time" value={defaultVal} defaultValue={defaultVal} onChange={onChange}
//     //            InputLabelProps={{
//     //              shrink: true,
//     //            }}
//     //            inputProps={{
//     //              step: 300, // 5 min
//     //            }}
//     // />
//   )
// }



function PickupHourSelector(props) {
  const { day, updatePickupHours, pickupHours } = props;
  const isDisabled = (pickupHours[day] && pickupHours[day].start === '') && (pickupHours[day] && pickupHours[day].end === '');
  const [checked, setChecked] = useState(!isDisabled)
  const [startTime, setStartTime] = useState(pickupHours[day] ? pickupHours[day].start : '')
  const [endTime, setEndTime] = useState(pickupHours[day] ? pickupHours[day].end : '')
  // const [error, setError] = useState('')

  useEffect(() => {
    if (startTime > endTime) {
      // setError('Start of window cannot be after end of window')
    }
  }, [startTime, endTime])

  const handleOnChange = (value, isStart) => {
    const time = value
    isStart ? setStartTime(time) : setEndTime(time)
    updatePickupHours({
      pickupHours: {
        ...pickupHours,
        [day]: { start: isStart ? time : startTime, end: !isStart ? time : endTime }
      }
    })
  }

  const handleCheckbox = (isChecked) => {
    setChecked(!isChecked)
    if (isChecked) {
      setStartTime('')
      setEndTime('')
      updatePickupHours({
        pickupHours: {
          ...pickupHours,
          [day]: { start: '', end: '' }
        }
      })
    }
  }

  return (
    <HourSelectorContainer>
      <CheckboxContainer>
        <Checkbox
          checked={checked}
          onClick={() => handleCheckbox(checked)}
          color="primary"
        />
        {day}
      </CheckboxContainer>
      {checked && <>
        <div className='width-1/8'>
          <DateTimePicker id={`${day}-start`} defaultValue={startTime} onChange={(value) => handleOnChange(value, true)} />
        </div>
        <Dash>–</Dash>
        <DateTimePicker id={`${day}-end`} defaultValue={endTime} onChange={(value) => handleOnChange(value, false)} />
      </>}
      {!checked && <NoPickups>
        No Pickups
      </NoPickups>}
    </HourSelectorContainer>
  )
}

export default PickupHourSelector
