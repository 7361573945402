import React, { useEffect, useState } from 'react'
import { SubTitle } from '../../../components/text'
import { connect } from 'react-redux'
import SizingButton from './SizingButton'

import box from '../../../assets/icons/box.png'
import { HtmlTooltip } from '../../../components/InputField'
import InfoIcon from '../../../assets/icons/Info-icon.svg'
import { SizeDescription } from './shared'



function SizeSectionBatch(props) {
  const {  sizeCategory, deeleeoDetail, validation } = props;
  const [error, setError] = useState(validation);

  useEffect(() => {
    setError(validation);
  }, [validation])

  return (
    <div>
      <SubTitle className='mt-6 mb-3'>Select Weight & Size *
        <HtmlTooltip
          // style={{ marginLeft: 7 }}
          placement="top"
          title={'Please select the weight and size that best matches with the largest item in your delivery request. The package count is to understand how many packages are in this order.'}>

          <img
            style={{ display: 'inline-block' }}
            className="-mt-1 ml-1"
            height={20}
            width={20}
            src={InfoIcon}
            alt={"premium information"}
          />
        </HtmlTooltip>
      </SubTitle>

      {!error &&
        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
          Please Select Size & Weight
        </p>
      }

      {/* <Grid2 error={validation}>
          {sizeCategory.map((item, i) =>
            <SizingButton key={i} sizeData={item} deeleeoDetail={deeleeoDetail}></SizingButton>)
          }
      </Grid2> */}

      <div className='block border-2 rounded-md mb-2'>
        <div className='grid grid-cols-3 gap-4'>
          <SizeDescription sizeTitle={'SMALL'} icon={box} height={'30px'} sizeDescription={'Largest dimension of the package(s) is less than 20 inches (51 cm)'} />

          <div className='col-span-2'>
            <SizingButton key={0} sizeData={sizeCategory[0]} deeleeoDetail={deeleeoDetail}></SizingButton>
            <SizingButton key={1} sizeData={sizeCategory[1]} deeleeoDetail={deeleeoDetail}></SizingButton>
          </div>
        </div>
      </div>

      <div className='block border-2 rounded-md mb-2'>
        <div className='grid grid-cols-3 gap-4'>
          <SizeDescription sizeTitle={'MEDIUM'} icon={box} height={'45px'} sizeDescription={'Largest dimension of the package(s) is less than 48 inches (122 cm)'} />

          <div className='col-span-2'>
            <SizingButton key={2} sizeData={sizeCategory[2]} deeleeoDetail={deeleeoDetail}></SizingButton>
            <SizingButton key={3} sizeData={sizeCategory[3]} deeleeoDetail={deeleeoDetail}></SizingButton>
          </div>
        </div>
      </div>

      <div className='block border-2 rounded-md mb-2'>
        <div className='grid grid-cols-3 gap-4'>
          <SizeDescription sizeTitle={'LARGE'} icon={box} height={'60px'} sizeDescription={'Largest dimension of the package(s) is less than 96 inches (244 cm)'} />

          <div className='col-span-2'>
            <SizingButton key={4} sizeData={sizeCategory[4]} deeleeoDetail={deeleeoDetail}></SizingButton>
            <SizingButton key={5} sizeData={sizeCategory[5]} deeleeoDetail={deeleeoDetail}></SizingButton>
          </div>
        </div>
      </div>

      {/* <div className='block border-2 rounded-md mb-2'>
        <div className='grid grid-cols-3 gap-4'>
          <SizeDescription sizeTitle={'COMMERCIAL'} icon={commercial} sizeDescription={'Commercial sized loads'} />

          <div className='col-span-2 my-3'>
            <SizingButton key={6} sizeData={sizeCategory[6]} deeleeoDetail={deeleeoDetail}></SizingButton>
          </div>
        </div>
      </div> */}

    </div>
  )
}

const mapStateToProps = state => ({
  sizeCategory: state.sizeCategoryReducer.data,
  deeleeoDetail: state.deeleeoReducer,
  validation: state.validationReducer.size_weight_selection
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SizeSectionBatch)
