import React, { useState } from 'react';
import Modal from 'react-modal';
import AddressField from '../../../components/AddressField';
import What3WordsField from '../../../components/What3WordsField';
// import { checkDistanceWithinRadius } from '../../../helpers/addressHelpers';
import * as yup from 'yup'
import { useFormik } from 'formik';
import styled from 'styled-components'
import tw from 'twin.macro'
import { ThinButton } from '../../../components/buttons';
import InputField from '../../../components/InputField';
import { useEffect } from 'react';
import axios from 'axios';
import { getURI } from '../../../helpers/apiHelpers';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '3px solid #555',
        borderRadius: '15px'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
};

const InformationFormContainer = styled.form`
  ${tw` w-full `}
`





const UpdateAddressModal = (props) => {
    const { isOpen, closeModal, isSender, w3wAddress,  doUpdate, setEditingRow, setGoogleSearchParameters, editingRow } = props;

    const [w3wValues, setW3wValues] = useState([]);
    const cleanW3WValues = () => {
        setW3wValues([]);
    }

    const close = () => {
        formik.resetForm();
        closeModal();
        setEditingRow({});
    }


    // console.log('editing row values', editingRow);



    const validationSchema = yup.object().shape({
        address: yup.string().when('w3w', {
            is: (w3w) => !w3w || w3w.length === 0,
            then: yup.string().required('Address is required'),
            otherwise: yup.string()
        }),
        w3w: yup.string().when('address', {
            is: (address) => !address || address.length === 0,
            then: yup.string().required('w3w is required'),
            otherwise: yup.string()
        }),
        latitude: yup.number().required('Validate the address first'),
        longitude: yup.number().required('Validate the address first'),
        name: yup.string().required(),
        packages: yup.number().required(),
    }, [['address', 'w3w'], ['w3w', 'address']]);





    const formik = useFormik({
        initialValues: {
            address: '',
            w3w: '',
            latitude: '',
            longitude: '',
            unit: '',
            name: '',
            packages: ''
        },
        validationSchema: validationSchema,
        onSubmit: ((values, { setSubmitting, setErrors, resetForm }) => {
            resetForm();
            doUpdate(values);
        })

    })

    useEffect(() => {

        if (editingRow && editingRow.values) {


            formik.setValues({
                ...formik.values,
                address: editingRow.values.address,
                name: editingRow.values.name,
                packages: editingRow.values.package_count,
                unit: editingRow.values.unitNumber
            })
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingRow, editingRow.values]);





    const handleAddressChange = (addressInfo) => {
        //console.log('handling handleAddressChange');
        //console.log(addressInfo);
        const { city, lat, lng, street, province } = addressInfo

        const payload = { lat: lat, lon: lng };

        axios
            .post(getURI(`/api/web/get-zone-from-coordinate`), payload)
            .then((response) => {

                // console.log(response);
                formik.setValues({
                    ...formik.values,
                    address: street + ', ' + city + ' ' + province,
                    latitude: lat,
                    longitude: lng
                })
            })
            .catch(error => {
                console.log(error);
                formik.setFieldError('address', 'This location is not currently serviced by Deeleeo')

            });

    }

    /**
  * When selecting an option, we validate the coords, and set the info if it passes validation
  * @param {*} data 
  */
    const handleW3WChange = (data) => {
        //console.log('handling handleW3WChange');
        //console.log(data);

        /**
         * Data now contains w3w data and the google autocomplete options
         */
        const { w3w, google } = data;
        const { coordinates, words } = w3w;


        const payload = { lat: coordinates.lat, lon: coordinates.lng };

        axios
            .post(getURI(`/api/web/get-zone-from-coordinate`), payload)
            .then((response) => {

                // console.log(response);


                /**
               * If we can service the location, we save the google options to be displayed
               */
                setW3wValues(google);
                /**
                 * Also save w3w address and coords to the deeleeo
                 */
                formik.setValues({
                    ...formik.values,
                    address: '///' + words,
                    latitude: coordinates.lat,
                    longitude: coordinates.lng
                })

            })
            .catch(error => {
                console.log(error);
                formik.setFieldError('w3w', 'This location is not currently serviced by Deeleeo')

            });



    }


    return (<Modal
        isOpen={isOpen}
        onRequestClose={close}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit Adress"
    >
        <InformationFormContainer className={'w-full'} onSubmit={formik.handleSubmit}>
            <InputField

                id={'name'}
                type={'text'}
                label={'Deeleeo Name'}
                nBlur={formik.handleBlur}
                helperText={formik.errors.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                onChange={formik.handleChange}
                value={formik.values.name} />
            <AddressField
                required
                id={'address'}
                label={isSender ? 'Pickup location' : 'Drop off location'}
                defaultValue={formik.values.address}
                onChange={handleAddressChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.address || formik.errors.latitude || formik.errors.longitude}
                error={(formik.touched.address && Boolean(formik.errors.address)) || Boolean(formik.errors.latitude) || Boolean(formik.errors.longitude)}
                values={w3wValues}//To pass external values to the component
                cleanValues={cleanW3WValues}//To clean these external values from the component
                pushAddress={setGoogleSearchParameters}
            />

            <What3WordsField
                id={'w3w'}
                label={isSender ? 'What3word pickup address (optional)' : 'What3word drop off address (optional)'}
                defaultValue={w3wAddress}
                onChange={handleW3WChange}
                helperText={formik.errors.w3w}
                error={formik.touched.w3w && Boolean(formik.errors.w3w)}
                onBlur={formik.handleBlur}
            />

            <InputField

                id={'unit'}
                type={'text'}
                label={'Unit (Optional)'} o
                nBlur={formik.handleBlur}
                helperText={formik.errors.unit}
                error={formik.touched.unit && Boolean(formik.errors.unit)}
                onChange={formik.handleChange}
                value={formik.values.unit} />

            <InputField

                id={'packages'}
                type={'number'}
                label={'Packages'}
                nBlur={formik.handleBlur}
                helperText={formik.errors.packages}
                error={formik.touched.packages && Boolean(formik.errors.packages)}
                onChange={formik.handleChange}
                value={formik.values.packages} />
            <hr />
            <br />

            <ThinButton type="submit">Update</ThinButton>
            <ThinButton onClick={close}>close</ThinButton>
        </InformationFormContainer>
    </Modal>);
}

export default UpdateAddressModal;