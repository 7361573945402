import { getURI } from '../../helpers/apiHelpers'
import { checkForOkStatus } from '../../helpers/requestHelpers'

export const dashboardActions = Object.freeze({
  CANCEL_DEELEEO: 'CANCEL_DEELEEO',
  CANCEL_DEELEEO_SUBMIT: 'CANCEL_DEELEEO_SUBMIT',
  CANCEL_DEELEEO_SUCCESS: 'CANCEL_DEELEEO_SUCCESS',
  CANCEL_DEELEEO_FAILURE: 'CANCEL_DEELEEO_FAILURE',
  CANCEL_DEELEEO_STATUS_RESET: 'CANCEL_DEELEEO_STATUS_RESET',

  FETCH_ALL_DEELEEOS_REQUEST: 'FETCH_ALL_DEELEEOS_REQUEST',
  FETCH_ALL_DEELEEOS_SUCCESS: 'FETCH_ALL_DEELEEOS_SUCCESS',
  FETCH_ALL_DEELEEOS_FAILURE: 'FETCH_ALL_DEELEEOS_FAILURE',
  FETCH_DONE_DEELEEOS_SUCCESS: 'FETCH_DONE_DEELEEOS_SUCCESS',
  FETCH_SHARED_DEELEEOS_SUCCESS: 'FETCH_SHARED_DEELEEOS_SUCCESS',


  FETCH_MULTI_DEELEEOS_REQUEST: 'FETCH_MULTI_DEELEEOS_REQUEST',
  FETCH_MULTI_DEELEEOS_SUCCESS: 'FETCH_MULTI_DEELEEOS_SUCCESS',
  FETCH_MULTI_DEELEEOS_FAILURE: 'FETCH_MULTI_DEELEEOS_FAILURE',

  POST_MULTIDEELEEOS_REQUEST: 'POST_MULTIDEELEEOS_REQUEST',
  POST_MULTIDEELEEOS_SUCCESS: 'POST_MULTIDEELEEOS_SUCCESS',
  POST_MULTIDEELEEOS_FAILURE: 'POST_MULTIDEELEEOS_FAILURE',
  POST_MULTIDEELEEOS_STATUS_RESET: 'POST_MULTIDEELEEOS_STATUS_RESET',

})


export const clearCancelDeeleeoStatus = () => ({ type: dashboardActions.CANCEL_DEELEEO_STATUS_RESET })
export const clearPostMultiDeeleeoDeeleeoStatus = () => ({ type: dashboardActions.POST_MULTIDEELEEOS_STATUS_RESET })

export const cancelDeeleeo = (requestData) => dispatch => {
  const request = () => ({ type: dashboardActions.CANCEL_DEELEEO })
  const submit = () => ({ type: dashboardActions.CANCEL_DEELEEO_SUBMIT })
  const success = (data) => ({ type: dashboardActions.CANCEL_DEELEEO_SUCCESS, data: data })
  const failure = (error) => ({ type: dashboardActions.CANCEL_DEELEEO_FAILURE, data: error })



  dispatch(request());
  dispatch(submit());



  fetch(getURI(`/api/web/deeleeo/${requestData.deeleeo_id}/cancel`), {
    method: 'PATCH',
    body: JSON.stringify({
      cancellation_reason_id: requestData.cancellation_reason_id,
      note: requestData.note,
      api_code: requestData.api_key
    }),
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then(response => response.text())
    .then(response => {
      const message = JSON.parse(response);

      if (message.message !== 'ok') {
        throw new Error(response.message);
      }
      dispatch(success());
    })

    .catch(e => dispatch(failure(e)))
}

export const fetchAllDeeleeos = (requestData) => dispatch => {
  const request = () => ({ type: dashboardActions.FETCH_ALL_DEELEEOS_REQUEST })
  const success = (data) => ({ type: dashboardActions.FETCH_ALL_DEELEEOS_SUCCESS, data: data })
  const successDone = (data) => ({ type: dashboardActions.FETCH_DONE_DEELEEOS_SUCCESS, data: data });
  const successShared = (data) => ({ type: dashboardActions.FETCH_SHARED_DEELEEOS_SUCCESS, data: data });
  const failure = (error) => ({ type: dashboardActions.FETCH_ALL_DEELEEOS_FAILURE, data: error })

  dispatch(request())


  fetch(getURI('/api/user/getDeeleeosForBusinessAccountPagination?' + new URLSearchParams(requestData)))
    .then(response => checkForOkStatus(response, 'Error fetching Deeleeos'))
    .then(response => {
      const parsedRes = JSON.parse(response)
      // console.log(requestData);

      //  console.log('response',parsedRes);
      switch (requestData.deeleeoView) {
        case 1:
          dispatch(successDone(parsedRes));
          break;
        case 2:
          dispatch(successShared(parsedRes));
          break;
        case 3:
          dispatch(success(parsedRes));
          break;
          default:
            //donothing

      }

    })
    .catch(e => dispatch(failure(e)))
}

export const fetchMultiDeeleeos = (requestData) => dispatch => {
  const { apiCode } = requestData;
  const request = () => ({ type: dashboardActions.FETCH_MULTI_DEELEEOS_REQUEST });
  const success = (data) => ({ type: dashboardActions.FETCH_MULTI_DEELEEOS_SUCCESS, data: data });
  const failure = (error) => ({ type: dashboardActions.FETCH_MULTI_DEELEEOS_FAILURE, error: error });

  dispatch(request());

  fetch(getURI(`/api/web/multi-deeleeo?api_key=${apiCode}`))
    .then(response => checkForOkStatus(response, 'Error fetching multi-deeleeos'))
    .then(response => {
      const parsedRes = JSON.parse(response);
      dispatch(success(parsedRes))
    })
    .catch(e => dispatch(failure(e)))

}

export const postMultiDeeleeos = (requestData) => dispatch => {
  const { file, apiCode } = requestData;
  const request = () => ({ type: dashboardActions.POST_MULTIDEELEEOS_REQUEST });
  const success = (data) => ({ type: dashboardActions.POST_MULTIDEELEEOS_SUCCESS, data: data });
  const failure = (error) => ({ type: dashboardActions.POST_MULTIDEELEEOS_FAILURE, error: error });

  dispatch(request());

  var data = new FormData()
  data.append('file', file);
  data.append('api_key', apiCode);


  fetch(getURI('/api/web/multi-deeleeo'), {
    method: 'POST',
    body: data,
    validateStatus: (status) => status === 200,
  })

    .then(response => checkForOkStatus(response, 'Error fetching multi-deeleeos'))
    .then(() => {
      //const parsedRes = JSON.parse(response);
      dispatch(success({}))
    })
    .catch(e => dispatch(failure(e)))

}
