import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { connect } from "react-redux";

import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { SubTitle } from "../../../components/text";
import InfoIcon from "../../../assets/icons/Info-icon.svg";
import { StyledToolTip } from "./shared";

import { updatePremiumOptions } from '../../../redux/reducers/deeleeoReducer'
import { useRecoilState } from "recoil";
import { isInterCity } from "../../../App";

const StyledLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    ${tw`text-fontBlue`}
    font-family: Montserrat;
    font-size: 13px;
  }
`;

const PremiumLabel = (props) => {
    const { label } = props;
    return (
        <div>
            <SubTitle className="mt-6 mb-3">
                {label ? label : 'Special Care'}
                <StyledToolTip
                    title={
                        <>
                            <div>
                                A PREMIUM fee will be added for Deeleeos that require special care & handling, and/or
                                extra time on part of the delivery agent. For example: returning or exchanging an item in person.
                                <br className="mb-4" />
                                You'll have the opportunity to review the cost at the payment step.
                            </div>
                        </>
                    }
                    placement={"top"} arrow>
                    <img src={InfoIcon} alt={"premium information"} />
                </StyledToolTip>
            </SubTitle>
        </div>
    );
};

const PremiumOption = (props) => {
    const { infoDetail, value, label, checked, checkboxHandle } = props
    return (
        <StyledToolTip
            title={<>
                <div>
                    {infoDetail}
                </div>
            </>
            }
            placement={"top"} arrow>

            <StyledLabel value={value} control={<Checkbox checked={checked} color="primary" />}
                label={label} onChange={checkboxHandle} labelPlacement="end" />
        </StyledToolTip>
    );
}



function PremiumSection(props) {
    const { updatePremiumOptions, premiumOptions } = props;
    const [premiumOptionsValue, setPremiumOptionsValue] = useState({
        special_care: premiumOptions.special_care,
        load_needs: premiumOptions.load_needs,
        unload_needs: premiumOptions.unload_needs,
        alcohol_delivery: premiumOptions.alcohol_delivery,
        require_refrigerated_vehicle: premiumOptions.require_refrigerated_vehicle,
        not_require_refrigerated_vehicle: premiumOptions.not_require_refrigerated_vehicle
    });

    // eslint-disable-next-line no-unused-vars
    const [interCity, setInterCity] = useRecoilState(isInterCity);

    const checkboxHandle = (event) => {

        if (event.target.value === 'require_refrigerated_vehicle' && event.target.checked) {
            setPremiumOptionsValue({ ...premiumOptionsValue, [event.target.value]: event.target.checked, 'not_require_refrigerated_vehicle': false });
            return;
        }

        if (event.target.value === 'not_require_refrigerated_vehicle' && event.target.checked) {
            setPremiumOptionsValue({ ...premiumOptionsValue, [event.target.value]: event.target.checked, 'require_refrigerated_vehicle': false });
            return;
        }

        setPremiumOptionsValue({ ...premiumOptionsValue, [event.target.value]: event.target.checked })
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            updatePremiumOptions(premiumOptionsValue)
        }, 500);
        return () => clearTimeout(timeout);
    }, [premiumOptionsValue, updatePremiumOptions]);


    return (
        <div>
            <PremiumLabel />
            <FormGroup aria-label="position" row>
                <PremiumOption infoDetail='Even though all items are cared for, these items will be given extra attention and care.'
                    value='special_care' label='Fragile' checked={premiumOptionsValue.special_care} checkboxHandle={checkboxHandle} />

                {/* <PremiumOption infoDetail='This means your item(s) need an extra person to help the delivery agent or other special equipment at pickup. Please specify.'
                    value='load_needs' label='Loading Needs' checked={premiumOptionsValue.load_needs} checkboxHandle={checkboxHandle} />

                <PremiumOption infoDetail='This means your item(s) need an extra person to help the delivery agent or other special equipment at drop off. Please specify.'
                    value='unload_needs' label='Unloading Needs' checked={premiumOptionsValue.unload_needs} checkboxHandle={checkboxHandle} /> */}
            </FormGroup>

            {interCity && <>
                <PremiumLabel label='Temperature Regulation' />
                <FormGroup aria-label="position" row>
                    <PremiumOption infoDetail='Select this option if you item(s) require to be transported in a temp-controlled environment for intercity deliveries.'
                        value='require_refrigerated_vehicle' label='Temp-controlled Item(s)' checked={premiumOptionsValue.require_refrigerated_vehicle} checkboxHandle={checkboxHandle} />

                    <PremiumOption infoDetail='These items do not need insulated bags nor a refrigerated vehicle.'
                        value='not_require_refrigerated_vehicle' label='Non Temp-controlled Item(s)' checked={premiumOptionsValue.not_require_refrigerated_vehicle} checkboxHandle={checkboxHandle} />
                </FormGroup>
            </>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    premiumOptions: state.deeleeoReducer.premium_options
});

const mapDispatchToProps = (dispatch) => ({
    updatePremiumOptions: (data) => dispatch(updatePremiumOptions(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PremiumSection);
