import React, { useEffect } from 'react'
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete'
import { InputContainer, StyledInput, StyledLabel } from './InputField'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledSuggestionList = styled.ul`
  ${tw`absolute mt-14 bg-white w-full px-4 py-2 shadow-lg z-10`}
`

const StyledListItem = styled.li`
  ${tw`cursor-pointer my-1`}
`
export const getValForType = (type, details) => {
  let value
  details.address_components.forEach((item) => {
    if (item.types.includes(type)) {
      value = item.long_name
    }
  })
  return value
}

const AddressField = (props) => {
  /**
   * Added values and cleanValues to handle data from the outside
   */
  const { id, label, type, onChange, onBlur, defaultValue, error, helperText, required, values, cleanValues, pushAddress } = props



  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["CA"] },
    },
    defaultValue: defaultValue,
    debounce: 300,
  });


  /**
   * To be triggered when the default value changes
   */
  useEffect(() => {
    // console.log('running default name changed');
    setValue(defaultValue, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);


  const handleInput = (e) => {
    setValue(e.target.value);
  };


  const handleSelect = (suggestion) => () => {

    /**
     * Set the value to display
     */
    setValue(suggestion.description, false);
    /**
     * remove suggestions
     */
    clearSuggestions();

    const parameter = {
      placeId: suggestion.place_id,
      fields: ['address_components'],
    };


    /**
     * If there were values set from the outside, clean them
     */
    if (values.length > 0) {
      cleanValues();
      /**
       * Push address if needed
       */
      if (pushAddress) {
        /**
         * This will be used on the file uploader
         */
        pushAddress(parameter);
      }
      /**
       * We don't need to continue, coordinates are already set from w3w
       */
      return false;
    }

    /**
     * In case user only used google address
     */
    if (pushAddress) {
      /**
       * This will be used on the file uploader
       */
      pushAddress(parameter);
    }



    /**
     * If the suggestion was not passed from the w3w data
     */
    getDetails(parameter)
      .then((details) => {
        // this thing will get coordinate of suggesstion address
        getGeocode({ address: suggestion.description })
          .then((results) => {
            return getLatLng(results[0])
          })
          .then(({ lat, lng }) => {
            onChange({
              street: `${getValForType('street_number', details)} ${getValForType('route', details)}`,
              city: getValForType('locality', details),
              province: getValForType('administrative_area_level_1', details),
              address: suggestion.description,
              lat: lat,
              lng: lng
            })
          })
          .catch((error) => {
            console.log("😱 Error: ", error);
          });
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const renderSuggestions = () => {

    /**
     * Render the values from the outside if they are present
     */
    if (values.length > 0) {
      return values.map((suggestion) => {
        const {
          place_id,
          formatted_address
        } = suggestion;

        suggestion.description = formatted_address;

        return (
          <StyledListItem key={place_id} onClick={handleSelect(suggestion)}>
            <strong>{formatted_address}</strong>
          </StyledListItem>
        );
      });
    }

    /**
     * Render the local suggestions that come from google
     */
    // console.log(data);    
    return data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <StyledListItem key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </StyledListItem>
      );
    });
  }

  return (
    <InputContainer className='relative'>
      <StyledLabel error={error} htmlFor={id}>{required ? `${label} *` : label}</StyledLabel>
      <StyledInput autocomplete='off' error={error} type={type} id={id} name={id} onBlur={onBlur} onChange={handleInput} value={value} />
      {error && <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>{helperText}</p>}
      {(status === "OK" || values.length > 0) && <StyledSuggestionList>{renderSuggestions()}</StyledSuggestionList>}
    </InputContainer>
  );
}

export default AddressField
