import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Flex } from '../../../components/containers'

const BaseCostText = styled.div`
  ${tw`text-fontBlue relative`}
  font-family: Varela Round;
`
const BeforeTotal = styled(BaseCostText)`
  font-size: 20px;
`
const Total = styled(BaseCostText)`
  font-size: 24px;
`
const CostDesc = styled.span`
  ${tw`absolute w-44 pl-2`}
  color: #999999;
  font-size: 14px;
`

function OrderBreakdown(props) {
  const { deeleeos } = props

  /**
   * Get the alternative name of properties
   * @param {String} field 
   * @returns 
   */
  const getAltName = (field) => {
    switch (field) {
      case 'delivery_fee':
        return 'deliveryFee'
      case 'premiums_fee':
        return 'premiumFee'
      default:
        break;
    }
  }

  /**
   * Calculates sum of a property
   * @param {String} field 
   * @returns 
   */
  const calculateTotalForField = (field) => {

    let searchField = 0.00
    const altName = getAltName(field);
    deeleeos.forEach((deeleeo) => {
      // console.log(deeleeo);


      if (deeleeo && (deeleeo[field] || deeleeo[altName])) {
        if (deeleeo[altName]) {
          searchField += deeleeo[altName]
        } else {
          searchField += deeleeo[field]
        }
      }
    })
    return searchField
  }

  return (
    <div className='mt-20 relative'>
      <Flex className='flex-col w-min'>
        <BeforeTotal>${(calculateTotalForField('delivery_fee') + calculateTotalForField('premiums_fee') + calculateTotalForField('service_admin_fee')).toFixed(2)} <CostDesc className='bottom-1'>Total before taxes</CostDesc></BeforeTotal>
        <BeforeTotal>${calculateTotalForField('taxes').toFixed(2)} <CostDesc className='bottom-1'>G.S.T</CostDesc></BeforeTotal>
        <Total>${(calculateTotalForField('delivery_fee') + calculateTotalForField('premiums_fee') + calculateTotalForField('taxes') + calculateTotalForField('service_admin_fee')).toFixed(2)}<CostDesc className='bottom-1.5'>TOTAL</CostDesc></Total>
      </Flex>
      <hr className='absolute w-full -mt-9' />
    </div>
  )
}

export default OrderBreakdown
