import React, { useEffect, useState } from "react";
import { SubTitle } from '../../../components/text'
import { connect } from "react-redux";
import styled from "styled-components";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { updateDropOffAddressType,  } from '../../../redux/reducers/deeleeoReducer'

const StyledLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: 14px;
    font-family: Montserrat;
    color: #3d405b;
  }
`

const RadioOption = (props) => {
    const { value, label, selectedValue, isDisabled } = props
    return (
        <StyledLabel value={value} control={<Radio disabled={isDisabled}
            checked={value === selectedValue} />} label={label} />
    )
}

function DropOffAddressTypeSection(props) {
    const dropoffType = {
        HOME: '1',
        BUSINESS: '2',
        SCHOOL: '3',
        HOSPITAL: '4',
        APARTMENT: '5',
    }

    const { updateDropOffAddressType } = props;
    const [dropoffMethodValue, setDropoffMethodvalue] = useState('0');
    // const [error, setError] = useState(validation);

    const optionHandle = (event) => {
        setDropoffMethodvalue(event.target.value);
    };

    // useEffect(() => {
    //     setError(validation);
    // }, [validation])

    useEffect(() => {
        updateDropOffAddressType(dropoffMethodValue);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropoffMethodValue]);

    return <div>
        <SubTitle className="mt-6 mb-1">
            Drop Off Location Type
        </SubTitle>



        <div className={''}>
            <FormControl component="fieldset">
                <RadioGroup row value={dropoffMethodValue} onChange={optionHandle}>
                    <RadioOption
                        selectedValue={dropoffMethodValue}
                        value={dropoffType.HOME}
                        label={'Home'} />

                    <RadioOption
                        selectedValue={dropoffMethodValue}
                        value={dropoffType.BUSINESS}
                        label={'Business'} />

                    <RadioOption
                        selectedValue={dropoffMethodValue}
                        value={dropoffType.SCHOOL}
                        label={'School'} />

                    <RadioOption
                        selectedValue={dropoffMethodValue}
                        value={dropoffType.HOSPITAL}
                        label={'Hospital'} />
                    
                    <RadioOption
                        selectedValue={dropoffMethodValue}
                        value={dropoffType.APARTMENT}
                        label={'Apartment Building'} />
                </RadioGroup>
            </FormControl>
        </div>
    </div>;
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    updateDropOffAddressType: (data) => dispatch(updateDropOffAddressType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropOffAddressTypeSection);
