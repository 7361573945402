import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
`
export const Col = styled.div`
  ${tw`flex flex-col items-center justify-between`}
`

export const Row = styled.div`
  ${tw`flex flex-row items-center items-baseline`}
`

export const FieldContainer = styled.form`
  ${tw`flex flex-col w-auto md:w-8/12 self-center mt-14 max-w-sm md:max-w-lg`}
  ${props => props.noMargin && tw`mt-0`}
`
export const FieldContainerNoMargin = styled(FieldContainer)`
  ${tw`mt-0`}
`
export const Flex = styled.div`
  ${tw`flex`}
`
export const Center = styled.div`
  ${tw`flex items-center justify-center`}
`
export const Grid2 = styled.div`
  ${tw`grid grid-cols-2`}
  ${props => !props.error && tw`border-2 border-errorRed`}
`
export const SizeTitle = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
`