import { Tooltip, withStyles } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export const InputContainer = styled.div`
  ${tw`flex flex-col mt-3 last:mb-6`}
  max-width: 420px;
`
export const StyledInput = styled.input`
  ${tw`border-b pb-1 focus:outline-none focus:border-primaryBlue`}
  ${props => props.error && tw`border-errorRed`}
`
export const StyledLabel = styled.label`
  ${tw`mb-1`}
  color: #000000;
  font-family: Varela Round;
  font-size: 13px;
  ${props => props.error && tw`text-errorRed`}
  ${props => props.blackColor && tw`text-black`}
`

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#dddddd',
    color: 'black',
    width: 'auto',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    textAlign: 'center'
  },
}))(Tooltip);

function InputField(props) {
  const { id, label, type, onChange, onBlur, value, error, helperText, required } = props
  return (
    <InputContainer>
      <StyledLabel error={error} htmlFor={id}>{required ? `${label} *` : label}</StyledLabel>
      <StyledInput error={error} type={type} id={id} name={id} onBlur={onBlur} onChange={onChange} value={value} />
      {error && <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>{helperText}</p>}
    </InputContainer>
  )
}

export default InputField
