
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import styled from 'styled-components'
import tw from "twin.macro";
import {  apiKeyAtom, editingCardAtom } from "../../../App";
import { getURI } from "../../../helpers/apiHelpers";
import { Divider } from "../../Shipment/ShipmentDetails";

const Input = styled.input`  
  ${tw`rounded-md  pl-12 pr-2 py-2 border-2 border-gray-200 placeholder-gray-300`}
`

const Submit = styled.button`
  font-size: 16px;
  color: white;
  font-family: Varela Round;
  border-radius: 3px;
  ${tw`bg-primaryBlue h-12 mt-4 px-8`}
  ${props => props.disabled && tw`text-fontBlue bg-disabled cursor-not-allowed`}
`

const PaymentForm = () => {



    // eslint-disable-next-line no-unused-vars
    const [apiKey, setApiKey] = useRecoilState(apiKeyAtom);
    const [editingCard, setEditingCard] = useRecoilState(editingCardAtom);


    const refresh = () => {
        window.location.reload();
    }




    const { setValue, register, handleSubmit,  formState: { errors } } = useForm();
    const onSubmit = (data) => {
        // console.log('submitting');
        // console.log(data);
        /**
         * Cleans spaces
         */
        if (!editingCard?.card_id) {
            data.card = data.card.replace(/\s/g, '');
        }
        data.postal_zip = data.postal_zip.replace(/\s/g, '');

        data.number = data.card;
        //console.log(data)

        /**
         * Get a bambora token
         */

        const bamboraConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        };

        data.number = data.card;
        data.api_key = apiKey;

        //console.log(data);
        //return false;


        if (editingCard?.card_id) {



            axios
                .patch(getURI(`/api/web/card/${editingCard.card_id}`), data, config)
                .then(response => {
                    //console.log(response);
                    refresh();
                })
                .catch(error => {
                    console.log(error);
                })



        } else {
            axios
                .post(getURI('/api/web/token'), data, bamboraConfig)
                .then(response => {
                    // console.log('bambora response');
                    // console.log(response);
                    if (response.data.hasOwnProperty('token')) {
                        data.token = response.data.token;
                        axios
                            .post(getURI('/api/web/card'), data, config)
                            .then(response => {
                                //console.log(response);
                                refresh();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    }
                }).catch(error => {
                    console.log(error);
                });

        }



    };

    const onChangeHandler = (data) => {
        const newValue = cardNumberFormatter(cardValue, data.target.value);
        setCardValue(newValue);
        setValue('card', newValue);
    }

    const cardNumberFormatter = (oldValue, newValue) => {
        // user is deleting so return without formatting
        if (oldValue.length > newValue.length) {
            return newValue;

        }

        return newValue
            .replace(/\W/gi, '')
            .replace(/(.{4})/g, '$1 ')
            .substring(0, 19);
    }
    const [cardValue, setCardValue] = useState('');

    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');

    useEffect(() => {
        if (editingCard?.card_id) {
            // console.log('we have card Id: ');
            // console.log(editingCard.card_id);
            setCardValue(editingCard.number);
            setExpiryMonth(editingCard.expiry_month);
            setExpiryYear(editingCard.expiry_year);
            setValue('card', editingCard.number);
            setValue('expiry_month', editingCard.expiry_month);
            setValue('expiry_year', editingCard.expiry_year);
        } else {
            setCardValue('');
            setExpiryMonth('');
            setExpiryYear('');
            setValue('card', '');
            setValue('expiry_month', '');
            setValue('expiry_year', '');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingCard]);

    const handleCancel = () => {
        //console.log('handling cancel');
        setEditingCard({})
    }


    return (<>
        <form className="flex flex-wrap gap-3 w-full p-5" onSubmit={handleSubmit(onSubmit)}>
            <label className="relative w-full flex flex-col">
                <span className="font-bold mb-3">Card number</span>
                <Input

                    //value={ cardValue }
                    type="text"
                    placeholder="0000 0000 0000"
                    {...register("card",
                        { disabled: editingCard?.card_id ? true : false, required: true, maxLength: 19, minLength: 16, onChange: (e) => { onChangeHandler(e) }, value: cardValue })} />

                <svg xmlns="http://www.w3.org/2000/svg" className="absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-black peer-placeholder-shown:text-gray-300 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                </svg>
            </label>

            <label className="relative flex-1 flex flex-col">
                <span className="font-bold mb-3">Expire month</span>
                <Input
                    type="text"
                    placeholder="MM"
                    {...register("expiry_month", { required: true, maxLength: 2, minLength: 2, onChange: (e) => { setExpiryMonth(e.target.value) }, value: expiryMonth })} />

                <svg xmlns="http://www.w3.org/2000/svg" className="absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-black peer-placeholder-shown:text-gray-300 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
            </label>


            <label className="relative flex-1 flex flex-col">
                <span className="font-bold mb-3">Expire year</span>
                <Input
                    type="text"
                    placeholder="YY"
                    {...register("expiry_year", { required: true, maxLength: 2, minLength: 2, onChange: (e) => { setExpiryYear(e.target.value) }, value: expiryYear })} />
                <svg xmlns="http://www.w3.org/2000/svg" className="absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-black peer-placeholder-shown:text-gray-300 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
            </label>

            <label className="relative flex-1 flex flex-col">
                <span className="font-bold mb-3">Postal Code</span>
                <Input
                    type="text"
                    placeholder="H0H0H0"
                    {...register("postal_zip", { required: true, maxLength: 7, minLength: 5 })} />
                <svg xmlns="http://www.w3.org/2000/svg" className="absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-black peer-placeholder-shown:text-gray-300 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                </svg>
            </label>

            <label className="relative flex-1 flex flex-col">
                <span className="font-bold flex items-center gap-3 mb-3">
                    CVC/CVV
                    <span className="relative group">
                        <span className="hidden group-hover:flex justify-center items-center px-2 py-1 text-xs absolute -right-2 transform translate-x-full -translate-y-1/2 w-max top-1/2 bg-black text-white">On the back of the card</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </span>
                </span>
                <Input
                    type="text"
                    placeholder="&bull;&bull;&bull;"
                    {...register("cvd", { required: true, maxLength: 5, minLength: 3 })}
                />
                <svg xmlns="http://www.w3.org/2000/svg" className="absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-black peer-placeholder-shown:text-gray-300 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
            </label>

            <Divider />


            <Submit type="submit" >{editingCard?.card_id ? 'Submit Changes' : 'Add New'}</Submit>


        </form>
        {editingCard?.card_id && <Submit onClick={handleCancel}>Cancel</Submit>}
        <div>
            {errors.card && <><span style={{ color: 'red' }}>Card is required 16 numbers</span><br /></>}
            {errors.expiry_month && <><span style={{ color: 'red' }}>Expire month is required 2 numbers</span><br /></>}
            {errors.expiry_year && <><span style={{ color: 'red' }}>Expire year is required 2 numbers</span><br /></>}
            {errors.postal_zip && <><span style={{ color: 'red' }}>Postal Code is required 5 - 6 numbers/letters</span><br /></>}
            {errors.cvd && <><span style={{ color: 'red' }}>CVC/CVV is required 2 - 5 numbers</span><br /></>}
        </div>
    </>);



}

export default PaymentForm;