import GooglePlayStore from '../../../assets/icons/google-play-store.png'
import AppleStore from '../../../assets/icons/apple-store.png'
import { Flex } from '../../../components/containers'

function AppStoreIcon() {
    return (
        <div>
            <div className='mt-1'>Get Deeleeo as a mobile app</div>
            <Flex>
                <div className="sqs-col sqs-col-2">
                    <a href="https://apps.apple.com/us/app/id1503993847">
                        <img src={AppleStore} alt='' style={{width:'160px',height:'50px'}} />
                    </a>
                </div>

                <div className="sqs-col sqs-col-2">
                    <a href="https://play.google.com/store/apps/details?id=com.deeleeo.user">
                    <img src={GooglePlayStore} alt='' style={{width:'160px',height:'50px'}} />
                    </a>
                </div>
            </Flex>
        </div>
    )
}
export default AppStoreIcon