import { updateUserInfo } from './userActions'
import { getURI } from '../../helpers/apiHelpers'
import axios from 'axios'

export const authActions = Object.freeze({
  UPDATE_USER: 'UPDATE_USER',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT: 'LOGOUT',

  CLEAR_LOGIN_ERROR: 'CLEAR_LOGIN_ERROR',

  FORGOT_REQUEST: 'FORGOT_REQUEST',
  FORGOT_SUCCESS: 'FORGOT_SUCCESS',
  FORGOT_FAILURE: 'FORGOT_FAILURE',
  CLEAR_FORGOT_STATUS: 'CLEAR_FORGOT_STATUS',
  UPDATE_OTP: 'UPDATE_OTP',

  RESET_REQUEST: 'RESET_REQUEST',
  RESET_SUCCESS: 'RESET_SUCCESS',
  RESET_FAILURE: 'RESET_FAILURE',

  CLEAR_AUTH_STATUS: 'CLEAR_AUTH_STATUS',
  CLEAR_REGISTER_ERROR: 'CLEAR_REGISTER_ERROR'
})

export const clearAuthStatus = () => ({ type: authActions.CLEAR_AUTH_STATUS })
export const clearAllState = () => ({ type: 'CLEAR_STATE' })
export const clearRegisterError = () => ({ type: authActions.CLEAR_REGISTER_ERROR })

export const logout = () => dispatch => {
  const logOut = () => ({ type: authActions.LOGOUT })

  dispatch(logOut())
}


export const registerUser = (requestData) => dispatch => {
  const request = () => ({ type: authActions.REGISTER_REQUEST })
  const success = () => ({ type: authActions.REGISTER_SUCCESS })
  const failure = (error) => ({ type: authActions.REGISTER_FAILURE, data: error })

  dispatch(request())

  fetch(getURI('/api/web/user-sign-up'), {
    method: 'POST',
    body: JSON.stringify(requestData),
    headers: {
      'Content-Type': 'application/json',
    }

  })
    .then(response => response.text())
    .then(response => {
      if (response.includes('Your email is existing in the system.')) {
        dispatch(failure(response))
      } else {
        const { first_name, last_name, mobile, email } = requestData
        const userInfo = {
          firstName: first_name,
          lastName: last_name,
          mobile,
          email,
          businessName: requestData.business_name,
          // apiCode: api_code
        }
        dispatch(updateUserInfo(userInfo))

        dispatch(success())

        dispatch(login({
          username: requestData.email,
          password: requestData.password
        }))

      }
    })
}

export const forgotPassword = (requestData) => dispatch => {
  const request = () => ({ type: authActions.FORGOT_REQUEST })
  const success = (data) => ({ type: authActions.FORGOT_SUCCESS, data: data })
  const failure = (error) => ({ type: authActions.FORGOT_FAILURE, data: error })

  dispatch(request())

  fetch(getURI('/api/user/forgot-password'), {
    method: 'POST',
    body: JSON.stringify(requestData),
    headers: {
      'Content-Type': 'application/json',
    }

  })
    .then(response => response.text())
    .then(response => {

      if (response.includes('Please check')) {
        const { data: { reset_password_token } } = JSON.parse(response)
        dispatch(success({ resetToken: reset_password_token, email: requestData.email }))
      } else {
        dispatch(failure('Account does not exist'))
      }
    })
}

export const clearForgotStatus = () => {
  return {
    type: authActions.CLEAR_FORGOT_STATUS
  }
}

export const updateOtp = (otp) => {
  return {
    type: authActions.UPDATE_OTP,
    data: otp
  }
}

export const resetPassword = (requestData) => dispatch => {
  const request = () => ({ type: authActions.RESET_REQUEST })
  const success = () => ({ type: authActions.RESET_SUCCESS })
  const failure = (error) => ({ type: authActions.RESET_FAILURE, data: error })

  dispatch(request())

  axios({
    method: 'post',
    url: getURI('/api/user/reset-password'),
    validateStatus: (status) => status === 200,
    data: requestData
  })
    .then(response => {
      // console.log(response)
      dispatch(success())
    })
    .catch(error => {
      console.log(error)
      dispatch(failure())
    })
}


export const clearLoginError = () => {
  return {
    type: authActions.CLEAR_LOGIN_ERROR
  }
}



export const login = (requestData) => dispatch => {
  const request = () => ({ type: authActions.LOGIN_REQUEST })
  const success = () => ({ type: authActions.LOGIN_SUCCESS })
  const failure = (error) => ({ type: authActions.LOGIN_FAILURE, data: error })

  dispatch(request())

  axios({
    method: 'post',
    url: getURI('/api/user/getMyBusinessApiCode'),
    validateStatus: (status) => status === 200,
    data: {
      username: requestData.username,
      password: requestData.password
    }
  })
    .then(response => {
      dispatch(updateUserInfo({ apiCode: response.data.api_code }))
      //dispatch(updateUserInfo({ apiCode: 'c33e5d8b-cbe8-70de-1b87-277bdc0833' }))
      dispatch(success())
    })
    .catch(error => {
      console.log(error)
      dispatch(failure('Invalid email or password'))
    })
}
