import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Header from '../components/Header'
import { connect } from 'react-redux'
import { logout } from '../redux/actions/authActions'
import { withRouter } from 'react-router-dom'
import downChevron from '../assets/icons/down-chevron.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { routes } from '../routes'

const LayoutContainer = styled.div`
  ${tw`w-full h-full flex flex-col`}
`
const PageContent = styled.div`
  ${tw`w-3/5 md:w-550 self-center pt-11`}
`
const PageContentWider = styled.div`
  ${tw`px-12 lg:px-20 w-full box-border self-center pt-11`}
`
const StyledSelect = styled.select`
  ${tw`text-fontBlue focus:outline-none appearance-none p-2 pr-5`}
  height: 36px;
  font-size: 14px;
  background-image: url(${downChevron});
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
`
const StyledName = styled.div`
  ${tw`mt-1 text-fontBlue font-medium`}
  font-size: 14px;
`

const AccountDropdown = (props) => {
  const { fullName, logout } = props;
  let history = useHistory();
  const handleChange = (e) => {
    if (e.target.value === 'logout') {
      logout()
    }

    if (e.target.value === 'favourites') {
      history.push(routes.favourites);
    }
  }
  return (
    <span className='ml-auto flex items-center'>
      <StyledSelect onChange={handleChange}>
        <option value='name'>{fullName}</option>
        {/* {isFavouriteAgentEnable && <option value='favourites'>Manage Favourite Agents</option>} */}
        <option value='logout'>Logout</option>
      </StyledSelect>
    </span>
  )
}

function PageLayout(props) {
  const { history, firstName, lastName, logout, deliveries, businessName, userData } = props
  const fullName = `${firstName !== undefined ? firstName : ''} ${lastName !== undefined ? lastName : ''}`


  return (
    <LayoutContainer>
      <Header deliveries={deliveries} history={history} />
      <div className='mx-20 flex'>
        <StyledName>{businessName}</StyledName>
        <AccountDropdown fullName={fullName} logout={logout} isFavouriteAgentEnable={userData.isFavouriteAgentEnable} />
      </div>
      {!props.wider && <PageContent>{props.children}</PageContent>}
      {props.wider && <PageContentWider>{props.children}</PageContentWider>}
    </LayoutContainer>
  )
}

const mapStateToProps = state => ({
  deliveries: state.deeleeosReducer.deeleeos.length,
  firstName: state.userReducer.firstName,
  lastName: state.userReducer.lastName,
  businessName: state.userReducer.businessName,
  userData: state.userReducer,
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageLayout))
