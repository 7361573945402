import React from "react";
import Modal from 'react-modal';

const LabelWarningModal = ({ contentLabel, modalIsOpen, setModalIsOpen }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const closeModal = () => {
        setModalIsOpen(false);
    }

    return (<Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={contentLabel}
    >

        <div className="" style={{ "width": "450px" }}>
            {/* <div className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" > */}
                <div className="mb-4">
                Select your desired printing order for the list of labels you selected:
                </div>
                <div className="flex items-center justify-between">
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={closeModal}>
                        close
                    </button>
                </div>
            {/* </div> */}

        </div>
        {/* <div className="inline-block">Cancelling: {cancelPayload?.deeleeo?.id}</div>
        <CloseButton onClick={closeModal}>X</CloseButton><br /> */}


    </Modal>);
}

export default LabelWarningModal;
