export const shipmentActions = Object.freeze({
  UPDATE_SENDER_INFO: 'UPDATE_SENDER_INFO',
  UPDATE_RECIPIENT_INFO: 'UPDATE_RECIPIENT_INFO',
  UPDATE_PACKAGE_INFO: 'UPDATE_PACKAGE_INFO',
  UPDATE_SHIPPING_INSTRUCTIONS: 'UPDATE_SHIPPING_INSTRUCTIONS',
  UPDATE_SHIPPING_OPTIONS: 'UPDATE_SHIPPING_OPTIONS',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  CLEAR_IMAGE: 'CLEAR_IMAGE',
  UPDATE_PICKUP: 'UPDATE_PICKUP',
  UPDATE_DROPOFF: 'UPDATE_DROPOFF',
  UPDATE_REFNUMBER: 'UPDATE_REFNUMBER',
  RESET_DEELEEO: 'RESET_DEELEEO',
  DUPLICATE_DEELEEO: 'DUPLICATE_DEELEEO',
  UPLOAD_IMAGE_FILES: 'UPLOAD_IMAGE_FILES'
})

export const resetDeeleeo = () => {
  return {
    type: shipmentActions.RESET_DEELEEO,
  }
}

export const duplicateDeeleeo = () => {
  return {
    type: shipmentActions.DUPLICATE_DEELEEO
  }
}

export const updateSenderInfo = (shipmentInfo) => {
  return {
    type: shipmentActions.UPDATE_SENDER_INFO,
    data: shipmentInfo
  }
}

export const updateRecipientInfo = (shipmentInfo) => {
  return {
    type: shipmentActions.UPDATE_RECIPIENT_INFO,
    data: shipmentInfo
  }
}

export const updatePackageInfo = (size, packageInfo) => {
  return {
    type: shipmentActions.UPDATE_PACKAGE_INFO,
    data: {
      size,
      packageInfo
    }
  }
}

export const updateShippingInstructions = (details) => {
  return {
    type: shipmentActions.UPDATE_SHIPPING_INSTRUCTIONS,
    data: { details }
  }
}

export const updateShippingOptions = (options) => {
  return {
    type: shipmentActions.UPDATE_SHIPPING_OPTIONS,
    data: { premiumOptions: options }
  }
}

export const uploadImages = (imageURLs) => {
  return {
    type: shipmentActions.UPLOAD_IMAGE,
    data: imageURLs
  }
}

export const uploadImageFiles = (imageFiles) => {
  return {
    type: shipmentActions.UPLOAD_IMAGE_FILES,
    data: imageFiles
  }
}


export const clearImage = (image) => {
  return {
    type: shipmentActions.CLEAR_IMAGE,
    data: image
  }
}

export const updatePickup = (pickupInfo) => {
  return {
    type: shipmentActions.UPDATE_PICKUP,
    data: { type: pickupInfo.type, date: pickupInfo.date, windowStart: pickupInfo.windowStart, windowClose: pickupInfo.windowClose }
  }
}

export const updateDropoff = (dropoffInfo) => {
  return {
    type: shipmentActions.UPDATE_DROPOFF,
    data: { type: dropoffInfo.type, date: dropoffInfo.date, windowStart: dropoffInfo.windowStart, windowClose: dropoffInfo.windowClose }
  }
}

export const updateRefNumber = (refNumber) => {
  return {
    type: shipmentActions.UPDATE_REFNUMBER,
    data: refNumber
  }
}




