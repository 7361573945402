import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { connect } from "react-redux";

import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { SubTitle } from "../../../components/text";
import InfoIcon from "../../../assets/icons/Info-icon.svg";
import { StyledToolTip } from "./shared";

import { updateOptions } from '../../../redux/reducers/deeleeoReducer'

const StyledLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    ${tw`text-fontBlue`}
    font-family: Montserrat;
    font-size: 13px;
  }
`;

const Label = () => {
    return (
        <div>
            <SubTitle className="mt-6 mb-3">
                Options
                <StyledToolTip
                    title={
                        <>
                            <div>
                                Extra options
                                <br className="mb-4" />
                            </div>
                        </>
                    }
                    placement={"top"} arrow>
                    <img src={InfoIcon} alt={"information"} />
                </StyledToolTip>
            </SubTitle>
        </div>
    );
};

const Option = (props) => {
    const { infoDetail, value, label, checked, checkboxHandle } = props
    return (
        <StyledToolTip
            title={<>
                <div>
                    {infoDetail}
                </div>
            </>
            }
            placement={"top"} arrow>

            <StyledLabel value={value} control={<Checkbox checked={checked} color="primary" />}
                label={label} onChange={checkboxHandle} labelPlacement="end" />
        </StyledToolTip>
    );
}

function OptionsSection(props) {
    const { updateOptions, options } = props;
    const [optionsValue, setOptionsValue] = useState(options);

    const checkboxHandle = (event) => {
        setOptionsValue({ ...optionsValue, [event.target.value]: event.target.checked })
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            updateOptions(optionsValue)
        }, 500);
        return () => clearTimeout(timeout);
    }, [optionsValue, updateOptions]);



    return (
        <div>
            <Label />
            <FormGroup aria-label="position" row>
                <Option
                    infoDetail='Use this option to submit your Deeleeos directly to your "favourite
    delivery agents". You must first add your favourite agents via your account
    profile in the top right corner of this page. Upon adding favourite agents
    to your account, if you use this option, these delivery agents will have 15
    mins to accept your Deeleeo requests. After 15 mins the Deeleeo orders will
    be submitted to the rest of the Deeleeo delivery agents. There is a small
    premium to use this service which can be seen on the Order Summary page.'
                    value='is_assign_favourite_driver'
                    label='Assign Favourite Driver'
                    checked={optionsValue.is_assign_favourite_driver}
                    checkboxHandle={checkboxHandle} />
            </FormGroup>
        </div>
    );
}

const mapStateToProps = (state) => ({
    options: state.deeleeoReducer.is_assign_favourite_driver
});

const mapDispatchToProps = (dispatch) => ({
    updateOptions: (options) => dispatch(updateOptions(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionsSection);
