import React from 'react'
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core'
import styled from 'styled-components'
import tw from 'twin.macro'
import { BlueTitleBig, LargerText } from './text'

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border: 6px solid #c0caef;
    ${tw`py-6`}
  }
`
const StyledContentText = styled(DialogContentText)`
  ${tw`flex flex-col justify-center items-center`}
`

const Saving = styled.div`
  @keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
  }
  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  span:nth-child(2) {
    animation-delay: .2s;
  }
  span:nth-child(3) {
    animation-delay: .4s;
  }
`
const Dot = styled.span`
  ${tw`h-3 w-3 mx-2 mt-8 inline-block rounded-full`}
  background-color: #a1a0a6;
`

function Loading(props) {
  return (
    <StyledDialog
      fullWidth={true}
      maxWidth={'xs'}
      open={props.open}
      aria-labelledby="loading"
    >
      <DialogContent>
        <StyledContentText>
          <BlueTitleBig>Your order is being submitted</BlueTitleBig>
          <LargerText className='text-center'>This may take up to a minute while <br/> payment is being processed</LargerText>
          <Saving><Dot/><Dot/><Dot/></Saving>
        </StyledContentText>
      </DialogContent>
    </StyledDialog>
  )
}

export default Loading
