import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import editIcon from '../../../assets/icons/edit-icon.svg'
import InputField from '../../../components/InputField'
import { updatePickupData, updateDropoffData } from '../../../redux/reducers/pickupDropoffReducer'
import { HelperText, SubTitle } from '../../../components/text'
import AddressField from '../../../components/AddressField'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Flex } from '../../../components/containers'
// import { checkDistanceWithinRadius } from '../../../helpers/addressHelpers'
import What3WordsField from '../../../components/What3WordsField'
import UserContactSelect from '../../../components/UserContactSelect'
import { useRecoilState } from 'recoil'
import { apiKeyAtom } from '../../../App'
import axios from 'axios'
import { getURI } from '../../../helpers/apiHelpers'
import { StyledToolTip } from './shared'
import { Checkbox, FormControlLabel } from '@material-ui/core'


export const FormContainer = styled.form`
${tw` w-full `}
`

export const InformationContainer = styled.div`
  ${tw` w-full flex flex-row`}
`

export const InformationSubContainer = styled.div`
  ${tw` w-5/12  `}
`

const SaveButton = styled.button`
  ${tw`text-primaryBlue border flex justify-center items-center`}
  font-size: 12px;
  border-color: rgb(221, 221, 221);
  width: 52px;
  height: 26px;
`

export const TitleSpan = styled.span`
  ${tw`flex ml-6 flex items-center`}
  cursor:pointer;
`

const DisplayField = styled.div`
  ${tw`text-fontBlue mb-2`}
  font-family: Varela Round;
  font-size: 16px;
  font-weight:bold;
  text-transform: ${props => props.uppercase && 'uppercase'};
`

const EditButton = (props) => {
  const { editMode, setEditMode } = props
  return (
    <TitleSpan>
      {!editMode && <Flex onClick={() => setEditMode(!editMode)}>
        <img src={editIcon} alt={'pencil icon'} />
        <HelperText>Edit</HelperText>
      </Flex>}
      {editMode && <>
        <SaveButton type='submit'>Save</SaveButton>
      </>}
    </TitleSpan>
  )
}

const StyledLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    ${tw`text-fontBlue`}
    font-family: Montserrat;
    font-size: 13px;
  }
`;

function ShipmentInformationFullWidth(props) {
  const { shipmentInfo, title, isSender, saveInfoCheckbox, updatePickup, updateDropoff, error } = props
  const hasShipmentInfo = shipmentInfo !== undefined

  const [editMode, setEditMode] = useState(shipmentInfo.address === '' ? true : false);

  // eslint-disable-next-line no-unused-vars
  const [apiKey, setApiKey] = useRecoilState(apiKeyAtom);
  const [saveContact, setSaveContact] = useState(false);

  /**
   * To handle suggestions generated by the W3W component.
   * Note: These are google suggestions
   */
  const [w3wValues, setW3wValues] = useState([]);
  const cleanW3WValues = () => {
    setW3wValues([]);
  }

  const emailValidation = isSender ? yup.string().email('Enter a valid email address').required('Email is required') : yup.string().email('Enter a valid email address');

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    address: yup.string().when('w3w', {
      is: (w3w) => !w3w || w3w.length === 0,
      then: yup.string().required('Address is required'),
      otherwise: yup.string()
    }),
    w3w: yup.string().when('address', {
      is: (address) => !address || address.length === 0,
      then: yup.string().required('w3w is required'),
      otherwise: yup.string()
    }),
    phonenumber: yup.string().phone('CA', false, 'Phone number must be valid'),
    email: emailValidation
  }, [['address', 'w3w'], ['w3w', 'address']]);

  const formik = useFormik({
    initialValues: {
      name: hasShipmentInfo ? props.shipmentInfo.name : '',
      address: hasShipmentInfo ? props.shipmentInfo.address : '',
      w3w: hasShipmentInfo ? props.shipmentInfo.w3w : '',
      unit: hasShipmentInfo ? props.shipmentInfo.unit : '',
      phonenumber: hasShipmentInfo ? props.shipmentInfo.phonenumber : '',
      email: hasShipmentInfo ? props.shipmentInfo.email : '',
      latitude: hasShipmentInfo ? props.shipmentInfo.latitude : '',
      longitude: hasShipmentInfo ? props.shipmentInfo.longitude : ''
    },
    validationSchema: validationSchema,
    onSubmit: (values => {
      setEditMode(!editMode)
      submitContact();

    })
  })

  const submitContact = () => {
    if (saveContact) {
      // console.log(formik);
      const payload = { ...formik.values, api_code: apiKey, is_pickup: isSender, is_dropoff: !isSender, phone: formik.values.phonenumber };
      // return false
      axios
        .post(getURI(`/api/web/user-contact`), payload)
        .then((response) => {
          // console.log(response);
          window.location.reload(false);
        }).catch((error) => {
          console.log(error);
        })
    }
  }

  const handleAddressChange = (addressInfo) => {

    const { city, lat, lng, street, province } = addressInfo;
    


    const payload = { lat: lat, lon: lng };

    axios
      .post(getURI(`/api/web/get-zone-from-coordinate`), payload)
      .then((response) => {
        // console.log(response);

        formik.setValues({
          ...formik.values,
          address: street + ', ' + city + ' ' + province,
          latitude: lat,
          longitude: lng
        })

      })
      .catch((error) => {
        console.log(error);

        formik.setFieldError('address', 'This address is not currently serviced by Deeleeo');
      })





  }

  const handleW3WChange = (data) => {


    const { w3w, google } = data;
    const { coordinates, words } = w3w;

    const payload = { lat: coordinates.lat, lon: coordinates.lng };

    
    axios
      .post(getURI(`/api/web/get-zone-from-coordinate`), payload)
      .then((response) => {

        // console.log(response);
        /**
      * If we can service the location, we save the google options to be displayed
      */
        setW3wValues(google);
        /**
         * Also save w3w address and coords to the deeleeo
         */
        formik.setValues({
          ...formik.values,
          address: '///' + words,
          latitude: coordinates.lat,
          longitude: coordinates.lng
        })

      })
      .catch(error => {
        console.log(error);
        formik.setFieldError('w3w', 'This location is not currently serviced by Deeleeo')

      });

  

  }

  const handleUserAddressChange = (data) => {

    setEditMode(false);
    formik.setValues({
      ...formik.values,
      name: data.name,
      address: data.address,
      unit: data.unit ? data.unit : '',
      phonenumber: data.phone,
      email: data.email,
      latitude: data.latitude,
      longitude: data.longitude
    })
  }

 

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Object.keys(formik.errors).length === 0) {
        isSender ? updatePickup(formik.values) : updateDropoff(formik.values)
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [formik.errors, formik.values, isSender, updateDropoff, updatePickup])

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <UserContactSelect
        isSender={isSender}
        onChange={handleUserAddressChange}
      />
      <InformationContainer>
        <InformationSubContainer>
          <SubTitle >
            {title}
            {!saveInfoCheckbox && <div className='w-full'><EditButton errors={formik.errors} editMode={editMode} setEditMode={setEditMode} /></div>}
            {!saveInfoCheckbox && editMode && <div className='w-full'>
              <StyledToolTip
                title={
                  <div>
                    {'Save it to your contacts.'}
                  </div>
                }
                placement={"top"} arrow>

                <StyledLabel
                  value={isSender ? 'is_pickup' : 'is_dropoff'}
                  control={<Checkbox
                    checked={saveContact}
                    color="primary" />
                  }
                  label={isSender ? 'Save as Pickup Contact' : 'Save as Drop off Contact'}
                  onChange={() => { setSaveContact(!saveContact) }}
                  labelPlacement="end" />
              </StyledToolTip>
            </div>}
          </SubTitle>

          {!error &&
            <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
              Please Check Your Data
            </p>
          }
        </InformationSubContainer>
      </InformationContainer>
      <br />


      {!editMode && <>
        <InformationContainer>
          <InformationSubContainer>
            <DisplayField uppercase>{formik.values.name}</DisplayField>
            <DisplayField>{formik.values.phonenumber}</DisplayField>
            <DisplayField>{formik.values.address}</DisplayField>
          </InformationSubContainer>
          <InformationSubContainer>
            <DisplayField>{formik.values.email}</DisplayField>
            <DisplayField>{formik.values.unit}</DisplayField>
          </InformationSubContainer>

        </InformationContainer>
        <hr />
      </>}

      {editMode && <>
        <InformationContainer>
          <InformationSubContainer>
            <InputField required id={'name'} type={'text'} label={isSender ? 'Shipper name' : 'Recipient name'} onBlur={formik.handleBlur} helperText={formik.errors.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              onChange={formik.handleChange} value={formik.values.name} />

            <InputField required id={'phonenumber'} type={'text'} label={'Phone'} onBlur={formik.handleBlur} helperText={formik.errors.phonenumber}
              error={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}
              onChange={formik.handleChange} value={formik.values.phonenumber} />

            <AddressField
              required
              id={'address'}
              label={isSender ? 'Pickup location' : 'Drop off location'}
              defaultValue={formik.values.address}
              onChange={handleAddressChange}
              onBlur={formik.handleBlur}
              helperText={formik.errors.address}
              error={formik.touched.address && Boolean(formik.errors.address)}
              values={w3wValues}//To pass external values to the component
              cleanValues={cleanW3WValues}//To clean these external values from the component
            />
          </InformationSubContainer>

          <InformationSubContainer>

            <InputField required={isSender} id={'email'} type={'text'} label={'Email'} onBlur={formik.handleBlur} helperText={formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              onChange={formik.handleChange} value={formik.values.email} />

            <What3WordsField
              id={'w3w'}
              label={isSender ? 'W3W Pickup location (optional)' : 'W3W Drop off location (optional)'}
              onChange={handleW3WChange}
            //onBlur={formik.handleBlur}
            />

            <InputField id={'unit'} label={isSender ? 'Pickup unit' : 'Drop off unit'} onBlur={formik.handleBlur} defaultValue={formik.values.unit} helperText={formik.errors.unit}
              error={formik.touched.unit && Boolean(formik.errors.unit)} onChange={formik.handleChange} value={formik.values.unit} />
          </InformationSubContainer>
        </InformationContainer>
      </>}
    </FormContainer>
  )
}

const mapStateToProps = state => ({
  apiCode: state.userReducer.apiCode,
  regions: state.userReducer.servicedRegions
})

const mapDispatchToProps = dispatch => ({
  updatePickup: (shipmentInfo) => dispatch(updatePickupData(shipmentInfo)),
  updateDropoff: (shipmentInfo) => dispatch(updateDropoffData(shipmentInfo))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentInformationFullWidth)
