import styled from "styled-components";
import tw from "twin.macro";

export const TableShadow = styled.div`
  ${tw`shadow p-4`}
`

export const ActionButton = styled.button`
  ${tw`justify-center items-center w-full pb-2 rounded-full align-middle my-1`}
  font-size: 10px;
  height: 20px;
  line-height:20px;
  ${tw`bg-primaryBlue`}
  ${tw`text-white`}
  ${props => props.warn && tw`bg-red-600`}
  ${props => props.yellow && tw`bg-yellow-300`}
  ${props => props.yellow && tw`text-black`}
`

export const Styles = styled.div`
  ${tw`w-full mt-4 text-fontBlue`}
  table {
    ${tw`w-full`}
    height: 100%;
  }

  tr {
    ${tw`border-b-2 border-gray-300`}
    td {
      height: 67px;
    }
  }

  th {
    ${tw`text-primaryBlue font-bold uppercase text-left`}
    font-family: Montserrat;
    font-size: 13px;
    height: 32px;
    white-space:nowrap;
    padding: 0 5px;
  }

  td {
    font-size: 14px;
    font-family: Varela Round;
  }
`