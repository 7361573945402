import React from "react";
import { useForm } from "react-hook-form";
import Modal from 'react-modal';
// import styled from 'styled-components'
// import tw from 'twin.macro';
import { CheckCircleOutlineOutlined,NewReleasesOutlined } from '@material-ui/icons';

// const CloseButton = styled.button`
//   ${tw`flex justify-center  w-auto float-right rounded-full align-middle  mx-5 inline-block pb-2 bg-red-500 text-white hover:bg-blue-700`}
//   font-size: 12px;
//   width: 20px;
//   height: 20px;
//   line-height:20px;

// `

const CancelModal = ({ contentLabel, modalIsOpen, setModalIsOpen, cancelPayload, cancelDeeleeo, cancelStatus }) => {


    // console.log(cancelPayload);
    // console.log(cancelStatus);
    //const [modalIsOpen, setIsOpen] = useState(false);

    // const openModal=()=> {
    //     setIsOpen(true);
    // }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };



    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = (data) => {

        cancelPayload.deeleeo_id = cancelPayload.deeleeo.id;
        cancelPayload.cancellation_reason_id = data.cancel_reason;
        cancelPayload.note = data.cancel_comment;
        cancelDeeleeo(cancelPayload);
    }


    return (<Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={contentLabel}
    >

        <div className="" style={{ "width": "450px" }}>
            <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                    <span>Cancelling: {cancelPayload?.deeleeo?.id}</span>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" for="cancel_reason">
                        * Cancellation Reason
                    </label>
                    <select defaultValue="1" {...register("cancel_reason")}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="cancel_reason" >
                        <option value="1">I changed my mind</option>
                        <option value="2">Not needed anymore</option>
                        <option value="3">Too expensive</option>
                        <option value="4">Nobody came/Too slow</option>
                    </select>
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" for="cancel_comment">
                        * Comment
                    </label>
                    <textarea
                        defaultValue=""
                        autoComplete="off"
                        rows="5"
                        spellCheck="false"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="cancel_comment"
                        {...register("cancel_comment", { required: true })}
                    >


                    </textarea>
                    {errors.cancel_comment?.type === 'required' && <p className="text-red-500 text-xs italic">Comment is required.</p>}
                </div>
                {
                    cancelPayload?.deeleeo?.status === 3 &&
                    <div className="mb-6">
                        <p className="text-red-500 text-xs italic">Cancellation Penalty: {cancelPayload?.deeleeo?.cancelFee }</p>
                    </div>
                }
                <div className="flex items-center justify-between">

                    {(cancelStatus === 'success' || cancelStatus === 'error') && <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={closeModal}>
                        close
                    </button>}
                    {(cancelStatus !== 'submitting' && cancelStatus !== 'success') && <input className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" />}
                    {cancelStatus === 'submitting' && <div style={{ borderTopColor: "transparent" }} className="w-16 h-16 border-4 border-blue-400 border-dotted rounded-full animate-spin"></div>}
                    {cancelStatus === 'success' && <> <span className="text-green-600 font-bold">Success!!! </span> <CheckCircleOutlineOutlined style={{ fill: "green" }} /></>}
                    {cancelStatus === 'error' && <> <span className="text-red-600 font-bold">Error!!! </span> <NewReleasesOutlined style={{ fill: "red" }} /></>}

                </div>
            </form>
        </div>
        {/* <div className="inline-block">Cancelling: {cancelPayload?.deeleeo?.id}</div>
        <CloseButton onClick={closeModal}>X</CloseButton><br /> */}

        {/* <form >
            <input {...register('name', { required: true })} />
            <input {...register('name', { required: true })} />
            <input type="submit" />
        </form> */}
    </Modal>);
}

export default CancelModal;