import React from 'react'
import { BlueTitleBig, LargerText } from '../../../components/text'
import { BackButton, Button } from '../../../components/buttons'
import { FieldContainer, Flex } from '../../../components/containers'
import * as yup from 'yup'
import { useFormik } from 'formik'
import FloatingInputField from '../../../components/FloatingInputField'
import leftChevron from '../../../assets/icons/left-chevron.svg'

function EnterOtp(props) {
  const { forgotEmail, updateOtp, clearForgotStatus } = props

  const validationSchema = yup.object({
    OTP: yup.string().min(6).max(6).required('OTP required'),
  })

  const formik = useFormik({
    initialValues: {
      OTP: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values => {
      updateOtp(values.OTP)
    })
  })

  return (
    <FieldContainer noMargin className='justify-center h-full' onSubmit={formik.handleSubmit}>
      <BlueTitleBig className='mb-8'>Email sent to {forgotEmail}</BlueTitleBig>
      <LargerText>Please check your email for a passcode to reset your password. If you don't see it in your in box, check your spam folder.</LargerText>
      <LargerText className='mt-5 mb-6'>Enter your one time passcode below:</LargerText>
      <FloatingInputField required id={'OTP'} label={'One time passcode'}
                          error={(formik.touched.OTP && Boolean(formik.errors.OTP))}
                          helperText={formik.touched.OTP && formik.errors.OTP}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}/>
      <Flex>
        <BackButton onClick={() => clearForgotStatus()}>
          <span><img src={leftChevron} alt={'left arrow'}/></span>
          Back
        </BackButton>
        <Button className='w-44' type='submit'>Next</Button>
      </Flex>
    </FieldContainer>
  )
}

export default EnterOtp
