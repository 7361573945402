import { useMemo } from "react";
import { ActionButton, TableShadow } from "../../../components/styledTableHelpers";
import { useTable } from "react-table";
import React from "react";


const DriverTableGeneric = (props) => {

    const { data, handleEdit } = props;


    const columns = useMemo(() =>
        [
            {
                Header: 'Name',
                accessor: 'name'
            },
            // {
            //     Header: 'Agent',
            //     accessor: 'image_profile'
            // },
            {
                Header: 'Actions',
                accessor: 'fav'
            }
        ], []
    )



    const tableInstance = useTable({ columns, data });



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (<TableShadow>
        <table {...getTableProps()} >
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {

                            console.log(column);
                            switch (column.Header) {
                                case 'Actions': {
                                    return <th>
                                       <div style={{  textAlign: 'center' }}>Actions</div>
                                    </th>
                                }
                                default:
                                    return <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                            }
                           
                        }
                        )}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                switch (cell.column.Header) {

                                    // case 'Agent':
                                    //     return (
                                    //         <td {...cell.getCellProps()}>
                                    //             {!row.original.fav && <img height={40} width={40} alt="" src={cell.value} />}
                                    //             {row.original.fav && <Flex><img height={40} width={40} alt="" src={cell.value} /> <CheckBoxTwoTone className="float-right" style={{ fill: "green" }} /></Flex>}
                                    //         </td>
                                    //     )

                                    case 'Actions':
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.value && <ActionButton style={{ width: 300, margin: 'auto', display: 'block' }} yellow onClick={() => handleEdit(row)}>Remove</ActionButton>}
                                                {!cell.value && <ActionButton style={{ width: 300, margin: 'auto', display: 'block' }} onClick={() => handleEdit(row)}>Add</ActionButton>}
                                            </td>
                                        )
                                    default:
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                }

                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </TableShadow>);
}

export default DriverTableGeneric;