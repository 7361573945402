import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { useRecoilState } from "recoil";
import { apiKeyAtom, editingCardAtom } from "../../../App";
import { ActionButton, Styles, TableShadow } from "../../../components/styledTableHelpers";
import { getURI } from "../../../helpers/apiHelpers";

const Cards = () => {

    const [cards, setCards] = useState([]);
    const data = cards;

    // eslint-disable-next-line no-unused-vars
    const [apiKey, setApiKey] = useRecoilState(apiKeyAtom);
    // eslint-disable-next-line no-unused-vars
    const [editingCard, setEditingCard] = useRecoilState(editingCardAtom);

    /**
     * Load Cards
     */
    useEffect(() => {
        if (apiKey && apiKey !== '') {
            axios
                .get(getURI('/api/web/card') + `?api_key=${apiKey}`)
                .then((response) => {
                    if (response.data?.data?.cards) {
                        setCards(response.data.data.cards);
                    }

                }).catch((error) => {
                    console.log(error);
                })
        }
    }, [apiKey])


    const columns = useMemo(() =>
        [
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Number',
                accessor: 'number'
            },
            {
                Header: 'Exp Month',
                accessor: 'expiry_month'
            },
            {
                Header: 'Exp Year',
                accessor: 'expiry_year'
            }
            ,
            {
                Header: 'Actions',
                accessor: ''
            }
        ], []
    )

    const tableInstance = useTable({ columns, data })

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    /**
     * Handles removing a card
     * @param {*} row 
     */
    const handleRemove = (row) => {
        const removePayload = {
            api_key: apiKey
        };
        /**
         * Remove from cards
         */
        axios.delete(getURI(`/api/web/card/${row.original.card_id}?` + new URLSearchParams(removePayload)))
            .then((result) => {
                window.location.reload();
            })
            .catch(e => console.log(e));
    }

    /**
     * Sets a Card to be editable
     * @param {*} row 
     */
    const handleEdit = (row) => {
        setEditingCard(row.original);
    }

    return (<>
        <Styles>
            <TableShadow>
                <table {...getTableProps()} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        switch (cell.column.Header) {

                                            /**
                                             * Add the buttons to the actions column
                                             */
                                            case 'Actions':
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        <ActionButton onClick={() => handleRemove(row)}>Remove</ActionButton>
                                                        <ActionButton onClick={() => handleEdit(row)}>Edit</ActionButton>
                                                    </td>
                                                )
                                            default:
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                        }

                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </TableShadow>
        </Styles>
    </>);

}

export default Cards;