import React, { useEffect, useState } from "react";
import {
  // useLoadScript,
  GoogleMap,
  Marker,
  // Polyline,
  InfoWindow
} from "@react-google-maps/api";
import ErrorBoundary from "../../../helpers/ErrorBoundary";


const UserContactsMap = (props) => {
  const { activeAddress } = props;

  // /**
  //  * Load google map script
  //  */
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: 'AIzaSyAiTYNynAOh-qIxDDKSGsuQGrSPxcduCZo' // ,
  //   // ...otherOptions
  // })


  // eslint-disable-next-line no-unused-vars
  const [center, setCenter] = useState({
    lat: activeAddress ? activeAddress.latitude : 53.541427,
    lng: activeAddress ? activeAddress.longitude : -113.500434
  });





  /**
   * Map reference
   */
  const [map, setMap] = useState(null);


  /**
   * When changing the view, we reset the map center and zoom level
   */
  useEffect(() => {

    if (map) {
      map.panTo(center);
      map.setZoom(13);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center.lat, center.lng]);


  const containerStyle = {

    height: '400px'
  };

  const windowTitleStyle = {
    fontWeight: 'bold'
  }

  const renderMap = () => {


    return (
      <GoogleMap
        mapContainerStyle={containerStyle}

        zoom={11}
        center={center}
        onLoad={map => setMap(map)}//To use a map reference
      >
        {activeAddress && <ErrorBoundary>
          <Marker
            position={{ lat: activeAddress.latitude, lng: activeAddress.longitude }}
          />
          <InfoWindow
            options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
            position={{ lat: activeAddress.latitude, lng: activeAddress.longitude }}
          >
            <div>
              <div><span style={windowTitleStyle}>Name: </span>{activeAddress.name}</div>
              <div><span style={windowTitleStyle}>Address: </span>{(activeAddress.unit ? activeAddress.unit + ', ' : '') + activeAddress.address}</div>
              <div><span style={windowTitleStyle}>Phone: </span>{activeAddress.phone}</div>
              <div><span style={windowTitleStyle}>Email: </span>{activeAddress.email}</div>
              <div><span style={windowTitleStyle}>Latitude: </span>{activeAddress.latitude}</div>
              <div><span style={windowTitleStyle}>Longitude: </span>{activeAddress.longitude}</div>
            </div>
          </InfoWindow>
        </ErrorBoundary>}





      </GoogleMap>
    );


  }


  return renderMap();


};



export default UserContactsMap 