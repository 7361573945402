import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { HeaderSubText, HeaderText, NumberListItem } from '../../../components/text'
import { Bold } from '../../Register/components/shared'
import { CloseIcon } from '../../../components/styled-icons'
import { Flex } from '../../../components/containers'

const DeliveriesContainer = styled.div`
  ${tw`w-full px-10 py-6 mt-5 text-fontBlue`}
  background-color: #f3f2f1;
  font-size: 13px;
`

const DeliverySquare = styled.div`
  ${tw`bg-white flex justify-center items-center text-secondaryOrange rounded my-3`}
  width: 160px;
  height: 36px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
`

const HalfWidth = styled.div`
  ${tw`w-1/2 first:mr-6 last:ml-8`}
`

const ListItem = styled.li`
  ${tw`ml-4`}
`

function DeliveriesInformation(props) {
  const { isOpen, setIsOpen } = props
  return (
    <>
      {isOpen && <DeliveriesContainer>
        <HeaderText>Scheduling multiple deliveries?<CloseIcon onClick={() => setIsOpen(!isOpen)}/></HeaderText>
        <HeaderSubText>Create as many Deeleeos as you want and then submit them as a single order.</HeaderSubText>
        <ol className='ml-4'>
          <NumberListItem>Create a Deeleeo</NumberListItem>
          <NumberListItem>When you get to your Order Summary, you'll have the option to <br/> <Bold>Add a delivery</Bold> or <Bold>Duplicate delivery</Bold></NumberListItem>
        </ol>
        <Flex>
          <HalfWidth>
            <DeliverySquare>Duplicate delivery</DeliverySquare>
            <div>
              Select <Bold>Duplicate delivery</Bold> to create a duplicate of your existing order:
              <ListItem>
                the same pickup location and same details (i.e. # of packages, size of package(s), drop off timing, photo, special instructions) going to different drop off locations
              </ListItem>
            </div>
          </HalfWidth>
          <HalfWidth>
            <DeliverySquare>Add a delivery</DeliverySquare>
            <div>
              Select <Bold>Add a delivery</Bold> to add a brand new delivery to your order:
              <ListItem>
                without any of the previous order details except for pickup location
              </ListItem>
            </div>
          </HalfWidth>
        </Flex>
      </DeliveriesContainer>}
    </>
  )
}

export default DeliveriesInformation
