import PageLayout from '../PageLayout'
import {connect } from 'react-redux'
import {DetailsContainer, Divider} from '../Shipment/ShipmentDetails'
import {AccentHeader, BlueTitle} from '../../components/text'
import {withRouter} from 'react-router-dom'
import DeeleeoTabs from '../../components/DeeleeoTabs'
import {ThinButton} from '../../components/buttons'
import {routes} from '../../routes'
import {useEffect, useLayoutEffect, useState} from 'react'
import tw from 'twin.macro'
import React from 'react';
import Pagination from '../Dashboard/components/Pagination'
import styled from 'styled-components'
import {fetchMultiDeeleeos, postMultiDeeleeos, clearPostMultiDeeleeoDeeleeoStatus} from '../../redux/actions/dashboardActions'
import { useTable, usePagination, useFilters, useSortBy, useGlobalFilter, useExpanded } from 'react-table';
import {Dialog, DialogContent, DialogActions, DialogContentText} from '@material-ui/core'
import { PostMultiDeeleeoStatus } from '../../redux/reducers/dashboardReducer'

const Styles = styled.div`
  ${tw`w-full mt-4 text-fontBlue`}
  table {
    ${tw`w-full`}
  }

  tr {
    ${tw`border-b-2 border-gray-300`}
    td {
      height: 67px;
    }
  }

  th {
    ${tw`text-primaryBlue font-bold uppercase text-left`}
    font-family: Montserrat;
    font-size: 13px;
    height: 32px;
    white-space:nowrap;
    padding: 0 5px;
  }

  td {
    font-size: 14px;
    font-family: Varela Round;
  }
`

function MultiDeeleeo(props) {

    const {history, multiDeeleeos, fetchMultiDeeleeos, postMultiDeeleeos, apiCode, postMultiDeeleeoStatus, clearPostMultiDeeleeoDeeleeoStatus} = props;

    const [open, setOpen] = useState(false);

    const tableInstance = useTable({
        columns: [{
            Header: 'ID',
            accessor: 'id',
            Cell: ({ value }) => <strong className='mr-4'>{value}</strong>
        }, {
            Header: 'File name',
            accessor: 'file_name',
            Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }}></span>
        }, {
            Header: 'Validation Status',
            accessor: 'validate_status',
            Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }}></span>
        }],
        data: multiDeeleeos || [],
        initialState: { pageIndex: 0, pageSize: 10 },
        manualPagination: true,
        autoResetHiddenColumns: false,
    }, useFilters, useGlobalFilter, useSortBy, useExpanded, usePagination)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
      } = tableInstance
    


    const hiddenFileInput = React.useRef(null);
    
    const handleClick = (event) => {
        hiddenFileInput.current.click(event);
    };
    
    const handleChange = event => {
        if(event?.target?.files[0]){
            const file = event.target.files[0];
            const requestData = {
                file,
                apiCode
            }
            postMultiDeeleeos(requestData);
        }
    };

    const handleClose = () => {
        setOpen(false);
        window.location.reload();
    }
      
    useLayoutEffect (() => {
        fetchMultiDeeleeos({apiCode});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(postMultiDeeleeoStatus===PostMultiDeeleeoStatus.PostMultiDeeleeoSuccess) {
            clearPostMultiDeeleeoDeeleeoStatus();
            setOpen(true);
            
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postMultiDeeleeoStatus])


    return (
        <PageLayout wider>
            <DeeleeoTabs selectedTab={1} />
            <Divider />
            <DetailsContainer>
                <AccentHeader className="mb-6">MultiDeeleeo</AccentHeader>

                    <div>
                        <div className='mb-6'>
                        For Multi-Deeleeo orders, you must submit the batch file the day before (or any at minimum 12hours before) pickup is required. 
                        Any orders submitted late will not be processed and a Deeleeo team member will reach out to you.
                        </div>
                    <BlueTitle className="mb-6">
                        <a href='../samples/DeeleeoBatchTemplate(WebApp Batching).xlsx'>
                            Download Excel template
                        </a>
                    </BlueTitle>

                    <ThinButton onClick={handleClick}>
                        Upload a file
                    </ThinButton>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{display: 'none'}} 
                    />
                    </div>
                <div>
                    <ThinButton onClick={() => history.push(routes.dashboard)}>
                        Cancel
                    </ThinButton>
                </div>
                <Divider />

                <>
                <Styles>
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    <Pagination
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                    />
                    </Styles>
                </>
            
                <Dialog
                    open={open}
                >
                    
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                            Thank you for submitting your Multi-Deeleeo batch file. A Deeleeo Team Member will be in touch. Thank you.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ThinButton  onClick={handleClose} autoFocus>
                            Close
                        </ThinButton>
                    </DialogActions>
                </Dialog>

            </DetailsContainer>
        </PageLayout >
    );

}

const mapStateToProps = state => ({
    apiCode: state.userReducer.apiCode,
    multiDeeleeos: state.dashboardReducer.multiDeeleeos,
    postMultiDeeleeoStatus: state.dashboardReducer.postMultiDeeleeoStatus
})

const mapDispatchToProps = dispatch => ({
    clearPostMultiDeeleeoDeeleeoStatus:() => dispatch(clearPostMultiDeeleeoDeeleeoStatus()),
    fetchMultiDeeleeos: (requestData) => dispatch(fetchMultiDeeleeos(requestData)),
    postMultiDeeleeos: (requestData) => dispatch(postMultiDeeleeos(requestData))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiDeeleeo))