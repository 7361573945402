import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { Button } from "../../../components/buttons";
import { Flex } from "../../../components/containers";
import { Styles } from "../../../components/styledTableHelpers";
import { SectionHeader } from "../../../components/text";
import { getURI } from "../../../helpers/apiHelpers";
import { StyledToolTip } from "../../DeeleeoDetails/components/shared";
import { Divider } from "../../Shipment/ShipmentDetails";
import DriverTableGeneric from "./DriverTableGeneric";
import MyFleetDriverTable from "./MyFleetDriverTable";
import InfoIcon from "../../../assets/icons/Info-icon.svg";


const DriversTable = (props) => {
    const { userData, apiCode } = props;

    const [favDrivers, setFavDrivers] = useState([]);
    const favData = favDrivers;

    const [drivers, setDrivers] = useState([]);
    const data = drivers;

    const [myFleetView, setMyFleetView] = useState(false);

    const [myFleetDrivers, setMyFleetDrivers] = useState([]);

    const [myFleetCode, setMyFleetCode] = useState('');

    const mapDrivers = (drivers, isFav) => {
        return drivers.map((driver) => {
            driver.fav = isFav;
            driver.image_profile = driver.image_profile.replace('staging', 'production');
            // console.log(driver);
            try {
                const [diverName]=driver.name.split(' ');
                driver.name = diverName;
            } catch (e) {

            }

            return driver;
        });
    }

    const payload = {
        api_code: apiCode
    };

    useEffect(() => {

        if (userData.isMyFleetEnabled) {
            // console.log('user is my fleet enabled');

            if (myFleetCode === '') {
                axios.get(getURI(`/api/web/my-fleet-code?api_code=${apiCode}`))
                    .then(data => {
                        // console.log(data.data);
                        setMyFleetCode(data.data.my_fleet_code);
                    })
                    .catch(error => {
                        console.log(error);

                    });
            }


            axios.get(getURI(`/api/web/my-fleet?api_code=${apiCode}`))
                .then(data => {
                    // console.log(data.data.data.drivers);
                    setMyFleetDrivers(data.data.data.drivers);
                    // setMyFleetCode(data.data.my_fleet_code);
                })
                .catch(error => {
                    console.log(error);

                });
            // }
        }



        let favouriteDrivers = [];
        let nonFavouriteDrivers = [];

        /**
         * Get Favourite Drivers
         */
        axios.get(getURI('/api/web/favourite-driver-list?' + new URLSearchParams(payload)))
            .then((result) => {
                favouriteDrivers = mapDrivers(result.data.data, true);
                setFavDrivers(favouriteDrivers);
            })
            .catch(e => console.log(e));

        /**
             * Get Non Favourite drivers
             */
        axios.get(getURI('/api/web/get-driver-list?' + new URLSearchParams(payload)))
            .then((result) => {
                nonFavouriteDrivers = mapDrivers(result.data.data, false);
                /**
                 * Set both arrays to state
                 */
                setDrivers(nonFavouriteDrivers);
            })
            .catch(e => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drivers.length, favDrivers.length, myFleetDrivers.length])

    const handleMyFleetEdit = (row) => {
        //751,756,810
        // console.log(row.original);
        const removePayload = {
            api_code: apiCode,
            driver_id: row.original.id
        };

        axios.delete(getURI('/api/web/my-fleet/remove-driver?' + new URLSearchParams(removePayload)))
            .then(data => {
                // console.log(data.data);
                setMyFleetDrivers([]);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const handleEdit = (row) => {
        //console.log(row.original);
        if (row.original.fav) {
            const removePayload = {
                api_code: apiCode,
                driver_id: row.original.id
            };
            /**
             * Remove from fav drivers
             */

            axios.delete(getURI('/api/web/remove-favourite-driver?' + new URLSearchParams(removePayload)))
                .then((result) => {
                    setDrivers([]);
                })
                .catch(e => console.log(e));

        } else {
            /**
             * Add to fav drivers
             */
            const addPayload = {
                api_code: apiCode,
                driver_id: row.original.id
            };
            axios.post(getURI('/api/web/add-favourite-driver'), addPayload)
                .then((result) => {
                    setDrivers([]);
                })
                .catch(e => console.log(e));

        }

    }


    return (
        <>
            <Styles>
                <Flex>
                    <Button selected={!myFleetView} onClick={() => setMyFleetView(false)} style={{ margin: 20, width: 250 }}>
                        Favourite Drivers
                        <StyledToolTip
                            title={<p>Favourite Driver feature allows you to save and request your favourite delivery agents for your Deeleeos! Please note that delivery agents have the right to accept or reject these requests. If rejected, your Deeleeo gets posted to the general Marketplace for all other delivery agents to see.</p>}>
                            <img
                                style={{ display: 'inline' }}
                                className="-mt-1 ml-1"
                                src={InfoIcon}
                                alt={"premium information"}
                            />
                        </StyledToolTip>
                    </Button>
                    <Button selected={myFleetView} onClick={() => setMyFleetView(true)} style={{ margin: 20, width: 250 }}>
                        My Fleet
                        <StyledToolTip
                            title={<p>My Fleet is a fleet  management feature for businesses that have their own delivery agents and want to maximize their efficiency. Submit and manage Deeleeos, and optimize and manage your delivery agents all with Deeleeo!</p>}>
                            <img
                                style={{ display: 'inline' }}
                                className="-mt-1 ml-1"
                                src={InfoIcon}
                                alt={"premium information"}
                            />
                        </StyledToolTip>
                    </Button>
                </Flex>
                {myFleetView && <p style={{ fontWeight: 'bold', fontSize: 11 }}>How to add a MyFleet Driver: download the ‘Deeleeo Agent’ app from the app store on your mobile device. Then while creating an account and registering your driver as a Deeleeo delivery agent, insert your MyFleet code into the appropriate field under ‘Account Info’ in the ‘My Account’ page of the app. If you need help or support, please contact the Deeleeo Success team at <a href="mailto:success@deeleeo.com" style={{ color: 'blue', fontWeight: 'bold' }}>success@deeleeo.com</a> or call/text us at <a href="tel:1-825-255-5778" style={{ color: 'blue', fontWeight: 'bold' }}>1-825-255-5778</a>.</p>}
                {!myFleetView && <Flex className="flex-col">
                    <SectionHeader>Your Favourite Agents:</SectionHeader>
                    <DriverTableGeneric
                        data={favData}
                        handleEdit={handleEdit}
                    />
                    <Divider />
                    <SectionHeader>Add Favourite Agents:</SectionHeader>
                    <DriverTableGeneric
                        data={data}
                        handleEdit={handleEdit}
                    />
                </Flex>}

                {myFleetView && <Flex className="flex-col">
                    <SectionHeader>My Fleet Code: {myFleetCode}</SectionHeader>
                    <SectionHeader>My Fleet Agents:</SectionHeader>
                    <MyFleetDriverTable
                        data={myFleetDrivers}
                        handleEdit={handleMyFleetEdit}
                    />
                </Flex>}
            </Styles>
        </>
    );
}
export default DriversTable;