import { orderActions } from '../actions/orderActions'
import isEqual from 'lodash/isEqual'

const INITIAL_STATE = {
  deeleeos: [],
  submittedDeeleeos: [],
  getPriceStatus: '',
  createDeeleeoStatus: '',
  createDeeleeoError: '',
  uploadImageToServer: ''
}

export const PRICE_STATE = {
  GetPriceRequest: 'GetPriceRequest',
  GetPriceSuccess: 'GetPriceSuccess',
  GetPriceFailure: 'GetPriceFailure'
}

export const CREATE_DEELEEO_STATUS = {
  CreateDeeleeoRequest: 'CreateDeeleeoRequest',
  CreateDeeleeoSuccess: 'CreateDeeleeoSuccess',
  CreateDeeleeoFailure: 'CreateDeeleeoFailure'
}

export const UPLOAD_IMAGE_TO_SERVER_STATUS = {
  UploadImageToServerRequest: 'UploadImageToServerRequest',
  UploadImageToServerSuccess: 'UploadImageToServerSuccess',
  UploadImageToServerFailure: 'UploadImageToServerFailure'
}

export const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case orderActions.UPDATE_ORDER:
      return {
        ...state, ...action.data
      }
    case orderActions.ADD_DEELEEO:
      return {
        ...state, deeleeos: [...state.deeleeos, action.data]
      }
    case orderActions.UPDATE_DEELEEO:
      const copy = [...state.deeleeos]
      copy[action.data.index] = action.data.deeleeo
      return {
        ...state, deeleeos: copy
      }
    case orderActions.DELETE_DEELEEO:
      return {
        ...state, deeleeos: state.deeleeos.filter(deeleeo => !isEqual(deeleeo, action.data))
      }

    case orderActions.GET_PRICE_REQUEST:
      return {
        ...state, getPriceStatus: PRICE_STATE.GetPriceRequest
      }
    case orderActions.GET_PRICE_SUCCESS:
      return {
        ...state, getPriceStatus: PRICE_STATE.GetPriceSuccess, deeleeos: state.deeleeos.map((deeleeo, index) => {
          if (index === action.data.index) {
            return {...deeleeo, ...action.data}
          } else {
            return {...deeleeo}
          }
        })
      }
    case orderActions.GET_PRICE_FAILURE:
      return {
        ...state, getPriceStatus: PRICE_STATE.GetPriceFailure
      }
    case orderActions.CREATE_DEELEEO_REQUEST:
      return {
        ...state, createDeeleeoStatus: CREATE_DEELEEO_STATUS.CreateDeeleeoRequest
      }
    case orderActions.CREATE_DEELEEO_SUCCESS:
      return {
        ...state, createDeeleeoStatus: CREATE_DEELEEO_STATUS.CreateDeeleeoSuccess, submittedDeeleeos: [...state.submittedDeeleeos, action.data.deeleeoId]
      }
    case orderActions.CREATE_DEELEEO_FAILURE:
      return {
        ...state, createDeeleeoStatus: CREATE_DEELEEO_STATUS.CreateDeeleeoFailure, createDeeleeoError: action.data
      }
    case orderActions.CLEAR_DEELEEO_REQUEST:
      return {
        ...state, createDeeleeoStatus: ''
      }
    case orderActions.UPLOAD_IMAGE_TO_SERVER_REQUEST:
      return {
        ...state, uploadImageStatus: UPLOAD_IMAGE_TO_SERVER_STATUS.UploadImageToServerRequest
      }
    case orderActions.UPLOAD_IMAGE_TO_SERVER_SUCCESS:
      return {
        ...state, uploadImageStatus: UPLOAD_IMAGE_TO_SERVER_STATUS.UploadImageToServerSuccess
      }
    case orderActions.UPLOAD_IMAGE_TO_SERVER_FAILURE:
      return {
        ...state, uploadImageStatus: UPLOAD_IMAGE_TO_SERVER_STATUS.UploadImageToServerFailure
      }
    case orderActions.CLEAR_ORDER:
      return {
        ...INITIAL_STATE, getPriceStatus: state.getPriceStatus

      }
    default:
      return state
  }
}
