import React, { useCallback } from 'react'
import { HintText, SubTitle } from '../../../components/text'
import UploadIcon from '../../../assets/icons/upload-icon.svg'
import { uploadImages } from '../../../redux/actions/shipmentActions'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import tw from 'twin.macro'
import Thumbnail from '../../../components/Thumbnail'

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#666666';
}

const StyledDragNDrop = styled.div`
  ${tw`flex flex-col items-center justify-around p-6 mt-2`}
  height: 210px;
  font-family: Varela Round;
  border-width: 1px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  color: #666666;
  transition: border .24s ease-in-out;
`;

const SelectButton = styled.button`
  ${tw`rounded px-4`}
  ${props => props.collapsed && tw`mt-4 ml-4`}
  height: 36px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  color: #6f84da;
  text-align: center;
`

function PhotoUpload(props) {
  const { uploadImages, images, collapsed, isValet } = props

  const onDrop = useCallback(acceptedFiles => {

    const handleFile = (files) => {

      const localImageUrls = files.map(file => {

        let formData = new FormData()
        formData.append('image',file)

        return {
          imageURL: window.URL.createObjectURL(file),
          imageFile: formData
        }
      })
   
      uploadImages([...images, ...localImageUrls])
    }

    handleFile(acceptedFiles)
  }, [images, uploadImages])

  const { getRootProps, getInputProps, open, isDragAccept, isDragActive, isDragReject } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/png',
    onDrop
  })

  const getHint=()=>{

    if(isValet){
    return '(Please upload at least one photo of your vehicle)';
    }
    return '(Hint: adding a photo will ensure a delivery agent accepts your Deeleeo quickly)';
  }

  return (
    <div>
      <SubTitle className='mt-11'>Upload a photo (Optional)<HintText>{getHint()}</HintText></SubTitle>
      {!collapsed && <StyledDragNDrop {...getRootProps({ className: 'dropzone', isDragAccept, isDragActive, isDragReject })}>
        <input {...getInputProps()} />
        <img src={UploadIcon} alt={'upload'}/>
        <p className='text-lg'>Drag file here</p>
        <p className='text-sm'>or</p>
        <SelectButton onClick={open}>Select File</SelectButton>
      </StyledDragNDrop>}

      <div className='flex'>
        {images.map((image, index) => <Thumbnail key={index} src={image.imageURL}/>)}
        {collapsed && <div {...getRootProps({ className: 'dropzone'})}>
          <input {...getInputProps()} />
          <SelectButton collapsed onClick={open}>Add photo</SelectButton>
        </div>}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  images: state.shipmentReducer.images
})

const mapDispatchToProps = dispatch => ({
  uploadImages: (imageUrl) => dispatch(uploadImages(imageUrl))
})

export default connect(mapStateToProps, mapDispatchToProps)(PhotoUpload)
