import { userActions } from '../actions/userActions'

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  mobile: '',
  email: '',
  businessName: '',
  apiCode: '',
  accessToken: '',
  servicedRegions: [],
  hasCardOnAccount: false,
  card_id: '',
  credit: 0,
  isFavouriteAgentEnable: false,
  isPickupAvailability: false,
  on_hold_deeleeo: false,
  mapLatLng: [53.541427, -113.500434],
  isExpressEnabled: false,
  expressMaxTime:''
  
}

export const userReducer = (state = INITIAL_STATE, action) => {  
  switch (action.type) {
    case userActions.UPDATE_USER:
      return {
        ...state, ...action.data
      }
    case userActions.SERVICED_REGIONS_SUCCESS:
      return {
        ...state, servicedRegions: action.data
      }
    case userActions.COMPANY_INFO_SUCCESS:
      return {
        ...state,
        email: action.data.email,
        mobile: action.data.mobile,
        businessName: action.data.name,
        hasCardOnAccount: action.data.hasCard,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        card_id: action.data.card_id,
        credit: action.data.credit,
        isFavouriteAgentEnable: action.data.isFavouriteAgentEnable,
        isPickupAvailability: action.data.isPickupAvailability,
        isOnHoldEnabled:action.data.isOnHoldEnabled,
        isMyFleetEnabled:action.data.isMyFleetEnabled,
        isValetEnabled:action.data.isValetEnabled,
        availableTimes:action.data.availableTimes,
        isEFTPayment:action.data.isEFTPayment,
        isUserCredit:action.data.isUserCredit,
        isSundayDeliveryEnabled: action.data.isSundayDeliveryEnabled,
        mapLatLng: action.data.mapLatLng,
        region_times: action.data.region_times,
        rush_distance: action.data.rush_distance,
        intercity_min_special_time: action.data.intercity_min_special_time,
        local_min_special_time: action.data.local_min_special_time,
        isExpressEnabled: action.data.isExpressEnabled,
        expressMaxTime: action.data.expressMaxTime

      }

    default: return state
  }
}
