import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { DeliveryButton } from '../../../components/buttons'
import { Bold } from '../../Register/components/shared'
import { CloseIconInside } from '../../../components/styled-icons'
import { routes } from '../../../routes'
import { connect } from 'react-redux'
import { clearDeeleeoData, duplicateDeeleeo } from '../../../redux/reducers/deeleeoReducer'
import { clearDropoffData } from '../../../redux/reducers/pickupDropoffReducer'
import { gatheringDeeleeoDataForEditing } from '../../../helpers/deeleeoHelper'

const InfoContainer = styled.div`
  ${tw`px-4 py-4 flex flex-col`}
  font-size: 12px;
  border: 1px solid #e2e7f8;
  color: #333;
  font-family: Varela Round;
`

function ActionSection(props) {
  const { clearDropoffData, clearDeeleeoData, duplicateDeeleeo, deeleeos, history, disabled } = props
  const [helpVisible, setHelpVisible] = useState(true)

  const addNewDeeleeoBtnHandle = () => {
    // extra step incase data still exist when user press back button
    clearDeeleeoData();
    clearDropoffData();
    history.push(routes.choose);
  }

  const duplicateDeeleeoBtnHandle = () => {
    // extra step incase data still exist when user press back button
    clearDeeleeoData();
    clearDropoffData();
    let last_index = deeleeos.length - 1;
    let deeleeoInfo = deeleeos[last_index];

    // extract needed data for deeleeo data
    let deeleeo_data = gatheringDeeleeoDataForEditing(deeleeoInfo, last_index);

    // update deeleeo data
    duplicateDeeleeo(deeleeo_data);

    history.push(routes.createDeeleeo);
  }

  return (
    <div className='mb-4 flex'>
      <div className='w-1/2'>
        <DeliveryButton disabled={disabled} onClick={addNewDeeleeoBtnHandle}>Add a Deeleeo</DeliveryButton>
        <DeliveryButton disabled={disabled} onClick={duplicateDeeleeoBtnHandle}>Duplicate Deeleeo</DeliveryButton>
      </div>
      <div className='w-1/2'>
        {helpVisible && <InfoContainer>
          <CloseIconInside onClick={() => setHelpVisible(false)}/>
          <div className='pb-2 -mt-3'><Bold>Add a Deeleeo</Bold> adds a brand new delivery to your order.</div>
          <div>
            <Bold>Duplicate Deeleeo</Bold> creates a duplicate of your most recent existing order: the
            same pickup location and same details (i.e. # of packages, size of package(s), drop off timing,
            photo, special instructions) going to different drop off locations.
          </div>
        </InfoContainer>} 
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  deeleeos: state.deeleeosReducer.deeleeos
})

const mapDispatchToProps = dispatch => ({
  clearDeeleeoData: () => dispatch(clearDeeleeoData()),
  clearDropoffData: () => dispatch(clearDropoffData()),
  duplicateDeeleeo: (data) => dispatch(duplicateDeeleeo(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionSection)
