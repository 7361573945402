import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useRecoilState } from 'recoil';
import { apiKeyAtom } from '../../App';
import { Flex } from '../../components/containers';
import DeeleeoTabs from '../../components/DeeleeoTabs';
import { SectionHeader } from '../../components/text';
import PageLayout from '../PageLayout';
import { Divider } from '../Shipment/ShipmentDetails';
import Contacts from './components/Contacts';
import UserContactsMap from './components/UserContactsMap';
import * as yup from 'yup'
import { useFormik } from 'formik';
import AddressField from '../../components/AddressField';
import ErrorBoundary from '../../helpers/ErrorBoundary';
// import { checkDistanceWithinRadius } from '../../helpers/addressHelpers';
import { ThinButton } from '../../components/buttons';
import axios from 'axios';
import { getURI } from '../../helpers/apiHelpers';
import Sticky from 'react-sticky-el';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import styled from "styled-components";
import tw from "twin.macro";
import What3WordsField from '../../components/What3WordsField';
import InputField from '../../components/InputField';
import ContactModal from './components/ContactModal';

const StyledLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    ${tw`text-fontBlue`}
    font-family: Montserrat;
    font-size: 13px;
  }
`;

const Container = styled.div`
${tw`flex flex-col mx-auto xl:mx-auto 2xl:mx-auto pb-12 w-5/6`}
`;

const UserAddress = (props) => {

    const { apiCode } = props;

    const [apiKey, setApiKey] = useRecoilState(apiKeyAtom);
    const [activeAddress, setActiveAddress] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [w3wValues, setW3wValues] = useState([]);
    const [readyToSave, setReadyToSave] = useState(false);
    const [pickupChecked, setPickupChecked] = useState(false);
    const [dropOffChecked, setDropOffChecked] = useState(false);
    setApiKey(apiCode);
    const [modalOpen, setModalOpen] = useState(false);

    const validationSchema = yup.object().shape({
        name: yup.string().required('Name is required'),
        address: yup.string().when('w3w', {
            is: (w3w) => !w3w || w3w.length === 0,
            then: yup.string().required('Address is required'),
            otherwise: yup.string()
        }),
        w3w: yup.string().when('address', {
            is: (address) => !address || address.length === 0,
            then: yup.string().required('w3w is required'),
            otherwise: yup.string()
        }),
        phone: yup.string().phone('CA', false, 'Phone number must be valid'),
        email: yup.string().email('Enter a valid email address').required('Email is required'),
        is_pickup: yup.number().when('is_dropoff', {
            is: (is_dropoff) => is_dropoff === 0,
            then: yup.number().integer().min(1).required('Pickup or Drop-Off is required'),
            otherwise: yup.number().integer()
        }),
        is_dropoff: yup.number().when('is_pickup', {
            is: (is_pickup) => is_pickup === 0,
            then: yup.number().integer().min(1).required('Pickup or Drop-Off is required'),
            otherwise: yup.number()
        }),
        latitude: yup.number().required(),
        longitude: yup.number().required(),

    }, [['is_pickup', 'is_dropoff'], ['is_dropoff', 'is_pickup'], ['address', 'w3w'], ['w3w', 'address']]);

    const formik = useFormik({
        initialValues: {
            name: '',
            address: '',
            unit: '',
            w3w: '',
            phone: '',
            email: '',
            is_pickup: 0,
            is_dropoff: 0
        },
        validationSchema: validationSchema,
        onSubmit: (values => {
            //    setEditMode(!editMode)
        }),

    })

    const handleAddressChange = (addressInfo) => {
        setReadyToSave(false);
        setActiveAddress({ ...formik.values, address: addressInfo.address, latitude: addressInfo.lat, longitude: addressInfo.lng });
        // console.log(formik);


        const { city, lat, lng, street, province } = addressInfo;



        const payload = { lat: lat, lon: lng };

        axios
            .post(getURI(`/api/web/get-zone-from-coordinate`), payload)
            .then((response) => {

                // console.log(response);
                formik.setValues({
                    ...formik.values,
                    address: street + ', ' + city + ' ' + province,
                    latitude: lat,
                    longitude: lng
                })
                setReadyToSave(formik.isValid);
            })
            .catch(error => {
                console.log(error);
                formik.setFieldError('address', 'This location is not currently serviced by Deeleeo')

            });




    }

    /**
   * When selecting an option, we validate the coords, and set the info if it passes validation
   * @param {*} data 
   */
    const handleW3WChange = (data) => {
        /**
         * Data now contains w3w data and the google autocomplete options
         */
        const { w3w, google } = data;
        const { coordinates, words } = w3w;

        setActiveAddress({ ...formik.values, address: '///' + words, latitude: coordinates.lat, longitude: coordinates.lng });

        const payload = { lat: coordinates.lat, lon: coordinates.lng };

        axios
            .post(getURI(`/api/web/get-zone-from-coordinate`), payload)
            .then((response) => {

                // console.log(response);


                /**
               * If we can service the location, we save the google options to be displayed
               */
                setW3wValues(google);
                /**
                 * Also save w3w address and coords to the deeleeo
                 */
                formik.setValues({
                    ...formik.values,
                    address: '///' + words,
                    latitude: coordinates.lat,
                    longitude: coordinates.lng
                })

            })
            .catch(error => {
                console.log(error);
                formik.setFieldError('w3w', 'This location is not currently serviced by Deeleeo')

            });



    }



    const handleSave = () => {
        // console.log(formik);
        const payload = { ...formik.values, api_code: apiKey };

        // console.log(payload);
        // console.log(formik);


        // return false
        axios
            .post(getURI(`/api/web/user-contact`), payload)
            .then((response) => {
                // console.log(response);
                window.location.reload(false);


            }).catch((error) => {
                console.log(error);
            })

    }

    const checkPickupBoxHandle = () => {
        setPickupChecked(!pickupChecked);
    }

    useEffect(() => {
        // console.log('use effect pickupChecked');

        formik.setValues({
            ...formik.values,
            is_pickup: pickupChecked ? 1 : 0
        });
        setReadyToSave(formik.isValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickupChecked, formik.isValid]);

    const checkDropOffBoxHandle = () => {
        setDropOffChecked(!dropOffChecked);
    }

    useEffect(() => {
        // console.log('use effect dropOffChecked');
        formik.setValues({
            ...formik.values,
            is_dropoff: dropOffChecked ? 1 : 0
        });
        setReadyToSave(formik.isValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropOffChecked, formik.isValid]);

    useEffect(() => {
        /**
         * Run the validation if the form is dirty
         */
        if (formik.dirty) {
            formik.handleSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);


    const modalContent = <div className='w-full'>
        {/* <Sticky stickyStyle={{ backgroundColor: 'white' }}> */}
        <ErrorBoundary>
            <Flex>
                <div className='mt-8 w-1/2 first:mr-12' style={{ fontSize: 10, padding: 0, margin: 0 }}>* = Mandatory Fields</div>
            </Flex>
            <div className="w-full">
                <InputField
                    required
                    id={'name'}
                    type={'text'}
                    label={'Name: '}
                    onBlur={formik.handleBlur}
                    helperText={formik.errors.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    onChange={formik.handleChange}
                    value={formik.values.name} />
            </div>

            <div className='w-full'>
                <AddressField
                    required
                    id={'address'}
                    label={'Add Address: '}
                    defaultValue={formik.values.address}
                    onChange={handleAddressChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.errors.address}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    values={[]}//To pass external values to the component
                    cleanValues={null}//To clean these external values from the component
                />
            </div>
            <div className='w-full'>
                <What3WordsField
                    id={'w3w'}
                    label='What3word address (optional)'
                    onChange={handleW3WChange}
                    helperText={formik.errors.w3w}
                    error={formik.touched.w3w && Boolean(formik.errors.w3w)}
                    onBlur={formik.handleBlur}
                />
            </div>
            <div className="w-full">
                <InputField
                    id={'unit'}
                    label={'Unit: '}
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.unit}
                    helperText={formik.errors.unit}
                    error={formik.touched.unit && Boolean(formik.errors.unit)} onChange={formik.handleChange} value={formik.values.unit} />
            </div>
            <div className="w-full">
                <InputField
                    required id={'phone'}
                    type={'text'}
                    label={'Phone: '}
                    onBlur={formik.handleBlur}
                    helperText={formik.errors.phone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    onChange={formik.handleChange}
                    value={formik.values.phone} />
            </div>
            <div className="w-full">
                <InputField
                    required
                    id={'email'}
                    type={'text'}
                    label={'Email'} o
                    nBlur={formik.handleBlur}
                    helperText={formik.errors.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    onChange={formik.handleChange}
                    value={formik.values.email} />
            </div>
            <div className='w-full flex'>
                <div className='w-3/8'>
                    <StyledLabel
                        value='is_pickup'
                        control={
                            <Checkbox
                                checked={pickupChecked}
                                color="primary"
                            />}
                        label='Save as Pickup Location'
                        onChange={checkPickupBoxHandle}
                        // onBlur={formik.handleBlur} 
                        labelPlacement="end" />
                    {formik.touched.address && Boolean(formik.errors.is_pickup) &&
                        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>Select Pickup or Drop-Off</p>}
                </div>
                <div className='w-3/8'>
                    <StyledLabel
                        value='is_dropoff'
                        control={
                            <Checkbox
                                checked={dropOffChecked}
                                color="primary"
                            />}
                        label='Save as Drop off Location'
                        onChange={checkDropOffBoxHandle}
                        // onBlur={formik.handleBlur} 
                        labelPlacement="end" />
                    {formik.touched.address && Boolean(formik.errors.is_dropoff) &&
                        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>Select Pickup or Drop-Off</p>}
                </div>
            </div>
            <div className='w-full'>
                <p style={{ fontSize: 10 }}>Select all that apply.</p>
            </div>
            <div className='w-full'>
                {readyToSave && <ThinButton onClick={handleSave}>Add Contact</ThinButton>}
            </div>

        </ErrorBoundary>
        {/* </Sticky> */}
    </div>;

    return (
        <PageLayout wider>
            <DeeleeoTabs selectedTab={5} />
            <Divider />
            <Container >
                <SectionHeader>Add Contact <ThinButton onClick={() => setModalOpen(true)}>Add</ThinButton></SectionHeader>
                <Divider />
                {/* <Flex>
                    <div className='mt-8 w-1/2 m-1'>
                        <ThinButton>Add Contact</ThinButton>
                    </div>
                </Flex> */}
                <Flex>

                    <Flex className='w-1/2'>
                        <div className='w-full'>
                            <Sticky stickyStyle={{ backgroundColor: 'white' }}>
                                <ErrorBoundary>
                                    <UserContactsMap
                                        activeAddress={activeAddress}
                                    />
                                </ErrorBoundary>
                            </Sticky>
                        </div>


                    </Flex>

                    <Flex className='w-1/2'>
                        <div className='w-full' style={{ height: '100px' }}>
                            <Contacts
                                setActiveAddress={setActiveAddress}
                            />
                        </div>
                    </Flex>

                </Flex>

                <ContactModal
                    contentLabel='Add Contact'
                    modalIsOpen={modalOpen}
                    setModalIsOpen={setModalOpen}
                    content={modalContent}
                />

                {/* <Cards/> */}
                {/* <SectionHeader>Add Payment Information:</SectionHeader> */}
                {/* <Divider /> */}

                {/* <PaymentForm />                 */}
            </Container>
        </PageLayout >
    );
}

const mapStateToProps = state => ({
    apiCode: state.userReducer.apiCode,
    regions: state.userReducer.servicedRegions
})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAddress));