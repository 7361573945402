import DeeleeoIcon from '../assets/icons/deeleeo-reverse.svg'
import xIcon from '../assets/icons/x-icon.svg'
import trash from '../assets/icons/Trash.svg'
import edit_icon from '../assets/icons/edit-icon.svg'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Flex } from './containers'

export const LogoText = styled.div`
  font-family: Varela Round;
  font-size: 14px;
  color: #3d405b;
  line-height: 14px;
  ${tw`ml-4 self-end`}
`

export const DeeleeoTextIcon = () => {
  return (
    <Flex>
      <img src={DeeleeoIcon} alt={'Deeleeo Logo'} style={{ height: '28px', width: '120px' }}/>
      <LogoText>Delivery simplified.</LogoText>
    </Flex>
  )
}


const PushRight = styled.span`
  ${tw`ml-auto -mr-8 -mt-8`}
`

export const CloseIcon = (props) => {
  const { onClick } = props
  return (
    <PushRight onClick={onClick}>
      <img onClick={onClick} src={xIcon} alt={'close'}/>
    </PushRight>
  )
}

const PushRightInside = styled.span`
  ${tw`ml-auto -mr-2 -mt-2 cursor-pointer`}
`

export const CloseIconInside = (props) => {
  const { onClick } = props
  return (
    <PushRightInside onClick={onClick}>
      <img onClick={onClick} src={xIcon} alt={'close'}/>
    </PushRightInside>
  )
}

const RightPush = styled.span`
  ${tw`ml-6 cursor-pointer`}
`

export const DeleteIcon = (props) => {
  const { onClick } = props
  return (
    <RightPush onClick={onClick}>
      <img onClick={onClick} src={trash} alt={'close'}/>
    </RightPush>
  )
}

export const EditIcon = (props) => {
  const { onClick } = props
  return (
    <RightPush onClick={onClick}>
      <img onClick={onClick} src={edit_icon} alt={'close'}/>
    </RightPush>
  )
}