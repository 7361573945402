import React from 'react'
import {
  createStyles,
  makeStyles,
  Input,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText
} from '@material-ui/core'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const InputContainer = styled.div`
  ${tw`w-full mb-5`}
`

const useStyles = makeStyles((theme) => {
  createStyles({
    input: {
      color: '#3d405b'
    }
  })
})

function FloatingInputField(props) {
  const { id, label, onChange, onBlur, inputType, handleClickShowPassword, showPassword, required, helperText, error } = props
  const classes = useStyles()
  const visible = showPassword ? 'text' : 'password'
  return (
    <InputContainer>
      <FormControl required={required || false} error={error} className={classes.root} fullWidth={true}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Input type={inputType ? visible : 'text'} className={classes.input} id={id} name={id} onChange={onChange} onBlur={onBlur}
               endAdornment={
                 inputType === 'password' && <InputAdornment position={'end'}>
                   <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                     {showPassword ? <Visibility/> : <VisibilityOff/>}
                   </IconButton>
                 </InputAdornment>
               }
        />
        <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>
      </FormControl>
    </InputContainer>
  )
}

export default FloatingInputField
