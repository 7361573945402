import React, { useEffect, useState } from "react";
import { SubTitle } from '../../../components/text'
import { Flex } from "../../../components/containers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { InputHeader, StyledDatePicker, TimeAndDistance } from "./shared";
import { updatePickupTime, updateDropoffTime } from '../../../redux/reducers/deeleeoReducer'
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import styled from "styled-components";
import tw from "twin.macro";


import { validatePickupTime, validateDropoffTime, validatePickupWindowTime, validateDropoffWindowTime } from "../../../redux/reducers/validationReducer";
// import { TimeField } from "./PickupHourSelector";
import DateTimePickerValet from "../../../components/DateTimePickerValet";
import moment from "moment";
import { getDateWithTime, isTodaySelected, roundTime } from "./PickupDropoffTimeSection";

import ErrorBoundary from "../../../helpers/ErrorBoundary";
import { formatDatetime } from "../../../helpers/deeleeoHelper";
import { getURI } from "../../../helpers/apiHelpers";
import axios from "axios";





const Dash = styled.div`
  ${tw`text-fontBlue`}
`



function PickupDropoffTimeSectionValet(props) {
  const HOUR_OF_DAY_THRESHOLD = 19;
  const PICKUP_URGENT_THRESHOLD = 90;
  const pickupOptions = {
    READY_NOW: '1',
    SCHEDULE: '2',
    URGENT: '3'
  }

  const dropoffOptions = {
    SAME_DAY: '1',
    SCHEDULE: '2',
    IMMEDIATELY: '5'
  }

  const {
    validatePickupTime, validateDropoffTime, validatePickupWindowTime, validateDropoffWindowTime, updateDropoffTime, updatePickupTime,
    pickup_value, dropoff_value, validation, userData, pickupData, dropoffData
  } = props;

  const SECONDS = 60;


  const [state, setState] = useState({
    pickupTimeValue: pickup_value,
    dropoffTimeValue: dropoff_value,
    // isPickupDateDisable:  false,
    timeDistance: null,
    error: validation,
    isFuture: false
  })

  const setPickupTimeValue = (data) => {
    setState(state => ({ ...state, pickupTimeValue: data }));
  }

  const setDropoffTimeValue = (data) => {
    setState(state => ({ ...state, dropoffTimeValue: data }));
  }

  const setTimeDistance = (data) => {
    setState(state => ({ ...state, timeDistance: data }));
  }

  // const setIsPickupDateDisable = (data) => {
  //   setState(state => ({ ...state, isPickupDateDisable: data }));
  // }

  const setError = (data) => {
    setState(state => ({ ...state, error: data }));
  }

  // eslint-disable-next-line no-unused-vars
  const setIsFuture = (data) => {
    setState(state => ({ ...state, isFuture: data }));
  }



  useEffect(() => {
    setError(validation);
  }, [validation])

  useEffect(() => {
    // getDistanceInKm

    const payload = {
      api_code: userData.apiCode,
      pickup_latitude: parseFloat(`${pickupData.latitude}`),
      pickup_longitude: parseFloat(`${pickupData.longitude}`),
      dropoff_latitude: parseFloat(`${dropoffData.latitude}`),
      dropoff_longitude: parseFloat(`${dropoffData.longitude}`)
    };

    if ((payload.pickup_latitude !== 0 && !isNaN(payload.pickup_latitude)) &&
      (payload.pickup_longitude !== 0 && !isNaN(payload.pickup_longitude)) &&
      (payload.dropoff_latitude !== 0 && !isNaN(payload.dropoff_latitude)) &&
      (payload.dropoff_longitude !== 0 && !isNaN(payload.dropoff_longitude))) {
      axios.post(getURI('/api/web/deeleeo/getTimeDistance'), payload)
        .then(data => {
          // console.log('data', data.data);
          setTimeDistance(data.data);
        })
    }


  }, [dropoffData.latitude, dropoffData.longitude, pickupData.latitude, pickupData.longitude, userData.apiCode]);

  useEffect(() => {
    if (state.timeDistance) {




      //  console.log('will update drop off data');


      const type = getDropOffType();



      /**
       * Get The later pickup time selected
       */
      let split = state.pickupTimeValue.pickup_later_datetime.split(':');

      const selected = moment(state.pickupTimeValue.pickup_date)
        .set({
          hour: parseInt(split[0]),
          minute: parseInt(split[1]),
        });


      const duration = getDuration();

      // console.log('duration', duration);


      selected.add(duration, 'minutes');

      let earlyTime = roundTime(selected.format('HH:mm'), 15);

      split = earlyTime.split(':');
      selected
        .set({
          hour: parseInt(split[0]),
          minute: parseInt(split[1]) + 1,
        });
      let lateTime = roundTime(selected.format('HH:mm'), 15);





      setDropoffTimeValue({
        ...state.dropoffTimeValue,
        dropoff_date: selected.toDate().toDateString(),
        dropoff_early_datetime: earlyTime,
        dropoff_later_datetime: lateTime,
        dropoff_type: type
      })

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.timeDistance, state.pickupTimeValue])

  const getDuration = () => {
    return state.timeDistance ? Math.ceil(parseInt(state.timeDistance.duration) / SECONDS) : 0;
  }


  const pickupDatetimeHandle = (value, isEarly) => {
    const start_time = value;
    const split = start_time.split(':');
    // const duration = getDuration();

    // console.log('duration', duration);


    if (isEarly) {
      const dropoff_time = moment(state.pickupTimeValue.pickup_date)
        .set({
          hour: parseInt(split[0]),
          minute: parseInt(split[1]),
        })
        .add(15, 'minutes')
        .format('HH:mm');

      if (isTodaySelected(new Date(state.pickupTimeValue.pickup_date))) {



        let selected = moment(state.pickupTimeValue.pickup_date);

        selected.set({
          hour: parseInt(split[0]),
          minute: parseInt(split[1]),
        });


        const diff = selected.diff(moment(), 'minutes');

        if (parseInt(split[0]) >= 17 || diff < PICKUP_URGENT_THRESHOLD) {
          setPickupTimeValue({
            ...state.pickupTimeValue,
            pickup_type: pickupOptions.URGENT
          });

        }
      }


      setPickupTimeValue({
        ...state.pickupTimeValue,
        pickup_early_datetime: start_time,
        pickup_later_datetime: dropoff_time
      });




    } else {
      const end_time = value;
      let type = getDropOffType();

      setPickupTimeValue({
        ...state.pickupTimeValue,
        dropoff_type: type,
        pickup_later_datetime: end_time
      });


    }
  };

  const getDropOffType = () => {

    const split = state.pickupTimeValue.pickup_early_datetime.split(':');

    const selected = moment(state.pickupTimeValue.pickup_date)
      .set({
        hour: parseInt(split[0]),
        minute: parseInt(split[1]),
      });

    if (isTodaySelected(new Date(state.pickupTimeValue.pickup_date))) {
      if (selected.hour() >= 17) {
        return dropoffOptions.IMMEDIATELY;
      }

      return dropoffOptions.SAME_DAY;


    } else {
      return dropoffOptions.SCHEDULE;
    }

  }

  const getPickupType = (full_date) => {
    let selected_date = new Date(full_date);


    const isToday = isTodaySelected(selected_date);
    /**
      * Set the pickup type
      */
    if (isToday) {
      return pickupOptions.READY_NOW;
    } else {
      return pickupOptions.SCHEDULE;
    }
  }


  const pickupDateChangeHandle = (full_date) => {
    let selected_date = new Date(full_date);

    let type = getPickupType(full_date);

    setPickupTimeValue({
      ...state.pickupTimeValue,
      pickup_type: type,
      pickup_date: selected_date.toString()
    })


  }



  useEffect(() => {

    const pickupEarly = moment(defaultTimeValet(true)).add(15, 'minutes');
    const pickupLater = moment(defaultTimeValet(false)).add(30, 'minutes');

    // console.log('early', pickupEarly.format('HH:mm'), ' later', pickupLater.format('HH:mm'));


    setPickupTimeValue({
      ...state.pickupTimeValue,
      pickup_early_datetime: pickupEarly.format('HH:mm'),
      pickup_later_datetime: pickupLater.format('HH:mm'),
      pickup_type: pickupOptions.URGENT,
    })



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    updatePickupTime(state.pickupTimeValue);

    // update validate when pick up type not empty
    if (!state.error.pickup_time.type && state.pickupTimeValue.pickup_type !== '') {
      validatePickupTime(state.pickupTimeValue);
    }

    // update validate based on pickup time window
    if (!state.error.pickup_time.window_time && state.pickupTimeValue.pickup_early_datetime !== '' && state.pickupTimeValue.pickup_later_datetime !== '') {
      validatePickupWindowTime(state.pickupTimeValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pickupTimeValue, updatePickupTime, validatePickupTime, validatePickupWindowTime]);

  useEffect(() => {
    updateDropoffTime(state.dropoffTimeValue);

    // update validate when drop off type not empty
    if (!state.error.dropoff_time.type && state.dropoffTimeValue.type !== '') {
      validateDropoffTime(state.dropoffTimeValue);
    }

    // update validate based on dropoff time window
    if (!state.error.dropoff_time.window_time && state.dropoffTimeValue.dropoff_early_datetime !== '' && state.dropoffTimeValue.dropoff_later_datetime !== '') {
      validateDropoffWindowTime(state.dropoffTimeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dropoffTimeValue, updateDropoffTime, validateDropoffTime, validateDropoffWindowTime]);



  const disableSundays = (date) => {

    let isAfter5Today = false;
    let testingMoment = moment(date);
    if (testingMoment.isBefore()) {
      testingMoment.add((new Date()).getHours(), 'hour');
      testingMoment.add((new Date()).getMinutes(), 'minute');
      testingMoment.add((new Date()).getSeconds(), 'second');

      if (testingMoment.isAfter(moment({ hour: HOUR_OF_DAY_THRESHOLD, minute: 0 }))) {
        isAfter5Today = true;
      }
    }


    if (userData.isSundayDeliveryEnabled) {
      return false;
    }
    if (date.getDay() === 0 || isAfter5Today) return true;
  }



  const minTimeValet = (isEarly) => {
    const minHour = 7;
    const minMinute = 0;
    /**
    * Get a moment with selected date and pickup later time
    */
    const pickup = moment(getDateWithTime(state.pickupTimeValue.pickup_date, state.pickupTimeValue.pickup_date));

    /**
     * Set Time
     */
    const isToday = isTodaySelected(new Date(state.pickupTimeValue.pickup_date));
    if (isToday) {
      let dropoff_time = roundTime(moment().format('HH:mm'), 15).split(':');
      // console.log('dropoff time: ' + dropoff_time);


      pickup.set('hours', dropoff_time[0]);
      pickup.set('minutes', dropoff_time[1]);
      pickup.add(30, 'minutes');

    } else {
      pickup.set('hours', minHour);
      pickup.set('minutes', minMinute);
    }

    if (!isEarly) {
      pickup.add(15, 'minutes');
    }


    return pickup.toDate();
  }

  const maxTimeValet = (isEarly) => {
    const minHour = 18;
    const minMinute = 0;




    /**
    * Get a moment with selected date and pickup later time
    */
    const pickup = moment(getDateWithTime(state.pickupTimeValue.pickup_date, state.pickupTimeValue.pickup_date));

    /**
     * Set Time
     */
    pickup.set('hours', minHour);
    pickup.set('minutes', minMinute);

    if (isEarly) {
      pickup.subtract(15, 'minutes');
    }

    return pickup.toDate();

  }

  const defaultTimeValet = (isEarly) => {


    let earlyTimeSplit = state.pickupTimeValue.pickup_early_datetime.split(':');

    let lateTimeSplit = state.pickupTimeValue.pickup_later_datetime.split(':');

    // console.log('earlyTimeSplit', earlyTimeSplit);
    // console.log('lateTimeSplit', lateTimeSplit);



    const current = moment(state.pickupTimeValue.pickup_date);
    if (isEarly) {

      if (earlyTimeSplit.length < 2) {
        // console.log('early was less than ');

        const rounded = roundTime(moment().format('HH:mm'), 15);

        const split = rounded.split(':');

        // console.log('early split', split);


        return moment()
          .set({
            hour: parseInt(split[0]),
            minute: parseInt(split[1]),
          })
          .toDate();
      }



      return current
        .set({
          hour: parseInt(earlyTimeSplit[0]),
          minute: parseInt(earlyTimeSplit[1]),
        }).toDate();




    } else {

      if (lateTimeSplit.length < 2) {
        // console.log('late was less than ');


        const rounded = roundTime(moment().add(15, 'minutes').format('HH:mm'), 15);

        const split = rounded.split(':');
        // console.log('later split', split);

        return moment()
          .set({
            hour: parseInt(split[0]),
            minute: parseInt(split[1]),
          })
          .toDate();
      }

      return current
        .set({
          hour: parseInt(lateTimeSplit[0]),
          minute: parseInt(lateTimeSplit[1]),
        }).toDate();
    }








  }

  const getHighlightedTimes = (time, minimalTime) => {

    const timeToCheck = moment(time);

    const min = moment(minimalTime).subtract(1, 'minutes').set({ seconds: 0 });
    const max = moment(min).add((PICKUP_URGENT_THRESHOLD), 'minutes');
    const isToday = isTodaySelected(new Date(state.pickupTimeValue.pickup_date));

    if (isToday) {
      const isItBetween = timeToCheck.isBetween(min, max);
      // console.log('is between', isItBetween);

      return isItBetween ? 'text-red-700 font-bold' : '';
    }
  }



  return <div>
    <div>
      <SubTitle className="mt-6 mb-1">
        Pickup Time *
      </SubTitle>

      {/* Error text about pickup type selection */}
      {!state.error.pickup_time.type &&
        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
          Please Select Your Pickup Type
        </p>
      }



      <div className='mt-3'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <StyledDatePicker
                disableToolbar
                variant="inline"
                id="pickup-date"
                label="Date"
                format="MM-dd-yyyy"
                value={state.pickupTimeValue.pickup_date}
                onChange={pickupDateChangeHandle}
                minDate={moment().format('YYYY/MM/DD')}
                shouldDisableDate={disableSundays} />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Flex className='flex-col'>
                <InputHeader>Pickup Time Window *</InputHeader>
                <Flex className='items-center'>
                  <div className='width-1/8'>
                    <ErrorBoundary>
                      <DateTimePickerValet
                        id='pickup-start-time'
                        defaultValue={defaultTimeValet(true)}
                        disabled={false}
                        onChange={(value) => pickupDatetimeHandle(value, true)}
                        minTime={minTimeValet(true)}
                        maxTime={maxTimeValet(true)}
                        timeClassName={(time) => getHighlightedTimes(time, minTimeValet(true))}
                      /></ErrorBoundary>
                  </div>
                  <Dash>–</Dash>
                  <ErrorBoundary>
                    <DateTimePickerValet
                      id='pickup-end-time'
                      defaultValue={defaultTimeValet(false)}
                      disabled={false}
                      onChange={(value) => pickupDatetimeHandle(value, false)}
                      minTime={minTimeValet(false)}
                      maxTime={maxTimeValet(false)}
                      timeClassName={(time) => getHighlightedTimes(time, minTimeValet(false))}

                    /></ErrorBoundary>
                </Flex>
                {!state.error.pickup_time.window_time &&
                  <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs' style={{ marginLeft: '8px', marginTop: '5px' }}>
                    {state.error.pickup_time.window_time_error_text}
                  </p>
                }
              </Flex>
            </Grid>
          </Grid>

        </MuiPickersUtilsProvider>
      </div>
      <Flex className="flex-row-reverse">
        {/* {formatDatetime(dropoff_value.dropoff_date, dropoff_value.dropoff_early_datetime)} - {formatDatetime(dropoff_value.dropoff_date, dropoff_value.dropoff_value)} */}


        <div className='border-b-2 border-indigo-500 font-semibold text-base w-1/2 px-10 pt-3'>
          {formatDatetime(dropoff_value.dropoff_date, dropoff_value.dropoff_early_datetime, 'YYYY-MM-DD HH:mm')} - {formatDatetime(dropoff_value.dropoff_date, dropoff_value.dropoff_later_datetime, 'HH:mm')}
        </div>


        <div className='border-b-2 border-indigo-500 font-semibold text-base w-1/2 px-10'>
          {state.timeDistance && <TimeAndDistance duration={state.timeDistance.duration} distance={state.timeDistance.distance} />}
        </div>
      </Flex>
    </div>

  </div>;
}

const mapStateToProps = (state) => ({
  dropoff_value: {
    dropoff_type: state.deeleeoReducer.dropoff_type,
    dropoff_date: state.deeleeoReducer.dropoff_date !== '' ? state.deeleeoReducer.dropoff_date : ((new Date()).getHours() >= 17) ? moment().add(1, 'day').toDate() : moment().toDate(),
    dropoff_early_datetime: state.deeleeoReducer.dropoff_early_datetime,
    dropoff_later_datetime: state.deeleeoReducer.dropoff_later_datetime
  },
  pickup_value: {
    pickup_type: state.deeleeoReducer.pickup_type,
    pickup_date: state.deeleeoReducer.pickup_date !== '' ? state.deeleeoReducer.pickup_date : ((new Date()).getHours() >= 17) ? moment().add(1, 'day').toDate() : moment().toDate(),
    pickup_early_datetime: state.deeleeoReducer.pickup_early_datetime,
    pickup_later_datetime: state.deeleeoReducer.pickup_later_datetime
  },
  validation: {
    pickup_time: state.validationReducer.pickup_time,
    dropoff_time: state.validationReducer.dropoff_time,
  },
  deeleeo_data: state.deeleeoReducer,
  userData: state.userReducer,
  pickupData: state.pickupDropoffReducer.pickup_data,
  dropoffData: state.pickupDropoffReducer.dropoff_data,
});

const mapDispatchToProps = (dispatch) => ({
  updatePickupTime: (pickup_value) => dispatch(updatePickupTime(pickup_value)),
  updateDropoffTime: (dropoff_value) => dispatch(updateDropoffTime(dropoff_value)),
  validatePickupTime: (data) => dispatch(validatePickupTime(data)),
  validateDropoffTime: (data) => dispatch(validateDropoffTime(data)),
  validatePickupWindowTime: (data) => dispatch(validatePickupWindowTime(data)),
  validateDropoffWindowTime: (data) => dispatch(validateDropoffWindowTime(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupDropoffTimeSectionValet);
