import React, { useEffect, useState } from 'react'
import PageLayout from '../PageLayout'
import { Center, Flex } from '../../components/containers'
import { DetailsContainer, Divider } from '../Shipment/ShipmentDetails'
import { connect } from 'react-redux'
import { LargeText, SectionHeader, SummarySectionTitle } from '../../components/text'
import OrderItems from './components/OrderItems'
import OrderBreakdown from './components/OrderBreakdown'
import { Button } from '../../components/buttons'
import { withRouter } from 'react-router-dom'
import ActionSection from './components/ActionSection'
import { routes } from '../../routes'
import Loading from '../../components/Loading'
import { useDidMount } from '../../hooks/useDidMount'
import DeeleeoTabs from '../../components/DeeleeoTabs'
import { getEstimatePrice, submitDeeleeo, updateProcessStatus, prepayDeeleeo, notifyError, removeDeeleeoFromListByUUID } from '../../redux/reducers/deeleeosReducer'
import { clearDeeleeoData } from '../../redux/reducers/deeleeoReducer'
import { clearDropoffData } from '../../redux/reducers/pickupDropoffReducer'
import { getCompanyInfo } from '../../redux/actions/userActions'
import { isw3wAddress } from '../../components/What3WordsField'
import { creditCardMissingMessage } from '../DeeleeoDetails/CreateDeeleeo'
import Sticky from 'react-sticky-el';
import ErrorBoundary from '../../helpers/ErrorBoundary'
import BundleMap from '../DeeleeoDetails/components/BundleMap'
import axios from 'axios'
import { getURI } from '../../helpers/apiHelpers'
import { v4 as uuidv4 } from 'uuid';



function DeeleeoSummary(props) {
  // eslint-disable-next-line no-unused-vars
  const { deeleeos, apiCode, history, getEstimatePrice, submitDeeleeo, notifyError, prepayDeeleeo, clearDropoffData, clearDeeleeoData, userData, deeleeoProcessStatus, errorMessage, removeDeeleeoFromListByUUID } = props
  const [loading, setLoading] = useState(false)
  const didMount = useDidMount();

  const [selectedDeeleeo, setSelectedDeeleeo] = useState(null);
  const [selectedBundle, setSelectedBundle] = useState([]);
  const [submitting, setSubmitting] = useState(false);


  useEffect(() => {
    if (errorMessage) {
      notifyError('');
      updateProcessStatus('');
      alert(errorMessage);
    }
    /**
     * Onloading go to the top of the page
     */
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage])


  useEffect(() => {
    // for first time load/refersh the page
    if (didMount) {
      deeleeos.forEach((deeleeo, index) => {
        if (deeleeo && deeleeo.polyline === '') {
          return getEstimatePrice(deeleeo, index)
        }
      })
    }
    /**
     * Onloading go to the top of the page
     */
    window.scrollTo(0, 0);
  }, [apiCode, deeleeos, didMount, getEstimatePrice])

  useEffect(() => {
    switch (deeleeoProcessStatus) {
      case 'Prepaying Deeleeo':
        setLoading(true);
        break
      case 'Prepay Successful':
        createDeeleeo();
        break;
      case 'Submitting':
        setLoading(true);
        break;
      case 'Done':
        setLoading(false)
        history.push(routes.dashboard)
        break
      default:
        setLoading(false)
        break
    }
    /**
     * Onloading go to the top of the page
     */
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeleeoProcessStatus, history])

  // const calculateTotalForField = (field) => {
  //   let searchField = 0.00
  //   deeleeos.forEach((deeleeo) => {
  //     if (deeleeo && deeleeo[field]) {
  //       searchField += deeleeo[field]
  //     }
  //   })
  //   return searchField
  // }

  const checkUserCredit = () => {
    if (!submitting) {
      setSubmitting(true);
      createDeeleeo();
    }    
  }

  const ajaxSubmitToOnHold = (submission, uuid) => {
    // let uuidsSubmitted = [];
    /**
       * Submit all the data to the server to generate bundles
       */
    // setAjaxSubmitingToOnHold(true);
    axios({
      method: 'post',
      url: getURI('/api/web/deeleeo/on-hold'),
      headers: {
        'Content-Type': 'application/json',
      },
      data: submission,
      validateStatus: (status) => status === 200,
    }
    )
      .then(response => {
        if (response.status !== 200) {
          updateProcessStatus('Fail');
          throw new Error('Unable to create Deeleeo');
        } else {
          // console.log('success');
          // return response.text()
        }
      })
      .then(response => {
        removeDeeleeoFromListByUUID(uuid);
        updateProcessStatus('Done');
      })
      .catch((e) => {
        setSubmitting(false);
        const message = e.response?.data?.message || 'An error ocurred while processing your request';
        notifyError(message);
        updateProcessStatus('Fail');
      });
  }

  const createDeeleeo = () => {
    for (let i = 0; i < deeleeos.length; i++) {
      if (!deeleeos[i]) {
        continue;
      }
      setTimeout(() => {
        /**
         * Before sending the data to the server, we prepend the what3words address if any exist 
         */
        const deeleeo = addw3wToDescription(deeleeos[i]);


        if (!deeleeo.uuid) {
          deeleeo.uuid = uuidv4();
        }

        let redirectDeeleeosToOnHold: any[] = [];

        if ((userData.isOnHoldEnabled
          // && parseInt(deeleeo.pickup_type + '') !== 3
          // && parseInt(deeleeo.dropoff_type + '') !== 3
          && !deeleeo.assign_my_fleet_driver) || deeleeo.is_valet) {
          redirectDeeleeosToOnHold.push(deeleeo);
          ajaxSubmitToOnHold(redirectDeeleeosToOnHold, deeleeo.uuid);
        } else {
          submitDeeleeo(deeleeo, i);
        }

      },  1000);
    }
  }



  /**
   * Prepends the what3words addresses to the description
   * @param {jsonObject} deeleeo 
   * @returns 
   */
  const addw3wToDescription = (deeleeo) => {
    const newLine = '\r\n';
    let prepend = '';

    /**
     * Checks for pickup being w3w
     */
    if (isw3wAddress(deeleeo.pickup_address)) {
      prepend = 'Pickup what3words: ' + deeleeo.pickup_address + newLine
    }

    /**
     * Checks for drop off being w3w
     */
    if (isw3wAddress(deeleeo.dropoff_address)) {
      prepend += 'Drop off what3words: ' + deeleeo.dropoff_address + newLine
    }

    /**
     * Modify description
     */
    deeleeo.description = prepend + deeleeo.description;

    return deeleeo;
  }



  return (
    <PageLayout wider>
      <DeeleeoTabs selectedTab={1} />
      <Divider />
      <DetailsContainer>
        <SectionHeader>Your order:</SectionHeader>
        <Divider />
        <ActionSection disabled={deeleeos.length === 0} history={history} />

        {deeleeos.length > 0 ?
          <>
            <Sticky>
              <ErrorBoundary><BundleMap deeleeos={deeleeos} selectedDeeleeo={selectedDeeleeo} selectedBundle={selectedBundle} /></ErrorBoundary>
            </Sticky>
            <Divider />
            {/* display detail of deeleeo in line */}
            <OrderItems setSelectedDeeleeo={setSelectedDeeleeo} setSelectedBundle={setSelectedBundle} deeleeos={deeleeos} />

            {/* display total amount */}
            <OrderBreakdown deeleeos={deeleeos} />

            <Flex className='mt-14 flex-col'>
              {/* temporary error message about missing card in user account */}
              {(!userData.hasCardOnAccount && !userData.isEFTPayment && !userData.isUserCredit) && creditCardMissingMessage()}

              <Flex>
                <Button
                  disabled={submitting||loading || (!userData.hasCardOnAccount && !userData.isEFTPayment && !userData.isUserCredit)}
                  onClick={() => checkUserCredit()}>{!loading ? 'Submit Deeleeo' : 'Submitted'}
                </Button>
              </Flex>
            </Flex>
          </>
          :
          <>
            <Flex className='content-around'>
              <SummarySectionTitle>Recipient</SummarySectionTitle>
              <SummarySectionTitle>Service & Prices</SummarySectionTitle>
              {/*<SummarySectionTitle>Promo Code</SummarySectionTitle>*/}
            </Flex>
            <Divider />
            <Center className='flex-col my-20'>
              <LargeText>Your order summary is empty</LargeText>
              <Button onClick={() => {
                clearDeeleeoData();
                clearDropoffData();
                return history.push(routes.choose)
              }}>Make a Deeleeo</Button>
            </Center>
            <Divider />
          </>
        }

        <Loading open={loading} />
      </DetailsContainer>
    </PageLayout>
  )
}

const mapStateToProps = state => ({
  deeleeos: state.deeleeosReducer.deeleeos,
  apiCode: state.userReducer.apiCode,
  userData: state.userReducer,
  deeleeoProcessStatus: state.deeleeosReducer.status,
  errorMessage: state.deeleeosReducer.error
})

const mapDispatchToProps = dispatch => ({
  getEstimatePrice: (deeleeo_data, index) => dispatch(getEstimatePrice(deeleeo_data, index)),
  submitDeeleeo: (deeleeo_data, index) => dispatch(submitDeeleeo(deeleeo_data, index)),
  prepayDeeleeo: (amount, api_key) => dispatch(prepayDeeleeo(amount, api_key)),
  notifyError: (error) => dispatch(notifyError(error)),
  clearDeeleeoData: () => dispatch(clearDeeleeoData()),
  clearDropoffData: () => dispatch(clearDropoffData()),
  updateProcessStatus: (data) => dispatch(updateProcessStatus(data)),
  getCompanyInfo: (api_key) => dispatch(getCompanyInfo(api_key)),
  removeDeeleeoFromListByUUID: (index) => dispatch(removeDeeleeoFromListByUUID(index))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeeleeoSummary))
