import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageLayout from "../PageLayout";
import { AccentHeader, SectionHeader } from "../../components/text";
import { Flex } from "../../components/containers";
import ShipmentInformation from "../../components/ShipmentInformation";
import { NextButton } from "../../components/buttons";
import DeliveriesInformation from "./components/DeliveriesInformation";
import { routes } from "../../routes";
import DeeleeoTabs from "../../components/DeeleeoTabs";

import { resetValidationStatus, validatedDropoffData, validatedPickupData } from '../../redux/reducers/validationReducer'
import { validatePickupDropOffData } from '../../helpers/validationHelper'

export const DashboardContainer = styled.div`
  ${tw`flex flex-col mx-8  pb-12`}
`;

export const DetailsContainer = styled.div`
${tw`flex flex-col mx-4 xl:mx-60 2xl:mx-80 pb-12`}
`;

export const Divider = styled.hr`
  ${tw`mb-10`}
`;

function ShipmentDetails(props) {
  const { history, pickupData, dropoffData, resetValidationStatus, validatedDropoffData, validatedPickupData, validation } = props;

  const [validationData, setValidationData] = useState(validation);

  const goToCreateDeeleeo = state => {
    let check_pickup_data = validatePickupDropOffData(pickupData);
    let check_dropoff_data = validatePickupDropOffData(dropoffData, true);

    setValidationData({
      ...validationData,
      pickup_data_validation: check_pickup_data,
      dropoff_data_valition: check_dropoff_data
    })

    if (check_pickup_data && check_dropoff_data) {
      // extra step force update validation status
      validatedPickupData(check_pickup_data);
      validatedDropoffData(check_dropoff_data);
      history.push(routes.createDeeleeo)
    }
  }

  useEffect(() => {
    // dispatch update validation status
    validatedPickupData(validationData.pickup_data_validation);
    validatedDropoffData(validationData.dropoff_data_valition);
  }, [validationData, validatedPickupData, validatedDropoffData])

  // componentDidMount
  // reset all validation
  useEffect(() => {
    resetValidationStatus()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageLayout wider>
      <DeeleeoTabs selectedTab={1} />
      <Divider />
      <DetailsContainer>
        <AccentHeader>What’s the Deeleeo?</AccentHeader>
        <Flex>
          <SectionHeader>Location details:</SectionHeader>
        </Flex>
        <hr />
        <DeliveriesInformation />
        <Flex>
          <ShipmentInformation
            error={validation.pickup_data_validation}
            shipmentInfo={pickupData}
            title={"Pickup Information"}
            isSender
          />
          <ShipmentInformation
            error={validation.dropoff_data_validation}
            shipmentInfo={dropoffData}
            title={"Drop off Information"}
            saveInfoCheckbox
            showFields
          />
        </Flex>
        <NextButton onClick={() => goToCreateDeeleeo() }>Next</NextButton>
      </DetailsContainer>
    </PageLayout>
  );
}

const mapStateToProps = (state) => ({
  pickupData: state.pickupDropoffReducer.pickup_data,
  dropoffData: state.pickupDropoffReducer.dropoff_data,
  validation: state.validationReducer
});

const mapDispatchToProps = (dispatch) => ({
  resetValidationStatus: () => dispatch(resetValidationStatus()),
  validatedPickupData: (data) => dispatch(validatedPickupData(data)),
  validatedDropoffData: (data) => dispatch(validatedDropoffData(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShipmentDetails)
);
