import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const Status = styled.div`
  ${tw`flex justify-center rounded-full items-center capitalize`}
  font-size: 12px;
  width: 124px;
  height: 28px;
  background-color: ${props => props.type};
`;

export const getStatusText = (status) => {

  /**
   * Status Legend

Bolded labels are what the User should see (web app, shopify, mobile, APIs, etc)

public const NEW = 1;//Submitted - Submitted
    public const AWAITING_DRIVER_ACCEPT = 2;//Submitted -Submitted
    public const ACCEPTED = 3; - Processed
    public const STARTED = 4; - Processed
    public const ENROUTE = 5; - Out for delivery
    public const DELIVERED = 6; - Delivered
    public const TRANSFERRING = 7; - 
    public const CANCELED_BY_AGENT = 8;- Cancelled
    public const CANCELED_BY_USER = 9; - Cancelled
    public const CANCELED_BY_ADMIN = 10;- Cancelled
    public const PAYMENT_ERROR = 11; - Payment Error
    public const RESTRICTED = 12; - Not Serviable

    // new status for help request
    public const HELP_REQUEST = 13;//Submitted - Submitted
    public const HELP_REQUEST_STARTED = 14;//On route - Enroute
    public const HELP_REQUEST_COMPLETED = 15;//Done - Completed

    // new status for return deeleeo
    public const RETURNING_DEELEEO = 16; - Returning
    public const RETURNED_DEELEEO = 18; - Returned

    public const ON_HOLD = 17; - Submitted

    public const CANCELED_AT_PICKED_UP = 19; - Cancelled at Pickup
   */



  switch (status) {
    case 'NEW':
    case 'AWAITING DRIVER ACCEPT':
    case 'HELP REQUEST':
    case 'ON HOLD':
      return 'Submitted';
    case 'ACCEPTED':
    case 'STARTED':
      return 'Processed';
    case 'ENROUTE':
      return 'Out for deliver';
    case 'DELIVERED':
      return 'Delivered';
    case 'CANCELED BY AGENT':
    case 'CANCELED BY USER':
    case 'CANCELED BY ADMIN':
      return 'Cancelled';
    case 'PAYMENT ERROR':
      return 'Payment Error';
    case 'RESTRICTED':
      return 'Not Serviable';
    case 'HELP REQUEST STARTED':
      return 'Enroute';
    case 'HELP REQUEST COMPLETED':
      return 'Completed';
    case 'RETURNING DEELEEO':
    case 'RETURNING':
      return 'Returning';
    case 'RETURNED DEELEEO':
      return 'Returned';
    case 'CANCELED AT PICKED UP':
      return 'Cancelled at Pickup';
    case 'PICKUP IN PROGRESS':
      return 'Pickup in progress';
    case 'IN TRANSIT':
      return 'In Transit';
    default:
      break;
  }



}


function DeeleeoStatus(props) {
  const { status, type } = props

  //  console.log(status);
  // console.log(type);

  // console.log(getStatusText(status));



  const getType = (type) => {
    const STATUS = {
      1: '#BEC9F3',//Blue New
      3: '#B4EFD0',//Pale Green ACCEPTED
      4: '#B4EFD0',//Pale Green STARTED
      5: '#FFDF99',//Pale yellow ENROUTE
      6: '#85EE91',//Strong green DELIVERED
      7: '#D9D9D9',//Pale grey TRANSFERRING
      8: '#DB6767',//Red CANCELED_BY_AGENT
      9: '#DB6767',//Red CANCELED_BY_USER
      10: '#DB6767',//Red CANCELED_BY_ADMIN
      11: '#D9D9D9',//Pale grey PAYMENT_ERROR
      12: '#D9D9D9',//Pale grey RESTRICTED
      13: '#FFDF99',//Pale yellow HELP REQUEST
      14: '#FFDF99',//Pale yellow ENROUTE
      15: '#85EE91',//Strong green HELP REQUEST COMPLETED
      16: '#D9D9D9',//Pale grey RETURNING_DEELEEO
      17: '#D9D9D9',//Pale grey ON_HOLD
      18: '#DB6767',//Red RETURNED DEELEEO
      19: '#DB6767',//Red CANCELED AT PICKED UP

    }

    return STATUS[type]
  }

  return (
    <Status type={getType(type)}>
      {getStatusText(status)}
    </Status>
  )
}

export default DeeleeoStatus
