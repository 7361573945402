import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { connect } from "react-redux";
import { updateMultiPackage, updatePackageCount, updateSizeSelection } from '../../../redux/reducers/deeleeoReducer'
import { validatePackageCount, validateSizeWeightSelection } from "../../../redux/reducers/validationReducer";
import { convertKGtoLBS } from "./SizingButton";

// const BaseContainer = styled.div`
//   ${tw`text-lightgrey mt-3`}
//   font-family: Montserrat;
//   font-size: 12px;
// `;

const StyledLabel = styled.label`
  ${tw``}
  color: #000000;
  font-family: Varela Round;
  font-size: 13px;
  ${props => props.error && tw`text-errorRed`}
  ${props => props.blackColor && tw`text-black`}
`

const StyledInput = styled.input`
  ${tw`border-b focus:outline-none focus:border-primaryBlue`}
  ${props => props.error && tw`border-errorRed`}
`

export const StyledNumberBox = (props) => {
  const {  value, label, changeHandle, id, type, error, helperText, required } = props
  return (
    <div className="w-3/12 inline-block absolute top-2 ml-3" >
      <div className="flex flex-col" >
        <StyledLabel error={error} htmlFor={id}>{required ? `${label} *` : label}
          
        </StyledLabel>
        <StyledInput error={error} type={type} id={id} name={id} onChange={changeHandle} value={value} min={0} />
        {error && <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>{helperText}</p>}
      </div>
    </div>
  );
}

const ButtonToggle = styled.button`
  ${tw`rounded text-fontBlue uppercase w-8/12 my-2`}
  background-color: #efefef;
  border: 1px solid #d9d9d9;
  ${(props) => props.selected && tw`bg-secondaryOrange text-white border-none`}
  height: 44px;
  font-family: Varela Round;
  font-size: 13px;
`;

const SizingButtonSingle = (props) => {
  const { validateSizeWeightSelection, sizeData, deeleeoDetail, validationWeight, validationPackage, validatePackageCount, updateMultiPackage, updatePackageCount } = props;
  // const [isSelected, setIsSelected] = useState('')
  // console.log('validation package', validationPackage);


  const [state, setState] = useState({
    sizeId: null,
    weightId: null,
    packageCount: 0,
    errorWeight: validationWeight,
    errorPackage: validationPackage,
    selected: false
  });





  // eslint-disable-next-line no-unused-vars
  const setSizeId = (data) => {
    setState(state => ({ ...state, sizeId: data }));
  }

  // eslint-disable-next-line no-unused-vars
  const setWeightId = (data) => {
    setState(state => ({ ...state, weightId: data }));
  }

  const setPackageCount = (data) => {
    setState(state => ({ ...state, packageCount: data }));
  }

  // eslint-disable-next-line no-unused-vars
  const setErrorWeight = (data) => {
    setState(state => ({ ...state, errorWeight: data }));
  }
  // eslint-disable-next-line no-unused-vars
  const setErrorPackage = (data) => {
    setState(state => ({ ...state, errorPackage: data }));
  }

  const setSelected = (data) => {
    setState(state => ({ ...state, selected: data }));
  }

  useEffect(() => {

    let packages = [...deeleeoDetail.multi_package];
    if (packages.length > 0) {
      validatePackageCount(true);
      validateSizeWeightSelection(true);
    }
    let packageObject = packages.find(p => p.size === sizeData.id && p.weight === sizeData.weight_category[0].id);
    if (packageObject) {


      if (packageObject.packages > 0) {

        setSelected(true);
        setPackageCount(packageObject.packages);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addPackageToState = ({ packageCount }) => {

    let packages = [...deeleeoDetail.multi_package];
    let packageObject = packages.find(p => p.size === sizeData.id && p.weight === sizeData.weight_category[0].id);

    if (packageObject) {
      packages.forEach(p => {
        if (p.size === sizeData.id && p.weight === sizeData.weight_category[0].id) {
          p.packages = packageCount;
        }
      })

    } else {
      packages.push({ size: sizeData.id, weight: sizeData.weight_category[0].id, packages: packageCount });
    }

    checkValidation({ packages });
    //update reducer here

    updateTotalPackageCount(packages);

    updateMultiPackage(packages);

  }

  const updateTotalPackageCount = (packages) => {
    console.log('updateTotalPackageCount', packages);
    
    updatePackageCount(packages.reduce((accumulator, currentValue) => accumulator + currentValue.packages, 0));
  }

  const removePackageFromState = ({ size, weight }) => {
    let packages = [...deeleeoDetail.multi_package];
    packages = packages.filter(p => p.size !== size && p.weight !== weight);
    checkValidation({ packages });
    updateTotalPackageCount(packages);
    updateMultiPackage(packages);
  }

  const checkValidation = ({ packages }) => {

    if (packages.length === 0) {

      validatePackageCount(false);
      validateSizeWeightSelection(false);
    } else {
      validatePackageCount(true);
      validateSizeWeightSelection(true);
    }
  }



  const onClickHandler = (() => {
    // updateSizeSelection(sizeData.id, sizeData.weight_category[0].id)
    const selected = !state.selected
    setSelected(selected);

    if (selected && state.packageCount > 0) {
      addPackageToState({ packageCount: state.packageCount });
    } else {
      removePackageFromState({ size: sizeData.id, weight: sizeData.weight_category[0].id });
    }
  })

  const updatePackageCountChange = (event) => {
    try {
      const packages = parseInt(event.target.value);
      setPackageCount(packages);

      if (state.selected && packages > 0) {
        addPackageToState({ packageCount: packages });
      } else {
        removePackageFromState({ size: sizeData.id, weight: sizeData.weight_category[0].id });
      }
    } catch {
      setPackageCount(0);
    }


  }

  return <div className="relative">
    <ButtonToggle
      className="mx-2"
      onClick={onClickHandler}
      selected={state.selected}>
      {sizeData.name}
      <p>
        {/* {sizeData.weight_category[0].low === 0 ? */}
        {/* <span>Less Than </span> : */}
        <span>{sizeData.weight_category[0].low} - </span>
        {/* } */}
        <span>{sizeData.weight_category[0].high} kg</span>
        <span> - ({convertKGtoLBS(sizeData.weight_category[0].low)} - </span>
        <span> {convertKGtoLBS(sizeData.weight_category[0].high)} lbs)</span>
      </p>

    </ButtonToggle>

    <StyledNumberBox
      infoDetail={'Please submit the number of items to be delivered for this delivery. And then select size/weight category that matches the largest package within this delivery request'}
      value={state.packageCount}
      label={'Package Count'}
      changeHandle={updatePackageCountChange}
      id={'package_count'}
      type={'number'}
      error={!validationPackage && state.selected}
      helperText={'Package Required'}
      required={true} />
  </div>
    ;
};

const mapStateToProps = state => ({
  validationWeight: state.validationReducer.size_weight_selection,
  validationPackage: state.validationReducer.package_count,
  deeleeoDetail: state.deeleeoReducer,
})

const mapDispatchToProps = dispatch => ({
  updateSizeSelection: (size_id, weight_id) => dispatch(updateSizeSelection(size_id, weight_id)),
  validateSizeWeightSelection: (data) => dispatch(validateSizeWeightSelection(data)),
  validatePackageCount: (data) => dispatch(validatePackageCount(data)),
  updateMultiPackage: (data) => dispatch(updateMultiPackage(data)),
  updatePackageCount: (data) => dispatch(updatePackageCount(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SizingButtonSingle);