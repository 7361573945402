import React from 'react'
import { connect } from 'react-redux'
import { updateMobileNumber } from '../../redux/actions/userActions'
import { createPickupLocation, updatePickupLocation } from '../../redux/actions/pickupActions'
import PageLayout from '../PageLayout'
import InputField from '../../components/InputField'
import { FieldContainerNoMargin, Flex } from '../../components/containers'
import { Button } from '../../components/buttons'
import { AccentHeader, BusinessName, DescriptionText, SectionHeader } from '../../components/text'
import ProgressBar from '../../components/ProgressBar'
import { withRouter } from 'react-router-dom'
import { routes } from '../../routes'
import { useFormik } from 'formik'
import * as yup from 'yup'
import 'yup-phone'

function RegisterPickupLocation(props) {
  const { apiCode, updateMobile, createPickupLocation, updatePickupLocation, history,
    businessName, mobile, street, appt, city, province, postalCode
  } = props

  const noDigits = (value) => /^([^0-9]*)$/.test(value)

  const validationSchema = yup.object({
    phone: yup.string().phone('',false, 'Phone number must be valid').required('Phone number is required'),
    street: yup.string().required('Address is required'),
    appt: yup.string(),
    city: yup.string().test('Only characters', 'Invalid entry', noDigits).required('City is required'),
    province: yup.string().test('Only characters', 'Invalid entry', noDigits).required('Province is required'),
    postalCode: yup.string().required('A postal code is required').matches(
      '^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY][0-9][abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ] {0,1}[0-9][abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ][0-9]$',
      'Must be a valid postal code'
      ),
  })

  const formik = useFormik({
    initialValues: {
      phone: mobile || '',
      street: street || '',
      appt: appt || '',
      city: city || '',
      province: province || '',
      postalCode: postalCode || ''
    },
    validationSchema: validationSchema,
    onSubmit: (values => {
      const mobileRequestData = {
        mobile: values.phone.trim(),
        api_key: apiCode
      }
      updateMobile(mobileRequestData)
      updatePickupLocation(values)

      const pickupLocationRequestData = {
        api_key: apiCode,
        business_appt_number: values.appt,
        business_street_address: values.street,
        business_pickup_city: values.city,
        business_pickup_province: values.province,
        business_pickup_postal_code: values.postalCode
      }
      createPickupLocation(pickupLocationRequestData)

      history.push(routes.registerPickupHours)
    })
  })

  return (
    <PageLayout>
      <AccentHeader>Account Details</AccentHeader>
      <BusinessName>{businessName}</BusinessName>
      <ProgressBar width={'50%'}/>
      <SectionHeader>Contact Information</SectionHeader>
      <DescriptionText>Enter the phone number of the person managing your deliveries</DescriptionText>
      <FieldContainerNoMargin onSubmit={formik.handleSubmit}>
        <InputField required id={'phone'} type={'text'} label={'Phone'} onBlur={formik.handleBlur} helperText={formik.errors.phone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    onChange={formik.handleChange} value={formik.values.phone}/>
        <SectionHeader>Pickup Location</SectionHeader>
        <InputField required id={'street'} type={'text'} label={'Street address'} onBlur={formik.handleBlur} helperText={formik.errors.street}
                    error={formik.touched.street && Boolean(formik.errors.street)}
                    onChange={formik.handleChange} value={formik.values.street}/>

        <InputField id={'appt'} type={'text'} label={'Suite / Appt #'} onBlur={formik.handleBlur} helperText={formik.errors.appt}
                    error={formik.touched.appt && Boolean(formik.errors.appt)}
                    onChange={formik.handleChange} value={formik.values.appt}/>

        <InputField required id={'city'} type={'text'} label={'City'} onBlur={formik.handleBlur} helperText={formik.errors.city}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    onChange={formik.handleChange} value={formik.values.city}/>
        <Flex>
          <InputField required id={'province'} type={'text'} label={'Province'} onBlur={formik.handleBlur} helperText={formik.errors.province}
                      error={formik.touched.province && Boolean(formik.errors.province)}
                      onChange={formik.handleChange} value={formik.values.province}/>

          <InputField required id={'postalCode'} type={'text'} label={'Postal code'} onBlur={formik.handleBlur} helperText={formik.errors.postalCode}
                      error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                      onChange={formik.handleChange} value={formik.values.postalCode}/>
        </Flex>
        <Button className={'w-52'} type='submit'>Next: Pickup hours</Button>
      </FieldContainerNoMargin>

    </PageLayout>
  )
}

const mapStateToProps = state => ({
  apiCode: state.userReducer.apiCode,
  businessName: state.userReducer.businessName,
  mobile: state.userReducer.mobile,
  street: state.pickupReducer.pickupLocation.street,
  appt: state.pickupReducer.pickupLocation.appt,
  city: state.pickupReducer.pickupLocation.city,
  province: state.pickupReducer.pickupLocation.province,
  postalCode: state.pickupReducer.pickupLocation.postalCode
})

const mapDispatchToProps = dispatch => ({
  updateMobile: (requestData) => dispatch(updateMobileNumber(requestData)),
  createPickupLocation: (requestData) => dispatch(createPickupLocation(requestData)),
  updatePickupLocation: (info) => dispatch(updatePickupLocation(info))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPickupLocation))
