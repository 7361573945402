import styled from 'styled-components'
import tw from 'twin.macro'

export const Button = styled.button`
  font-size: 16px;
  color: white;
  font-family: Varela Round;
  border-radius: 3px;
  ${tw`bg-primaryBlue h-12 mt-4 px-8`}
  ${props => props.disabled && tw`text-fontBlue bg-disabled cursor-not-allowed`}
  ${props => props.selected && tw` bg-indigo-900 cursor-not-allowed`}
`

export const CancelButton = styled(Button)`
  ${tw`bg-errorRed h-12 m-4 px-8`}
`

export const ThinSelect = styled.select`
  ${tw`ml-auto my-auto h-12 mt-4 px-8`}
height: 36px;
width: 240px;
font - size: 14px;
margin: 5px;
white - space: nowrap;
`
export const ThinButton = styled(Button)`
  ${tw`ml-auto my-auto`}
  height: 36px;
  width:200px;
  font-size: 14px;
  margin : 5px;
  white-space:nowrap;
`

export const ChooseButton = styled(Button)`
  ${tw` p-6  object-center`}
  font-size: 20px;
`

export const BackButton = styled.button`
  ${tw`flex items-center pl-3 mt-4 mr-4`}
  width: 100px;
  height: 48px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  font-family: Varela Round;
  font-size: 16px;
  color: #6f84da;
`

export const SecondaryButton = styled.button`
  ${tw`flex items-center justify-center mt-4 mr-4`}
  width: 216px;
  height: 48px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  font-family: Varela Round;
  font-size: 16px;
  color: #6f84da;
`

export const DeliveryButton = styled.button`
  ${tw`text-secondaryOrange px-4 rounded mr-4`}
  height: 36px;
  font-family: Varela Round;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  ${props => props.disabled && tw`text-gray-300 cursor-not-allowed`}
`


export const NextButton = styled(Button)`
  width: 160px;
`
