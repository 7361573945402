import { authActions } from '../actions/authActions'

const INITIAL_STATE = {
  loginStatus: '',
  loginError: '',
  signupStatus: '',
  forgotStatus: '',
  forgotError: '',
  resetToken: '',
  forgotEmail: '',
  resetStatus: '',
  otp: '',
  error: ''
}

export const SignupStatus = {
  SignupRequest: 'SignupRequest',
  SignupSuccess: 'SignupSuccess',
  SignupError: 'SignupError'
};

export const LoginStatus = {
  LoginRequest: 'LoginRequest',
  LoginSuccess: 'LoginSuccess',
  LoginError: 'LoginError',
  Logout: 'Logout'
};

export const ForgotStatus = {
  ForgotRequest: 'ForgotRequest',
  ForgotSuccess: 'ForgotSuccess',
  ForgotError: 'ForgotError'
}

export const ResetStatus = {
  ResetRequest: 'ResetRequest',
  ResetSuccess: 'ResetSuccess',
  ResetError: 'ResetError'
}

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authActions.REGISTER_REQUEST:
      return {
        ...state, signupStatus: SignupStatus.SignupRequest
      }
    case authActions.REGISTER_SUCCESS:
      return {
        ...state, signupStatus: SignupStatus.SignupSuccess
      }
    case authActions.REGISTER_FAILURE:
      return {
        ...state, signupStatus: SignupStatus.SignupError, error: action.data
      }
    case authActions.LOGIN_REQUEST:
      return {
        ...state, loginStatus: LoginStatus.LoginRequest
      }
    case authActions.LOGIN_SUCCESS:
      return {
        ...state, loginStatus: LoginStatus.LoginSuccess
      }
    case authActions.LOGIN_FAILURE:
      return {
        ...state, loginStatus: LoginStatus.LoginError, loginError: action.data
      }
    case authActions.FORGOT_REQUEST:
      return {
        ...state, forgotStatus: ForgotStatus.ForgotRequest
      }
    case authActions.FORGOT_SUCCESS:
      return {
        ...state, forgotStatus: ForgotStatus.ForgotSuccess, resetToken: action.data.resetToken, forgotEmail: action.data.email
      }
    case authActions.FORGOT_FAILURE:
      return {
        ...state, forgotStatus: ForgotStatus.ForgotError, forgotError: action.data
      }
    case authActions.RESET_REQUEST:
      return {
        ...state, resetStatus: ResetStatus.ResetRequest
      }
    case authActions.RESET_SUCCESS:
      return {
        ...state, resetStatus: ResetStatus.ResetSuccess
      }
    case authActions.RESET_FAILURE:
      return {
        ...state, resetStatus: ResetStatus.ResetError
      }
    case authActions.UPDATE_OTP:
      return {
        ...state, otp: action.data
      }
    case authActions.CLEAR_LOGIN_ERROR:
      return {
        ...state, loginError: ''
      }
    case authActions.CLEAR_FORGOT_STATUS:
      return {
        ...state, forgotStatus: '', forgotError: ''
      }
    case authActions.CLEAR_AUTH_STATUS:
      return {
        ...state, forgotStatus: '', resetStatus: '', loginStatus: '', signupStatus: ''
      }
    case authActions.LOGOUT:
      return {
        ...state, loginStatus: LoginStatus.Logout
      }
    case authActions.CLEAR_REGISTER_ERROR:
      return {
        ...state, error: '', signupStatus: ''
      }
    default: return state
  }
}
