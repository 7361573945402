import React, { useEffect, useState } from "react";
import { SubTitle } from "../../../components/text";
import styled from "styled-components";
import tw from "twin.macro";
import { connect } from "react-redux";
import { StyledLabel } from "../../../components/InputField";
import InfoIcon from "../../../assets/icons/Info-icon.svg";
import { StyledToolTip } from "./shared";
import { updateItemName, updateRO, updateVin } from "../../../redux/reducers/deeleeoReducer";
import { validateItemName, validateVin } from "../../../redux/reducers/validationReducer";
import { useRecoilState } from "recoil";
import { isVehicleSwap } from "../../../App";

const StyledInput = styled.input`
    ${tw`border p-1 focus:outline-none focus:border-primaryBlue`}
    font-size: 13px;
    ${props => !props.error && tw`border-errorRed`}
`;

function ItemName(props) {
    const { updateItemName, validateItemName, validateVin, validation, validationVin, isValet, updateVin, updateRO } = props;

    // eslint-disable-next-line no-unused-vars
    const [vehicleSwap, setVehicleSwap] = useRecoilState(isVehicleSwap);
    const [state, setState] = useState({
        item_name: '',
        vin: '',
        ro: '',
    });
    const [error, setError] = useState({
        item_name: validation,
        vin:validationVin
    });

    // console.log(error);
    

    const setItemName = (data) => {
        setState(state => ({ ...state, item_name: data }));
    }

    const setVin = (data) => {
        setState(state => ({ ...state, vin: data }));
    }

    const setRO = (data) => {
        setState(state => ({ ...state, ro: data }));
    }

    const handleChange = (event) => {
        setItemName(event.target.value);
    };

    const handleVinChange = (event) => {
        setVin(event.target.value);
    };

    const handleROChange = (event) => {
        setRO(event.target.value);
    };

    useEffect(() => {
        setError(error => ({ ...error, item_name: validation }));
    }, [validation])

    useEffect(() => {
        setError(error => ({ ...error, vin: validationVin }));
    }, [validationVin])

    useEffect(() => {
        const timeout = setTimeout(() => {
            const appended = ' - Valet';
            const appendedVehicleSwap = ' - Vehicle Swap';
            let value = state.item_name.replace(appended, '');
            value = state.item_name.replace(appendedVehicleSwap, '');
            // console.log('valet: ',isValet,'swap: ',vehicleSwap);
            
            if (value !== '' && isValet && !vehicleSwap) {
                value = value + appended;
            }

            if (value !== '' && vehicleSwap) {
                value = value + appendedVehicleSwap;
            }

            updateItemName(value);

            if (!error.item_name && value !== '') {
                validateItemName(true);
                setError(error => ({ ...error, item_name: true }));
            }
        }, 1000);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.item_name]);

    useEffect(() => {

        const timeout = setTimeout(() => {
            
            updateVin(state.vin);

            if (!error.vin && state.vin.length === 17) {
                validateVin(true);
                setError(error => ({ ...error, vin: true }));
            }
        }, 1000);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.vin]);

    useEffect(() => {

        const timeout = setTimeout(() => {
            updateRO(state.ro);
        }, 1000);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.ro]);

    const getLabel = () => {
        if (isValet) {
            return 'Customer Name / License Plate'
        }
        return 'Reference name / Purchase Order#'
    }

    return (
        <div className="flex flex-row">
            <div className="flex flex-col w-1/2 px-5">
                <StyledLabel className="flex mt-6" htmlFor={"ref-number"}>
                    <SubTitle>{getLabel()} *</SubTitle>
                    <StyledToolTip
                        title={
                            <>
                                <div>
                                    Create a custom reference name or number to track your shipment.
                                    <br className="mb-4" />
                                    When you add a reference name or number, both you and your
                                    recipient can use it to monitor the delivery status of your
                                    item(s) on the Deeleeo mobile app (available on both the App
                                    Store and Google Play).
                                </div>
                            </>
                        }
                        placement={"top"}
                        arrow
                    >
                        <img
                            className="-mt-1 ml-1"
                            src={InfoIcon}
                            alt={"premium information"}
                        />
                    </StyledToolTip>
                </StyledLabel>
                <StyledInput error={error.item_name} onChange={handleChange} value={state.item_name} />
                {!error.item_name &&
                    <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
                        Reference Name Is Required
                    </p>
                }
                {isValet && <>
                    <StyledLabel className="flex mt-6" htmlFor={"ref-number"}>
                        <SubTitle>RO</SubTitle>
                        <StyledToolTip
                            title={
                                <>
                                    <div>
                                        Repair Oder Number.
                                    </div>
                                </>
                            }
                            placement={"top"}
                            arrow
                        >
                            <img
                                className="-mt-1 ml-1"
                                src={InfoIcon}
                                alt={"premium information"}
                            />
                        </StyledToolTip>
                    </StyledLabel>
                    <StyledInput error={true} onChange={handleROChange} value={state.ro} />
                </>
                }
            </div>
            <div className="flex flex-col w-1/2 px-5">
                {isValet && <>
                    <StyledLabel className="flex mt-6" htmlFor={"ref-number"}>
                        <SubTitle>VIN*</SubTitle>
                        <StyledToolTip
                            title={
                                <>
                                    <div>
                                        VIN 17 characters long.
                                    </div>
                                </>
                            }
                            placement={"top"}
                            arrow
                        >
                            <img
                                className="-mt-1 ml-1"
                                src={InfoIcon}
                                alt={"premium information"}
                            />
                        </StyledToolTip>
                    </StyledLabel>
                    <StyledInput error={error.vin} onChange={handleVinChange} value={state.vin} />
                    {!error.vin &&
                        <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
                            VIN Is Required
                        </p>
                    }
                </>
                }


            </div>

        </div>
    );
}

const mapStateToProps = (state) => ({
    item_name: state.deeleeoReducer.item_name,
    validation: state.validationReducer.item_name,
    validationVin: state.validationReducer.vin,
    vin: state.deeleeoReducer.vin,
    ro: state.deeleeoReducer.ro
});

const mapDispatchToProps = (dispatch) => ({
    updateItemName: (itemName) => dispatch(updateItemName(itemName)),
    validateItemName: (data) => dispatch(validateItemName(data)),
    validateVin: (data) => dispatch(validateVin(data)),
    updateVin: (data) => dispatch(updateVin(data)),
    updateRO: (data) => dispatch(updateRO(data))

});

export default connect(mapStateToProps, mapDispatchToProps)(ItemName);
