import React, { useEffect, useState } from "react";
import { SubTitle } from '../../../components/text'
import { connect } from "react-redux";
import { StyledToolTip } from "./shared";
import styled from "styled-components";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { updateDropoffMethod } from '../../../redux/reducers/deeleeoReducer'
import { validateDropoffMethod } from "../../../redux/reducers/validationReducer";

const StyledLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: 14px;
    font-family: Montserrat;
    color: #3d405b;
  }
`

const RadioOption = (props) => {
    const { value, label, selectedValue, isDisabled, infoDetail } = props
    return (
        <StyledToolTip
            title={<>
                <div>
                    {infoDetail}
                </div>
            </>
            }
            placement={"top"} arrow>

            <StyledLabel value={value} control={<Radio disabled={isDisabled}
                checked={value === selectedValue} />} label={label} />
        </StyledToolTip>
    )
}

function DropoffTypeSection(props) {
    const dropoffType = {
        CONTACTLESS: '0',
        IN_PERSON: '1',
        AGE_RESTRICTED: '2'
    }

    const { dropoff_method, updateDropoffMethod, validateDropoffMethod, validation, deeleeoDetail } = props;
    const [dropoffMethodValue, setDropoffMethodvalue] = useState(dropoff_method);
    const [error, setError] = useState(validation);
    const [leaveAtDoorDisabled, setLeaveAtDoorDisabled] = useState(false);

    const optionHandle = (event) => {
        setDropoffMethodvalue(event.target.value);
    };

    useEffect(() => {
        setError(validation);
    }, [validation])

    useEffect(() => {
        updateDropoffMethod(dropoffMethodValue);

        if (!error) {
            validateDropoffMethod(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropoffMethodValue, updateDropoffMethod, validateDropoffMethod]);

    const shouldEnforceInPerson = (dropOffAddressType) => {

        switch (dropOffAddressType) {
            case 2:
            case 3:
            case 4:
                return true;
            default:
                return false;
        }

    }

    useEffect(() => {

        // console.log('deeleeoDetail.drop_off_address_type', deeleeoDetail.drop_off_address_type);

        let dropOffAddressType = -1;
        try {
            dropOffAddressType = parseInt(deeleeoDetail.drop_off_address_type);
        } catch (error) {

        }


        /**
         * if it's Business, School or hospital
         */
        if (shouldEnforceInPerson(dropOffAddressType)) {
            setDropoffMethodvalue(dropoffType.IN_PERSON);
            setLeaveAtDoorDisabled(true);
        } else {
            setDropoffMethodvalue(dropoffType.CONTACTLESS);
            setLeaveAtDoorDisabled(false);
        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deeleeoDetail.drop_off_address_type])

    return <div>
        <SubTitle className="mt-6 mb-1">
            Drop off Type *
        </SubTitle>

        {!error &&
            <p className='MuiFormHelperText-root Mui-error Mui-required text-errorRed text-xs'>
                Drop off Type Is Required
            </p>
        }

        <div className={!error ? 'border-2 border-errorRed' : ''}>
            <FormControl component="fieldset">
                <RadioGroup row value={dropoffMethodValue} onChange={optionHandle}>
                    <RadioOption isDisabled={leaveAtDoorDisabled} infoDetail={'Risk of theft, damage or otherwise is assumed by the customer. Drop off photo confirmation is still provided.'}
                        selectedValue={dropoffMethodValue} value={dropoffType.CONTACTLESS} label={'Leave At Door (Contactless)'} />
                    <RadioOption infoDetail={'This means you require someone present at drop off or else the delivery agent cannot drop off the item(s). If no one is present at drop off the delivery agent will attempt to contact you. If you do not respond it is possible that the delivery agent will return the item(s) to the pickup location and the Deeleeo will be canceled.'}
                        selectedValue={dropoffMethodValue} value={dropoffType.IN_PERSON} label={'In-Person'} />
                    <RadioOption infoDetail={'Delivery agent must check for a valid ID upon delivering this item. If none is provided, the item will be returned to the pickup loaction.'}
                        selectedValue={dropoffMethodValue} value={dropoffType.AGE_RESTRICTED} label={'Age Restricted'} />
                </RadioGroup>
            </FormControl>
        </div>
    </div>;
}

const mapStateToProps = (state) => ({
    dropoff_method: state.deeleeoReducer.dropoff_method,
    validation: state.validationReducer.dropoff_method,
    deeleeoDetail: state.deeleeoReducer,
});

const mapDispatchToProps = (dispatch) => ({
    updateDropoffMethod: (data) => dispatch(updateDropoffMethod(data)),
    validateDropoffMethod: (data) => dispatch(validateDropoffMethod(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DropoffTypeSection);
