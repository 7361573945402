import { createDateTimeString } from './dateHelpers'

export const checkForOkStatus = (response, errorMessage) => {
  if (response.status !== 200) {
    throw new Error(errorMessage)
  } else {
    return response.text()
  }
}

export const getEnumForType = (type) => {
  const TYPES = {
    REGULAR: 1,
    CUSTOM: 2,
    URGENT: 3,
    RUSH: 4
  }
  return TYPES[type]
}

export const getTypeFromEnum = (num) => {
  const TYPES = {
    1: 'SAME DAY',
    2: 'SCHEDULED',
    3: 'URGENT',
    4: 'RUSH',
  }
  return TYPES[num]
}

export const getStatusFromEnum = (num) => {
  const STATUS = {
    1: 'NEW',
    2: 'AWAITING DRIVER ACCEPT',
    3: 'ACCEPTED',
    4: 'STARTED',
    5: 'ENROUTE',
    6: 'DELIVERED',
    7: 'TRANSFERRING',
    8: 'CANCELED BY AGENT',
    9: 'CANCELED BY USER',
    10: 'CANCELED BY ADMIN',
    11: 'PAYMENT ERROR',
    12: 'RESTRICTED',
    13: 'HELP REQUEST',
    14: 'HELP REQUEST STARTED',
    15: 'HELP REQUEST COMPLETED',
    16: 'RETURNING',
    17: 'ON HOLD',
    18: 'RETURNED DEELEEO',
    19: 'CANCELED AT PICKED UP',
    50: 'PICKUP IN PROGRESS',
    51: 'IN TRANSIT'
  }
  return STATUS[num]
}


export const assembleRequestDataFromDeeleeo = (deeleeoInfo, apiCode) => {
  const selectedOptions = Object.keys(deeleeoInfo.package).filter(key => deeleeoInfo.package[key].selected === true)
  const deeleeoList = selectedOptions.map((option) => {

    // const pickupUnit = deeleeoInfo.senderInfo.unit;
    // const dropoffUnit = deeleeoInfo.recipientInfo.unit;

    /**
     * Somewhere an empty space is introduced in the address, this helps to remove it later
     */
    const fullPickUpAddress = `${deeleeoInfo.senderInfo.address}`;
    const fullDropoffAddress = `${deeleeoInfo.recipientInfo.address}`;

    return {
      dimension: option.toUpperCase(),
      // qty: deeleeoInfo.package[option].qty,
      qty: 1,
      api_key: apiCode,
      load_needs: deeleeoInfo.instructions.premiumOptions.special || deeleeoInfo.instructions.premiumOptions.loading,
      unload_needs: deeleeoInfo.instructions.premiumOptions.special || deeleeoInfo.instructions.premiumOptions.unloading || deeleeoInfo.instructions.premiumOptions.returning,
      pickup_early_datetime: createDateTimeString(deeleeoInfo.pickup.date, deeleeoInfo.pickup.windowStart),
      pickup_later_datetime: createDateTimeString(deeleeoInfo.pickup.date, deeleeoInfo.pickup.windowClose),
      dropoff_early_datetime: createDateTimeString(deeleeoInfo.dropoff.date, deeleeoInfo.pickup.windowStart),
      dropoff_later_datetime: createDateTimeString(deeleeoInfo.dropoff.date, deeleeoInfo.pickup.windowClose),
      pickup_address: fullPickUpAddress.trim(),//removing empty spaces
      dropoff_address: fullDropoffAddress.trim(),//removing empty spaces
      dropoff_type: getEnumForType(deeleeoInfo.dropoff.type),
      pickup_type: getEnumForType(deeleeoInfo.pickup.type)
    }
  })
  return { deeleeoList: deeleeoList }
}

// const determinePickupType = (type) => {
//   // eslint-disable-next-line default-case
//   switch (type) {
//     case "REGULAR":
//       return 1;
//     case "CUSTOM":
//       return 2;
//   }
// }

// const determineDropoffType = (type) => {
//   // eslint-disable-next-line default-case
//   switch (type) {
//     case "REGULAR":
//       return 1;
//     case "CUSTOM":
//       return 2;
//     case "URGENT":
//       return 3;
//     case "RUSH":
//       return 4;
//   }
// }

// const determineWeightAndSizeCategoryIDs = (deeleeoPackage) => {
//   let weight_category_id = 0;
//   let size_category_id = 0;
//   if (deeleeoPackage.large.selected) {
//     weight_category_id = 10;
//     size_category_id = 3;
//   } else if (deeleeoPackage.medium.selected) {
//     weight_category_id = 7;
//     size_category_id = 2;
//   } else if (deeleeoPackage.small.selected) {
//     weight_category_id = "2";
//     size_category_id = "1";
//   } else { // xlarge - figure out what to do with the weight. its deleted in db
//     weight_category_id = 10;
//     size_category_id = 2;
//   }

//   return [size_category_id, weight_category_id]
// }

export const formatPickupHoursFromRequest = (pickupHours) => {
  return Object.keys(pickupHours).reduce((acc, day) => {
    const open = pickupHours[day].open
    const close = pickupHours[day].close
    return { ...acc, [day]: { start: open.substr(0, open.length - 3), end: close.substr(0, open.length - 3) } }
  }, {})
}

export const calculateTotalForField = (field, dataArray) => {
  let searchField = 0.00
  dataArray.forEach((element) => {
    if (element && element[field]) {
      searchField += element[field]
    }
  })
  return searchField
}

export const addItemsAndImages = (element) => {


  let cloned = { ...element };

  /**
           * Append items
           */
  cloned.items = [{
    name: cloned.item_name,
    weight: 0,
    length: 0,
    width: 0,
    height: 0
  }];

  /**
   * Append images
   */
  cloned.images = [
    {
      // uuid: '',
      // key: '',
      // file_extension:'',
      using_placeholder: 1,
      is_main_image: 0
    }
  ];

  return cloned;
}

/**
   * Finds a deeleeo by uuid in the data collection
   * @param {*} uuid 
   * @param {*} data 
   * @returns 
   */
export const findDeeleeo = (uuid, data) => {
  return data.find(e => {
    return e.uuid === uuid;
  });
}