import React, { useEffect, useState } from 'react'
import { IconSubText, LeftSide, PageLink, RightSide, SignUpButton, TitleText, } from '../Register/components/shared'
import { Container, FieldContainer, Flex } from '../../components/containers'
import DeeleeoIcon from '../../assets/icons/deeleeo-reverse.svg'
import { routes } from '../../routes'
import FloatingInputField from '../../components/FloatingInputField'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { clearLoginError, login } from '../../redux/actions/authActions'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { LoginStatus } from '../../redux/reducers/authReducer'
import { useDebounce } from '../../hooks/useDebounce'
import AppStoreIcon from '../Register/components/AppStoreIcon'

function LoginPage(props) {
  const { login, history, loginError, loginStatus, clearError } = props
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('');


  const validationSchema = yup.object({
    email: yup.string().email('Enter a valid email address').required('Email is required'),
    password: yup.string().required('Password is required'),
  })




  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values => {
      clearError()
      setError('')
      const data = {
        username: values.email,
        password: values.password
      }
      login(data);
    })
  })

  const debouncedEmail = useDebounce(formik.values.email, 500)
  const debouncedPassword = useDebounce(formik.values.password, 500)

  useEffect(() => {
    clearError()
    setError('')
  }, [clearError, debouncedEmail, debouncedPassword])

  useEffect(() => {
    if (loginStatus === LoginStatus.LoginSuccess) {
      history.push(routes.dashboard)
    }
    if (loginStatus === LoginStatus.LoginError) {
      setError(loginError)
    }
  }, [formik.errors, history, loginError, loginStatus])

  return (
    <Container>
      <LeftSide>
        <Flex className='h-full justify-center pt-96'>
          <div>
            <img src={DeeleeoIcon} alt='Deeleeo Logo' />
            <IconSubText>Delivery simplified.</IconSubText>
          </div>
        </Flex>
      </LeftSide>

      <RightSide>
        <FieldContainer noMargin className='justify-center h-full' onSubmit={formik.handleSubmit}>
          <TitleText className='mb-16'>Log in to Deeleeo</TitleText>
          <FloatingInputField required id={'email'} label={'Email'}
            error={(formik.touched.email && Boolean(formik.errors.email)) || error !== ''}
            helperText={(formik.touched.email && formik.errors.email) || error}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange} />
          <FloatingInputField required inputType={'password'} id={'password'} label={'Password'}
            error={(formik.touched.password && Boolean(formik.errors.password)) || error !== ''}
            helperText={(formik.touched.password && formik.errors.password) || error}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            handleClickShowPassword={() => setShowPassword(!showPassword)} showPassword={showPassword} />
          <SignUpButton topMargin type='submit'>Log in</SignUpButton>
          <PageLink to={routes.forgotPassword} className='mt-4'>Forgot your password?</PageLink>
          <div className='mt-24'>Don't have an account with Deeleeo, <PageLink to={routes.registerAccountInfo}>sign up here!</PageLink></div>

          {/* Icon here */}
          <AppStoreIcon />

        </FieldContainer>
      </RightSide>
    </Container>
  )
}

const mapStateToProps = state => ({
  loginStatus: state.authReducer.loginStatus,
  loginError: state.authReducer.loginError
})

const mapDispatchToProps = dispatch => ({
  login: (requestData) => dispatch(login(requestData)),
  clearError: () => dispatch(clearLoginError())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage))
