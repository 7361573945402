import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { LoginStatus } from '../redux/reducers/authReducer'
import { routes } from '../routes'

function AuthRoute(props) {
  const { loginStatus, apiCode } = props
  if (loginStatus === LoginStatus.Logout || apiCode === '' ) return <Redirect to={routes.login}/>
  else return <Route {...props} />
}

const mapStateToProps = state => ({
  loginStatus: state.authReducer.loginStatus,
  apiCode: state.userReducer.apiCode
})

export default connect(mapStateToProps)(AuthRoute)
