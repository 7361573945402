export const routes = {
  login: '/login',
  forgotPassword: '/reset-password',
  registerAccountInfo: '/',
  registerPickupLocation: '/register/location',
  registerPickupHours: '/register/hours',
  shipment: '/shipment',
  choose:'/deeleeo/choose',
  createDeeleeo: '/deeleeo/details',
  createDeeleeoValet: '/deeleeo/valet',
  createOneToMany: '/deeleeo/createOneToMany',
  createManyToOne: '/deeleeo/createManyToOne',
  multiDeeleeo: '/deeleeo/multideeleeo',
  dashboard: '/dashboard',
  summary: '/deeleeo/summary',
  addPayment: '/addPayment',
  favourites:'/favouriteAgents',
  userAddress:'/userAddress'  
}
