import React from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import tw from 'twin.macro'
import { dashboardPageIndex, dashboardSearchDeeleeoStatus } from '../../../App'
import { getStatusFromEnum } from '../../../helpers/requestHelpers'
import { getStatusText } from './DeeleeoStatus'

const StyledSelect = styled.select`
  ${tw`text-fontBlue focus:outline-none appearance-none p-2 pr-6`}
  height: 36px;
  font-size: 13px;
  border: 1px solid #d9d9d9;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
`
const StyledLabel = styled.div`
  ${tw`absolute -mt-5 uppercase`}
  font-family: Varela Round;
  font-size: 10px;
  color: #8b8d99;
`


function StatusSelect(props) {
  // eslint-disable-next-line no-unused-vars
  const { setGlobalFilter, globalFilter, preFilteredRows } = props
  // eslint-disable-next-line no-unused-vars
  const [searchingStatus, setSearchingStatus] = useRecoilState(dashboardSearchDeeleeoStatus);
  // eslint-disable-next-line no-unused-vars
  const [dashboardIndex, setDasboardIndex] = useRecoilState(dashboardPageIndex);
  const options = React.useMemo(() => {
    const options = new Set()
    // preFilteredRows.forEach(row => {
    //   options.add(row.values.status[0])
    // })

    options.add(1);//New 
    options.add(5);//En route
    options.add(6);//Delivered
    options.add(8);//Cancelled by (Agent,user,admin)
    options.add(16);//Returned


    return [...options.values()]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFilteredRows])



  return (
    <div>
      <StyledLabel>Status</StyledLabel>
      <StyledSelect
        value={globalFilter}
        onChange={e => {
          /**
           * Sets the searching status to be used in the query
           */
          setSearchingStatus(e.target.value || undefined);
          /**
           * When triggering the search reset the page index to zero, 
           * after that, the table plugin takes care of going back and forward in the pages
           */
          setDasboardIndex(0);
          //setGlobalFilter(e.target.value || undefined)
        }}
      >
        <option value=''>All</option>
        {options.map((option, i) => (

          <option key={option} value={option}>
            {getStatusText(getStatusFromEnum(option))}
          </option>
        ))}
      </StyledSelect>
    </div>
  )
}

export default StatusSelect
