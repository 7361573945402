
export const getURI = (endpoint) => {
  if (window.location.host === "app-staging.deeleeo.com") {
    return `${process.env.REACT_APP_API_ORIGIN}${endpoint}`
  } else if (window.location.host === "app.deeleeo.com") {
    return `${process.env.REACT_APP_API_ORIGIN_PROD}${endpoint}`
  } else {
    return `http://192.168.56.10${endpoint}`
  }
}
