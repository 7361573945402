import React, { useEffect } from 'react';
import PageLayout from '../PageLayout';
import { Flex } from '../../components/containers';
import { AccentHeader, SectionHeader } from '../../components/text';
import { ChooseButton } from '../../components/buttons';
import { Divider } from '../Shipment/ShipmentDetails';
import tw from 'twin.macro';
import styled from 'styled-components';
import DeeleeoTabs from '../../components/DeeleeoTabs';
import { routes } from '../../routes';
import { useHistory, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createCookie, readCookie } from '../../helpers/cookieHelper';
import { useRecoilState } from 'recoil';
import { isInterCity, isVehicleSwap } from '../../App';
import { HtmlTooltip } from '../../components/InputField';
import InfoIcon from '../../assets/icons/Info-icon.svg';

// const HoverContent = styled.div`
//   ${tw`h-4 w-full px-4 py-3 text-fontBlue   `}
//   height: 140px;
//   border-radius:15px;
//   font-family: Varela Round;
//   font-size: 14px;
//   box-shadow: inset 0px 1px 12px rgba(0, 0, 0, 0.3);
// `;

const ChooseContainer = styled.div`
${tw`flex flex-col mx-auto items-center  pb-12`}
`;

const templateCookieName = 'newFile-1';
export const fileAlertText = 'A new Template is available for Deeleeo Batch uploading, which includes Package Count per delivery. Please use the new template to avoid errors in your submission. Thank you.';

function Choose(props) {
  const { userData } = props;
  let history = useHistory();




  // eslint-disable-next-line no-unused-vars
  const [interCity, setInterCity] = useRecoilState(isInterCity);
  // eslint-disable-next-line no-unused-vars
  const [vehicleSwap, setVehicleSwap] = useRecoilState(isVehicleSwap);


  useEffect(() => {

    const counts = readCookie(templateCookieName);
    if (counts === '') {
      createCookie(templateCookieName, 1, 360);
      alert(fileAlertText);
    } else {
      let displayedTimes = parseInt(counts);
      if (displayedTimes < 3) {
        displayedTimes++;
        createCookie(templateCookieName, displayedTimes, 360);
        alert(fileAlertText);
      }
    }
  }, []);


  const handleOneToManyClick = (e) => {

    const cookieName = 'newFileCreateOneToMany-1';

    const counts = readCookie(cookieName);
    if (counts === '') {
      createCookie(cookieName, 1, 360);
      alert(fileAlertText);
    } else {
      let displayedTimes = parseInt(counts);
      if (displayedTimes < 2) {
        displayedTimes++;
        createCookie(cookieName, displayedTimes, 360);
        alert(fileAlertText);
      }
    }

    history.push(routes.createOneToMany)

  }

  const handleManyToOneClick = () => {
    const cookieName = 'newFileCreateManyToOne-1';

    const counts = readCookie(cookieName);
    if (counts === '') {
      createCookie(cookieName, 1, 360);
      alert(fileAlertText);
    } else {
      let displayedTimes = parseInt(counts);
      if (displayedTimes < 2) {
        displayedTimes++;
        createCookie(cookieName, displayedTimes, 360);
        alert(fileAlertText);
      }
    }

    history.push(routes.createManyToOne);
  }

  const singleTooltip = <HtmlTooltip
    // style={{ marginLeft: 7 }}
    placement='top'
    title='Click here to submit a normal point to point (A to B) delivery. A ‘Deeleeo’ means ‘delivery’.'>
    <img
      style={{ display: 'inline' }}
      className='-mt-1 ml-1'
      src={InfoIcon}
      alt={'premium information'}
    />
  </HtmlTooltip>;

  const manyDropOffsTooltip = <HtmlTooltip
    style={{ display: 'inline' }}
    placement='top'
    title='Click here to submit multiple Deeleeos all coming from the same pickup location with multiple different drop off locations. All of the packages within this ‘batch’ must be within the same size category, have the same pickup and drop off time windows, and share the same premium options (if applied).'>
    <img
      style={{ display: 'inline' }}
      className='-mt-1 ml-1'
      src={InfoIcon}
      alt={'premium information'}
    />
  </HtmlTooltip>;

  const manyPickupsTooltip = <HtmlTooltip
    placement='top'
    title='Click here to submit multiple Deeleeos have multiple different pick up locations and all being dropped off at the same location. All of the packages within this ‘batch’ must be within the same size category, have the same pickup and drop off time windows, and share the same premium options (if applied).'>
    <img
      style={{ display: 'inline' }}
      className='-mt-1 ml-1'
      src={InfoIcon}
      alt={'premium information'}
    />
  </HtmlTooltip>;

  

  const vehicleServiceTooltip = <HtmlTooltip
    placement='top'
    title={'Use Deeleeo\'s professional and insured valet service to transport your vehicle(s), so you don\'t have to. Common use-cases include: staying at home/work while your vehicle gets an oil change/serviced, or taking your vehicle home after you drop yourself off at the airport, or you just need to move your vehicle from one place to another and you aren\'t able to coordinate or do it yourself at the moment.'}>
    <img
      style={{ display: 'inline' }}
      className='-mt-1 ml-1'
      src={InfoIcon}
      alt={'premium information'}
    />
  </HtmlTooltip>;

  const valetTooltip = <HtmlTooltip
    placement='top'
    title='A true valet requires two people. Use this button to schedule a valet where you need your vehicle moved from one location to another. An additional valet will need to be scheduled to get the vehicle back to the original location, if necessary.'>
    <img
      style={{ display: 'inline' }}
      className='-mt-1 ml-1'
      src={InfoIcon}
      alt={'premium information'}
    />
  </HtmlTooltip>;

  const vehicleSwapTooltip = <HtmlTooltip
    placement='top'
    title='Use this service when you need Deeleeo to exchange/swap vehicles at a specific location. An example would include be a rental or loaner pickup/drop off. Please note: we use the "Pickup Location" information as the start and end of this service. And the "Drop off Location" information is where the vehicle exchange/swap takes place.'>
    <img
      style={{ display: 'inline' }}
      className='-mt-1 ml-1'
      src={InfoIcon}
      alt={'premium information'}
    />
  </HtmlTooltip>;


  return (
    <PageLayout wider>
      <DeeleeoTabs selectedTab={1} />
      <Divider />
      <ChooseContainer>

        <AccentHeader className='mb-20'>What’s the Deeleeo?
          {/* <Button onClick={() => setInterCity(!interCity)} className='w-1/4 ml-20'>{interCity ? 'Local Delivery' : 'Intercity Deeleeo'}</Button> */}
        </AccentHeader>


        <Flex className='w-full'>

          {/* Fisrt Column */}
          <Flex className='w-1/3 flex-col items-center'>
            <SectionHeader style={{ height: '2em' }}>Local Delivery: </SectionHeader>
            <ChooseButton
              className='w-3/4'
              style={{ height: 'auto', paddingBottom: '1rem' }}
              onClick={() => {
                setInterCity(false);
                history.push(routes.createDeeleeo)
              }}
            >
              Single Deeleeo
              {singleTooltip}
            </ChooseButton>
            <ChooseButton
              className='w-3/4'
              style={{ height: 'auto', paddingBottom: '1rem' }}
              onClick={
                () => {
                  setInterCity(false);
                  handleOneToManyClick();
                }
              }
            >
              <div>Deeleeo Batch</div>
              <span>(Many Drop Offs)</span>
              {manyDropOffsTooltip}
            </ChooseButton>
            {/* </div> */}
            {/* <div className=' flex w-1/2 justify-center items-center '> */}
            <ChooseButton
              className='w-3/4'
              style={{ height: 'auto', paddingBottom: '1rem' }}
              onClick={
                () => {
                  setInterCity(false);
                  handleManyToOneClick();
                }
              }
            >
              <div>Deeleeo Batch</div>
              <span>(Many Pickups)</span>
              {manyPickupsTooltip}
            </ChooseButton>
          </Flex>

          {/* Second column */}
          <Flex className='w-1/3 flex-col  items-center'>
            <SectionHeader style={{ height: '2em' }}>Intercity Delivery: (Edmonton/Red Deer/Calgary)</SectionHeader>

            <ChooseButton
              className='w-3/4'
              style={{ height: 'auto', paddingBottom: '1rem' }}
              onClick={() => {
                setInterCity(true);
                history.push(routes.createDeeleeo)
              }}>
              Single Deeleeo
              {singleTooltip}
            </ChooseButton>
            <ChooseButton
              className='w-3/4'
              style={{ height: 'auto', paddingBottom: '1rem' }}
              onClick={
                () => {
                  setInterCity(true);
                  handleOneToManyClick();
                }
              }>
              <div>Deeleeo Batch</div>
              <span>(Many Drop Offs)</span>
              {manyDropOffsTooltip}
            </ChooseButton>
            <ChooseButton
              className='w-3/4'
              style={{ height: 'auto', paddingBottom: '1rem' }}
              onClick={
                () => {
                  setInterCity(true);
                  handleManyToOneClick();
                }
              }>
              <div>Deeleeo Batch</div>
              <span>(Many Pickups)</span>
              {manyPickupsTooltip}
            </ChooseButton>
          </Flex>
          {userData.isValetEnabled && <Flex className='w-1/3 flex-col  items-center'>
            <SectionHeader style={{ height: '2em' }}>Valet Service{ vehicleServiceTooltip}</SectionHeader>

            <ChooseButton
              className='w-3/4'
              style={{ height: 'auto', paddingBottom: '1rem' }}
              onClick={() => {
                setVehicleSwap(false);
                history.push(routes.createDeeleeoValet);
              }}>
              Deeleeo Valet
              {valetTooltip}
            </ChooseButton>

            <ChooseButton
              className='w-3/4'
              style={{ height: 'auto', paddingBottom: '1rem' }}
              onClick={() => {
                setVehicleSwap(true);
                history.push(routes.createDeeleeoValet);
              }}>
              Vehicle Swap
              {vehicleSwapTooltip}
            </ChooseButton>


          </Flex>}

        </Flex>

        <Flex>
          <p style={{ padding: 10 }}>Please contact the Deeleeo Support team at <a href='mailto:support@deeleeo.com' style={{ color: 'blue', fontWeight: 'bold' }}>support@deeleeo.com</a> or <a href='tel:1-825-255-5778' style={{ color: 'blue', fontWeight: 'bold' }}>1-825-255-5778</a> for any questions or to learn how to get the most out of Deeleeo.</p>
        </Flex>
        <br />
        <br />
        <Flex>
          {/* {single && <HoverContent>
            <p style={{ fontSize: 17 }}>Click here to submit a normal point to point (A to B) delivery. A ‘Deeleeo’ means ‘delivery’.</p>
          </HoverContent>} */}
          {/* {oneToMany && <HoverContent>
            <p style={{ fontSize: 17 }}>Click here to submit multiple Deeleeos all coming from the same pickup location with multiple different drop off locations. All of the packages within this ‘batch’ must be within the same size category, have the same pickup and drop off time windows, and share the same premium options (if applied).</p>
          </HoverContent>} */}
          {/* {manyToOne && <HoverContent>
            <p style={{ fontSize: 17 }}>Click here to submit multiple Deeleeos have multiple different pick up locations and all being dropped off at the same location. All of the packages within this ‘batch’ must be within the same size category, have the same pickup and drop off time windows, and share the same premium options (if applied).</p>
          </HoverContent>} */}
          {/* {valet && <HoverContent>
            <p style={{ fontSize: 17 }}>“Deeleeo Valet” is a service that will drive your vehicle from one location to another for you. Be sure to give clear and specific instructions in the ‘Valet Instructions’ text box.</p>
          </HoverContent>} */}
        </Flex>
      </ChooseContainer>
    </PageLayout>
  );
}

const mapStateToProps = state => ({
  userData: state.userReducer
})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Choose))
