import moment from "moment";

const initState = {
    pickup_data_validation: true,
    dropoff_data_validation: true,
    item_name: true,
    vin: true,
    size_weight_selection: true,
    dropoff_method: true,
    description: true,
    pickup_time: {
        type: true,
        date: true,
        window_time: true,
        window_time_error_text: ''
    },
    dropoff_time: {
        type: true,
        date: true,
        window_time: true,
        window_time_error_text: ''
    },
    refrigeration: true,
    package_count:true

}

const VALIDATED_PICKUP_DATA = 'VALIDATED_PICKUP_DATA';
const VALIDATED_DROPOFF_DATA = 'VALIDATED_DROPOFF_DATA';
const VALIDATED_ITEM_NAME = 'VALIDATED_ITEM_NAME';
const VALIDATED_VIN = 'VALIDATED_VIN';
const VALIDATED_SIZE_WEIGHT_SELECTION = 'VALIDATED_SIZE_WEIGHT_SELECTION';
const VALIDATED_PACKAGE_COUNT = 'VALIDATED_PACKAGE_COUNT';
const VALIDATED_DROPOFF_METHOD = 'VALIDATED_DROPOFF_METHOD';
const VALIDATED_DESCRIPTION = 'VALIDATED_DESCRIPTION';
const VALIDATED_PICKUP_TIME = 'VALIDATED_PICKUP_TIME';
const VALIDATED_DROPOFF_TIME = 'VALIDATED_DROPOFF_TIME';
const VALIDATED_PICKUP_WINDOW_TIME = 'VALIDATED_PICKUP_WINDOW_TIME';
const VALIDATED_DROPOFF_WINDOW_TIME = 'VALIDATED_DROPOFF_WINDOW_TIME';
const RESET_VALIDATION_STATUS = 'RESET_VALIDATION_STATUS';


export const validatePackageCount = (data) => ({
    type: VALIDATED_PACKAGE_COUNT,
    data: data
})

export const validateItemName = (data) => ({
    type: VALIDATED_ITEM_NAME,
    data: data
})


export const validateVin = (data) => ({
    type: VALIDATED_VIN,
    data: data
})

export const validateSizeWeightSelection = (data) => ({
    type: VALIDATED_SIZE_WEIGHT_SELECTION,
    data: data
})

export const validateDropoffMethod = (data) => ({
    type: VALIDATED_DROPOFF_METHOD,
    data: data
})

export const validateDescription = (data) => ({
    type: VALIDATED_DESCRIPTION,
    data: data
})

export const validatePickupTime = (data) => ({
    type: VALIDATED_PICKUP_TIME,
    data: {
        ...initState.pickup_time,
        type: (data.pickup_type === '' ? false : true),
        date: (data.pickup_date === '' ? false : true),
        window_time: (data.pickup_early_datetime === '' || data.pickup_later_datetime === '' ? false : true)
    }
})

export const validatePickupTimeFromAll = (data) => ({
    type: VALIDATED_PICKUP_TIME,
    data: data
})

export const validateDropoffTime = (data) => ({
    type: VALIDATED_DROPOFF_TIME,
    data: {
        ...initState.dropoff_time,
        type: (data.dropoff_type === '' ? false : true),
        date: (data.dropoff_date === '' ? false : true),
        window_time: (data.dropoff_early_datetime === '' || data.dropoff_later_datetime === '' ? false : true)
    }
})

export const validateDropoffTimeFromAll = (data) => ({
    type: VALIDATED_DROPOFF_TIME,
    data: data
})

export const validatedPickupData = (data) => ({
    type: VALIDATED_PICKUP_DATA,
    data: data
})

export const validatedDropoffData = (data) => ({
    type: VALIDATED_DROPOFF_DATA,
    data: data
})

const validatedPickupWindowTime = (data, error_content) => ({
    type: VALIDATED_PICKUP_WINDOW_TIME,
    data: data,
    content: error_content
})

const validatedDropoffWindowTime = (data, error_content) => ({
    type: VALIDATED_DROPOFF_WINDOW_TIME,
    data: data,
    content: error_content
})

export const resetValidationStatus = () => ({
    type: RESET_VALIDATION_STATUS
})

export const updateValidationStatus = (data) => dispatch => {
    // console.log('updateValidationStatus', data.vin);

    dispatch(validateItemName(data.item_name));
    dispatch(validateVin(data.vin));
    dispatch(validateSizeWeightSelection(data.size_weight_selection));
    dispatch(validateDropoffMethod(data.dropoff_method));
    dispatch(validateDescription(data.description));

    dispatch(validatePickupTimeFromAll(data.pickup_time));
    dispatch(validateDropoffTimeFromAll(data.dropoff_time));

    dispatch(validatedPickupData(data.pickup_data_validation));
    dispatch(validatedDropoffData(data.dropoff_data_validation));

    dispatch(validatedPickupWindowTime(data.pickup_time.window_time, data.pickup_time.window_time_error_text))
    dispatch(validatedDropoffWindowTime(data.dropoff_time.window_time, data.dropoff_time.window_time_error_text))
}

export const validatePickupWindowTime = (deeleeo) => dispatch => {

    if (deeleeo.pickup_early_datetime === '' || deeleeo.pickup_later_datetime === '') {
        /// false
        dispatch(validatedPickupWindowTime(false, 'Please Check Your Time'));
    } else {
        let early_time = new Date(deeleeo.pickup_date + ' ' + deeleeo.pickup_early_datetime);
        let later_time = new Date(deeleeo.pickup_date + ' ' + deeleeo.pickup_later_datetime);

        let minute_diff = Math.abs(Math.round(((later_time.getTime() - early_time.getTime()) / 1000) / 60));
        // console.log('minutes difference');
        // console.log(minute_diff);
        if (minute_diff < 120 && deeleeo.pickup_type !== '3') {
            //// false
            dispatch(validatedPickupWindowTime(false, 'Minimum Time Is 2 Hours'));
        } else {
            /// true
            dispatch(validatedPickupWindowTime(true, ''));
        }
    }
}

export const validateDropoffWindowTime = (deeleeo) => dispatch => {

    if (deeleeo.dropoff_early_datetime === '' || deeleeo.dropoff_later_datetime === '') {
        /// false
        dispatch(validatedDropoffWindowTime(false, 'Please Check Your Time'));
    } else {
        let early_time = moment(deeleeo.dropoff_date);
        const splitEarly = deeleeo.dropoff_early_datetime.split(':');
        early_time.set({
            hours: parseInt(splitEarly[0]),
            minutes: parseInt(splitEarly[1]),

        });


        let later_time = moment(deeleeo.dropoff_date);
        const splitLate = deeleeo.dropoff_later_datetime.split(':');
        later_time.set({
            hours: parseInt(splitLate[0]),
            minutes: parseInt(splitLate[1]),

        });


        let minute_diff = early_time.diff(later_time, 'minutes');

        // console.log('minutes difference');
        // console.log(minute_diff);
        if (minute_diff < 60) {
            //// false
            dispatch(validatedDropoffWindowTime(false, 'Minimum Time Is 1 Hours'));
        } else {
            /// true
            dispatch(validatedDropoffWindowTime(true, ''));
        }
    }
}

export const validationReducer = (state = initState, action) => {
    switch (action.type) {
        case VALIDATED_PICKUP_DATA:
            return {
                ...state,
                pickup_data_validation: action.data
            }
        case VALIDATED_DROPOFF_DATA:
            return {
                ...state,
                dropoff_data_validation: action.data
            }
        case VALIDATED_VIN:
            return {
                ...state,
                vin: action.data
            }
        case VALIDATED_ITEM_NAME:
            return {
                ...state,
                item_name: action.data
            }
        case VALIDATED_SIZE_WEIGHT_SELECTION:
            return {
                ...state,
                size_weight_selection: action.data
            }
        case VALIDATED_DROPOFF_METHOD:
            return {
                ...state,
                dropoff_method: action.data
            }
        case VALIDATED_DESCRIPTION:
            return {
                ...state,
                description: action.data
            }
        case VALIDATED_PICKUP_TIME:
            return {
                ...state,
                pickup_time: action.data
            }
        case VALIDATED_DROPOFF_TIME:
            return {
                ...state,
                dropoff_time: action.data
            }
        case VALIDATED_PICKUP_WINDOW_TIME:
            return {
                ...state,
                pickup_time: {
                    ...state.pickup_time,
                    window_time: action.data,
                    window_time_error_text: action.content
                }
            }
        case VALIDATED_DROPOFF_WINDOW_TIME:
            return {
                ...state,
                dropoff_time: {
                    ...state.dropoff_time,
                    window_time: action.data,
                    window_time_error_text: action.content
                }
            }
        case VALIDATED_PACKAGE_COUNT:
            return {
                ...state,
                package_count: action.data
            }
        case RESET_VALIDATION_STATUS:
            return {
                ...initState
            }
        default:
            return state
    }
}