import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import tw from "twin.macro";
import { connect } from "react-redux";

import { updateOptions } from '../../../redux/reducers/deeleeoReducer'
import axios from "axios";
import { getURI } from "../../../helpers/apiHelpers";
import { FormControl, FormGroup, InputLabel, MenuItem, Select } from "@material-ui/core";
import { StyledToolTip } from "../../../components/What3WordsField";



// const StyledSuggestionList = styled.ul`
//   ${tw`absolute mt-14 bg-white w-full px-4 py-2 shadow-lg z-10`}
// `

// const StyledListItem = styled.li`
//   ${tw`cursor-pointer my-1`}
// `







const MyFleetSection = (props) => {
    const { updateOptions, options, userData } = props;
    const [optionsValue, setOptionsValue] = useState(options);
    const [myFleetDrivers, setMyFleetDrivers] = useState([]);

    // console.log(optionsValue);
    

    useEffect(() => {
        if (userData.isMyFleetEnabled) {
         
                axios.get(getURI(`/api/web/my-fleet?api_code=${userData.apiCode}`))
                // axios.get(getURI(`/api/web/my-fleet?api_code=bb80bf8b-9b29-20f0-37c8-0fd3d09950`))
                    .then(data => {
                        setMyFleetDrivers(data.data.data.drivers);
                    })
                    .catch(error => {
                        console.log(error);
                    });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myFleetDrivers.length])


    const selectorHandle = (event) => {

        // eslint-disable-next-line no-useless-computed-key
        setOptionsValue({ ...optionsValue, ['assign_my_fleet_driver']: event.target.value })
        
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            updateOptions(optionsValue)
        }, 500);
        return () => clearTimeout(timeout);
    }, [optionsValue, updateOptions]);



    const renderSuggestions = () => {


        if (myFleetDrivers && myFleetDrivers.length > 0) {
            return myFleetDrivers.map((suggestion) => {
                //console.log(suggestion);

                const {
                    id,
                    name
                } = suggestion;



                return (
                    <MenuItem value={id}><strong>{name}</strong></MenuItem>
                );
            });
        } else {
            return null;
        }
    }


    const infoDetail = `Use this option to submit your Deeleeos directly to your "My Fleet Drivers"`;


    const toRender =
        <div style={{width:250, marginTop: 40, marginLeft: 100 }}>
            <FormGroup aria-label="position" row>
                <StyledToolTip
                    title={
                        <div>
                            {infoDetail}
                        </div>
                    }
                    placement={"top"} arrow>
                    <>
                        <FormControl fullWidth>
                            <InputLabel id="myFleetDriver"  >My Fleet Driver</InputLabel>
                            <Select
                                labelId="myFleetDriver"
                                id="demo-simple-select"
                                 value={optionsValue.assign_my_fleet_driver}
                                label="Age"
                                onChange={selectorHandle}
                            >
                                {renderSuggestions()}
                            </Select>
                        </FormControl>
                    </>
                </StyledToolTip>
            </FormGroup>
        </div>;


    const finalRender = myFleetDrivers.length > 0 ? toRender : <></>;

    return (
        finalRender
    );

}

const mapStateToProps = (state) => ({
    options: state.deeleeoReducer.assign_my_fleet_driver,
    userData: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
    updateOptions: (options) => dispatch(updateOptions(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyFleetSection);
